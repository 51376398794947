import {useContext} from 'react'
import {useSelector} from 'react-redux'
import {getOrganizationNameFromType} from 'helpers/organization'
import {useAuth} from 'hooks/useAuth'
import {useEntity} from 'hooks/useEntity'
import {BOOKING_BASE_PATH, BOOKING_PATHS} from 'options/booking'
import type {RootStore} from 'store/types'
import {compact} from 'utils'
import {AppNavigationContext} from './context'

export const useAppNavigation = () => {
  const {maximized} = useContext(AppNavigationContext)

  return {
    maximized,
  }
}

export const useCurrentBookingViewPath = () => {
  const currentBookingView = useSelector<RootStore, string>(
    (store) => store.booking.shared.view
  )
  return `/${BOOKING_BASE_PATH}/${BOOKING_PATHS[currentBookingView]}`
}

export const useUserMenu = () => {
  const {user, organization} = useAuth()

  return {
    userName: compact([
      user?.business_card.given_name,
      user?.business_card.family_name,
    ]).join(' '),
    userTitle: user?.business_card.title,
    organizationName: organization?.name ?? '',
    organizationType: organization
      ? getOrganizationNameFromType(organization.type)
      : '',
  }
}

export const useOpenBookingAgency = () => {
  const {openEntity} = useEntity()
  const {organization} = useAuth()
  const bookingAgency = organization?.departments.find(
    (department) => department.type === 'BOOKING_AGENCY'
  )

  return {
    canOpenBookingAgency: true,
    openBookingAgency: () => {
      if (bookingAgency) {
        openEntity(bookingAgency.id, 'BOOKING_AGENCY')
      }
    },
  }
}

export const useOrganizationSwitcher = () => {
  const {user, switchUserContext} = useAuth()

  const bookingAgencies =
    user?.affiliations.filter(
      (a) => a.organization.type === 'ORGANIZATION_TYPE_BOOKING_AGENCY'
    ) ?? []

  return {
    bookingAgencies,
    switchBookingAgency: switchUserContext,
  }
}
