import React from 'react'
import {startOfMonth} from 'date-fns'
import {useDispatch} from 'react-redux'
import {MonthRangePicker as MonthRangePickerComp} from 'components/elements'
import {setStartMonth, setEndMonth} from 'store/booking/actions'
import {formatDate} from 'utils'
import {useData} from './MonthPicker'

interface MonthRangePickerProps {
  allowForever?: boolean
}

export const MonthRangePicker = ({allowForever}: MonthRangePickerProps) => {
  const dispatch = useDispatch()

  const {view, data} = useData()

  if (!data) {
    return null
  }

  if (!('startMonth' in data)) {
    return null
  }
  if (!('endMonth' in data)) {
    return null
  }

  const startMonth = startOfMonth(data.startMonth)
  const endMonth = data.endMonth === null ? null : startOfMonth(data.endMonth)

  const handleChangeStart = (value: string | number | Date | null) => {
    if (!(value instanceof Date)) {
      return
    }
    dispatch(
      setStartMonth({
        month: formatDate(value, 'ISOYearMonth'),
        view: view,
      })
    )
  }

  const handleChangeEnd = (value: string | number | Date | null) => {
    let month: string | null | undefined

    if (value instanceof Date || value === null) {
      if (allowForever && value === null) {
        month = null
      } else if (value !== null) {
        month = formatDate(value, 'ISOYearMonth')
      }

      if (month === undefined) {
        return
      }

      dispatch(
        setEndMonth({
          month: month,
          view: view,
        })
      )
    }
  }

  return (
    <MonthRangePickerComp
      valueStart={startMonth}
      valueEnd={endMonth}
      onChangeStart={handleChangeStart}
      onChangeEnd={handleChangeEnd}
      allowForever={allowForever}
    />
  )
}
