import type {ReactElement} from 'react'
import {useCallback} from 'react'
import type {PayloadAction} from '@reduxjs/toolkit'
import {captureException} from '@sentry/react'
import type {FormikHelpers, FormikState, FormikValues} from 'formik'
import {FormikProvider, useFormik} from 'formik'
import {get} from 'lodash'
import {reach} from 'yup'
import type {Test} from 'yup/lib/util/createValidation'
import {useAppDispatch} from 'hooks/store'
import {submitFormAction} from 'store/form'
import {traverseErrors} from './helpers'

interface PromiseFormProps<Values, SuccessResponse> {
  onSubmit:
    | ((values: Values) => Promise<SuccessResponse>)
    | ((values: Values) => SuccessResponse)
}

interface ActionFormProps {
  action?: string
}

interface SharedFormProps<Values, SuccessResponse, ErrorResponse> {
  onSubmitSuccess?: (response: SuccessResponse) => void
  onSubmitFailure?: (response: ErrorResponse) => void
  initialValues: Values
  validationSchema?: any
  enableReinitialize?: boolean
  children: (values: FormikState<Values>) => React.ReactElement
  className?: string
}

export type FormProps<Values, SuccessResponse, ErrorResponse> =
  | (PromiseFormProps<Values, SuccessResponse> &
      SharedFormProps<Values, SuccessResponse, ErrorResponse>)
  | (ActionFormProps & SharedFormProps<Values, SuccessResponse, ErrorResponse>)

export const Form = <
  Values extends FormikValues,
  SuccessResponse,
  ErrorResponse
>({
  initialValues,
  validationSchema,
  children,
  enableReinitialize,
  onSubmitSuccess,
  onSubmitFailure,
  className,
  ...submission
}: FormProps<Values, SuccessResponse, ErrorResponse>): ReactElement | null => {
  const dispatch = useAppDispatch()

  const handleFormSubmission = useCallback(
    (values: Values, actions: FormikHelpers<Values>) => {
      const processedValues = validationSchema
        ? validationSchema.cast(values)
        : values

      let submitter
      if ('action' in submission) {
        submitter = dispatch(
          submitFormAction(processedValues, {action: submission.action})
        ) as unknown as Promise<PayloadAction<SuccessResponse>>
      } else if ('onSubmit' in submission) {
        const {onSubmit} = submission

        submitter = new Promise<SuccessResponse>((resolve, reject) => {
          try {
            const response = onSubmit(processedValues)
            resolve(response)
          } catch (e: any) {
            reject(e)
          }
        }).then((payload) => ({payload} as PayloadAction<SuccessResponse>))
      }

      if (!submitter || !('then' in submitter)) {
        actions.setSubmitting(false)
        return
      }

      submitter.then(
        (successResponse: PayloadAction<SuccessResponse>) => {
          actions.setSubmitting(false)
          if (onSubmitSuccess) {
            onSubmitSuccess(successResponse.payload)
          }
        },
        (errorResponse: ErrorResponse) => {
          actions.setSubmitting(false)
          if (onSubmitFailure) {
            onSubmitFailure(errorResponse)
          }
          actions.setErrors(traverseErrors(errorResponse))
        }
      )
    },
    [submission, dispatch, onSubmitFailure, onSubmitSuccess, validationSchema]
  )

  const {handleSubmit, errors, ...formikProps} = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleFormSubmission,
    enableReinitialize,
    validateOnBlur: true,
  })

  const isFieldRequired = useCallback(
    (model: string, value: unknown, values: unknown) => {
      try {
        // https://github.com/jquense/yup/issues/1041#issuecomment-694269871

        if (!validationSchema) {
          return
        }

        const path = model.split('.')
        const parentPath = path.slice(0, -1).join('.')
        const parent = path.length > 1 ? get(values, parentPath) : values

        const schema = reach(validationSchema, model)

        if (!schema) {
          return
        }

        const description = schema
          .resolve({
            value: value,
            parent: parent,
          })
          .describe()

        if (!description) {
          return
        }

        const tests = description.tests

        if (!tests) {
          return
        }

        return tests.some((test: Test) => test.name === 'required')
      } catch (error: unknown) {
        if (
          error instanceof Error &&
          error.message?.includes('The schema does not contain the path')
        ) {
          // if (process.env.NODE_ENV === 'development') {
          //   console.info(`Schema path not defined: '${model}'`)
          // }
          return false
        } else if (
          error instanceof Error &&
          error.message.includes('cannot resolve an array item')
        ) {
          return false
        } else {
          captureException(error)
        }
      }
    },
    [validationSchema]
  )

  if (!initialValues) {
    return null
  }

  const contextValues = {
    ...formikProps,
    handleSubmit,
    errors,
    isFieldRequired,
  }

  return (
    <FormikProvider value={contextValues}>
      <form className={className} onSubmit={handleSubmit}>
        {children({...formikProps, errors})}
      </form>
    </FormikProvider>
  )
}
