import styled from 'styled-components'
import {Select as SelectComp} from 'components/elements'
import {colors, fonts, spacing} from 'styles/variables'

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
`

export const Buttons = styled.div`
  display: flex;
  gap: ${spacing.u2_5};
`

export const Select = styled(SelectComp)`
  width: 200px;
`

export const Filters = styled.div`
  margin: ${spacing.u5};
  padding: ${spacing.u5};
  background: ${colors.springWood};
  border: 1px solid ${colors.alto};
  display: grid;
  gap: ${spacing.u2_5};
  justify-items: start;
`

export const Filter = styled.div`
  display: grid;

  label {
    font-family: ${fonts.fontBold};
    color: ${colors.secondaryTextColor};
  }
`

export const Content = styled.div`
  padding: ${spacing.u5};
  overflow-y: auto;
`

export const Controls = styled.fieldset`
  padding: ${spacing.u5};
  border: 1px solid ${colors.alto};
`
