import {map} from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import type {TooltipPosition} from 'components/elements'
import {Tooltip} from 'components/elements'
import {useEntity} from 'hooks/useEntity'
import {highlightTour} from 'store/planner/actions'
import type {RootStore} from 'store/types'
import {colors, effects} from 'styles/variables'
import type {PlannerEvent} from 'types/planner'

interface ToursContainerProps {
  dots?: boolean
}
const ToursContainer = styled.div<ToursContainerProps>`
  display: flex;
`

interface TourItemContainerProps {
  highlighted: boolean
  dot?: boolean
}
const TourItemContainer = styled.div<TourItemContainerProps>`
  background: ${colors.gossamer};
  opacity: 0.4;
  transition: width ${effects.transitionTime}, opacity ${effects.transitionTime};

  &:not(:first-child) {
    ${({dot}) =>
      dot
        ? `
      margin-left: 6px;`
        : `
      border-left: 1px solid white;`}
  }

  ${({dot}) =>
    dot
      ? `
    width: 12px;
    height: 12px;
    border-radius: 999px;`
      : `
    width: 6px;
    height: 100%;`}

  ${({highlighted, dot}) =>
    highlighted &&
    `
    opacity: ${dot ? 1 : 0.6};
    width: ${dot ? 32 : 24}px;`}
`

const TooltipAction = styled.div`
  color: ${colors.secondaryTextColor};
`

const useTourItem = (id: number) => {
  const dispatch = useDispatch()
  const {openTour} = useEntity()
  const highlightedTour = useSelector<RootStore, number | null>(
    ({listPlanner}) => listPlanner.highligtedTour
  )

  return {
    openTour: () => openTour(id),
    highlighted: id === highlightedTour,
    setHighlight: () => dispatch(highlightTour(id)),
    unsetHighlight: () => dispatch(highlightTour(null)),
  }
}
interface TourItemProps {
  tooltipPosition?: TooltipPosition
  id: number
  text: string
  dots?: boolean
}

const TourItem: React.FC<React.PropsWithChildren<TourItemProps>> = ({
  id,
  text,
  tooltipPosition,
  dots,
}) => {
  const {openTour, highlighted, setHighlight, unsetHighlight} = useTourItem(id)

  return (
    <Tooltip
      content={
        <div>
          <div>{text || 'No name'}</div>
          <TooltipAction>Click to edit</TooltipAction>
        </div>
      }
      position={tooltipPosition}
    >
      <TourItemContainer
        highlighted={highlighted}
        dot={dots}
        onMouseEnter={setHighlight}
        onMouseLeave={unsetHighlight}
        onClick={openTour}
      />
    </Tooltip>
  )
}

interface ToursProps {
  data: PlannerEvent[]
  dots?: boolean
}

export const Tours = ({data, dots}: ToursProps) => {
  if (!data || !data.length) {
    return null
  }

  return (
    <ToursContainer>
      {map(data, (tour) => (
        <TourItem
          key={tour.id}
          id={tour.id}
          text={tour.text ?? ''}
          tooltipPosition={dots ? 'top' : 'left'}
          dots={dots}
        />
      ))}
    </ToursContainer>
  )
}
