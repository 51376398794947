import {useState} from 'react'
import styled from 'styled-components'
import {Button} from 'components/elements'
import type {EmailRecipient} from 'store/email/types'
import {colors, spacing} from 'styles/variables'
import {RecipientsSelector} from './RecipientsSelector'

interface RowProps {
  hasError?: boolean
}

const Row = styled.div<RowProps>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 32px;
  align-items: center;
  grid-gap: 0 ${spacing.u2_5};

  ${({hasError}) =>
    hasError &&
    `
    color: ${colors.crimson};
    background-color: ${colors.pippin};`}
`

const Title = styled.div`
  color: ${colors.secondaryTextColor};
`

const Actions = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0 ${spacing.u1_5};
`

interface RecipientsProps {
  value: EmailRecipient[]
  onChange: (value: any) => void
  options: EmailRecipient[]
  error?: string
}

export const Recipients: React.FC<React.PropsWithChildren<RecipientsProps>> = ({
  value,
  onChange,
  options,
  error,
}) => {
  const [showCc, setShowCc] = useState(false)
  const [showBcc, setShowBcc] = useState(false)
  const [mockCc, setMockCc] = useState([])
  const [mockBcc, setMockBcc] = useState([])

  const toggleCc = () => setShowCc(!showCc)
  const toggleBcc = () => setShowBcc(!showBcc)
  const handleCc = (value: any) => setMockCc(value)
  const handleBcc = (value: any) => setMockBcc(value)

  return (
    <div>
      <Row>
        <Title>To</Title>
        <RecipientsSelector
          value={value}
          options={options}
          onChange={onChange}
          hasError={!!error}
        />
        {false && (
          <Actions>
            <Button onClick={toggleCc} active={showCc}>
              Cc
            </Button>
            <Button onClick={toggleBcc} active={showBcc}>
              Bcc
            </Button>
          </Actions>
        )}
      </Row>

      {showCc && (
        <Row>
          <Title>Cc</Title>
          <RecipientsSelector
            value={mockCc}
            options={options}
            onChange={handleCc}
          />
        </Row>
      )}

      {showBcc && (
        <Row>
          <Title>Bcc</Title>
          <RecipientsSelector
            value={mockBcc}
            options={options}
            onChange={handleBcc}
          />
        </Row>
      )}
    </div>
  )
}
