import {useFormikContext} from 'formik'
import {Input} from 'components/form'
import {
  Section,
  Row,
  Column,
  Cell,
  Spacer,
  InlineText,
  InlineSpacer,
} from 'containers/Form'
import type {Contract} from 'types/contract'
import styles from './Tickets.module.css'
import {IsSeated, VenueCapacity} from '../fields'
import type {ContractPanelProps} from '../panels/types'

export const Capacities: React.FC<
  React.PropsWithChildren<ContractPanelProps>
> = ({locked}) => {
  const {values} = useFormikContext<Contract>()

  const ticketsSum = (values.show.tickets ?? []).reduce((sum, line) => {
    return sum + ((line && line.capacity) || 0)
  }, 0)

  const sum =
    (values.show.stage_capacity ?? 0) -
    ticketsSum -
    (values.show.guest_list_capacity ?? 0)

  return (
    <Section label='Capacities'>
      <Row>
        <Column>
          <Input
            model='show.stage_capacity'
            disabled={locked}
            label='Stage capacity'
            kind='number'
            min='0'
            max='999999999'
            step='1'
            grid='8'
          />
          <VenueCapacity grid='fill' />
          <IsSeated
            model='show.is_seated'
            disabled={locked}
            text='seated'
            aligned
            grid='10'
          />
        </Column>
        <Column>
          <Input
            kind='number'
            model='show.guest_list_capacity'
            disabled={locked}
            label='Guest list capacity'
            grid='10'
            min='0'
          />
        </Column>
      </Row>

      <Spacer />

      <Row className={styles.list}>
        <Column>
          <Cell grid='17' className={styles.budgetLineItem}>
            <InlineText grid='fit' text='Stage capacity' />
            <InlineSpacer grid='fill' />
            <InlineText grid='fit' text={values.show.stage_capacity ?? 0} />
          </Cell>
          <Cell grid='17' className={styles.budgetLineItem}>
            <InlineText grid='fit' text='Tickets' />
            <InlineSpacer grid='fill' />
            <InlineText grid='fit' text={ticketsSum} />
          </Cell>
          <Cell grid='17' className={styles.budgetLineItem}>
            <InlineText grid='fit' text='Guest list' />
            <InlineSpacer grid='fill' />
            <InlineText
              grid='fit'
              text={values.show.guest_list_capacity ?? 0}
            />
          </Cell>
        </Column>
      </Row>

      <Spacer />

      <Row className={styles.list}>
        <Column>
          <Cell grid='17'>
            <InlineText
              grid='fit'
              text='Remaining'
              className={styles.budgetTotalText}
            />
            <InlineSpacer grid='fill' />
            <InlineText
              grid='fit'
              text={sum}
              className={styles.budgetTotalText}
            />
          </Cell>
        </Column>
      </Row>
    </Section>
  )
}
