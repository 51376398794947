import * as Sentry from '@sentry/react'
import {decodeToken} from './auth'

export function registerUserTracking(token: string) {
  const {email, username, user_id} = decodeToken(token)
  // Set sentry data
  Sentry.configureScope((scope) => {
    scope.setUser({
      email,
      username,
      id: user_id.toString(),
    })
  })
}

export function unregisterUserTracking() {
  // Clear sentry
  Sentry.configureScope((scope) => {
    scope.setUser(null)
  })
}
