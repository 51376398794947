import {uniq, uniqBy, flatten, compact, map, find, filter, reject} from 'lodash'
import type {SelectOption, SelectOptionItem} from 'components/elements/Select'
import type {Country} from 'types/basicData'
import type {CountrySelectKind} from './types'

const DEFAULT_LANGUAGES = ['da', 'en']
const PRIORITY_LANGUAGES = ['sv', 'no', 'de']

// Also used in Phone country code selector
export const DEFAULT_COUNTRIES = ['DK']
export const PRIORITY_COUNTRIES = [
  'SE',
  'NO',
  'DE',
  'FI',
  'GB',
  'NL',
  'FR',
  'PL',
  'FO',
  'GL',
  'CH',
  'BE',
  'IE',
  'ES',
  'PT',
  'IT',
  'GR',
]

function grouping(
  data: SelectOptionItem[],
  default_values: string[],
  priority_values: string[]
): SelectOption[] {
  return [
    {
      label: 'Default',
      options: filter(data, ({value}) => default_values.includes(value)),
    },
    {
      label: 'Priority',
      options: filter(data, ({value}) => priority_values.includes(value)),
    },
    {
      label: 'Other',
      options: reject(data, ({value}) =>
        [...default_values, ...priority_values].includes(value)
      ),
    },
  ]
}

export function buildOptions(
  data: Country[],
  kind: CountrySelectKind
): SelectOption[] {
  switch (kind) {
    case 'language': {
      const languages = uniq(
        compact(map(data, (i) => (i.languages || [''])[0].split('-')[0]))
      )

      const details = uniqBy(flatten(map(data, 'language_details')), 'iso')

      const formattedData = compact(
        map(languages, (iso) => {
          const item = find(details, {iso})
          return item
            ? {
                label: `${item.name_en} (${item.name})`,
                value: iso,
              }
            : null
        })
      )

      return grouping(formattedData, DEFAULT_LANGUAGES, PRIORITY_LANGUAGES)
    }

    case 'country': {
      const filteredData = data.filter((country) => !!country.iso)

      return grouping(
        filteredData.map((country) => ({
          label: country.name,
          value: country.iso,
        })),
        DEFAULT_COUNTRIES,
        PRIORITY_COUNTRIES
      )
    }

    case 'phonePrefix': {
      const filteredData = data.filter((country) => !!country.phone?.prefix)

      return grouping(
        filteredData.map((country) => ({
          label: `(+${country.phone.prefix?.replace('+', '')}) ${country.name}`,
          value: country.phone.prefix,
        })),
        DEFAULT_COUNTRIES,
        PRIORITY_COUNTRIES
      )
    }
  }
}
