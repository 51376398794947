import {combineReducers} from 'redux'
import {standardReducer} from 'helpers/reducers'
import {START_EDIT, STOP_EDIT} from './constants'

const initialState = false

const ACTION_CREATORS = {
  [START_EDIT]: () => true,
  [STOP_EDIT]: () => false,
}

export const affiliationsReducer = combineReducers({
  anybodyEditing: standardReducer(initialState, ACTION_CREATORS),
})
