import {useState} from 'react'
import {Portal} from 'react-portal'
import {Transition} from 'react-transition-group'
import {Button} from 'components/elements'
import {cls} from 'utils'
import {SearchView} from './View'

export const Search = () => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <Button
        icon='search'
        round
        size='big'
        onClick={() => setVisible((state) => !state)}
      />
      <Transition in={visible} timeout={150}>
        {(state) => {
          if (state === 'exited') {
            return null
          }
          return (
            <Portal>
              <div
                className={cls(
                  'fixed inset-0 bg-desertStorm bg-opacity-60 z-[1000] transition-opacity',
                  'grid justify-end justify-items-end',
                  state === 'entering' || state === 'exiting'
                    ? 'opacity-0'
                    : 'opacity-100'
                )}
              >
                <div
                  className={cls(
                    'overflow-y-auto max-w-5xl ml-20',
                    'bg-white shadow-drawerRight',
                    'translate-x-0 w-[80vw] transition-transform',
                    state === 'entering' || state === 'exiting'
                      ? 'translate-x-12'
                      : ''
                  )}
                >
                  <SearchView onCloseClick={() => setVisible(false)} />
                </div>
              </div>
            </Portal>
          )
        }}
      </Transition>
    </>
  )
}
