export const NEW = 'Entity/Tour/NEW'

export const FETCH = 'Entity/Tour/FETCH'
export const FETCH_SUCCESS = 'Entity/Tour/FETCH_SUCCESS'
export const FETCH_FAILURE = 'Entity/Tour/FETCH_FAILURE'

export const SHOW = 'Entity/Tour/SHOW'
export const SHOW_SUCCESS = 'Entity/Tour/SHOW_SUCCESS'
export const SHOW_FAILURE = 'Entity/Tour/SHOW_FAILURE'

export const CREATE = 'Entity/Tour/CREATE'
export const CREATE_SUCCESS = 'Entity/Tour/CREATE_SUCCESS'
export const CREATE_FAILURE = 'Entity/Tour/CREATE_FAILURE'

export const UPDATE = 'Entity/Tour/UPDATE'
export const UPDATE_SUCCESS = 'Entity/Tour/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'Entity/Tour/UPDATE_FAILURE'

export const CLEAR = 'Entity/Tour/CLEAR'
