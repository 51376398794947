import type {CSSProperties} from 'react'
import {memo} from 'react'
import cx from 'classnames'
import {range} from 'lodash'
import {Time} from 'components/elements'
import type {ActsInColumn, DaysArrayItem, MonthColumn} from 'helpers/booking'
import {HeaderDay} from './elements'
import {Day} from '../components'

interface ActsInColumnsProps {
  viewKey: string
  grid: ActsInColumn[]
  monthColumn: MonthColumn
}

interface GridCellProps {
  grid: ActsInColumn[]
  columnIndex: number
  rowIndex: number
  isVisible: boolean
}

interface CellProps extends Pick<GridCellProps, 'columnIndex' | 'rowIndex'> {
  className?: string
  style?: CSSProperties
}

interface HeaderCellProps extends CellProps {
  title: string
}

const HeaderCell = memo(({title, style, className}: HeaderCellProps) => {
  return (
    <div
      className={cx(
        className,
        'bg-oxfordBlue text-white font-bold',
        'sticky top-0 width-[340px] z-10 h-[34px]',
        'py-2 px-2.5'
      )}
      style={{
        ...style,
      }}
    >
      {title}
    </div>
  )
})
HeaderCell.displayName = 'HeaderCell'

interface DayCellProps extends CellProps, DaysArrayItem {
  actId?: number
}

const DayCell = memo(
  ({
    date,
    actId,
    events,
    isToday,
    isWeekend,
    tours,
    className,
    style,
  }: DayCellProps) => {
    return (
      <div
        className={cx(className, ' border-b border-borderColor')}
        style={style}
      >
        <Day
          date={date}
          actId={actId}
          events={events}
          isToday={isToday}
          isWeekend={isWeekend}
          tours={tours}
        />
      </div>
    )
  }
)
DayCell.displayName = 'DayCell'

const CellRenderer = memo(
  ({grid, columnIndex, rowIndex, isVisible}: GridCellProps) => {
    if (!isVisible) {
      return null
    }

    const column = grid[columnIndex]
    const cell = column.rows[rowIndex]

    const style = {}

    if (rowIndex === -1) {
      return (
        <HeaderCell
          columnIndex={columnIndex}
          rowIndex={rowIndex}
          title={column.title}
          className={'border-r border-b border-scarpaFlow text-center'}
          style={style}
        />
      )
    }

    if (!cell) {
      return <div className={'border-r border-b border-borderColor'} />
    }

    return (
      <DayCell
        {...cell}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        className='grid border-r border-b border-borderColor'
      />
    )
  }
)
CellRenderer.displayName = 'CellRenderer'

export function ActsInColumns({
  grid,
  viewKey,
  monthColumn,
}: ActsInColumnsProps): JSX.Element {
  const columns = grid.length
  const rows = monthColumn.rows.length

  return (
    <div
      key={viewKey}
      className='grid content-start overflow-auto relative'
      style={{
        gridTemplateColumns: `80px repeat(${columns}, 250px)`,
        gridTemplateRows: `repeat(${rows + 1}, auto)`,
        gridAutoFlow: 'column',
      }}
    >
      <div className='border-r border-scarpaFlow bg-oxfordBlue text-white font-bold py-2 px-2.5 sticky top-0 left-0 z-[11]'>
        <Time obj={monthColumn.month} format='MonthYear' />
      </div>
      {monthColumn.rows.map((row) => (
        <HeaderDay
          key={row.key}
          date={new Date(row.date)}
          className='border-r border-b border-borderColor sticky left-0 z-10'
        />
      ))}

      {grid.map((column, columnIndex) =>
        range(-1, rows).map((rowIndex) => {
          return (
            <CellRenderer
              key={`${columnIndex}-${rowIndex}`}
              columnIndex={columnIndex}
              rowIndex={rowIndex}
              grid={grid}
              isVisible
            />
          )
        })
      )}
    </div>
  )
}
