import {Button, ButtonMenu} from 'components/elements'
import {useContract} from 'hooks/useContract'
import {useEntity} from 'hooks/useEntity'

export const AddNewButtonMenu = () => {
  const {newContract} = useContract()
  const {newTour, newAct, newOrganizer, newPerson} = useEntity()

  return (
    <ButtonMenu icon='plus'>
      <Button
        label='Contract'
        icon='contract'
        width='fill'
        theme='light'
        alignment='left'
        onClick={() => newContract({})}
      />
      <Button
        label='Tour'
        icon='road'
        width='fill'
        theme='light'
        alignment='left'
        onClick={() => newTour({})}
      />
      <Button
        label='Act'
        icon='microphone'
        width='fill'
        theme='light'
        alignment='left'
        onClick={() => newAct()}
      />
      <Button
        label='Organizer / Venue'
        icon='phone'
        width='fill'
        theme='light'
        alignment='left'
        onClick={() => newOrganizer()}
      />
      <Button
        label='Person'
        icon='user'
        width='fill'
        theme='light'
        alignment='left'
        onClick={() => newPerson({})}
      />
    </ButtonMenu>
  )
}
