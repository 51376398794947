import {getApiUrl} from 'api/urls'
import {apiFetch} from 'lib/fetch'
import type {DateTimeString} from 'types/generic'
import type {PlannerEvent, PlannerEventType} from 'types/planner'
import {castArray} from 'utils'

type PlannerEventTypes = PlannerEventType | PlannerEventType[]

export type PlannerActIds = 'represented_by_me' | number[]

export interface ListPlannerProps {
  before?: DateTimeString
  after?: DateTimeString
  actIds?: PlannerActIds
  includeInactiveActs?: boolean
  eventTypes?: PlannerEventTypes
}

export interface ListPlannerQueryParams {
  before?: string
  after?: string
  act_ids?: string
  include_inactive_acts?: boolean
  event_types?: string
}

export function getPlannerQueryParams(
  props: ListPlannerProps
): ListPlannerQueryParams {
  return {
    before: props.before,
    after: props.after,
    act_ids: props.actIds
      ? props.actIds === 'represented_by_me'
        ? props.actIds
        : props.actIds.sort().join(',')
      : undefined,
    include_inactive_acts: props.includeInactiveActs,
    event_types: props.eventTypes
      ? castArray(props.eventTypes).sort().join(',')
      : undefined,
  }
}

export const list = async (
  props: ListPlannerProps
): Promise<PlannerEvent[]> => {
  const queryParams = getPlannerQueryParams(props)

  const resp = await apiFetch.get<PlannerEvent[]>(
    getApiUrl('plannerEvents'),
    queryParams
  )

  return resp
}
