import styled from 'styled-components'
import {colors, effects, fonts, spacing} from 'styles/variables'

export const UserMenuButton = styled.button<{active?: boolean}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${spacing.u1_5} 0;
  padding: ${spacing.u1_5} ${spacing.u2_5};
  border: 1px solid transparent;
  border-radius: ${effects.borderRadius};
  color: ${colors.primaryTextColor};
  transition: all ${effects.transitionTime};
  font-family: ${fonts.fontRegular};
  font-size: 1em;
  background-color: transparent;

  &:not(:disabled):hover {
    cursor: pointer;
    border-color: ${colors.borderColor};
    background-color: ${colors.springWood};
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  &:active {
    border-color: ${colors.pictonBlue};
    outline: none;
  }

  ${({active}) =>
    active &&
    `
    border-color: ${colors.bostonBlue};
    background: ${colors.hawkesBlue};
  `}
`
