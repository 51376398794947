import {useContext} from 'react'
import styled from 'styled-components'
import {usePerson} from 'app/modal/Entity/hooks'
import {colors, spacing} from 'styles/variables'
import type {Person} from 'types/person'
import {AffiliationSectionContext} from './context'
import {useSearchSelect} from './hooks'
import {EntityEditView} from '../EditView'

const Fieldset = styled.fieldset`
  margin-left: -20px;
  margin-right: -20px;
  background: ${colors.white};
  border: 0;
  border-top: 1px solid ${colors.borderColor};
  border-bottom: 1px solid ${colors.borderColor};
  margin-bottom: ${spacing.u5};
  padding-left: 0;
  padding-right: 0;
`

const Legend = styled.legend`
  margin-left: 12px;
  color: ${colors.bombay};
  padding: 0 ${spacing.u2};
  position: relative;
  top: -3px;
`

export const AffiliationSectionForm = () => {
  const {roleType, showForm, setView} = useContext(AffiliationSectionContext)
  const {onSelect} = useSearchSelect(roleType)
  const {data} = usePerson()

  if (!showForm) {
    return null
  }

  return (
    <Fieldset>
      <Legend>Create new person</Legend>
      <EntityEditView
        initialValues={data as Person}
        onCancel={() => setView('show')}
        onSubmitSuccess={(person) => {
          onSelect(person.id)
          setView('show')
        }}
      />
    </Fieldset>
  )
}
