import {createContext} from 'react'
import type {
  AffiliationContextProps,
  AffiliationSectionContextProps,
} from './types'

export const AffiliationContext = createContext<AffiliationContextProps>(
  {} as AffiliationContextProps
)

export const AffiliationSectionContext =
  createContext<AffiliationSectionContextProps>(
    {} as AffiliationSectionContextProps
  )
