export const INIT = 'Email/INIT'
export const INIT_START = 'Email/INIT_START'
export const INIT_SUCCESS = 'Email/INIT_SUCCESS'
export const INIT_FAILURE = 'Email/INIT_FAILURE'

export const CLEAR = 'Email/CLEAR'

export const FETCH = 'Email/FETCH'
export const FETCH_START = 'Email/FETCH_START'
export const FETCH_SUCCESS = 'Email/FETCH_SUCCESS'
export const FETCH_FAILURE = 'Email/FETCH_FAILURE'

export const CREATE = 'Email/CREATE'
export const CREATE_SUCCESS = 'Email/CREATE_SUCCESS'
export const CREATE_FAILURE = 'Email/CREATE_FAILURE'

export const SET_ERROR = 'Email/SET_ERROR'

export const SET_RECIPIENTS = 'Email/SET_RECIPIENTS'
export const SET_SUBJECT = 'Email/SET_SUBJECT'
export const SET_BODY = 'Email/SET_BODY'
