import {combineReducers} from 'redux'
import {isLoading} from 'helpers/reducers'
import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILURE,
  CREATE,
  CREATE_SUCCESS,
  CREATE_FAILURE,
} from './constants'
import {contentReducer} from './reducers/content'
import {errorsReducer} from './reducers/errors'
import {metaReducer} from './reducers/meta'
import {urlReducer} from './reducers/url'
import {visibleReducer} from './reducers/visible'

export const emailReducer = combineReducers({
  visible: visibleReducer,
  content: contentReducer,
  errors: errorsReducer,
  meta: metaReducer,
  url: urlReducer,
  loading: isLoading({
    constants: [INIT, INIT_SUCCESS, INIT_FAILURE],
  }),
  sending: isLoading({
    constants: [CREATE, CREATE_SUCCESS, CREATE_FAILURE],
  }),
})
