import styled from 'styled-components'
import {Icon} from 'components/elements'

export const CloseButtonComp = styled.button`
  cursor: pointer;
  color: var(--santasGray);
  background: transparent;
  transition: all var(--transitionTime);
  border: 0;
  outline: 0;
  box-sizing: content-box;

  &:hover {
    color: var(--pictonBlue);
    transition: all var(--transitionTime);
  }
`

export const CloseButton: React.FC<
  React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>
> = (props) => {
  return (
    <CloseButtonComp {...props}>
      <Icon icon='close' size='20' />
    </CloseButtonComp>
  )
}
