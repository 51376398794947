import type {PayloadAction} from '@reduxjs/toolkit'
import merge from 'lodash/merge'
import {combineReducers} from 'redux'
import {standardReducer, isLoading} from 'helpers/reducers'
import type {
  PersonBusinessCard,
  BusinessCardPhoneNumber,
  BusinessCardEmailAddress,
} from 'types/businessCard'
import type {Person} from 'types/person'
import {
  SHOW,
  SHOW_SUCCESS,
  SHOW_FAILURE,
  NEW,
  CREATE_SUCCESS,
  UPDATE_SUCCESS,
  CLEAR,
} from './constants'
import type {PersonNewParams, PersonStoreData} from './types'

const initialState: Person = {
  type: 'PERSON',
  business_card: {
    formal_title: '',
    family_name: '',
    given_name: '',
    title: '',
    email_addresses: [{value: ''}] as BusinessCardEmailAddress[],
    phone_numbers: [
      {
        number_type: null,
        value: {country_code: null, value: null},
      } as unknown as BusinessCardPhoneNumber,
    ],
    postal_address: {
      city_name: '',
      country_code: '',
      line1: '',
      line2: '',
      postal_code: '',
    },
    website: '',
  } as Partial<PersonBusinessCard>,
} as Person

const ACTION_CREATORS = {
  [NEW]: (state: PersonStoreData, action: PayloadAction<PersonNewParams>) =>
    merge({}, initialState, action.payload),
  [SHOW]: () => initialState,
  [SHOW_SUCCESS]: (state: PersonStoreData, action: PayloadAction<Person>) =>
    action.payload,
  [SHOW_FAILURE]: () => initialState,
  [CREATE_SUCCESS]: (state: PersonStoreData, action: PayloadAction<Person>) =>
    action.payload,
  [UPDATE_SUCCESS]: (state: PersonStoreData, action: PayloadAction<Person>) =>
    action.payload,
  [CLEAR]: () => initialState,
}

export const personReducer = combineReducers({
  isLoading: isLoading({
    constants: [SHOW, SHOW_SUCCESS, SHOW_FAILURE],
  }),
  data: standardReducer(initialState, ACTION_CREATORS),
})
