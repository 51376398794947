import {createReducer} from '@reduxjs/toolkit'
import {combineReducers} from 'redux'
import {isLoading} from 'helpers/reducers'
import {highlightTour} from './actions'
import {FETCH, FETCH_SUCCESS, FETCH_FAILURE} from './constants'
import {asyncActionReducer, listReducer} from './reducers'
import type {PlannerStoreHighlightedTour} from './types'

export const plannerReducer = combineReducers({
  isFetching: isLoading(FETCH, FETCH_SUCCESS, FETCH_FAILURE),
  list: listReducer,
  asyncAction: asyncActionReducer,
  highligtedTour: createReducer<PlannerStoreHighlightedTour>(
    null,
    (builder) => {
      builder.addCase(highlightTour, (s, a) => a.payload)
    }
  ),
})
