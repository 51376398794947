import {
  startOfMonth,
  endOfMonth,
  addMonths,
  eachDay,
  isSameMonth,
  isWeekend,
  isToday,
  isWithinRange,
  differenceInCalendarMonths,
} from 'date-fns'
import type {Location} from 'history'
import {chain, filter, find, findKey, groupBy, map, range, sortBy} from 'lodash'
import {BOOKING_VIEWS, BOOKING_BASE_PATH, BOOKING_PATHS} from 'options/booking'
import type {
  BookingStoreViewsData,
  BookingStoreViewsDataWithAct,
  BookingStoreViewsDataWithShowKind,
} from 'store/booking/types'
import type {ListAct} from 'types/department'
import type {PlannerEvent} from 'types/planner'
import {formatDate} from 'utils'

type TourSelector = (date: string) => PlannerEvent[]

export interface MonthColumnRow {
  date: Date
  key: string
  isWeekend: boolean
  isToday: boolean
}
export interface MonthColumn {
  month: Date
  formattedMonth: string
  rows: MonthColumnRow[]
}

export interface DaysArrayItem {
  date: string
  isWeekend: boolean
  isToday: boolean
  events: PlannerEvent[]
  tours: PlannerEvent[]
}

export type DaysArray = DaysArrayItem[]

export interface ActsInColumn {
  key: string
  title: string
  rows: DaysArray
}

export function splitEventsAndTours(input: PlannerEvent[] = []): {
  events: PlannerEvent[]
  tours: PlannerEvent[]
} {
  return {
    events: input.filter((event) => event.type !== 'EVENT_TYPE_TOUR'),
    tours: input.filter((event) => event.type === 'EVENT_TYPE_TOUR'),
  }
}

export function resolveViewFromUrl(locationObj: Location) {
  const {pathname} = locationObj
  const path = pathname.replace(`/${BOOKING_BASE_PATH}/`, '')
  const foundKey = findKey(BOOKING_PATHS, (bp) => bp === path)
  return find(BOOKING_VIEWS, (val) => val === foundKey)
}

export function getActAsArray(acts: ListAct[], actId?: number | null) {
  return filter(acts, (act) => act.id === actId && act.is_active === true)
}

export function filterActs(acts: ListAct[]) {
  return filter(acts, (act) => act.is_active)
}

export function filterMyActs(acts: ListAct[]) {
  return sortBy(
    filter(filterActs(acts), (act) => act.is_represented_by_me),
    'name'
  )
}

export function eventsInMonth(month: string, events: PlannerEvent[]) {
  return filter(events, (show) => isSameMonth(show.start_on, month))
}

export function buildDateRange(start: Date | string, end: Date | string) {
  return eachDay(start, end)
}

export function buildDateRangeForMonth(month: Date | string) {
  return buildDateRange(startOfMonth(month), endOfMonth(month))
}

export function formatDateRange(dates: Date[]) {
  return map(dates, (date) => formatDate(date, 'ISOYearMonthDay'))
}

interface ToursForDateProps {
  tours: PlannerEvent[]
  date: string
  actId?: number
}

export function toursForDate({
  tours,
  date,
  actId,
}: ToursForDateProps): PlannerEvent[] {
  // As we do not need tours unless there is only one act
  // just return an empty array is actId is not present
  if (!actId) {
    return []
  }

  return filter(tours, (tour) => {
    if (actId && tour.act?.id !== actId) {
      return false
    }
    return isWithinRange(date, tour.start_on, tour.end_on)
  })
}

interface BuildMonthColumnProps {
  month: Date
}
type BuildMonthColumnReturn = MonthColumn

export function buildMonthColumn({
  month,
}: BuildMonthColumnProps): BuildMonthColumnReturn {
  // Get formatted month
  const formattedMonth = formatDate(month, 'ISOYearMonth')
  // Get range of dates in month
  const datesInMonth = buildDateRangeForMonth(month)
  // Construct date rows
  const rows = map(datesInMonth, (date) => {
    const formattedDate = formatDate(date, 'ISOYearMonthDay')
    return {
      date,
      key: formattedDate,
      isWeekend: isWeekend(date),
      isToday: isToday(date),
    }
  })
  // Return object
  return {
    month,
    formattedMonth,
    rows: rows,
  }
}

interface BuildDaysArrayProps {
  days: string[]
  events: PlannerEvent[]
  tourSelector?: TourSelector
  actId?: number
}
type DaysArrayReturn = DaysArray

export function buildDaysArray(
  {
    days,
    events,
    tourSelector,
    actId,
  }: BuildDaysArrayProps = {} as BuildDaysArrayProps
): DaysArrayReturn {
  return map(days, (date) => {
    const dayEvents = filter(events, (event) => event.start_on === date)
    const sortedDayEvents = sortBy(dayEvents, ['show_at', 'id'])
    return {
      date: date,
      isWeekend: isWeekend(date),
      isToday: isToday(date),
      events: sortedDayEvents,
      tours: tourSelector ? tourSelector(date) : [],
      actId,
    }
  })
}

interface BuildMonthDaysArrayProps {
  month: Date | string
  events: PlannerEvent[]
  tourSelector?: TourSelector
  actId?: number
}
type BuildMonthDaysArrayReturn = DaysArray

export function buildMonthDaysArray({
  month,
  events,
  tourSelector,
  actId,
}: BuildMonthDaysArrayProps): BuildMonthDaysArrayReturn {
  // Get range of formatted dates
  const days = formatDateRange(buildDateRangeForMonth(month))
  return buildDaysArray({days, events, tourSelector, actId})
}

interface BuildActsInColumnsProps {
  month: string
  events: PlannerEvent[]
  acts: ListAct[]
}
type BuildActsInColumnsReturn = ActsInColumn[]

export function buildActsInColumns({
  month,
  events: _events,
  acts,
}: BuildActsInColumnsProps): BuildActsInColumnsReturn {
  const {events, tours} = splitEventsAndTours(_events)
  // Return empty array if no acts
  if (!acts || !acts.length) {
    return []
  }
  const formattedMonth = formatDate(month, 'ISOYearMonth')
  // Get events in month
  const eventsThisMonth = eventsInMonth(month, events)
  // Group events by act_id
  const eventsThisMonthByActId = groupBy(eventsThisMonth, 'act.id')
  // Build the individual days
  const actDays = chain(acts)
    // Assign objects to acts id
    .mapKeys((act) => act.id)
    .mapValues((act) =>
      buildMonthDaysArray({
        month,
        actId: act.id,
        events: eventsThisMonthByActId[act.id],
        tourSelector: (date) => toursForDate({tours, actId: act.id, date}),
      })
    )
    .value()

  // Construct the columns
  const actColumns = map(acts, (act) => {
    return {
      key: `${formattedMonth}_${act.id}`,
      title: act.name,
      rows: actDays[act.id],
    }
  })

  // Return
  return actColumns
}

export interface MonthsInColumnsColumn {
  key: string
  month: string
  actId?: number
  rows: DaysArray
}

interface BuildMonthsInColumnsProps {
  startMonth: string
  endMonth: string
  events: PlannerEvent[]
  actId?: number
}
type BuildMonthsInColumnsReturn = MonthsInColumnsColumn[]

export function buildMonthsInColumns({
  startMonth,
  endMonth,
  events: _events,
  actId,
}: BuildMonthsInColumnsProps): BuildMonthsInColumnsReturn {
  const {events, tours} = splitEventsAndTours(_events)

  const start = new Date(startMonth)
  const end = new Date(endMonth)
  const diff = differenceInCalendarMonths(end, start)

  return range(0, diff + 1)
    .map((i) => {
      const m = addMonths(start, i)

      return {
        key: `${formatDate(m, 'ISOYearMonth')}`,
        actId,
        month: formatDate(m, 'MonthYear'),
        rows: buildMonthDaysArray({
          month: m,
          events,
          tourSelector: (date) => toursForDate({tours, actId, date}),
          actId,
        }),
      } satisfies MonthsInColumnsColumn
    })
    .flat()
}

interface BuildOneMonthProps {
  startDay: string
  endDay: string
  actId?: number
  events: PlannerEvent[]
}
type BuildOneMonthReturn = DaysArray

export function buildOneMonth({
  startDay,
  endDay,
  actId,
  events: _events,
}: BuildOneMonthProps): BuildOneMonthReturn {
  const {events, tours} = splitEventsAndTours(_events)
  const days = formatDateRange(buildDateRange(startDay, endDay))

  return buildDaysArray({
    days,
    events,
    actId,
    tourSelector: (date) => toursForDate({tours, actId, date}),
  })
}

export function isBookingViewsWithAct(
  bookingView?: BookingStoreViewsData
): bookingView is BookingStoreViewsDataWithAct {
  if (!bookingView) {
    return false
  }
  return (bookingView as BookingStoreViewsDataWithAct).act !== undefined
}

export function isBookingViewsWithShowKind(
  bookingView?: BookingStoreViewsData
): bookingView is BookingStoreViewsDataWithShowKind {
  if (!bookingView) {
    return false
  }
  return (
    (bookingView as BookingStoreViewsDataWithShowKind).showKind !== undefined
  )
}
