import styled from 'styled-components'
import {Input} from 'components/elements'
import {colors, effects, fontSizes, spacing} from 'styles/variables'

export const Overlay = styled.div<{opened: boolean}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  opacity: ${({opened}) => (opened ? 1 : 0)};
  transition: opacity ${effects.transitionTime};
  pointer-events: ${({opened}) => (opened ? 'initial' : 'none')};
  z-index: ${({opened}) => (opened ? 20000 : 'unset')};
`

export const Root = styled.div<{opened: boolean}>`
  width: 550px;
  box-shadow: 0 0 178px 0 rgba(0, 0, 0, 0.75);
  z-index: 20001;
  background: ${colors.white};
  opacity: ${({opened}) => (opened ? 1 : 0)};
  transition: opacity ${effects.transitionTime};
`

export const Header = styled.div`
  margin-top: ${spacing.u5};
  padding: 0 ${spacing.u5};
  font-size: ${fontSizes.tigerSize};
  color: ${colors.scarpaFlow};
  text-align: center;
  height: 27px;
`

export const Content = styled.div`
  margin-top: ${spacing.u2_5};
  margin-bottom: ${spacing.u5};
  padding: 0 ${spacing.u5};
  font-size: ${fontSizes.catSize};
  color: ${colors.santasGray};
  text-align: center;
  min-height: 60px;
`

export const InputField = styled(Input)`
  margin-top: ${spacing.u2_5};
`

export const Actions = styled.div`
  padding: ${spacing.u5};
  border-top: 1px solid ${colors.borderColor};
  display: flex;
  justify-content: space-between;
`
