import {createReducer} from '@reduxjs/toolkit'
import {
  copyContract,
  createContractSuccess,
  initializeContract,
  resetContract,
  showContract,
} from '../actions'
import type {ContractStoreInitializeParams} from '../types'

export const initializeParamsReducer =
  createReducer<ContractStoreInitializeParams>(null, (builder) => {
    builder
      .addCase(resetContract, () => null)
      .addCase(copyContract, (store, action) => ({
        fromContractId: action.payload.id,
      }))
      .addCase(showContract, () => null)
      .addCase(initializeContract, (store, action) =>
        store !== null ? {...store, ...action.payload} : action.payload
      )
      .addCase(createContractSuccess, () => null)
  })
