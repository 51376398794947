import {queryKeys} from 'api'
import {queryClient} from 'main'
import type {DepartmentType, StorableDepartment} from 'types/department'

export function invalidateDepartmentList(type: StorableDepartment['type']) {
  switch (type) {
    case 'ACT':
      queryClient.invalidateQueries(queryKeys.acts.list.queryKey)
      break
    case 'VENUE':
      queryClient.invalidateQueries(queryKeys.venues.list.queryKey)
      break
    case 'ORGANIZER':
      queryClient.invalidateQueries(queryKeys.organizers.list.queryKey)
      break
  }
}

export function invalidateDepartment(type: DepartmentType, id: number) {
  switch (type) {
    case 'ACT':
      queryClient.invalidateQueries(queryKeys.acts.show(id).queryKey)
      break
    case 'VENUE':
      queryClient.invalidateQueries(queryKeys.venues.show(id).queryKey)
      break
    case 'ORGANIZER':
      queryClient.invalidateQueries(queryKeys.organizers.show(id).queryKey)
      break
  }
}
