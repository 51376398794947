import type {PayloadAction} from '@reduxjs/toolkit'
import {addDays, addHours} from 'date-fns'
import merge from 'lodash/merge'
import type {SagaIterator} from 'redux-saga'
import {takeLatest, select, put, call} from 'redux-saga/effects'
import {getContractTemplates} from 'api'
import {api} from 'api/api'
import {standardErrorAction} from 'helpers/saga'
import {CONTRACT_DEFAULT_LANGUAGE, CONTRACT_LANGUAGES} from 'options/contract'
import type {RootStore} from 'store/types'
import type {ContractDummy, ContractDummyRemote} from 'types/contract'
import type {ContractTemplate} from 'types/contractTemplate'
import type {PlannerEvent} from 'types/planner'
import {initializeContractSuccess} from '../actions'
import {CONTRACT_INITIALIZE, CONTRACT_INITIALIZE_FAILURE} from '../constants'
import type {ContractInitializeParams} from '../types'

function getEvent(
  {listPlanner}: RootStore,
  eventId: number
): PlannerEvent | undefined {
  return listPlanner.list.find(({id}) => id === eventId)
}

function* buildInitialData(
  rawPayload: ContractDummyRemote,
  options: ContractInitializeParams
): SagaIterator {
  const rawDate = options.date ?? new Date(new Date().toJSON().split('T')[0])
  const date = addHours(rawDate, 2)

  /* PREPOLULATE FIELDS */
  const prepopulate: Partial<ContractDummy> = {
    _commissionAbsolute: !!rawPayload.absolute_commission,
  }

  if (options.fromEventId) {
    const event = (yield select(getEvent, options.fromEventId)) as
      | PlannerEvent
      | undefined
    if (event) {
      prepopulate.notes_for_economy = event?.text ?? undefined
    }
  }

  if (
    prepopulate._commissionAbsolute === true &&
    !rawPayload.absolute_commission
  ) {
    prepopulate.absolute_commission = 0
  } else if (
    prepopulate._commissionAbsolute === false &&
    !rawPayload.relative_commission
  ) {
    prepopulate.relative_commission = 0
  }

  const lang = Object.keys(CONTRACT_LANGUAGES).includes(rawPayload.language)
    ? rawPayload.language
    : CONTRACT_DEFAULT_LANGUAGE
  // Prepolulate custom_economic_terms
  const economic_term_templates =
    rawPayload.terms_template.economic_term_templates

  if (economic_term_templates.length === 1) {
    const {id, text_da, text_en} = economic_term_templates[0]
    prepopulate.economic_terms_id = id
    prepopulate.custom_economic_terms = lang === 'en' ? text_en : text_da
    prepopulate.financial_export_status = {
      value: economic_term_templates[0].automatically_mark_for_financial_export
        ? 'EXPORT_STATUS_TO_BE_EXPORTED'
        : 'EXPORT_STATUS_NO_EXPORT',
      marked_for_export_at: null,
      most_recently_exported_at: null,
    }
  }
  // Prepolulate supplementary_contract_text
  const supplementary_contracts =
    rawPayload.terms_template.supplementary_contracts
  if (supplementary_contracts.length === 1) {
    const supplementaryContract = supplementary_contracts[0]
    prepopulate.supplementary_contract = supplementaryContract
    const {text_da, text_en} = supplementaryContract
    prepopulate.supplementary_contract_text = lang === 'en' ? text_en : text_da
  }
  /* PREPOLULATE FIELDS - END */

  const rtn = merge({}, rawPayload, prepopulate) as ContractDummy

  // Default date to today if not present
  if (!rtn.show.start_at) {
    rtn.show.start_at = date.toISOString()
    rtn.show.end_at = addDays(date, 1).toISOString()
  }

  return rtn
}

function* selectContractTemplate(): SagaIterator {
  const templates: ContractTemplate[] = yield call(getContractTemplates)
  // TODO: Better error handling
  if (!templates || !templates.length) {
    throw new Error('selectContractTemplate: Could not find any templates')
  }
  return templates[0]
}

// REQUEST TEMPLATE API SAGA
export function* requestContractDummy({
  actId,
  fromContractId,
}: Pick<ContractInitializeParams, 'actId' | 'fromContractId'>) {
  if (!actId) {
    throw new Error('requestContractDummy: actId required')
  }

  const {id: termsTemplateId} = yield call(selectContractTemplate)

  const payload: ContractDummyRemote = yield api.contracts.initialize({
    actId,
    fromContractId,
    termsTemplateId,
  })

  return payload
}

export function* initializeContract(
  action: PayloadAction<ContractInitializeParams>
): SagaIterator {
  try {
    // Get right contract template
    // Get contract template dummy
    const dummy = yield call(requestContractDummy, {
      actId: action.payload.actId,
      fromContractId: action.payload.fromContractId,
    })

    // Manipulate contract template dummy with right inititals
    // TODO: move to backend
    const payload = yield call(buildInitialData, dummy, action.payload)
    // Done!
    yield put(initializeContractSuccess(payload))
  } catch (e: any) {
    yield put(standardErrorAction(CONTRACT_INITIALIZE_FAILURE, e))
    throw e
  }
}

export function* initializeSaga() {
  yield takeLatest(CONTRACT_INITIALIZE, initializeContract)
}
