export const FETCH = 'Planners/FETCH'
export const FETCH_START = 'Planners/FETCH_START'
export const FETCH_SUCCESS = 'Planners/FETCH_SUCCESS'
export const FETCH_FAILURE = 'Planners/FETCH_FAILURE'

export const CREATE = 'Planners/CREATE'
export const CREATE_START = 'Planners/CREATE_START'
export const CREATE_SUCCESS = 'Planners/CREATE_SUCCESS'
export const CREATE_FAILURE = 'Planners/CREATE_FAILURE'

export const UPDATE = 'Planners/UPDATE'
export const UPDATE_START = 'Planners/UPDATE_START'
export const UPDATE_SUCCESS = 'Planners/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'Planners/UPDATE_FAILURE'

export const DELETE = 'Planners/DELETE'
export const DELETE_START = 'Planners/DELETE_START'
export const DELETE_SUCCESS = 'Planners/DELETE_SUCCESS'
export const DELETE_FAILURE = 'Planners/DELETE_FAILURE'

export const HIGHLIGHT_TOUR = 'HIGHLIGHT_TOUR'

export const SHOW_CREATED_SUCCESS = 'Planner/SHOW_CREATED_SUCCESS'
export const SHOW_UPDATED_SUCCESS = 'Planner/SHOW_UPDATED_SUCCESS'
export const SHOW_DESTROYED_SUCCESS = 'Planner/SHOW_DESTROYED_SUCCESS'

export const LIST_UPDATE_EVENT = 'Planner/LIST_UPDATE_EVENT'
export const LIST_DESTROY_EVENT = 'Planner/LIST_DESTROY_EVENT'
