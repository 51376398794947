import {apiFetch} from 'lib/fetch'
import type {Country, Currency, Language} from 'types/basicData'
import {getApiUrl} from '../urls'

export const countries = async (): Promise<Country[]> =>
  apiFetch.get<Country[]>(getApiUrl('countries'), {simple: 'true'})

export const currencies = async (): Promise<Currency[]> =>
  apiFetch.get<Currency[]>(getApiUrl('currencies'), {simple: 'true'})

export const languages = async (): Promise<Language[]> =>
  apiFetch.get<Language[]>(getApiUrl('languages'), {simple: 'true'})
