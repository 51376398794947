import {Spinner} from 'components/elements'
import {Container, Main} from 'containers/Core'
import {useContract} from 'hooks/useContract'
import {useSingleList, useSort} from './hooks'
import {
  ViewHeader,
  MonthRangePicker,
  SingleActPriorityMenu,
  EmptyMessage,
} from '../components'
import {List} from '../views'

export const SingleList = () => {
  const {events, actId, isLoading, sortBy, sortDirection} = useSingleList()
  const {setSort} = useSort()
  const {openContract} = useContract()

  return (
    <Container>
      <ViewHeader filters={<SingleActPriorityMenu />}>
        <MonthRangePicker />
      </ViewHeader>

      <Main flex>
        {isLoading && <Spinner overlay />}
        {actId && (
          <List
            events={events}
            onRowClick={({contract_id}) => openContract(contract_id)}
            onSort={setSort}
            sortBy={sortBy}
            sortDirection={sortDirection}
          />
        )}
        {!actId && (
          <EmptyMessage title='Please choose an act from the menu above' />
        )}
      </Main>
    </Container>
  )
}
