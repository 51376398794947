import {
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  isAfter,
} from 'date-fns'
import {startsWith} from 'lodash'
import {standardReducer} from 'helpers/reducers'
import {formatDate} from 'utils'

function checkView(view, func) {
  return (state, action) => (action.view === view ? func(state, action) : state)
}
function checkViewType(viewType, func) {
  return (state, action) =>
    startsWith(action.view, viewType) ? func(state, action) : state
}

export const month = (constant, view, offset = 0) => {
  const initialValue =
    offset === null
      ? null
      : formatDate(addMonths(new Date(), offset), 'ISOYearMonth')
  return standardReducer(initialValue, {
    [constant]: checkView(view, (state, action) => action.month),
  })
}

export const monthWithMinMonth = (constant, view, offset = 0, minConstant) => {
  const initialValue =
    offset === null
      ? null
      : formatDate(addMonths(new Date(), offset), 'ISOYearMonth')
  return standardReducer(initialValue, {
    [constant]: checkView(view, (state, action) => action.month),
    [minConstant]: checkView(view, (state, action) => {
      if (state === null) {
        return null
      }
      return isAfter(action.month, state) ? action.month : state
    }),
  })
}

export const monthWeekStartDay = (constant, view) => {
  return standardReducer(
    formatDate(
      startOfWeek(startOfMonth(new Date()), {weekStartsOn: 1}),
      'ISOYearMonthDay'
    ),
    {
      [constant]: checkView(view, (state, action) =>
        formatDate(
          startOfWeek(startOfMonth(action.month), {weekStartsOn: 1}),
          'ISOYearMonthDay'
        )
      ),
    }
  )
}
export const monthWeekEndDay = (constant, view) => {
  return standardReducer(
    formatDate(
      endOfWeek(endOfMonth(new Date()), {weekStartsOn: 1}),
      'ISOYearMonthDay'
    ),
    {
      [constant]: checkView(view, (state, action) =>
        formatDate(
          endOfWeek(endOfMonth(action.month), {weekStartsOn: 1}),
          'ISOYearMonthDay'
        )
      ),
    }
  )
}

export const showKind = (constant, view) => {
  return standardReducer('represented_by_me', {
    [constant]: checkViewType(view, (state, action) => action.kind),
  })
}

export const act = (constant, view) => {
  return standardReducer(null, {
    [constant]: checkViewType(view, (state, action) => action.id),
  })
}

export const sortBy = (constant, view) => {
  return standardReducer('date', {
    [constant]: checkView(view, (state, action) => action.sortBy),
  })
}

export const sortDirection = (constant, view) => {
  return standardReducer('ASC', {
    [constant]: checkView(view, (state, action) => action.sortDirection),
  })
}
