import {useState} from 'react'
import cx from 'classnames'
import {map} from 'lodash'
import type {CSSProperties} from 'styled-components'
import type {PlannerEvent} from 'types/planner'
import styles from './Day.module.css'
import type {NewPlannerEvent} from './Event'
import {Event} from './Event'
import {Tours} from './Tours'

interface DayProps {
  style?: CSSProperties
  className?: string
  actId?: number
  date: string
  tours: PlannerEvent[]
  events: PlannerEvent[]
  isWeekend: boolean
  isToday: boolean
  showTours?: boolean
}

export const Day = ({
  tours,
  events,
  isWeekend,
  isToday,
  actId,
  date,
  showTours = true,
  className,
  style,
}: DayProps) => {
  const [showNewEvent, setShowNewEvent] = useState(false)

  const addNoteStart = () => {
    setShowNewEvent(true)
  }

  const addNoteEnd = () => {
    setShowNewEvent(false)
  }

  const classNames = cx(
    {
      [styles.isWeekend]: isWeekend,
      [styles.isToday]: isToday,
    },
    styles.root,
    className
  )

  return (
    <div className={classNames} style={style}>
      {Boolean(showTours && actId) && <Tours data={tours} />}
      <div className={styles.cells}>
        {map(events, (evt) => (
          <Event
            key={evt.id}
            data={evt}
            actId={actId}
            date={date}
            showActName={!actId}
            addNoteStart={addNoteStart}
            addNoteEnd={addNoteEnd}
          />
        ))}
        {!!actId && Boolean(showNewEvent || events.length === 0) && (
          <Event
            data={
              {
                type: 'EMPTY_DAY',
                act: {id: actId},
                start_on: date,
                text: '',
              } as NewPlannerEvent
            }
            actId={actId}
            date={date}
            addNoteStart={addNoteStart}
            addNoteEnd={addNoteEnd}
          />
        )}
      </div>
    </div>
  )
}
