import {useQuery} from '@tanstack/react-query'
import {getTTL} from 'utils'
import {queryKeys} from '../queries'

export const useActs = () => {
  return useQuery({
    ...queryKeys.acts.list,
    staleTime: getTTL('NORMAL'),
  })
}

export const useAct = (id?: number) =>
  useQuery({
    ...queryKeys.acts.show(id ?? 0),
    enabled: !!id,
  })
