import {memo, useContext} from 'react'
import styled from 'styled-components'
import {EntityCard} from 'components/elements'
import {useEntity} from 'hooks/useEntity'
import {ROLE_TYPE_OPTIONS} from 'options/role'
import {colors, fontSizes, spacing} from 'styles/variables'
import type {SearchResult} from 'types/searchResult'
import {AffiliationSectionContext} from './context'
import {useContent} from './hooks'
import type {AffiliationItems} from './types'

const Content = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${spacing.u2_5};
`

const EmptyContainer = styled.main`
  flex: 1 1 auto;
  text-align: center;
  margin-bottom: ${spacing.u5};
`

const EmptyTitle = styled.div`
  font-size: ${fontSizes.wolfSize};
  color: ${colors.santasGray};
`

const EmptyDescription = styled.div`
  font-size: ${fontSizes.dogSize};
  color: ${colors.santasGray};
`

interface AffiliationSectionContentProps {
  items: AffiliationItems[]
}

export const AffiliationSectionContent: React.FC<
  React.PropsWithChildren<AffiliationSectionContentProps>
> = memo(({items}) => {
  const {openPerson} = useEntity()
  const {roleType, showData} = useContext(AffiliationSectionContext)
  const {onDelete} = useContent(roleType)
  const options = ROLE_TYPE_OPTIONS[roleType]

  if (!options || !showData) {
    return null
  }

  if (!items || !items.length) {
    return (
      <EmptyContainer>
        <EmptyTitle>{options.affiliationTexts.emptyTitle}</EmptyTitle>
        <EmptyDescription>
          {options.affiliationTexts.emptyDescription}
        </EmptyDescription>
      </EmptyContainer>
    )
  }

  return (
    <Content>
      {items.map((item) => {
        const handleOpen = () => {
          openPerson(item.affiliation.person.id)
        }

        return (
          <EntityCard
            key={`ROLE_CARD_${roleType}_${item.id}`}
            entityType={roleType}
            data={item as unknown as SearchResult}
            onClick={handleOpen}
            options={{
              actions: {
                topRight: {
                  icon: 'close',
                  action: () => onDelete(item.id),
                },
              },
            }}
          />
        )
      })}
    </Content>
  )
})

AffiliationSectionContent.displayName = 'AffiliationSectionContent'
