import type {ReactElement} from 'react'
import {useFormikContext} from 'formik'
import {NumberInput, Toggle} from 'components/form'
import {
  Container,
  Section,
  Row,
  Column,
  Cell,
  InlineText,
} from 'containers/Form'
import type {Act} from 'types/department'
import {Name} from '../sections'

export const EntityFormPanelAct = (): ReactElement => {
  const {values} = useFormikContext<Act>()

  return (
    <Container>
      <Name />

      <Section label='Commision'>
        <Row>
          <Column>
            <Cell grid='8'>
              <NumberInput model='default_commission' grid='fill' />
              <InlineText text='%' grid='fit' />
            </Cell>
          </Column>
          <Column />
        </Row>
      </Section>

      {!!values.id && (
        <Section label='Status'>
          <Row>
            <Column>
              <Toggle
                model='is_active'
                grid='fit'
                activeText='Act is active'
                inactiveText='Act is deactivated'
              />
            </Column>
            <Column />
          </Row>
        </Section>
      )}
    </Container>
  )
}
