import {useHistory} from 'react-router-dom'
import type {ButtonProps} from '../Button'
import {Button} from '../Button'

export interface LinkButtonProps extends Omit<ButtonProps, 'to'> {
  to: string
}

export const LinkButton: React.FC<React.PropsWithChildren<LinkButtonProps>> = ({
  to,
  ...buttonProps
}) => {
  const {push} = useHistory()

  const handleClick = () => {
    push(to)
  }

  return <Button {...buttonProps} onClick={handleClick} />
}
