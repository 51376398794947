import {useDispatch} from 'react-redux'
import {Button, EmptyMessage} from 'components/elements'
import {useModal} from 'hooks/useModal'
import {newDepartment} from 'store/entity/actions'
import {EmptyContainer} from './components'
import {DepartmentCRUDView} from './DepartmentCRUD'
import {useEntityView, useMainDepartment, useOrganization} from './hooks'

export const DepartmentView = () => {
  const dispatch = useDispatch()
  const {viewMode, setViewMode} = useEntityView()
  const {isLoading, data: organization} = useOrganization()
  const {closeModal} = useModal()
  useMainDepartment()

  const handleNewOrganizer = () => {
    dispatch(newDepartment({type: 'ORGANIZER'}))
    setViewMode('new')
  }

  if (
    !isLoading &&
    organization?.type === 'ORGANIZATION_TYPE_VENUE' &&
    viewMode !== 'new'
  ) {
    return (
      <EmptyContainer flex>
        <EmptyMessage
          title='No organizer has been created yet'
          description='If you want the organization to be able to organize events, please create a organizer, clicking on the button below.'
        />
        <Button
          icon='plus'
          label='Create organizer'
          theme='primary'
          onClick={handleNewOrganizer}
        />
      </EmptyContainer>
    )
  }

  return (
    <DepartmentCRUDView
      onCancel={() =>
        viewMode === 'new' && !organization.id
          ? closeModal()
          : setViewMode('show')
      }
    />
  )
}
