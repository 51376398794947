import {useContext} from 'react'
import {useCurrencies, useLanguages} from 'api'
import {getCustomLabel} from 'app/modal/Entity/helpers'
import {Definition} from 'components/elements'
import {showNumber} from 'helpers/currency'
import {phoneNumber, phoneNumberType, postalAddress} from 'helpers/formatters'
import type {
  BusinessCardEmailAddress,
  BusinessCardPhoneNumber,
  BusinessCardPostalAddress,
} from 'types/businessCard'
import type {Ean, TaxIdUnvalidated} from 'types/taxAndEan'
import {compact} from 'utils'
import {EntitiyShowViewContext} from './context'

export const Address = () => {
  const {data} = useContext(EntitiyShowViewContext)
  let postal_address

  if ('business_card' in data) {
    postal_address = data.business_card
      .postal_address as BusinessCardPostalAddress
  }

  if (!postal_address) {
    return null
  }

  return (
    <Definition
      icon='location'
      list={postalAddress(postal_address)}
      primary='value'
      secondary='label'
      emptyText='No address'
    />
  )
}

export const Commission = () => {
  const {data} = useContext(EntitiyShowViewContext)

  if (!('default_commission' in data)) {
    return null
  }

  const commission =
    data.default_commission !== null
      ? `${showNumber(data.default_commission, {decimalSymbol: ','})} %`
      : null

  return (
    <Definition
      icon='commission'
      primary={commission}
      secondary='Commission'
      locked='This is only visible to you and other colleagues affiliated to this act'
    />
  )
}

export const CompanyRegistration = () => {
  const {data} = useContext(EntitiyShowViewContext)
  const list = []

  if ('tax_id' in data && !!data.tax_id) {
    const taxId = data.tax_id as TaxIdUnvalidated
    list.push({
      value: compact([taxId.country_code, taxId.value]).join(''),
      title: 'Tax-ID',
    })
  }

  if ('ean' in data && !!data.ean) {
    const {value} = data.ean as Ean
    if (value) {
      list.push({
        value: value,
        title: 'EAN',
      })
    }
  }

  return (
    <Definition
      icon='cvr'
      list={list}
      primary='value'
      secondary='title'
      emptyText='No Tax-ID'
    />
  )
}

export const Currency = () => {
  const {data} = useContext(EntitiyShowViewContext)
  const {data: currencies = []} = useCurrencies()

  if (!('preferred_currency' in data)) {
    return null
  }

  const currency = currencies.find(
    (currency) => currency.code === data.preferred_currency
  )
  const name = currency ? `${currency.code} (${currency.name})` : undefined

  return <Definition icon='currency' primary={name} emptyText='No currency' />
}

export const EmailAddresses = () => {
  const {data} = useContext(EntitiyShowViewContext)
  let email_addresses

  if ('business_card' in data) {
    email_addresses = data.business_card
      .email_addresses as BusinessCardEmailAddress[]
  }

  return (
    <Definition
      icon='mail'
      list={email_addresses}
      primary='value'
      emptyText='No emails'
    />
  )
}

export const Language = () => {
  const {data} = useContext(EntitiyShowViewContext)
  const {data: languages = []} = useLanguages()

  if (!('preferred_language' in data)) {
    return null
  }

  const name = languages.find(
    (language) => language.iso === data.preferred_language
  )?.name_en

  return <Definition icon='language' primary={name} emptyText={'Loading'} />
}

export const Name = () => {
  const {data} = useContext(EntitiyShowViewContext)
  let label

  if (!('business_card' in data)) {
    return null
  }

  const name =
    data.type !== 'PERSON'
      ? data.name
      : `${data.business_card.given_name} ${data.business_card.family_name}`

  if (data.type?.startsWith('ORGANIZATION')) {
    label = 'Organization Name'
  } else if (data.type === 'ORGANIZER') {
    label = 'Organizer Name'
  }

  return (
    <Definition icon='cards' primary={name} label={label} emptyText='No name' />
  )
}

export const PhoneNumbers = () => {
  const {data} = useContext(EntitiyShowViewContext)
  let phone_numbers

  if ('business_card' in data) {
    phone_numbers = data.business_card
      .phone_numbers as BusinessCardPhoneNumber[]
  }

  return (
    <>
      {/* @ts-expect-error Definition needs rewrite */}
      <Definition
        icon='phone'
        list={phone_numbers}
        primary={phoneNumber}
        secondary={phoneNumberType}
        emptyText='No phones'
      />
    </>
  )
}

export const VenueCapacity = () => {
  const {data} = useContext(EntitiyShowViewContext)

  if (data.type !== 'VENUE') {
    return null
  }

  return (
    <Definition
      icon='capacity'
      list={[
        {value: data.stage_capacity_seated, label: 'Seated capacity'},
        {value: data.stage_capacity_standing, label: 'Standing capacity'},
      ]}
      primary='value'
      secondary='label'
      emptyText='N/A'
    />
  )
}

export const Website = () => {
  const {data} = useContext(EntitiyShowViewContext)
  let website

  if ('business_card' in data) {
    website = data.business_card.website
  }

  return <Definition icon='earth' primary={website} emptyText='No website' />
}

export const WhnueId = () => {
  const {data} = useContext(EntitiyShowViewContext)

  if (!('global_id' in data)) {
    return null
  }

  return (
    <Definition
      icon='commission-number'
      primary={data.global_id}
      secondary='Whnue ID'
      emptyText='N/A'
    />
  )
}

export const SapActCode = () => {
  const {data} = useContext(EntitiyShowViewContext)

  if (!('custom_fields' in data)) {
    return null
  }

  return (
    <Definition
      icon='commission-number'
      primary={data.custom_fields.sap_act_code}
      secondary={getCustomLabel('sap_act_code')}
      emptyText='N/A'
      showSecondaryWhenEmpty
    />
  )
}

export const SapActAsl = () => {
  const {data} = useContext(EntitiyShowViewContext)

  if (!('custom_fields' in data)) {
    return null
  }

  return (
    <Definition
      icon='commission-number'
      primary={data.custom_fields.sap_act_asl}
      secondary={getCustomLabel('sap_act_asl')}
      emptyText='N/A'
      showSecondaryWhenEmpty
    />
  )
}

export const SapOrganizerCustomerId = () => {
  const {data} = useContext(EntitiyShowViewContext)

  if (!('custom_fields' in data)) {
    return null
  }

  return (
    <Definition
      icon='commission-number'
      primary={data.custom_fields.sap_organizer_customer_id}
      secondary={getCustomLabel('sap_organizer_customer_id')}
      emptyText='N/A'
      showSecondaryWhenEmpty
    />
  )
}
