import {Spinner} from 'components/elements'
import {AppLayout} from 'components/layout'
import {useMultipleMonth} from './hooks'
import {MonthPicker, ShowKind} from '../components'
import {OneMonth} from '../views'

export const MultipleMonth = () => {
  const props = useMultipleMonth()

  return (
    <AppLayout
      title='Multiple acts - One month'
      headerFilters={
        <>
          <MonthPicker />
          <ShowKind />
        </>
      }
    >
      {props.isLoading && <Spinner overlay />}
      <OneMonth {...props} />
    </AppLayout>
  )
}
