import {createReducer} from '@reduxjs/toolkit'
import {
  copyContract,
  createContractSuccess,
  initializeContract,
  initializeContractFailure,
  initializeContractSuccess,
  resetContract,
  showContract,
  showContractFailure,
  showContractSuccess,
  updateContractSuccess,
} from '../actions'
import type {ContractStoreResult} from '../types'

export const resultReducer = createReducer<ContractStoreResult>(
  null,
  (builder) => {
    builder
      .addCase(resetContract, () => null)
      .addCase(copyContract, () => null)
      .addCase(initializeContract, () => null)
      .addCase(initializeContractSuccess, (s, a) =>
        Object.assign({}, s, a.payload)
      )
      .addCase(initializeContractFailure, () => null)
      .addCase(showContract, () => null)
      .addCase(showContractSuccess, (s, a) => Object.assign({}, s, a.payload))
      .addCase(showContractFailure, () => null)
      .addCase(createContractSuccess, (s, a) => Object.assign({}, s, a.payload))
      .addCase(updateContractSuccess, (s, a) => Object.assign({}, s, a.payload))
  }
)
