import {ButtonGroup, Button} from 'components/elements'
import type {IconName} from '../Icon'

type ButtonSelectOptionBase<Value> = {
  value: Value
}
type ButtonSelectOptionLabel = {
  label: string
}
type ButtonSelectOptionIcon = {
  icon: IconName
}

export type ButtonSelectOption<Value> = ButtonSelectOptionBase<Value> &
  (ButtonSelectOptionIcon | ButtonSelectOptionLabel)

type ButtonSelectItemProps<Value> = {
  onClick: () => void
  isSelected: boolean
  item: ButtonSelectOption<Value>
}

const ButtonSelectItem = <Value,>({
  onClick,
  isSelected,
  item,
}: ButtonSelectItemProps<Value>) => {
  const label = 'label' in item ? item.label : undefined
  const icon = 'icon' in item ? item.icon : undefined

  return (
    <Button
      label={label}
      icon={icon}
      isSelected={isSelected}
      onClick={() => onClick()}
    />
  )
}

interface ButtonSelectProps<Value> {
  onChange: (value: Value) => void
  value: Value
  options: ButtonSelectOption<Value>[]
}

export const ButtonSelect = <Value,>({
  value: selectedValue,
  onChange,
  options,
}: ButtonSelectProps<Value>) => {
  return (
    <ButtonGroup>
      {options.map((item) => (
        <ButtonSelectItem
          key={item.value as unknown as string}
          isSelected={item.value === selectedValue}
          item={item}
          onClick={() => onChange(item.value)}
        />
      ))}
    </ButtonGroup>
  )
}
