import {useFormikContext} from 'formik'
import {CONTRACT_DEFAULT_LANGUAGE, CONTRACT_LANGUAGES} from 'options/contract'
import type {Contract} from 'types/contract'
import {RefreshButton} from './RefreshButton'

export const RefreshSupplementaryContractText = (props: any) => {
  const {values, setFieldValue} = useFormikContext<Contract>()

  const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()

    const id = values.supplementary_contract.id
    const language = values.language
    const supplementary_contracts =
      values.terms_template.supplementary_contracts

    const lang = Object.keys(CONTRACT_LANGUAGES).includes(language)
      ? language
      : CONTRACT_DEFAULT_LANGUAGE

    const contract = supplementary_contracts.find((c) => c.id === id)

    if (!contract) {
      return
    }

    setFieldValue(
      'supplementary_contract_text',
      lang === 'en' ? contract.text_en : contract.text_da
    )
  }

  return (
    <RefreshButton
      {...props}
      tooltip='Reloading the text template will overwrite your changes below with the original text'
      onClick={handleClick}
    />
  )
}
