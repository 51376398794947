import {CloseButton} from 'components/elements'
import {useModal} from 'hooks/useModal'

export const ModalCloseButton = () => {
  const {inModal, closeModal} = useModal()

  if (!inModal) {
    return null
  }

  return <CloseButton onClick={closeModal} />
}
