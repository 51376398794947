import {useState} from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import {Icon, MobileMenu} from 'components/elements'
import {useIsDesktop} from 'hooks/useIsDesktop'
import {mq} from 'styles/variables'
import {UserMenuButton} from './Button'
import {UserMenuSectionHeader} from './SectionHeader'
import styles from './UserMenu.module.css'
import {useOrganizationSwitcher} from '../hooks'

const Root = styled.div`
  position: relative;
`

const Menu = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  padding-left: 20px;
`

const MenuInner = styled.div`
  padding: var(--spacing-u2_5);

  @media (${mq.desktop}) {
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: var(--borderRadius);
    box-shadow: var(--popoverBoxshadow);
    font-size: var(--dogSize);
    cursor: default;
    color: var(--primaryTextColor);
    z-index: var(--dropdown);
  }
`

interface HoverMenuTargetProps {
  isActive: boolean
  onMouseEnter: () => void
}
interface HoverMenuProps {
  target: (targetProps: HoverMenuTargetProps) => React.ReactElement
  content: () => React.ReactElement
}

const HoverMenu = ({target, content}: HoverMenuProps) => {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <Root onMouseLeave={() => setShowMenu(false)}>
      {target({isActive: showMenu, onMouseEnter: () => setShowMenu(true)})}
      {showMenu && <Menu>{content()}</Menu>}
    </Root>
  )
}

export const OrganizationSwitcher = () => {
  const isDesktop = useIsDesktop()
  const {bookingAgencies, switchBookingAgency} = useOrganizationSwitcher()

  const content = (
    <MenuInner>
      {bookingAgencies.map((ba) => (
        <UserMenuButton
          key={ba.id}
          className={cx(styles.contentButton)}
          onClick={() => {
            switchBookingAgency(ba.organization.id)
          }}
        >
          <UserMenuSectionHeader
            avatar='organizer'
            primary={ba.organization.name}
            secondary='Booking agency'
          />
        </UserMenuButton>
      ))}
    </MenuInner>
  )

  if (!isDesktop) {
    return (
      <MobileMenu
        title='Switch organization'
        target={({openMenu}) => (
          <UserMenuButton onClick={openMenu}>
            Switch organization
            <Icon icon='chevron-right' />
          </UserMenuButton>
        )}
      >
        {() => content}
      </MobileMenu>
    )
  }

  return (
    <HoverMenu
      target={({isActive, onMouseEnter}: HoverMenuTargetProps) => (
        <UserMenuButton active={isActive} onMouseEnter={onMouseEnter}>
          Switch organization
          <Icon icon='chevron-right' />
        </UserMenuButton>
      )}
      content={() => content}
    />
  )
}
