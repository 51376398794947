import {call} from 'redux-saga/effects'
import {rawFetch} from 'lib/fetch'

export function* sagaFetch(uri, method, body, options = {}) {
  return yield call(rawFetch, uri, {
    ...options,
    method,
    body,
  })
}

export function standardErrorAction(actionName, error, meta) {
  const {message, ...rest} = error

  if (import.meta.env.DEV) {
    console.info('standardErrorAction', actionName, message, meta)
  }

  return {
    type: actionName,
    error: error.message,
    ...rest,
    meta,
  }
}
