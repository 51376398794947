import type {SagaIterator} from 'redux-saga'
import {call} from 'redux-saga/effects'
import {getApiUrl} from 'api'
import {sagaFetch} from 'helpers/saga'
import type {ContractStatusType} from 'types/contract'
import type {PlannerEvent} from 'types/planner'
import type {FlowActionMeta} from './types'

export function getId(event: PlannerEvent, meta: FlowActionMeta) {
  if (meta.flow === 'production') {
    return event.id
  } else if (meta.flow === 'booking') {
    return event.contract_id
  }
}

export function getFlowBody(status: ContractStatusType, meta: FlowActionMeta) {
  if (meta.flow === 'production') {
    return {production_status: status}
  } else if (meta.flow === 'booking') {
    return {status: status}
  }
}

export function* handleEventUpdate(
  id: number,
  status: ContractStatusType,
  meta: FlowActionMeta
): SagaIterator {
  if (meta.flow === 'production') {
    // Fetch event
    const event = yield call(sagaFetch, getApiUrl('shows', id), 'get')
    // Update status
    const body = Object.assign({}, event, getFlowBody(status, meta))
    // Save event
    return yield call(sagaFetch, getApiUrl('shows', id), 'put', body)
  } else if (meta.flow === 'booking') {
    // Fetch contract
    const contract = yield call(sagaFetch, getApiUrl('contracts', id), 'get')
    // Update status
    const body = getFlowBody(status, meta)
    // Save event
    return yield call(sagaFetch, contract.status_url, 'post', body)
  }
}
