import type {PayloadAction} from '@reduxjs/toolkit'
import type {SagaIterator} from 'redux-saga'
import {
  fork,
  takeLatest,
  select,
  call,
  put,
  putResolve,
} from 'redux-saga/effects'
import {getApiUrl} from 'api'
import {toastrActions} from 'features/toastr'
import {dateDeforeAndAfter} from 'helpers/api'
import {sagaFetch, standardErrorAction} from 'helpers/saga'
import {CONTRACT_UPDATE_SUCCESS} from 'store/contract/constants'
import {ORGANIZATION, DEPARTMENT} from 'store/entity/constants'
import {prompt} from 'store/prompt'
import type {ContractInvoicingStatusType} from 'types/contract'
import {
  exportAgainSuccess,
  exportManuallySuccess,
  fetchSuccess,
  setInvoicingStatusSuccess,
  taxIdExemptionSuccess,
} from './actions'
import {
  EXPORT_MANUALLY,
  EXPORT_MANUALLY_FAILURE,
  EXPORT_AGAIN,
  EXPORT_AGAIN_FAILURE,
  TAX_ID_EXEMPTION,
  TAX_ID_EXEMPTION_FAILURE,
  SET_INVOICING_STATUS,
  SET_INVOICING_STATUS_FAILURE,
  FETCH,
  FETCH_FAILURE,
  SET_STARTDAY,
  SET_ENDDAY,
  SET_SHOW_EXPORTED,
  SET_SHOW_INVOICE_OFF,
  SET_FILTER,
} from './constants'
import type {
  ExportAgainParams,
  ExportManuallyParams,
  SetInvoicingStatusParams,
  TaxIdExemptionParams,
} from './types'

function* exportManually(
  action: PayloadAction<ExportManuallyParams>
): SagaIterator {
  try {
    yield call(
      sagaFetch,
      `/contracts/booking-contracts/${action.payload.contractId}/financial-export-status/`,
      'POST',
      {
        value: 'EXPORT_STATUS_EXPORTED_MANUALLY',
      }
    )
    yield put(toastrActions.successToast('Contract was exported manually'))
    yield put(exportManuallySuccess(action.payload))
  } catch (e: any) {
    console.error(e)
    yield put(toastrActions.errorToast('Could not export', 'Please try again'))
    yield put(standardErrorAction(EXPORT_MANUALLY_FAILURE, e))
  }
}

function* watchExportManually() {
  yield takeLatest(EXPORT_MANUALLY, exportManually)
}

function* exportAgain(action: PayloadAction<ExportAgainParams>) {
  try {
    const {contractId} = action.payload
    yield call(
      sagaFetch,
      `/contracts/booking-contracts/${contractId}/financial-export-status/`,
      'POST',
      {
        value: 'EXPORT_STATUS_TO_BE_EXPORTED_AGAIN',
      }
    )
    yield put(toastrActions.successToast('Contract will be exported again'))
    yield put(exportAgainSuccess(action.payload))
  } catch (e: any) {
    console.error(e)
    yield put(
      toastrActions.errorToast('Could not re-export', 'Please try again')
    )
    yield put(standardErrorAction(EXPORT_AGAIN_FAILURE, e))
  }
}

function* watchExportAgain() {
  yield takeLatest(EXPORT_AGAIN, exportAgain)
}

function* taxIdExemption(
  action: PayloadAction<TaxIdExemptionParams>
): SagaIterator {
  let shouldExempt = true
  const {id, name} = action.payload

  try {
    // Prompt user
    yield putResolve(
      prompt({
        title: 'Tax-ID excemption',
        description: `Are you sure ${name} should be allowed to have no Tax-ID`,
        confirmText: 'Allow no Tax-ID',
        cancelText: 'Tax-ID is required',
      })
    )
  } catch (e: any) {
    console.log('e', e)
    shouldExempt = false
  }

  try {
    const {validation_status} = yield call(
      sagaFetch,
      `/organizations/departments/organizers/${id}/tax-id-verification-exemption/`,
      'GET'
    )

    if (
      (validation_status === 'VALIDATION_STATUS_EXEMPT' && shouldExempt) ||
      (validation_status !== 'VALIDATION_STATUS_EXEMPT' && !shouldExempt)
    ) {
      yield put(toastrActions.successToast('No changes made'))
      return
    }

    const response = yield call(
      sagaFetch,
      `/organizations/departments/organizers/${id}/tax-id-verification-exemption/`,
      'PUT',
      {
        validation_status: shouldExempt
          ? 'VALIDATION_STATUS_EXEMPT'
          : 'VALIDATION_STATUS_UNVALIDATED',
      }
    )

    yield put(toastrActions.successToast('Tax-ID exemption status updated'))
    yield put(
      taxIdExemptionSuccess({
        id,
        name,
        validation_status: response.validation_status,
      })
    )
  } catch (e: any) {
    console.log(Object.keys(e))
    console.error(e)
    yield put(
      toastrActions.errorToast(
        e.payload?.detail ?? 'Unknown error',
        'Could not update Tax-ID exemption status'
      )
    )
    yield put(standardErrorAction(TAX_ID_EXEMPTION_FAILURE, e))
  }
}

function* watchTaxIdExemption() {
  yield takeLatest(TAX_ID_EXEMPTION, taxIdExemption)
}

function* setInvoicingStatus(
  action: PayloadAction<SetInvoicingStatusParams>
): SagaIterator {
  const {id, status} = action.payload
  let external_invoice_id
  if (status === 'INVOICING_STATUS_INVOICE_SENT') {
    try {
      // Prompt user
      const res = yield putResolve(
        prompt({
          title: 'Invoicing number',
          description: `Please write the invoicing number, if given:`,
          showInput: true,
          inputPlaceholder: 'Invoicing number',
          confirmText: 'Mark invoice as sent',
        })
      )
      external_invoice_id = res.inputValue
    } catch (e: any) {
      throw new Error('Status update cancelled')
    }
  }

  try {
    yield call(
      sagaFetch,
      `/contracts/booking-contracts/${id}/invoicing-status/`,
      'GET'
    )

    const response = yield call(
      sagaFetch,
      `/contracts/booking-contracts/${id}/invoicing-status/`,
      'POST',
      {
        value: status,
        external_invoice_id,
      }
    )

    yield put(toastrActions.successToast('Invoicing status updated'))
    yield put(
      setInvoicingStatusSuccess({
        id,
        status,
        invoicing_status: response,
      })
    )
  } catch (e: any) {
    console.log(Object.keys(e))
    console.error(e)
    yield put(
      toastrActions.errorToast(
        e.payload?.detail ?? 'Unknown error',
        'Could not update invoicing status'
      )
    )
    yield put(standardErrorAction(SET_INVOICING_STATUS_FAILURE, e))
  }
}

function* watchsetInvoicingStatus() {
  yield takeLatest(SET_INVOICING_STATUS, setInvoicingStatus)
}

function* fetchData(): SagaIterator {
  try {
    const {startDay, endDay, filter} = yield select(
      ({economyPlanner}) => economyPlanner
    )

    const dates = dateDeforeAndAfter({
      startMonth: new Date(startDay),
      endMonth: new Date(endDay),
    })

    let exportStatus
    let invoicingStatus: ContractInvoicingStatusType[] = []

    if (filter === 'pendingExport') {
      exportStatus = [
        'EXPORT_STATUS_TO_BE_EXPORTED',
        'EXPORT_STATUS_TO_BE_EXPORTED_AGAIN',
      ]
    } else if (filter === 'exported') {
      exportStatus = [
        'EXPORT_STATUS_EXPORTED',
        'EXPORT_STATUS_EXPORTED_MANUALLY',
      ]
      invoicingStatus = ['INVOICING_STATUS_NO_INVOICE']
    } else if (filter === 'invoiceSent') {
      exportStatus = [
        'EXPORT_STATUS_EXPORTED',
        'EXPORT_STATUS_EXPORTED_MANUALLY',
      ]
      invoicingStatus = ['INVOICING_STATUS_INVOICE_SENT']
    } else if (filter === 'invoicePaid') {
      exportStatus = [
        'EXPORT_STATUS_EXPORTED',
        'EXPORT_STATUS_EXPORTED_MANUALLY',
      ]
      invoicingStatus = ['INVOICING_STATUS_INVOICE_PAID']
    } else {
      exportStatus = ['EXPORT_STATUS_NO_EXPORT']
    }

    const data = yield call(
      sagaFetch,
      getApiUrl('plannerEconomy'),
      'GET',
      null,
      {
        queryParams: {
          after: dates.after,
          before: dates.before,
          export_statuses: exportStatus.join(','),
          invoicing_statuses: invoicingStatus.join(','),
        },
      }
    )
    yield put(fetchSuccess(data))
  } catch (e: any) {
    console.error(e)
    yield put(
      toastrActions.errorToast('Could not fetch list', 'Please try again')
    )
    yield put(standardErrorAction(FETCH_FAILURE, e))
  }
}

function* watchFetchData() {
  yield takeLatest([FETCH], fetchData)
}

function* watchReFetchData() {
  yield takeLatest(
    [
      SET_STARTDAY,
      SET_ENDDAY,
      SET_FILTER,
      SET_SHOW_EXPORTED,
      SET_SHOW_INVOICE_OFF,
      CONTRACT_UPDATE_SUCCESS,
      ORGANIZATION.UPDATE_SUCCESS,
      DEPARTMENT.UPDATE_SUCCESS,
    ],
    function* () {
      yield put({type: FETCH})
    }
  )
}

export function* economyPlannerSaga() {
  yield fork(watchExportManually)
  yield fork(watchExportAgain)
  yield fork(watchTaxIdExemption)
  yield fork(watchsetInvoicingStatus)
  yield fork(watchFetchData)
  yield fork(watchReFetchData)
}
