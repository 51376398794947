import type {IconName} from 'components/elements/Icon'
import type {
  ContractAuditTrailInternalStatuses,
  ContractStatusType,
} from '../types/contract'
import {ContractAuditTrailStatuses} from '../types/contract'

interface ContractAuditTrailStatusOption {
  name: string
  tooltip?: (input: {
    name: string
    invoiceId?: number | string | null
  }) => React.ReactNode
  icon: IconName
}

export const CONTRACT_STATUS_TO_AUDIT_TRAIL_STATUS: {
  [key in ContractStatusType]: ContractAuditTrailStatuses
} = {
  CONTRACT_STATUS_TBC: ContractAuditTrailStatuses.TBC,
  CONTRACT_STATUS_CONFIRMED: ContractAuditTrailStatuses.CONFIRMED,
  CONTRACT_STATUS_FINALIZED: ContractAuditTrailStatuses.FINALIZED,
  CONTRACT_STATUS_SIGNED: ContractAuditTrailStatuses.SIGNED,
  CONTRACT_STATUS_UNSIGNED: ContractAuditTrailStatuses.UNSIGNED,
  CONTRACT_STATUS_PARTIALLY_SIGNED: ContractAuditTrailStatuses.PARTIALLY_SIGNED,
  CONTRACT_STATUS_FULLY_SIGNED: ContractAuditTrailStatuses.FULLY_SIGNED,
  CONTRACT_STATUS_CANCELLED: ContractAuditTrailStatuses.CANCELLED,
}

export const CONTRACT_AUDIT_TRAIL_STATUS_OPTIONS: {
  [key in ContractAuditTrailInternalStatuses]?: ContractAuditTrailStatusOption
} = {
  [ContractAuditTrailStatuses.TBC]: {
    name: 'created',
    tooltip: ({name}) => `The contract was created by ${name}`,
    icon: 'pencil',
  },
  [ContractAuditTrailStatuses.CONFIRMED]: {
    name: 'confirmed',
    tooltip: () => 'The show was confirmed by the organizer and us',
    icon: 'checkmark-fill',
  },
  [ContractAuditTrailStatuses.FINALIZED]: {
    name: 'sent to organizer',
    tooltip: ({name}) => `The contract was sent to the organizer by ${name}`,
    icon: 'mail',
  },
  [ContractAuditTrailStatuses.UNSIGNED]: {
    name: 'signing link sent',
    tooltip: ({name}) =>
      `The signing link was sent to the organizer by ${name}`,
    icon: 'link',
  },
  [ContractAuditTrailStatuses.SIGNED_PROMISEE]: {
    name: 'signed by organizer',
    tooltip: () => 'The contract has been signed by the organizer',
    icon: 'phone',
  },
  [ContractAuditTrailStatuses.SIGNED_PROMISOR]: {
    name: 'signed by booker',
    tooltip: () => 'The contract has been signed by the booker',
    icon: 'final',
  },
  [ContractAuditTrailStatuses.SIGNED]: {
    name: 'signed',
    tooltip: () => 'The contract has been signed by both parties',
    icon: 'signed',
  },
  [ContractAuditTrailStatuses.CANCELLED]: {
    name: 'cancelled',
    tooltip: ({name}) => `The show was cancelled by ${name}`,
    icon: 'error',
  },
  [ContractAuditTrailStatuses.UNCANCELLED]: {
    name: 'Reopened',
    tooltip: ({name}) => `The show was reopened by ${name}`,
    icon: 'arrow-left',
  },
  [ContractAuditTrailStatuses.REEDITED]: {
    name: 'Back to edit mode',
    tooltip: ({name}) => `The show was reopened for editing by ${name}`,
    icon: 'arrow-left',
  },
  INVOICING_STATUS_INVOICE_SENT: {
    name: 'Invoice sent',
    tooltip: ({invoiceId}) =>
      `Invoicing id: ${invoiceId ? `#${invoiceId}` : 'Unknown'}`,
    icon: 'money',
  },
  INVOICING_STATUS_INVOICE_PAID: {
    name: 'Invoice paid',
    icon: 'safe',
  },
}
