import {startOfMonth} from 'date-fns'
import {useDispatch, useSelector} from 'react-redux'
import {MonthPicker as MonthPickerComp} from 'components/elements'
import {setMonth} from 'store/booking/actions'
import type {BookingStoreViewsData, BookingViews} from 'store/booking/types'
import type {RootStore} from 'store/types'
import {formatDate} from 'utils'

interface SelectorData {
  view: BookingViews
  data: BookingStoreViewsData
}

export const useData = () =>
  useSelector<RootStore, SelectorData>(({booking}) => {
    const view = booking.shared.view
    const data = booking.views[view]
    return {view, data}
  })

export const MonthPicker = () => {
  const dispatch = useDispatch()
  const {view, data} = useData()

  if (!data) {
    return null
  }

  if (!('month' in data)) {
    return null
  }

  const value = startOfMonth(data.month)

  const handleChange = (value: string | number | Date | null) => {
    if (!(value instanceof Date)) {
      return
    }

    dispatch(
      setMonth({
        month: formatDate(value, 'ISOYearMonth'),
        view: view,
      })
    )
  }

  return (
    <MonthPickerComp showNavigation value={value} onChange={handleChange} />
  )
}
