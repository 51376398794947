import * as Sentry from '@sentry/react'
import get from 'lodash/get'
import {takeLatest, fork, put, call} from 'redux-saga/effects'
import {getApiUrl} from 'api'
import {sagaFetch} from 'helpers/saga'
import {CREATE_SUCCESS, UPDATE_SUCCESS} from 'store/entity/tour/constants'
import {SHOW_CREATED_SUCCESS, SHOW_UPDATED_SUCCESS} from '../constants'

function* tourCreatedCallback(action) {
  try {
    const id = get(action.payload, 'show.id') || get(action.payload, 'id')

    const payload = yield call(sagaFetch, getApiUrl('plannerEvents', id), 'get')
    yield put({type: SHOW_CREATED_SUCCESS, payload})
    return payload
  } catch (e) {
    Sentry.captureException(e)
  }
}

function* tourUpdatedCallback(action) {
  try {
    const id = get(action.payload, 'show.id') || get(action.payload, 'id')

    const payload = yield call(sagaFetch, getApiUrl('plannerEvents', id), 'get')
    yield put({type: SHOW_UPDATED_SUCCESS, payload})
    return payload
  } catch (e) {
    Sentry.captureException(e)
  }
}

export function* tourCallbacksSaga() {
  // CREATE
  yield fork(function* () {
    yield takeLatest([CREATE_SUCCESS], tourCreatedCallback)
  })
  // UPDATE
  yield fork(function* () {
    yield takeLatest([UPDATE_SUCCESS], tourUpdatedCallback)
  })
}
