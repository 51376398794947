import {MyActsSelect} from 'components/connected'
import type {FlowActionSetFiltersPayload} from 'store/flow/types'
import styles from './filters.module.css'

interface ActSelectorProps {
  value: number | null
  onChange: (data: FlowActionSetFiltersPayload) => void
  isSelected: boolean
}

export const ActSelector = ({
  value,
  onChange,
  isSelected,
}: ActSelectorProps) => {
  const handleChange = (actId: number) =>
    onChange({
      kind: 'act',
      actId,
    })

  return (
    <MyActsSelect
      isSelected={isSelected}
      value={value}
      placeholder='Select Act...'
      onChange={handleChange}
      className={styles.actSelector}
    />
  )
}
