import {Spinner} from 'components/elements'
import {Main} from 'containers/Core'
import {useDepartment, useEntityView} from './hooks'
import {EntityEditView} from './shared/EditView'
import {EntityShowView} from './shared/ShowView'

interface DepartmentCRUDViewProps {
  onCancel: () => void
}

export const DepartmentCRUDView: React.FC<
  React.PropsWithChildren<DepartmentCRUDViewProps>
> = ({onCancel}) => {
  const {viewMode, setViewMode} = useEntityView()
  const {isLoading, data} = useDepartment()

  if (isLoading) {
    return <Spinner />
  }

  if (viewMode === 'new' || viewMode === 'edit') {
    return (
      <Main flex>
        <EntityEditView
          initialValues={data}
          onCancel={onCancel}
          onSubmitSuccess={() => setViewMode('show')}
        />
      </Main>
    )
  }

  return (
    <Main>
      <EntityShowView data={data} onEditClick={() => setViewMode('edit')} />
    </Main>
  )
}
