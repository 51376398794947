import * as yup from 'yup'
import {
  businessCardValidationSchema,
  eanValidationSchema,
  nameValidationSchema,
  taxIdValidationSchema,
} from './shared'

export const organizationValidationSchema = yup.object().shape({
  name: nameValidationSchema,
  business_card: businessCardValidationSchema,
  ean: eanValidationSchema,
  tax_id: taxIdValidationSchema,
})
