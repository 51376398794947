import {useFormikContext} from 'formik'
import omit from 'lodash/omit'
import {Toggle} from 'components/form'
import {Column, Container, Row, Section} from 'containers/Form'
import type {StorableDepartment} from 'types/department'
import {Contact, Address, AdditionalInformation} from '../sections'

export const EntityFormPanelContactInherited = () => {
  const {values, setFieldValue} = useFormikContext<StorableDepartment>()
  const handleInheritanceChange = (value: boolean) => {
    // Switch to own business card
    if (value) {
      // If people keep pushing the button, guard if values are not defined
      if (!values.business_card) {
        return
      }
      const {phone_numbers, email_addresses, ...rest} = values.business_card
      const businessCard = omit(
        {
          ...rest,
          phone_numbers: phone_numbers.map((o) => omit(o, 'id')),
          email_addresses: email_addresses.map((o) => omit(o, 'id')),
        },
        'id'
      )

      setFieldValue('business_card', businessCard)
      // Switch to inherited business card
    } else {
      setFieldValue('business_card', null)
    }
  }

  const disableInherited = !values.business_card_is_own

  return (
    <Container>
      <Section label='Inherit data from Organizer'>
        <Row>
          <Column>
            <Toggle
              model='business_card_is_own'
              onChange={handleInheritanceChange}
              inactiveText='Inherit'
              activeText='Own data'
              grid='fit'
            />
          </Column>
        </Row>
      </Section>

      <Contact disabled={disableInherited} />
      <Address disabled={disableInherited} />
      <AdditionalInformation disabled={disableInherited} />
    </Container>
  )
}
