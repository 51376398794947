import {ButtonSelect} from 'components/elements'
import type {FlowActionSetFiltersPayload} from 'store/flow/types'

interface GeneralKindSelectorProps {
  value: string | null
  onChange: (data: FlowActionSetFiltersPayload) => void
}

export const GeneralKindSelector = ({
  value,
  onChange,
}: GeneralKindSelectorProps) => {
  const handleChange = (kind: string | null) => {
    onChange({
      kind,
    })
  }

  return (
    <ButtonSelect<string | null>
      value={value}
      onChange={handleChange}
      options={[
        {label: 'My acts', value: 'represented_by_me', icon: 'user'},
        {label: 'Roster', value: 'roster', icon: 'group'},
      ]}
    />
  )
}
