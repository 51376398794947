import {useState} from 'react'
import {captureException} from '@sentry/react'
import {reduce} from 'lodash'
import {useDispatch} from 'react-redux'
import parser from 'ua-parser-js'
import {toastrActions} from 'features/toastr'
import {apiFetchReturnResponse} from 'lib/fetch'
import type {HttpMethod} from 'lib/fetch/types'
import type {DocumentFile} from 'types/document'
import type {Url} from 'types/generic'

function downloadFile(sUrl: string) {
  const browser = parser(window.navigator.userAgent)

  // If in Chrome or Safari - download via virtual link click
  if (
    ['webkit', 'blink'].includes(browser?.engine?.name?.toLowerCase() ?? '')
  ) {
    sUrl += '?download'
    // Creating new link node.
    const link = document.createElement('a')
    link.href = sUrl
    link.setAttribute('target', '_blank')

    if (link.download !== undefined) {
      // Set HTML5 download attribute. This will prevent file from opening if supported.
      const fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length)
      link.download = fileName
    }

    link.click()
    return true
  }

  window.open(sUrl, '_blank')
  return true
}

interface DocumentDownloadToasts {
  noContent?: {
    message?: string
    description?: string
  }
  notFound?: {
    message?: string
    description?: string
  }
  unknown?: {
    message?: string
    description?: string
  }
}

export interface DocumentDownloadProps {
  directFileUrl?: Url
  url?: Url
  method?: HttpMethod
  urlParams?: Record<string, unknown>
  queryParams?: Record<string, unknown>
  queryParamsFormatter?: (data: any) => any
  successCallback?: (payload: any) => void
  failureCallback?: (error: Error) => void
  toasts?: DocumentDownloadToasts
}

export function useDocumentDownload() {
  const [activityUrl, setActivityUrl] = useState<string | undefined>()
  const dispatch = useDispatch()

  async function fetchPDF({
    directFileUrl,
    url,
    method = 'GET',
    urlParams,
    queryParams: queryParamsRaw,
    queryParamsFormatter,
    successCallback,
    failureCallback,
    toasts,
  }: DocumentDownloadProps) {
    try {
      let payload
      let fileUrl

      if (!url && !directFileUrl) {
        throw new Error('DocumentDownload: No url or directFileUrl given')
      }

      setActivityUrl(directFileUrl ?? url ?? 'unknown')

      if (directFileUrl) {
        fileUrl = directFileUrl
      } else {
        let uri = url

        if (!uri) {
          throw new Error('DocumentDownload: No url given')
        }

        if (urlParams) {
          uri = reduce(
            urlParams,
            (result, val, key) => result.replace(`{${key}}`, val as string),
            uri
          )
        }

        let queryParams
        if (queryParamsRaw) {
          queryParams = queryParamsFormatter
            ? queryParamsFormatter(queryParamsRaw)
            : queryParamsRaw
        }

        const {payload, response} = await apiFetchReturnResponse<
          DocumentFile | {file: DocumentFile}
        >({
          uri,
          options: {
            method: method,
            queryParams,
          },
        })

        if (response.status === 204) {
          throw new Error('FetchPDF: No content present')
        }

        fileUrl = 'file' in payload ? payload.file.file_url : payload.file_url
      }

      if (!fileUrl) {
        throw new Error('FetchPDF: No file_url present')
      }

      if (!downloadFile(fileUrl)) {
        throw new Error('FetchPDF: Could not download file')
      }

      if (successCallback) {
        successCallback(payload)
      }
    } catch (e: any) {
      captureException(e)

      if (failureCallback) {
        failureCallback(e)
      }

      if (e.message === 'FetchPDF: No content present') {
        e.handled = true
        if (!failureCallback) {
          dispatch(
            toastrActions.warningToast(
              toasts?.noContent?.message ?? 'Content not found',
              toasts?.noContent?.message
                ? toasts?.noContent?.description ?? undefined
                : 'The file you are trying to download has no content. Try adjusting the export settings to make sure some content is exported.'
            )
          )
        }
      } else if (e.code === 404) {
        e.handled = true
        if (!failureCallback) {
          dispatch(
            toastrActions.errorToast(
              toasts?.notFound?.message ?? 'Content not found',
              toasts?.notFound?.message
                ? toasts?.notFound?.description
                : undefined
            )
          )
        }
      } else {
        if (!failureCallback) {
          dispatch(
            toastrActions.errorToast(
              toasts?.unknown?.message ?? 'Unknown download error',
              toasts?.unknown?.message
                ? toasts?.unknown?.description
                : undefined
            )
          )
        }
      }
    } finally {
      setActivityUrl(undefined)
    }
  }

  return {
    fetchPDF,
    isFetching: !!activityUrl,
  }
}
