import {useFormikContext} from 'formik'
import {Input, DatePicker, Toggle} from 'components/form'
import {Container, Section, Row, Column} from 'containers/Form'
import type {Contract} from 'types/contract'
import type {ContractPanelProps} from './types'
import {
  EconomicTermsId,
  RefreshCustomEconomicTerms,
  RefreshSupplementaryContractText,
  SupplementaryContractId,
} from '../fields'

export const Scope: React.FC<React.PropsWithChildren<ContractPanelProps>> = ({
  locked,
}) => {
  const {values} = useFormikContext<Contract>()

  return (
    <Container>
      <Section label='Economic terms'>
        <Row paddingBottom='none'>
          <Column>
            <EconomicTermsId
              theme='form'
              model='economic_terms_id'
              disabled={locked}
              label='Text template'
              grid='10'
              horizontal
            />
            <RefreshCustomEconomicTerms grid='fit' />
          </Column>
        </Row>
        <Row paddingTop='none'>
          <Column>
            <Input
              model='custom_economic_terms'
              disabled={locked}
              multiline
              rows='12'
            />
          </Column>
        </Row>
      </Section>

      <Section secondary label='Scope of contract'>
        <Row paddingBottom='none'>
          <Column>
            <SupplementaryContractId
              theme='form'
              model='supplementary_contract.id'
              disabled={locked}
              label='Text template'
              horizontal
              grid='10'
            />
            <RefreshSupplementaryContractText grid='fit' />
          </Column>
        </Row>
        <Row paddingTop='none'>
          <Column>
            <Input
              model='supplementary_contract_text'
              disabled={locked}
              multiline
              rows='12'
            />
          </Column>
        </Row>
      </Section>

      <Section
        secondary
        label='Date of disclosure'
        action={
          <Toggle
            model='enable_disclosure_date'
            inactiveIcon='invisible'
            activeIcon='visible'
            size='small'
            rawField
          />
        }
      >
        {!!values.enable_disclosure_date && (
          <Row>
            <Column>
              <DatePicker
                model='show.disclose_at'
                placeholder='Disclose at'
                maxDate={values.show.start_at}
                isClearable
              />
            </Column>
            <Column />
          </Row>
        )}
      </Section>
    </Container>
  )
}
