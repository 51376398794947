import {addDays} from 'date-fns'
import {useFormikContext} from 'formik'
import {ActId} from 'app/modal/Contract/Form/fields'
import {Input, DatePicker} from 'components/form'
import {Container, Section, Column, Row} from 'containers/Form'
import type {Tour} from 'types/tour'
import {formatDate} from 'utils'

export const EntityFormPanelTour = () => {
  const {values} = useFormikContext<Tour>()

  return (
    <Container>
      <Section>
        <Row>
          <Column>
            <ActId
              model='stakeholder_act.department.id'
              disabled={!!values.id}
              placeholder='Please select act...'
            />
          </Column>
          <Column />
        </Row>
        <Row>
          <Column>
            <Input model='text' label='Title' placeholder='Title of the tour' />
          </Column>
          <Column />
        </Row>
      </Section>

      <Section>
        <Row>
          <Column>
            <DatePicker
              model='start_at'
              label='Start on'
              placeholder='Select start date...'
            />
          </Column>
          <Column>
            <DatePicker
              model='end_at'
              label='End on'
              placeholder='Select end date...'
              minDate={
                values?.start_at
                  ? formatDate(
                      addDays(new Date(values.start_at), 1),
                      'ISOYearMonthDay'
                    )
                  : undefined
              }
            />
          </Column>
        </Row>
      </Section>
    </Container>
  )
}
