import {useState} from 'react'
import {EmptyMessage} from 'app/main/Booking/components'
import type {BadgeColor} from 'components/elements'
import {Badge} from 'components/elements'
import {Table} from 'components/elements/Table'
import type {
  CellRenderer,
  TableSortDirection,
} from 'components/elements/Table/types'
import {useContract} from 'hooks/useContract'
import {CONTRACT_STATUS_OPTIONS} from 'options/contractStatus'
import type {ContractInvoicingStatusType, ContractNumber} from 'types/contract'
import type {PlannerEconomy} from 'types/planner'
import {sortData} from './helpers'
import {ActionsMenu} from './ListActions'

export interface AccountingListExtraProps {
  onExportManually: (contractId: ContractNumber) => void
  onExportAgain: (contractId: ContractNumber) => void
  onTaxIdExemption: (id: number, name: string) => void
  onInvoiceStatus: (id: number, status: ContractInvoicingStatusType) => void
}

// eslint-disable-next-line
const TaxIdRenderer: CellRenderer<PlannerEconomy, AccountingListExtraProps> = ({
  item,
}) => {
  if (!item) {
    return <></>
  }

  const {taxId, taxIdStatus} = item

  if (taxId) {
    return <>{taxId}</>
  }
  if (taxIdStatus === 'VALIDATION_STATUS_EXEMPT') {
    return (
      <Badge color='secondary' inverted>
        Tax-ID exempt
      </Badge>
    )
  }
  return <Badge color='alert'>No Tax-ID</Badge>
}

const ExportStatusRenderer: CellRenderer<
  PlannerEconomy,
  AccountingListExtraProps
> = ({item}) => {
  if (!item) {
    return <></>
  }

  const {exportStatus, invoicingStatus, invoicingStatusExternalId} = item

  if (!exportStatus) {
    return <></>
  }

  const status = [
    'INVOICING_STATUS_INVOICE_SENT',
    'INVOICING_STATUS_INVOICE_PAID',
  ].includes(invoicingStatus ?? '')
    ? invoicingStatus
    : exportStatus

  let text
  let color: BadgeColor
  let inverted

  switch (status) {
    case 'INVOICING_STATUS_INVOICE_SENT':
      text = invoicingStatusExternalId ? `Sent` : 'Sent'
      color = 'info'
      break
    case 'INVOICING_STATUS_INVOICE_PAID':
      text = invoicingStatusExternalId ? `Paid` : 'Paid'
      color = 'success'
      break
    case 'EXPORT_STATUS_NO_EXPORT':
      text = 'Invoice off'
      color = 'secondary'
      inverted = true
      break
    case 'EXPORT_STATUS_TO_BE_EXPORTED':
    case 'EXPORT_STATUS_TO_BE_EXPORTED_AGAIN':
      text = 'Export pending'
      color = 'primary'
      inverted = true
      break
    case 'EXPORT_STATUS_EXPORTED':
    case 'EXPORT_STATUS_EXPORTED_MANUALLY':
      text = 'Invoice pending'
      color = 'primary'
      break
    default:
      text = 'Unknown'
      color = 'alert'
      inverted = true
      break
  }

  return (
    <div>
      <Badge inverted={inverted} color={color}>
        {text}
      </Badge>
      {invoicingStatusExternalId}
    </div>
  )
}

const ContractStatusRenderer: CellRenderer<
  PlannerEconomy,
  AccountingListExtraProps
> = ({item}) => {
  if (!item) {
    return <></>
  }

  const {status, contractId} = item

  const options = CONTRACT_STATUS_OPTIONS[status]

  let text = options ? options.name : status
  let color: BadgeColor = 'alert'
  let inverted

  switch (status) {
    case 'CONTRACT_STATUS_TBC':
    case 'CONTRACT_STATUS_CONFIRMED':
      color = 'secondary'
      inverted = true
      break
    case 'CONTRACT_STATUS_FINALIZED':
      break
    case 'CONTRACT_STATUS_SIGNED':
      color = 'success'
      break
    case 'CONTRACT_STATUS_CANCELLED':
      color = 'secondary'
      inverted = true
      break
    default:
      text = 'Unknown'
      color = 'alert'
      inverted = true
      break
  }

  return (
    <div>
      <Badge inverted={inverted} color={color}>
        {text}
      </Badge>
      {!!contractId && contractId !== 'TBC' && ` #${contractId}`}
    </div>
  )
}

interface AccountingListProps extends AccountingListExtraProps {
  data: PlannerEconomy[]
}

export const AccountingList = ({
  data,
  onExportManually,
  onExportAgain,
  onTaxIdExemption,
  onInvoiceStatus,
}: AccountingListProps) => {
  const [sortBy, setSortBy] = useState('date')
  const [sortDirection, setSortDirection] = useState<TableSortDirection>('ASC')
  const {openContract} = useContract()

  const onSort = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string
    sortDirection: TableSortDirection
  }) => {
    setSortBy(sortBy)
    setSortDirection(sortDirection)
  }

  const sortedData = sortData(data, sortBy, sortDirection)

  return (
    <Table
      data={sortedData}
      sortBy={sortBy}
      sortDirection={sortDirection}
      setSort={onSort}
      onRowClick={(item) => openContract(item.id)}
      extraProps={{
        onExportManually,
        onExportAgain,
        onTaxIdExemption,
        onInvoiceStatus,
      }}
      cells={[
        {id: 'date', sortKey: 'date', label: 'Date', width: 160, flexGrow: 1},
        {
          id: 'status',
          sortKey: 'status',
          label: 'Contract status',
          renderer: ContractStatusRenderer,
          width: 160,
        },

        {
          id: 'actName',
          sortKey: 'actName',
          label: 'Act',
          width: 200,
          flexGrow: 1,
        },
        {
          id: 'organizerName',
          sortKey: 'organizerName',
          label: 'Invoice recipient',
          width: 200,
          flexGrow: 1,
        },
        // {
        //   id: 'taxId',
        //   sortKey: 'taxId',
        //   label: 'Tax-ID',
        //   width: 100,
        //   flexGrow: 1,
        //   renderer: TaxIdRenderer,
        // },
        {
          id: 'sapCustomerId',
          sortKey: 'sapCustomerId',
          label: 'SAP Customer-Id',
          width: 100,
          flexGrow: 1,
        },
        {
          id: 'exportStatus',
          sortKey: 'exportStatus',
          label: 'Invoice status',
          renderer: ExportStatusRenderer,
          width: 160,
        },
        {
          id: 'actions',
          renderer: ActionsMenu,
          width: 60,
        },
      ]}
      emptyMessage={
        <EmptyMessage
          title='There are no events in the chosen time span'
          description='Please change the time span in the filters above'
        />
      }
    />
  )
}
