import styled from 'styled-components'
import {colors} from 'styles/variables'
import type {ToastKind} from 'types/toast'

interface RootProps {
  visible: boolean
  kind: ToastKind
}

const getTheme = (kind: ToastKind) => {
  switch (kind) {
    case 'success':
      return `
        background: ${colors.gossamer};
        color: ${colors.white};`

    case 'warning':
      return `
        background-color: ${colors.casablanca};
        color: ${colors.white};`

    case 'error':
      return `
        background-color: ${colors.crimson};
        color: ${colors.white};`

    case 'info':
      return `
        background-color: ${colors.pictonBlue};
        color: ${colors.white};`
  }
}

export const Root = styled.div<RootProps>`
  box-shadow: var(--popoverBoxshadow);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  max-width: 500px;
  cursor: pointer;
  opacity: ${({visible}) => (visible ? 1 : 0)};
  transform: translateX(${({visible}) => (visible ? 0 : '30px')});
  transition: opacity var(--transitionTime) ease-in-out,
    transform var(--transitionTime) ease-in-out;

  ${({kind}) => getTheme(kind)}
`

export const IconWrapper = styled.div`
  flex: 0 0 auto;
  color: var(--white);
  opacity: 0.5;
  padding-left: var(--spacing-u5);
  transition: opacity var(--transitionTime) ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  ${Root}:hover & {
    opacity: 0.7;
  }
`

export const Content = styled.div`
  flex: 1 1 auto;
  padding: var(--spacing-u2_5) var(--spacing-u5);
`

export const Message = styled.div`
  opacity: 0.8;
  font-size: var(--wolfSize);
  font-family: var(--fontBold);
  transition: opacity var(--transitionTime) ease-in-out;

  ${Root}:hover & {
    opacity: 1;
  }
`

export const Description = styled.div`
  opacity: 0.7;
  font-size: var(--catSize);
  transition: opacity var(--transitionTime) ease-in-out;

  ${Root}:hover & {
    opacity: 0.9;
  }
`
