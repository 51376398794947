import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import {Button, ButtonMenu} from 'components/elements'
import {useConfig} from 'hooks/useConfig'
import {changeState} from 'store/contract/actions'
import {spacing} from 'styles/variables'
import type {
  AdvanceOption,
  AdvanceOptionWithState,
} from 'types/contractConfigurations'
import {CancelContract} from '../Actions/Cancel'
import {EmailContract} from '../Actions/Email'
import {SignContract} from '../Actions/Sign'
import {useContractFlow} from '../helpers'

const FILTER_KEYS = 'sendSigningLink'

const useAdvanceOptions = () => {
  const {digisign} = useConfig()
  const {advanceOptions} = useContractFlow()

  const options =
    (digisign
      ? advanceOptions
      : advanceOptions?.filter((option) => {
          if ('action' in option) {
            return !FILTER_KEYS.includes(option.action)
          }
          return true
        })) ?? []

  let primary
  let secondary
  if (options[0] && 'action' in options[0] && options[0].action === 'cancel') {
    secondary = options
  } else {
    primary = options[0]
    secondary = options.slice(1)
  }

  return {
    primary,
    secondary: digisign ? secondary.reverse() : secondary,
  }
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: ${spacing.u5};
  gap: ${spacing.u2_5};

  button div::first-letter {
    text-transform: uppercase;
  }
`

const StateChangeButton = ({newState, label, icon}: AdvanceOptionWithState) => {
  const dispatch = useDispatch()
  const {isNew, isChangingState, stateChange} = useContractFlow()

  const handleChangeState = () => dispatch(changeState(newState))

  return (
    <Button
      onClick={() => handleChangeState()}
      disabled={isNew || isChangingState}
      hasActivity={stateChange === newState && isChangingState}
      activitySpinnerOverlay
      width='fill'
      theme='secondary'
      label={label}
      icon={icon}
    />
  )
}

interface AdvanceActionProps {
  options?: AdvanceOption
}
const AdvanceAction = ({options}: AdvanceActionProps) => {
  if (!options) {
    return null
  }

  if ('newState' in options) {
    return <StateChangeButton {...options} />
  }

  if ('action' in options) {
    switch (options.action) {
      case 'sendSigningLink':
        return <EmailContract action='sendSigningLink' />
      case 'sendPdfAndMarkAsSent':
        return <EmailContract action='sendPdfAndMarkAsSent' />
      case 'cancel':
        return <CancelContract />
      case 'sign':
        return <SignContract />
      case 'awaitingPartialSignature':
        return <SignContract disabled />
      default:
        return null
    }
  }

  return null
}

const PrimaryAction = () => {
  const {primary} = useAdvanceOptions()
  if (!primary) {
    return <div></div>
  }

  return <AdvanceAction options={primary} />
}

const SecondaryActions = () => {
  const {secondary} = useAdvanceOptions()

  if (!secondary.length) {
    return null
  }

  return (
    <ButtonMenu icon='more' position='right' flipped>
      {secondary.map((option) => (
        <AdvanceAction key={JSON.stringify(option)} options={option} />
      ))}
    </ButtonMenu>
  )
}

export const ContractFlowActions = () => {
  const {status} = useContractFlow()

  if (!status) {
    return null
  }

  return (
    <Container>
      <PrimaryAction />
      <SecondaryActions />
    </Container>
  )
}
