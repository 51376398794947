// https://www.natterly.com/blog/toast-notification-system-in-a-react-redux-application
import styled from 'styled-components'
import {useAppSelector} from 'hooks/store'
import {spacing} from 'styles/variables'
import {Toast} from './Toast'

const Root = styled.div`
  z-index: 999999;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  top: ${spacing.u2_5};
  right: ${spacing.u2_5};

  & > :not(:last-child) {
    margin-bottom: ${spacing.u2};
  }
`

export const Toastr = () => {
  const toasts = useAppSelector((store) => store.toastr)

  return (
    <Root>
      {toasts.map((toast) => (
        <Toast key={toast.id} data={toast} />
      ))}
    </Root>
  )
}
