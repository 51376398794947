import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import styled from 'styled-components'
import {
  EmptyMessage as EmptyMessageComp,
  HeaderText,
  PriorityMenu,
} from 'components/elements'
import {Container, Filters, Header, Main} from 'containers/Core'
import {spacing} from 'styles/variables'
import {PlaygroundIcon} from './Icon'
import {PlaygroundToastr} from './Toastr'

const Content = styled(Main)`
  overflow: hidden;
  display: grid;
  flex-grow: 0 !important;
`

const EmptyMessage = styled(EmptyMessageComp)`
  margin-top: ${spacing.u10};
`

export const PlaygroundSwitch = () => {
  const location = useLocation()
  const {path} = useRouteMatch()
  const {push} = useHistory()

  return (
    <Container>
      <Header>
        <HeaderText primary='Playground' />
      </Header>
      <Filters>
        <PriorityMenu
          value={location.pathname.replace(path, '')}
          items={[
            {label: 'Icon', value: '/Icon'},
            {label: 'Toast', value: '/toast'},
          ]}
          onChange={(value) => push(`${path}${value}`)}
        />
      </Filters>
      <Content>
        <Switch>
          <Route path={`${path}/icon`}>
            <PlaygroundIcon />
          </Route>
          <Route path={`${path}/toast`}>
            <PlaygroundToastr />
          </Route>
          <Route>
            <EmptyMessage title='Choose something cool from the menu above' />
          </Route>
        </Switch>
      </Content>
    </Container>
  )
}
