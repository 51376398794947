import {Container} from 'containers/Form'
import type {ContractPanelProps} from './types'
import {Budget, EconomyInvoicing} from '../sections'

export const Economy: React.FC<React.PropsWithChildren<ContractPanelProps>> = ({
  locked,
}) => {
  return (
    <Container>
      <Budget locked={locked} />
      <EconomyInvoicing />
    </Container>
  )
}
