import {useState} from 'react'
import styled from 'styled-components'
import {ROLE_TYPES_FOR_DEPARTMENT} from 'options/departmentTypes'
import type {StorableDepartment} from 'types/department'
import {AffiliationContext} from './context'
import {AffiliationSection} from './Section'
import type {AffiliationContextProps} from './types'

const Root = styled.div`
  flex: 1 1 auto;
  overflow-y: scroll;
`

interface EntityAffiliationsViewProps {
  data: StorableDepartment
}

export const EntityAffiliationsView: React.FC<
  React.PropsWithChildren<EntityAffiliationsViewProps>
> = ({data}) => {
  const [globalEditing, setGlobalEditing] = useState(false)
  const roleTypesForDepartment = ROLE_TYPES_FOR_DEPARTMENT[data.type]

  const contextValues: AffiliationContextProps = {
    globalEditing,
    setGlobalEditing,
  }

  return (
    <AffiliationContext.Provider value={contextValues}>
      <Root>
        {roleTypesForDepartment?.map((roleType) => (
          <AffiliationSection key={roleType} data={data} roleType={roleType} />
        ))}
      </Root>
    </AffiliationContext.Provider>
  )
}
