import {combineReducers} from 'redux'
import {multipleActsInColumnsReducer} from './multipleActsInColumns/reducer'
import {multipleListReducer} from './multipleList/reducer'
import {multipleMonthReducer} from './multipleMonth/reducer'
import {multipleMonthsInColumnsReducer} from './multipleMonthsInColumns/reducer'
import {singleListReducer} from './singleList/reducer'
import {singleMonthReducer} from './singleMonth/reducer'
import {singleMonthsInColumnsReducer} from './singleMonthsInColumns/reducer'

export const viewsReducer = combineReducers({
  multipleActsInColumns: multipleActsInColumnsReducer,
  multipleMonth: multipleMonthReducer,
  multipleMonthsInColumns: multipleMonthsInColumnsReducer,
  multipleList: multipleListReducer,
  singleMonth: singleMonthReducer,
  singleMonthsInColumns: singleMonthsInColumnsReducer,
  singleList: singleListReducer,
})
