import {createAction} from '@reduxjs/toolkit'
import {
  CLEAR,
  CREATE,
  CREATE_FAILURE,
  CREATE_SUCCESS,
  NEW,
  SHOW,
  SHOW_FAILURE,
  SHOW_SUCCESS,
  UPDATE,
  UPDATE_FAILURE,
  UPDATE_SUCCESS,
} from './constants'
import type {
  TourCreateParams,
  TourCreateResponsePayload,
  TourNewParams,
  TourShowParams,
  TourShowResponsePayload,
  TourUpdateParams,
  TourUpdateResponsePayload,
} from './types'

export const newTour = createAction<TourNewParams>(NEW)

export const showTour = createAction<TourShowParams>(SHOW)
export const showTourSuccess =
  createAction<TourShowResponsePayload>(SHOW_SUCCESS)
export const showTourFailure = createAction(SHOW_FAILURE)

export const createTour = createAction<TourCreateParams>(CREATE)
export const createTourSuccess =
  createAction<TourCreateResponsePayload>(CREATE_SUCCESS)
export const createTourFailure = createAction(CREATE_FAILURE)

export const updateTour = createAction<TourUpdateParams>(UPDATE)
export const updateTourSuccess =
  createAction<TourUpdateResponsePayload>(UPDATE_SUCCESS)
export const updateTourFailure = createAction(UPDATE_FAILURE)

export const clearTour = createAction(CLEAR)
