import type {ReactNode} from 'react'
import {
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import type {Options, Placement} from '@popperjs/core'
import {usePopper} from 'react-popper'
import './Tooltip.module.css'
import {Portal} from 'react-portal'
import {Arrow, Content, Popper, Target} from './components'
import type {BadgeColor} from '../Badge/types'

export type TooltipColor = BadgeColor

export type TooltipPosition = Extract<
  Placement,
  'top' | 'right' | 'bottom' | 'left'
>

interface TooltipProps {
  content: React.ReactNode
  color?: TooltipColor
  position?: TooltipPosition
  showDelay?: number
  maxWidth?: number
  popperClassName?: string
}

export const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = ({
  children,
  content,
  color = 'primary',
  position = 'top',
  showDelay = 200,
  maxWidth = 150,
  popperClassName,
}) => {
  const [visible, setVisible] = useState(false)
  const _onMouseEnter = useRef<NodeJS.Timeout | null>(null)

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null)

  const popperConfig = useMemo(
    (): Partial<Options> => ({
      placement: position,
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowElement,
          },
        },
        {
          name: 'preventOverflow',
          options: {
            padding: 8,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 7],
          },
        },
      ],
    }),
    [position, arrowElement]
  )

  const {styles, attributes} = usePopper(
    referenceElement,
    popperElement,
    popperConfig
  )

  const handleMouseEnter = useCallback(() => {
    _onMouseEnter.current = setTimeout(() => setVisible(true), showDelay)
  }, [showDelay])

  const handleMouseLeave = useCallback(() => {
    if (_onMouseEnter.current) {
      clearTimeout(_onMouseEnter.current)
    }
    setVisible(false)
  }, [])

  useEffect(() => {
    const _referenceElement = referenceElement
    if (_referenceElement) {
      _referenceElement.addEventListener('mouseenter', handleMouseEnter)
      _referenceElement.addEventListener('mouseleave', handleMouseLeave)
    }

    return () => {
      if (_referenceElement) {
        _referenceElement.removeEventListener('mouseenter', handleMouseEnter)
        _referenceElement.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [referenceElement, handleMouseEnter, handleMouseLeave])

  const handleTarget = (child: ReactNode) => {
    if (!child) {
      return null
    }

    if (typeof child === 'string' || typeof child === 'number') {
      return <Target ref={setReferenceElement}>{child}</Target>
    }

    if (typeof child === 'object' && 'type' in child) {
      return cloneElement(child, {
        ref: setReferenceElement,
      })
    }
  }

  return (
    <>
      {handleTarget(children)}

      {content && visible && (
        <Portal>
          <Popper
            ref={setPopperElement}
            color={color}
            className={popperClassName}
            style={styles.popper}
            maxWidth={maxWidth}
            {...attributes.popper}
          >
            <Content>{content}</Content>
            <Arrow ref={setArrowElement} style={styles.arrow} />
          </Popper>
        </Portal>
      )}
    </>
  )
}
