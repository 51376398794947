import {combineReducers, configureStore} from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import createSagaMiddleware from 'redux-saga'
import {middleware as sagaThunkMiddleware} from 'redux-saga-thunk'
// Add sync reducers here
import {bookingReducer} from './booking/reducer'
// LISTS
import {contractReducer} from './contract/reducer'
// SYSTEM
import {economyPlannerReducer} from './economyPlanner/reducer'
import {emailReducer} from './email/reducer'
import {entityReducer} from './entity/reducer'
import {flowReducer} from './flow/reducer'
import {plannerReducer} from './planner/reducer'
import {promptReducer} from './prompt'
import {rootSaga} from './saga'
import {toastrSlice} from '../features/toastr'

const sagaMiddleware = createSagaMiddleware({
  onError: (err) => {
    console.log('Error in Sagas', err)
    Sentry.captureException(err)
  },
})

const rootReducer = combineReducers({
  // LISTS
  listPlanner: plannerReducer,
  // SYSTEM
  economyPlanner: economyPlannerReducer,
  booking: bookingReducer,
  entity: entityReducer,
  contract: contractReducer,
  flow: flowReducer,
  [toastrSlice.name]: toastrSlice.reducer,
  email: emailReducer,
  prompt: promptReducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaThunkMiddleware, sagaMiddleware),
})

sagaMiddleware.run(rootSaga)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
