import type {FC} from 'react'
import {isFunction, isArray, isObject, isEmpty} from 'lodash'

type Test = (() => boolean) | Record<string, unknown> | unknown[] | boolean

function resolveTest(test: Test): boolean {
  if (isFunction(test)) {
    return test()
  } else if (isArray(test)) {
    return test.length === 0
  } else if (isObject(test)) {
    return isEmpty(test)
  } else {
    return !test
  }
}

interface NoResultsProps {
  className?: string
  test: Test
}

export const NoResults: FC<React.PropsWithChildren<NoResultsProps>> = ({
  test,
  className,
  children,
}) => {
  const hasResults = resolveTest(test)

  if (!hasResults) {
    return null
  }

  return <section className={className}>{children}</section>
}
