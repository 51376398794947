import {useQuery} from '@tanstack/react-query'
import {getTTL} from 'utils'
import {queryKeys} from '../queries'

export const useVenues = () => {
  return useQuery({
    ...queryKeys.venues.list,
    staleTime: getTTL('NORMAL'),
  })
}

export const useVenue = (id?: number) =>
  useQuery({
    ...queryKeys.venues.show(id ?? 0),
    enabled: !!id,
  })
