import styled from 'styled-components'

interface SpinnerInlineComponentProps {
  kind?: 'dark' | 'light'
}

const Component = styled.div<SpinnerInlineComponentProps>`
  @keyframes uil-ring-anim {
    0% {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  background: none;
  width: 15px;
  height: 15px;
  position: relative;
  display: inline-block;

  & > div {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50px;
    box-shadow: 0 1px 0 0 ${({kind}) => (kind === 'light' ? '#FFF' : '#555459')};
    -ms-animation: uil-ring-anim 1s linear infinite;
    -moz-animation: uil-ring-anim 1s linear infinite;
    -webkit-animation: uil-ring-anim 1s linear infinite;
    -o-animation: uil-ring-anim 1s linear infinite;
    animation: uil-ring-anim 1s linear infinite;
  }
`

interface SpinnerInlineProps extends SpinnerInlineComponentProps {
  className?: string
}

export const SpinnerInline: React.FC<
  React.PropsWithChildren<SpinnerInlineProps>
> = ({className, kind = 'dark'}) => (
  <Component className={className} kind={kind}>
    <div />
  </Component>
)
