import {useFormikContext} from 'formik'
import {Button, Tooltip} from 'components/elements'
import type {InlineContentProps} from 'containers/Form'
import {InlineContent} from 'containers/Form'
import {useEntity} from 'hooks/useEntity'
import type {Contract} from 'types/contract'

export const ViewTour: React.FC<React.PropsWithChildren<InlineContentProps>> = (
  props
) => {
  const {values} = useFormikContext<Contract>()
  const {openTour} = useEntity()

  const tourId = values.show.tour?.id

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (!tourId) {
      return
    }
    openTour(tourId)
  }

  return (
    <InlineContent {...props} aligned>
      <Tooltip content='View Tour'>
        <Button
          onClick={handleClick}
          disabled={!tourId}
          round
          theme='light'
          icon='new-window'
        />
      </Tooltip>
    </InlineContent>
  )
}
