import {createContext} from 'react'
import type {TabsType} from './types'

interface TabsContextProps {
  kind: TabsType
  registerTab: (key: string) => void
  selectTab: (key: string) => void
  selectedTab: string | undefined
}

export const TabsContext = createContext<TabsContextProps>({
  kind: 'tabs',
  registerTab: () => undefined,
  selectTab: () => undefined,
  selectedTab: '',
})
