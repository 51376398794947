import {Input} from 'components/form'
import {Container, Section, Row, Column} from 'containers/Form'
import {Name} from '../sections'

export const EntityFormPanelVenue = () => {
  return (
    <Container>
      <Name />

      <Section label='Capacity'>
        <Row>
          <Column>
            <Input
              model='stage_capacity_seated'
              kind='number'
              label='Seated'
              min='0'
              max='999999'
              step='1'
              grid='8'
            />
            <Input
              model='stage_capacity_standing'
              kind='number'
              label='Standing'
              min='0'
              max='999999'
              step='1'
              grid='8'
            />
          </Column>
          <Column />
        </Row>
      </Section>
    </Container>
  )
}
