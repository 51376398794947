import {Spinner} from 'components/elements'
import {Container} from 'containers/Core'
import {Main} from './elements'
import {useMultipleActsInColumns} from './hooks'
import {ViewHeader, MonthPicker, ShowKind} from '../components'
import {ActsInColumns} from '../views'

export const MultipleActsInColumns = () => {
  const props = useMultipleActsInColumns()

  return (
    <Container>
      <ViewHeader>
        <MonthPicker />
        <ShowKind />
      </ViewHeader>

      <Main flex>
        {props.isLoading && <Spinner overlay />}
        <ActsInColumns {...props} />
      </Main>
    </Container>
  )
}
