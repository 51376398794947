import {Fragment} from 'react'
import {getISOWeek, isSameMonth, isFirstDayOfMonth} from 'date-fns'
import {chunk} from 'lodash'
import styled from 'styled-components'
import type {DaysArray} from 'helpers/booking'
import {colors, fonts} from 'styles/variables'
import {formatDate} from 'utils'
import {Day, Tours} from '../components'

const Grid = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: 40px repeat(7, minmax(150px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
  grid-auto-flow: row;
  overflow: auto;
`

const WeekNumber = styled.div`
  padding: 5px 0;
  font-family: ${fonts.fontBold};
  text-align: center;

  &:not(:first-child) {
    border-top: 1px solid ${colors.borderColor};
  }
`

const Cell = styled.div`
  border-top: 1px solid ${colors.borderColor};
  border-left: 1px solid ${colors.borderColor};
  display: flex;
  flex-flow: column nowrap;

  &:nth-child(-n + 8) {
    border-top: 0;
  }
`

interface CellHeaderProps {
  otherMonth?: boolean
  isWeekend?: boolean
  isToday?: boolean
}

const CellHeader = styled.div<CellHeaderProps>`
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px;

  ${({otherMonth}) =>
    otherMonth &&
    `
    color: ${colors.secondaryTextColor};`}

  ${({isWeekend}) =>
    isWeekend &&
    `
    background: ${colors.desertStorm};`}

  ${({isToday}) =>
    isToday &&
    `
    background: ${colors.selago};`}
`
const CellHeaderDate = styled.span`
  font-family: ${fonts.fontBold};
`

const CellHeaderDay = styled.span`
  text-transform: lowercase;
  margin-left: 3px;
  color: ${colors.secondaryTextColor};
`

const CellContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  overflow: hidden;

  & > * {
    flex: 1 1 auto;
    overflow-y: auto;
  }

  & > *::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  & > *::-webkit-scrollbar-track {
    background: var(--babyBlue);
  }

  & > *::-webkit-scrollbar-thumb {
    background: var(--malibu);
  }
`

interface OneMonthProps {
  month: string
  viewKey: string
  grid: DaysArray
}

export const OneMonth = ({grid, viewKey, month}: OneMonthProps) => {
  return (
    <Grid>
      {chunk(grid, 7).map((row, rowIndex) => (
        <Fragment key={`${viewKey}_row_${rowIndex}`}>
          <WeekNumber>{getISOWeek(row[0].date)}</WeekNumber>
          {row.map((item, columnIndex) => {
            const {date, isToday, isWeekend, tours} = item
            const otherMonth = !isSameMonth(month, date)
            const headerDateFormat =
              !otherMonth && isFirstDayOfMonth(date)
                ? 'DayOfMonthAndMonth'
                : 'DayOfMonth'

            return (
              <Cell key={`${viewKey}_cell_${rowIndex}_${columnIndex}`}>
                <CellHeader
                  otherMonth={otherMonth}
                  isWeekend={isWeekend}
                  isToday={isToday}
                >
                  <div>
                    <CellHeaderDate>
                      {formatDate(date, headerDateFormat)}
                    </CellHeaderDate>
                    {rowIndex === 0 && (
                      <CellHeaderDay>
                        {formatDate(date, 'DayOfWeek')}
                      </CellHeaderDay>
                    )}
                  </div>
                  <div>
                    <Tours data={tours} dots />
                  </div>
                </CellHeader>
                <CellContent>
                  <Day {...item} showTours={false} />
                </CellContent>
              </Cell>
            )
          })}
        </Fragment>
      ))}
    </Grid>
  )
}
