import {
  addDays,
  addQuarters,
  addYears,
  startOfDay,
  startOfMonth,
  startOfYear,
  startOfQuarter,
  startOfWeek,
  endOfWeek,
  endOfMonth,
  isValid,
  isBefore,
} from 'date-fns'
import type {DateTimeString} from 'types/generic'
import {formatDate} from 'utils'

// Handle dates
// After has to be the first day of the month: 2017-04-01 00:00:00
// Before has to be the first day of the next month: 2017-05-01 00:00:00
// It cannot the the last day (2017-04-30 23:59:59) because that excludes
// notes and simple all-day events, which ends the day after at 00:00:00
export const toString = (date: Date): string => {
  if (!date) {
    throw 'date required'
  }

  // This try / catch handles both type checking and date validation
  // isValid throws if not given a Date object
  try {
    if (!isValid(date)) {
      throw 'invalid date'
    }
  } catch {
    throw 'invalid date'
  }

  return `${formatDate(date, 'ISOYearMonthDay')}T00:00:00Z`
}

export type BeforeAndAfterInterval =
  | 'day'
  | 'week'
  | 'month'
  | 'extendedMonth'
  | 'quarter'
  | 'year'

export function beforeAndAfter(
  interval: BeforeAndAfterInterval,
  startDay: DateTimeString,
  endDay?: DateTimeString
) {
  if (!interval) {
    throw 'interval required'
  }

  if (!startDay) {
    throw 'startDay required'
  }

  const startDate = new Date(startDay)
  const endDate = new Date(endDay ?? startDay)

  if (!isValid(startDate)) {
    throw 'startDay invalid'
  }

  if (!isValid(endDate)) {
    throw 'endDay invalid'
  }

  if (isBefore(endDate, startDate)) {
    throw 'endDay is before startDay'
  }

  let after: Date | undefined
  let before: Date | undefined

  switch (interval) {
    case 'day': {
      after = startDate
      before = addDays(endDate, 1)
      break
    }

    case 'week': {
      after = startOfWeek(startDate, {weekStartsOn: 1})
      before = addDays(endOfWeek(endDate, {weekStartsOn: 1}), 1)
      break
    }

    case 'month': {
      after = startOfMonth(startDate)
      before = addDays(endOfMonth(endDate), 1)
      break
    }

    case 'extendedMonth': {
      after = startOfWeek(startOfMonth(startDate), {weekStartsOn: 1})
      before = addDays(endOfWeek(endOfMonth(endDate), {weekStartsOn: 1}), 1)
      break
    }

    case 'quarter': {
      after = startOfQuarter(startDate)
      before = startOfQuarter(addQuarters(endDate, 1))
      break
    }

    case 'year': {
      after = startOfYear(startDate)
      before = startOfYear(addYears(endDate, 1))
      break
    }

    default: {
      const _unknown: never = interval
      throw `unknown interval: ${_unknown}`
    }
  }

  if (!before || !after) {
    throw 'Before and after not present'
  }

  return {
    before: toString(startOfDay(before)),
    after: toString(startOfDay(after)),
  }
}
