import {Children} from 'react'
import cx from 'classnames'
import styles from './Form.module.css'

export interface RowProps {
  className?: string
  paddingTop?: 'none' | 'normal' | 'double'
  paddingBottom?: 'none' | 'normal' | 'double'
}

export const Row: React.FC<React.PropsWithChildren<RowProps>> = ({
  children,
  className,
  paddingTop = 'normal',
  paddingBottom = 'normal',
}) => {
  const classNames = cx(
    styles.row,
    {
      [styles[`row${Children.count(children)}`]]: Children.count(children) > 0,
    },
    styles[`paddingTop-${paddingTop}`],
    styles[`paddingBottom-${paddingBottom}`],
    className
  )

  return <section className={classNames}>{children}</section>
}
