import type {ReactNode} from 'react'
import {Actions, Content, Header, Overlay, Root} from './components'
import {Button} from '../../elements/Button'

export interface PromptProps {
  opened: boolean
  header: ReactNode
  content: ReactNode
  onCancel: () => void
  onConfirm: () => void
  cancelText: string
  confirmText: string
  dangerous?: boolean
  confirmDisabled?: boolean
  confirmHasActivity?: boolean
}

export const Prompt = ({
  opened,
  header,
  content,
  onCancel,
  onConfirm,
  cancelText,
  confirmText,
  dangerous,
  confirmDisabled,
  confirmHasActivity,
}: PromptProps) => {
  return (
    <Overlay opened={opened}>
      <Root opened={opened}>
        <Header>{header}</Header>
        <Content>{content}</Content>
        <Actions>
          <Button
            onClick={onCancel}
            width='fixed'
            theme='secondary'
            label={cancelText}
          />
          <Button
            onClick={onConfirm}
            width='fixed'
            theme={dangerous ? 'dangerous' : 'primary'}
            label={confirmText}
            disabled={confirmDisabled}
            hasActivity={confirmHasActivity}
          />
        </Actions>
      </Root>
    </Overlay>
  )
}
