export const SET_FILTERS = 'Flow/SET_FILTERS'
export const TOGGLE_PASSIVE_COLUMNS = 'Flow/TOGGLE_PASSIVE_COLUMNS'
export const TOGGLE_HISTORIC_EVENTS = 'Flow/TOGGLE_HISTORIC_EVENTS'

export const FETCH_REQUEST = 'Flow/FETCH_REQUEST'
export const FETCH_SUCCESS = 'Flow/FETCH_SUCCESS'
export const FETCH_FAILURE = 'Flow/FETCH_FAILURE'

export const UPDATE_STATUS = 'Flow/UPDATE_STATUS'
export const UPDATE_STATUS_REQUEST = 'Flow/UPDATE_STATUS_REQUEST'
export const UPDATE_STATUS_SUCCESS = 'Flow/UPDATE_STATUS_SUCCESS'
export const UPDATE_STATUS_FAILURE = 'Flow/UPDATE_STATUS_FAILURE'

export const UPDATE_EVENT_STATUS = 'Flow/UPDATE_EVENT_STATUS'

export const LIST_UPDATE_EVENT = 'Flow/LIST_UPDATE_EVENT'
export const LIST_DESTROY_EVENT = 'Flow/LIST_DESTROY_EVENT'
