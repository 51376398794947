import type {CSSProperties} from 'react'
import {createElement, useCallback, useEffect, useState} from 'react'
import {TabsContext} from './context'
import type {TabsType} from './types'

export interface TabsProps {
  component?: string | false
  kind?: TabsType
  className?: string
  style?: CSSProperties
  initialTab?: string
  onChange?: (tab: string) => void
}

export const Tabs: React.FC<React.PropsWithChildren<TabsProps>> = ({
  component = 'div',
  children,
  kind = 'tabs',
  initialTab,
  className,
  style,
  onChange,
}) => {
  const [tabs, setTabs] = useState<string[]>([])
  const [selectedTab, setSelectedTab] = useState<string | undefined>()

  const selectTab = useCallback(
    (tab: string) => {
      if (onChange) {
        onChange(tab)
      }
      setSelectedTab(tab)
    },
    [onChange]
  )

  const registerTab = (key: string) => {
    setTabs((oldState) => [...oldState, key])
  }

  useEffect(() => {
    if (!selectedTab) {
      if (initialTab) {
        selectTab(initialTab)
      } else if (tabs.length) {
        selectTab(tabs[0])
      }
    }
  }, [selectTab, selectedTab, tabs, initialTab])

  const contentValues = {
    kind,
    registerTab,
    selectTab,
    selectedTab,
  }

  if (component) {
    return (
      <TabsContext.Provider value={contentValues}>
        {createElement(component, {className, style}, children)}
      </TabsContext.Provider>
    )
  }

  return (
    <TabsContext.Provider value={contentValues}>
      {children}
    </TabsContext.Provider>
  )
}
