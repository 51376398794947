import {Input} from 'components/form'
import {Section, Column, Row} from 'containers/Form'

interface AdditionalInformationProps {
  disabled?: boolean
}

export const AdditionalInformation: React.FC<
  React.PropsWithChildren<AdditionalInformationProps>
> = ({disabled}) => (
  <Section label='Additional information' secondary>
    <Row>
      <Column>
        <Input
          model='business_card.website'
          disabled={disabled}
          placeholder='http://...'
          label='Website'
        />
      </Column>
      <Column />
    </Row>
  </Section>
)
