import {ButtonMenu} from 'components/elements'
import {CopyContract} from './Actions/Copy'
import {DownloadContract} from './Actions/Download'
import {EmailContract} from './Actions/Email'
import {useContractActions} from './helpers'

const EmailDraft = () => {
  const {status} = useContractActions()

  if (status !== 'CONTRACT_STATUS_TBC') {
    return null
  }

  return <EmailContract action='sendPdf' />
}

export const ContractExports = () => {
  return (
    <ButtonMenu icon='export'>
      <DownloadContract />
      <EmailDraft />
      <CopyContract />
    </ButtonMenu>
  )
}
