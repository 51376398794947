import type {NumberFormatValues} from 'react-number-format'
import {NumericFormat} from 'react-number-format'
import styled from 'styled-components'
import {
  fonts,
  spacing,
  effects,
  colors,
  units,
  fontSizes,
} from 'styles/variables'
import type {FormFieldBaseProps} from 'types/form'

const THOUSANDS_SEPARATOR = '.'
const DECIMAL_SEPARATOR = ','

const InputComponent = styled.input`
  font-family: ${fonts.fontRegular};
  font-size: ${fontSizes.inputFontSize};
  padding-right: ${spacing.u1_5};
  border: ${effects.inputBorder};
  color: ${colors.scarpaFlow};
  transition: border ${effects.transitionTime},
    box-shadow ${effects.transitionTime}, color ${effects.transitionTime};
  outline: none;
  box-sizing: border-box;
  text-align: right;
  height: ${units.formElementHeight};

  &:hover {
    border: ${effects.inputHoverBorder};
  }
  &:focus {
    border: ${effects.inputFocusBorder};
    box-shadow: ${effects.inputFocusBoxShadow};
  }
  &::placeholder {
    color: ${colors.santasGray};
    font-family: ${fonts.fontRegular};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: all 5000s ease-in-out 0s;
    transition-property: background-color, color;
  }

  ${({disabled}) =>
    disabled &&
    `
  background-color: ${colors.desertStorm};
  &:hover {
    border: ${effects.inputBorder};
    box-shadow: none;
  }`}
`

interface NumberInputProps extends FormFieldBaseProps<number | undefined> {
  placeholder?: string
  decimalScale?: number
}

export const NumberInput: React.FC<
  React.PropsWithChildren<NumberInputProps>
> = ({
  value,
  onChange,
  onFocus,
  onBlur,
  disabled,
  placeholder,
  decimalScale = 2,
}) => {
  return (
    <>
      <NumericFormat
        customInput={InputComponent}
        value={value}
        displayType='input'
        thousandSeparator={THOUSANDS_SEPARATOR}
        decimalSeparator={DECIMAL_SEPARATOR}
        decimalScale={decimalScale}
        fixedDecimalScale={!!value && !Number.isInteger(value)}
        allowNegative={false}
        onValueChange={({floatValue}: NumberFormatValues) =>
          onChange(floatValue)
        }
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        placeholder={placeholder}
      />
    </>
  )
}
