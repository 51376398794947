import {useState} from 'react'
import {useDispatch} from 'react-redux'
import type {IconName} from 'components/elements'
import {Button, Input, Toggle} from 'components/elements'
import {ICON_NAMES} from 'components/elements/Icon/config'
import {toastrActions} from 'features/toastr'
import {
  Container,
  Filters,
  Buttons,
  Controls,
  Filter,
  Select,
  Content,
} from './components'

export const PlaygroundToastr = () => {
  const dispatch = useDispatch()

  const [message, setMessage] = useState('Hello world')
  const [description, setDescription] = useState('')
  const [icon, setIcon] = useState<IconName | undefined>()
  const [sticky, setSticky] = useState(false)

  return (
    <Container>
      <Filters>
        <Filter>
          <label htmlFor=''>Message</label>
          <Input value={message} onChange={setMessage} />
        </Filter>
        <Filter>
          <label htmlFor=''>Description</label>
          <Input value={description} onChange={setDescription} />
        </Filter>
        <Filter>
          <label htmlFor=''>Icon</label>
          <Select
            value={icon}
            onChange={setIcon}
            options={ICON_NAMES.map((value) => ({label: value, value}))}
            isClearable
          />
        </Filter>
        <Filter>
          <label htmlFor=''>Sticky</label>
          <Toggle
            value={sticky}
            onChange={setSticky}
            inactiveText='Not sticky'
            activeText='Sticky'
          />
        </Filter>
      </Filters>

      <Content>
        <Controls>
          <legend>Standard</legend>
          <Buttons>
            <Button
              onClick={() =>
                dispatch(
                  toastrActions.add(message, description, {sticky, icon})
                )
              }
              label='Vanilla toast'
            />
            <Button
              onClick={() =>
                dispatch(
                  toastrActions.add(message, description, {
                    sticky,
                    icon,
                    kind: 'info',
                  })
                )
              }
              label='Info toast'
            />
            <Button
              onClick={() =>
                dispatch(
                  toastrActions.add(message, description, {
                    sticky,
                    icon,
                    kind: 'success',
                  })
                )
              }
              label='Success toast'
            />
            <Button
              onClick={() =>
                dispatch(
                  toastrActions.add(message, description, {
                    sticky,
                    icon,
                    kind: 'warning',
                  })
                )
              }
              label='Warning toast'
            />
            <Button
              onClick={() =>
                dispatch(
                  toastrActions.add(message, description, {
                    sticky,
                    icon,
                    kind: 'error',
                  })
                )
              }
              label='Error toast'
            />
          </Buttons>
        </Controls>

        <Controls>
          <legend>Specific actions</legend>
          <Buttons>
            <Button
              onClick={() =>
                dispatch(
                  toastrActions.successToast(message, description, {
                    sticky,
                    icon,
                  })
                )
              }
              label='success()'
            />
            <Button
              onClick={() =>
                dispatch(
                  toastrActions.warningToast(message, description, {
                    sticky,
                    icon,
                  })
                )
              }
              label='warning()'
            />
            <Button
              onClick={() =>
                dispatch(
                  toastrActions.errorToast(message, description, {sticky, icon})
                )
              }
              label='error()'
            />
            <Button
              onClick={() =>
                dispatch(
                  toastrActions.infoToast(message, description, {sticky, icon})
                )
              }
              label='info()'
            />
          </Buttons>
        </Controls>
      </Content>
    </Container>
  )
}
