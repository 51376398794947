import {Button, Tooltip} from 'components/elements'
import type {InlineContentProps} from 'containers/Form'
import {InlineContent} from 'containers/Form'

interface RefreshButtonProps extends InlineContentProps {
  onClick: (e: React.MouseEvent<Element, MouseEvent>) => void
  disabled?: boolean
  tooltip: React.ReactNode
}

export const RefreshButton: React.FC<
  React.PropsWithChildren<RefreshButtonProps>
> = ({onClick, disabled, tooltip, ...props}) => (
  <InlineContent {...props} aligned>
    <Tooltip content={tooltip}>
      <Button
        onClick={onClick}
        disabled={disabled}
        modal
        round
        theme='light'
        icon='refresh'
      />
    </Tooltip>
  </InlineContent>
)
