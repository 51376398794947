import type {ReactElement} from 'react'
import {createElement} from 'react'
import type {EntityTypes} from 'app/modal/Entity/types'
import {Button} from 'components/elements'
import {Tabs, TabList, TabPanel} from 'components/elements/Tabs'
import {
  Form,
  ErrorsTooltip,
  SubmitButton,
  TabWithErrorCount,
} from 'components/form'
import * as validationSchemas from 'schema'
import {PERSON, DEPARTMENT, TOUR, ORGANIZATION} from 'store/entity/constants'
import type {EntityDataTypes} from 'store/entity/types'
import styles from './Edit.module.css'
import {getTabsForType, TABS} from './tabs'

function getKindFromData(data: EntityDataTypes): EntityTypes {
  if (data.type === 'EVENT_TYPE_TOUR') {
    return 'TOUR'
  }

  if (data.type?.startsWith('ORGANIZATION')) {
    return 'ORGANIZATION'
  }

  if (
    data.type === 'PERSON' ||
    data.type === 'ACT' ||
    data.type === 'ORGANIZER' ||
    data.type === 'VENUE' ||
    data.type === 'BOOKING_AGENCY'
  ) {
    return data.type
  }

  throw new Error(
    `EntityEditView: Unknown or missing data.type: '${data.type}'.`
  )
}

function getAction(kind: EntityTypes, isNew: boolean) {
  switch (kind) {
    case 'PERSON':
      return isNew ? PERSON.CREATE : PERSON.UPDATE
    case 'TOUR':
      return isNew ? TOUR.CREATE : TOUR.UPDATE
    case 'ORGANIZATION':
      if (isNew) {
        throw new Error('Entity: Cannot create Organization directly')
      }
      return ORGANIZATION.UPDATE
    default:
      return isNew ? DEPARTMENT.CREATE : DEPARTMENT.UPDATE
  }
}

function getValidationSchema(kind: EntityTypes) {
  switch (kind) {
    case 'ACT':
      return validationSchemas.actValidationSchema
    case 'ORGANIZATION':
      return validationSchemas.organizationValidationSchema
    case 'ORGANIZER':
      return validationSchemas.organizerValidationSchema
    case 'PERSON':
      return validationSchemas.personValidationSchema
    case 'VENUE':
      return validationSchemas.venueValidationSchema
    case 'TOUR':
      return validationSchemas.tourValidationSchema
    default:
      return undefined
  }
}

interface EntityEditViewProps {
  onSubmitSuccess?: (response: EntityDataTypes) => void
  onCancel?: () => void
  initialValues: EntityDataTypes
}

export const EntityEditView = ({
  onSubmitSuccess,
  onCancel,
  initialValues,
}: EntityEditViewProps): ReactElement => {
  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault()
    if (onCancel) {
      onCancel()
    }
  }

  const isNew = !initialValues.id
  const kind = getKindFromData(initialValues)
  const tabs = getTabsForType(kind)

  return (
    <Form<EntityDataTypes, EntityDataTypes, unknown>
      action={getAction(kind, isNew)}
      initialValues={initialValues}
      validationSchema={getValidationSchema(kind)}
      className={styles.form}
      onSubmitSuccess={onSubmitSuccess}
    >
      {({isSubmitting}) => {
        return (
          <Tabs kind='panes' className={styles.tabsContainer}>
            <TabList className={styles.aside} hideSingleTab>
              {!!tabs &&
                tabs.map((key) => {
                  const config = TABS[key]
                  if (!config) {
                    return null
                  }
                  return (
                    <TabWithErrorCount
                      key={key}
                      text={config.title}
                      keys={config.bubbleKeys}
                      name={key}
                    />
                  )
                })}
            </TabList>

            <section className={styles.main}>
              <section className={styles.content}>
                {!!tabs &&
                  tabs.map((key) => {
                    const config = TABS[key]
                    if (!config) {
                      return null
                    }
                    return (
                      <TabPanel key={key} name={key}>
                        {createElement(config.component)}
                      </TabPanel>
                    )
                  })}
              </section>

              <section className={styles.actions}>
                <section className={styles.cancelActions}>
                  {onCancel && (
                    <Button
                      onClick={handleCancel}
                      disabled={isSubmitting}
                      label='Cancel'
                      width='fixed'
                    />
                  )}
                </section>

                <section className={styles.submitActions}>
                  <ErrorsTooltip />

                  <SubmitButton
                    width='fixedDouble'
                    theme='primary'
                    label='Save'
                    type='submit'
                  />
                </section>
              </section>
            </section>
          </Tabs>
        )
      }}
    </Form>
  )
}
