import {useFormikContext} from 'formik'
import {useToursForActAndDay} from 'api'
import {Select} from 'components/form'
import type {Contract} from 'types/contract'
import {formatDate} from 'utils'

export const TourId = (props: any) => {
  const {values} = useFormikContext<Contract>()

  const date = values.show.start_at
  const actId = values.show.stakeholder_act?.department.id

  const {data: tours} = useToursForActAndDay({
    date,
    actId,
  })

  const options = (tours ?? []).map((t) => ({
    value: t.id,
    label: `${t.text || 'No name'} (${formatDate(
      t.start_at,
      'DateShort'
    )} - ${formatDate(t.end_at, 'DateShort')})`,
  }))

  return (
    <Select
      {...props}
      label='Tour'
      placeholder={
        options.length < 1 ? 'No tours on date' : 'Select tour if applicable...'
      }
      disabled={options.length < 1}
      options={options}
      isClearable
    />
  )
}
