import {fork} from 'redux-saga/effects'
import {callbackReactionsSaga} from './sagas/callbackReactions'
import {createSaga} from './sagas/create'
import {deleteSaga} from './sagas/delete'
import {fetchSaga} from './sagas/fetch'
import {showCallbacksSaga} from './sagas/showCallbacks'
import {tourCallbacksSaga} from './sagas/tourCallbacks'
import {updateSaga} from './sagas/update'

export function* plannerSaga() {
  yield fork(fetchSaga)
  yield fork(createSaga)
  yield fork(updateSaga)
  yield fork(deleteSaga)
  yield fork(showCallbacksSaga)
  yield fork(tourCallbacksSaga)
  yield fork(callbackReactionsSaga)
}
