import cx from 'classnames'
import styles from './Form.module.css'
import type {GridHOCProps} from './gridHOC'
import {gridHOC} from './gridHOC'

interface InlineTextInnerProps {
  text: React.ReactNode | React.ReactNode[]
  textAlign?: 'left' | 'right'
  wrapperClassName?: string
  className?: string
}

export type InlineTextProps = GridHOCProps<InlineTextInnerProps>

const InlineTextInner: React.FC<React.PropsWithChildren<InlineTextProps>> = ({
  text,
  textAlign = 'left',
  wrapperClassName,
  className,
}) => {
  return (
    <div
      className={cx(
        styles.inlineText,
        'form-inlineText',
        `textAlign-${textAlign}`,
        wrapperClassName,
        className
      )}
    >
      {text}
    </div>
  )
}

export const InlineText = gridHOC(InlineTextInner)
