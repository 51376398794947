import {includes} from 'lodash'
import {useCurrencies} from 'api'
import type {
  SelectOption,
  SelectOptionItem,
  SelectProps,
} from 'components/elements/Select'
import {Select} from 'components/elements/Select'
import type {Currency} from 'types/basicData'

const DEFAULT_LANGUAGES = ['DKK']
const PRIORITY_LANGUAGES = ['SEK', 'NOK', 'USD', 'EUR', 'GBP']

function mapOption(currency: Currency): SelectOptionItem {
  return {
    label: `${currency.code} (${currency.name})`,
    value: currency.code,
  }
}

const useOptions = (): SelectOption[] => {
  const {data = []} = useCurrencies()

  return [
    {
      label: 'Default',
      options: data
        .filter((currency) => includes(DEFAULT_LANGUAGES, currency.code))
        .map(mapOption),
    },
    {
      label: 'Priority',
      options: data
        .filter((currency) => includes(PRIORITY_LANGUAGES, currency.code))
        .map(mapOption),
    },
    {
      label: 'Other',
      options: data
        .filter(
          (currency) =>
            !includes(
              [...DEFAULT_LANGUAGES, ...PRIORITY_LANGUAGES],
              currency.code
            )
        )
        .map(mapOption),
    },
  ]
}

type CurrencySelectProps = Omit<SelectProps, 'options'>

export const CurrencySelect = (props: CurrencySelectProps) => {
  const options = useOptions()

  return <Select {...props} options={options} />
}
