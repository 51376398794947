import {colors} from 'styles/variables'
import type {BadgeColor} from './types'

export const colorFunc =
  (inverted?: boolean) =>
  (bg: string, color: string): string => {
    return inverted
      ? `
  color: ${bg};
  background: ${color};
  border-color: ${bg};`
      : `
  color: ${color};
  background: ${bg};
  border-color: ${bg};`
  }

export const coloring = (color?: BadgeColor, inverted?: boolean): string => {
  const chooseColor = colorFunc(inverted)
  switch (color) {
    case 'secondary':
      return chooseColor(colors.secondaryTextColor, colors.white)
    case 'info':
      return chooseColor(colors.bostonBlue, colors.white)
    case 'alert':
      return chooseColor(colors.thunderbird, colors.white)
    case 'warning':
      return chooseColor(colors.casablanca, colors.white)
    case 'success':
      return chooseColor(colors.mountainMeadow, colors.white)
    case 'primary':
    default:
      return chooseColor(colors.primaryTextColor, colors.white)
  }
}
