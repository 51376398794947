import type {EntityTypes} from 'app/modal/Entity/types'
import {EntityFormPanelAct} from './panels/Act'
import {EntityFormPanelBilling} from './panels/Billing'
import {EntityFormPanelContact} from './panels/Contact'
import {EntityFormPanelContactInherited} from './panels/ContactInherited'
import {EntityFormPanelOrganization} from './panels/Organization'
import {EntityFormPanelOrganizer} from './panels/Organizer'
import {BaseFormPanelPerson} from './panels/Person'
import {EntityFormPanelTour} from './panels/Tour'
import {EntityFormPanelVenue} from './panels/Venue'

interface TabConfig {
  title: string
  component: React.FC<React.PropsWithChildren<unknown>>
  bubbleKeys?: string[]
}

export type AddressbookEditTabs =
  | 'organization'
  | 'person'
  | 'organizer'
  | 'act'
  | 'venue'
  | 'tour'
  | 'booking_agency'
  | 'contact'
  | 'contactInherited'
  | 'billing'

export const TABS: {
  [key in AddressbookEditTabs]?: TabConfig
} = {
  organization: {
    title: 'Company',
    component: EntityFormPanelOrganization,
    bubbleKeys: ['name'],
  },
  person: {
    title: 'Person',
    component: BaseFormPanelPerson,
    bubbleKeys: ['name'],
  },
  organizer: {
    title: 'Organizer',
    component: EntityFormPanelOrganizer,
    bubbleKeys: ['name', 'preferred_language', 'preferred_currency'],
  },
  booking_agency: {
    title: 'Booking agency',
    component: EntityFormPanelOrganizer,
    bubbleKeys: ['name', 'preferred_language', 'preferred_currency'],
  },
  act: {
    title: 'Act',
    component: EntityFormPanelAct,
    bubbleKeys: ['name', 'default_commission'],
  },
  venue: {
    title: 'Stage',
    component: EntityFormPanelVenue,
    bubbleKeys: ['name', 'stage_capacity_seated', 'stage_capacity_standing'],
  },
  tour: {
    title: 'Tour',
    component: EntityFormPanelTour,
    bubbleKeys: ['start_at', 'end_at'],
  },
  contact: {
    title: 'Contact',
    component: EntityFormPanelContact,
    bubbleKeys: ['business_card'],
  },
  contactInherited: {
    title: 'Contact',
    component: EntityFormPanelContactInherited,
    bubbleKeys: ['business_card'],
  },
  billing: {
    title: 'Billing',
    component: EntityFormPanelBilling,
    bubbleKeys: ['tax_id', 'ean'],
  },
}

export const getTabsForType = (type: EntityTypes): AddressbookEditTabs[] => {
  switch (type) {
    case 'ORGANIZATION':
      return ['organization', 'contact', 'billing']
    case 'PERSON':
      return ['person']
    case 'ACT':
      return ['act', 'contact', 'billing']
    case 'ORGANIZER':
      return ['organizer', 'contact', 'billing']
    case 'VENUE':
      return ['venue', 'contactInherited']
    case 'BOOKING_AGENCY':
      return ['booking_agency', 'contact', 'billing']
    case 'TOUR':
      return ['tour']
    default:
      throw new Error(`EditView: '${type}' not supported`)
  }
}
