import {useTour} from 'app/modal/Entity/hooks'
import {Content, Row} from 'app/modal/Entity/shared/ShowView/components'
import {DocumentDownloader} from 'components/connected'
import {Definition, ReportCard, Tooltip} from 'components/elements'
import {accountingExportQueryParamsFormatter} from 'helpers/queryParams'
import {REPORT_CARD_OPTIONS} from 'options/reportCards'
import {formatDate} from 'utils'

export const TourShowView = () => {
  const {data} = useTour()

  if (!data) {
    return null
  }

  return (
    <Content>
      <Row>
        <Definition
          icon='microphone'
          primary={data.stakeholder_act.department.name}
          secondary='Act'
        />
        <Definition
          icon='star'
          primary={formatDate(data.start_at, 'DateShort')}
          secondary='Start on'
        />
        <Definition
          icon='checkered-flag'
          primary={formatDate(data.end_at, 'DateShort')}
          secondary='End on'
        />
        <Definition
          icon='abacus'
          primary={data.events.length}
          secondary='No. of shows'
        />
      </Row>

      <Row>
        <Tooltip
          content={
            data.events.length === 0 &&
            'There are no shows related to this tour'
          }
        >
          <DocumentDownloader
            url={data.export_url}
            disabled={data.events.length === 0}
          >
            <ReportCard
              icon={REPORT_CARD_OPTIONS.TOUR_JOB_LIST.icon}
              title={REPORT_CARD_OPTIONS.TOUR_JOB_LIST.title}
              description={REPORT_CARD_OPTIONS.TOUR_JOB_LIST.description}
              download
            />
          </DocumentDownloader>
        </Tooltip>
        <Tooltip
          content={
            data.events.length === 0 &&
            'There are no shows related to this tour'
          }
        >
          <DocumentDownloader
            url={REPORT_CARD_OPTIONS.TOUR_FINANCIAL.url}
            urlParams={{
              tourId: data.id,
            }}
            disabled={data.events.length === 0}
          >
            <ReportCard
              icon={REPORT_CARD_OPTIONS.TOUR_FINANCIAL.icon}
              title={REPORT_CARD_OPTIONS.TOUR_FINANCIAL.title}
              description={REPORT_CARD_OPTIONS.TOUR_FINANCIAL.description}
              download
            />
          </DocumentDownloader>
        </Tooltip>
        <Tooltip
          content={
            data.events.length === 0 &&
            'There are no shows related to this tour'
          }
        >
          <DocumentDownloader
            url={REPORT_CARD_OPTIONS.FINANCIAL_OVERVIEW.url}
            queryParams={{
              tourId: data.id,
              actIds: data.stakeholder_act.department.id,
              startDay: data.start_on,
              endDay: data.end_on,
            }}
            disabled={data.events.length === 0}
            queryParamsFormatter={accountingExportQueryParamsFormatter}
          >
            <ReportCard
              icon={REPORT_CARD_OPTIONS.FINANCIAL_OVERVIEW.icon}
              title={REPORT_CARD_OPTIONS.FINANCIAL_OVERVIEW.title}
              description={REPORT_CARD_OPTIONS.FINANCIAL_OVERVIEW.description}
              download
            />
          </DocumentDownloader>
        </Tooltip>
      </Row>
    </Content>
  )
}
