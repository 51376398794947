import {chain, isNil, isNaN} from 'lodash'

export const notDefined = (val) => isNil(val) || isNaN(val) || val === ''
// http://stackoverflow.com/a/29494612
export function toFixed(num, decimals = 0) {
  if (!num) {
    return 0
  }
  const exp = Math.pow(10, decimals)
  return Math.round(parseFloat(num).toFixed(decimals) * exp) / exp
}

export function showNumber(rawStr, opts) {
  if (notDefined(rawStr)) {
    return
  }

  const masked = maskNumber(rawStr, opts)
  const parts = masked.split(opts.decimalSymbol)

  if (opts.requireDecimal && !parts[1]) {
    return `${masked}${opts.decimalSymbol}00`
  } else {
    return parts[1] && parts[1].length === 1 ? `${masked}0` : masked
  }
}

export function maskNumber(rawStr, opts) {
  if (notDefined(rawStr)) {
    return
  }

  const raw = opts.roundWhenMasking ? toFixed(rawStr, 2) : rawStr

  const [rawNum, decimals] = raw.toString().split('.')

  const num = chain(rawNum)
    // split into parts
    .split('')
    // reverse array
    .reverse()
    // split into chunks
    .chunk(3)
    // reverse each chunk to original sorting
    .map((a) => [...a].reverse().join(''))
    // reverse whole array
    .reverse()
    // join with '.'
    .join(opts.thousandsSeparatorSymbol)
    // done
    .value()

  return decimals !== undefined && decimals.length
    ? `${num}${opts.decimalSymbol}${decimals}`
    : num
}

export function unmaskNumber(raw, opts) {
  if (notDefined(raw)) {
    return null
  }
  // Normalize the separators
  const regex = new RegExp(`\\${opts.thousandsSeparatorSymbol}`, 'g')
  const unmasked = raw
    .toString()
    .replace(regex, '')
    .replace(opts.decimalSymbol, '.')
  // Apply the right number of decimals
  const parts = unmasked.split('.')
  const decimals = parts[1] ? parts[1].length : 0
  // Deliver the shit
  // http://stackoverflow.com/a/29494612
  // return Math.round(parseFloat(unmasked).toFixed(decimals) * 1e2) / 1e2
  return toFixed(unmasked, decimals)
}
