import cx from 'classnames'
import styles from './Spinner.module.css'

interface SpinnerProps {
  overlay?: boolean
  absolute?: boolean
}

export const Spinner = ({overlay, absolute}: SpinnerProps) => (
  <>
    {overlay && (
      <div className={cx(styles.overlay, {[styles.absolute]: absolute})} />
    )}
    <div className={cx(styles.spinnerWrapper, {[styles.absolute]: absolute})}>
      <div className={styles.doubleBounce1} />
      <div className={styles.doubleBounce2} />
    </div>
  </>
)
