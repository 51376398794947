import {useDispatch} from 'react-redux'
import type {ButtonProps} from 'components/elements'
import {Button} from 'components/elements'
import {copyContract} from 'store/contract/actions'
import {useContractActions} from '../helpers'

export const CopyContract = (props: ButtonProps) => {
  const {id} = useContractActions()
  const dispatch = useDispatch()

  if (!id) {
    return null
  }

  const handleClick = () => {
    dispatch(copyContract({id}))
  }

  return (
    <Button
      {...props}
      onClick={handleClick}
      theme='secondary'
      key='copy'
      label='Duplicate'
      icon='duplicate'
    />
  )
}
