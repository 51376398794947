import {PostalAddress} from 'components/form/templates'
import {Section, Column, Row} from 'containers/Form'

interface AddressProps {
  disabled?: boolean
}

export const Address: React.FC<React.PropsWithChildren<AddressProps>> = ({
  disabled,
}) => (
  <Section label='Address'>
    <Row paddingTop='double'>
      <Column>
        <PostalAddress
          disabled={disabled}
          model='business_card.postal_address'
        />
      </Column>
    </Row>
  </Section>
)
