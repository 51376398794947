import {useState} from 'react'
import {DocumentDownloader} from 'components/connected'
import {Button, DatePicker, Checkbox} from 'components/elements'

interface FinancialExportDownloaderProps {
  url: string
  allowPreview?: boolean
  onSuccess: () => void
}
interface QueryParams {
  before: string | null
  preview?: boolean
}
export const FinancialExportDownloader: React.FC<
  React.PropsWithChildren<FinancialExportDownloaderProps>
> = ({url, allowPreview, onSuccess}) => {
  const [before, setBefore] = useState<string | null>(null)
  const [preview, setPreview] = useState<boolean>(false)

  const queryParams: QueryParams = {before}
  if (allowPreview) {
    queryParams.preview = preview
  }

  return (
    <>
      <DatePicker
        value={before}
        onChange={setBefore}
        placeholder='Export end date'
        isClearable
        fixedHeight
      />
      {allowPreview && (
        <Checkbox value={preview} onChange={setPreview} text='Preview' />
      )}
      <DocumentDownloader
        url={url}
        queryParams={queryParams as unknown as Record<string, unknown>}
        method='POST'
        successCallback={() => {
          setBefore(null)
          onSuccess()
        }}
        toasts={{
          noContent: {
            message: 'Nothing to export',
            description:
              'There are no data to export in the time frame you selected.',
          },
        }}
      >
        <Button theme='primary' icon='download' label='Generate' />
      </DocumentDownloader>
    </>
  )
}
