import {memo, useContext, useState} from 'react'
import styled from 'styled-components'
import {HeaderText, Button, Spinner} from 'components/elements'
import {ROLE_TYPE_OPTIONS} from 'options/role'
import {colors, effects, spacing} from 'styles/variables'
import type {StorableDepartment} from 'types/department'
import type {RoleType} from 'types/role'
import {AffiliationContext, AffiliationSectionContext} from './context'
import {getDataFromRoleType} from './helpers'
import {AffiliationSectionContent} from './SectionContent'
import {AffiliationSectionForm} from './SectionForm'
import {AffiliationSectionSearch} from './SectionSearch'
import type {
  AffiliationSectionContextProps,
  AffiliationSectionView,
} from './types'

const Section = styled.section`
  padding: ${spacing.u5};
  border-bottom: ${effects.inputBorder};
  position: relative;

  &:nth-child(2n + 1) {
    background-color: ${colors.springWood};
  }
`

const Header = styled.header`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: ${spacing.u5};

  .whnue-headerText {
    margin-right: ${spacing.u5};
  }
`

interface AffiliationSectionProps {
  data: StorableDepartment
  roleType: RoleType
}

export const AffiliationSection: React.FC<
  React.PropsWithChildren<AffiliationSectionProps>
> = memo(({data, roleType}) => {
  const {globalEditing, setGlobalEditing} = useContext(AffiliationContext)
  const [view, setView] = useState<AffiliationSectionView>('show')

  const showSpinner = false
  const showData = view !== 'new'
  const showForm = view === 'new'
  const showSearchButton = globalEditing ? view === 'show' : true
  const showSearchDisabled = globalEditing
  const showSearch = globalEditing ? view === 'search' : false

  const options = ROLE_TYPE_OPTIONS[roleType]
  const items = getDataFromRoleType(roleType, data)

  if (!options) {
    return null
  }

  const handleSetView = (view: AffiliationSectionView) => {
    setGlobalEditing(view !== 'show')
    setView(view)
  }

  const contextValues: AffiliationSectionContextProps = {
    roleType,
    showData,
    showForm,
    showSearch,
    setView: handleSetView,
  }

  return (
    <AffiliationSectionContext.Provider value={contextValues}>
      <Section>
        {showSpinner && <Spinner overlay absolute />}
        <Header>
          <HeaderText primary={options.displayName} secondary={items.length} />
          <AffiliationSectionSearch />
          {showSearchButton && (
            <Button
              round
              icon='plus'
              onClick={() => handleSetView('search')}
              disabled={showSearchDisabled}
            />
          )}
        </Header>
        <AffiliationSectionContent items={items} />
        <AffiliationSectionForm />
      </Section>
    </AffiliationSectionContext.Provider>
  )
})

AffiliationSection.displayName = 'AffiliationSection'
