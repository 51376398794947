import type {PayloadAction} from '@reduxjs/toolkit'
import findIndex from 'lodash/findIndex'
import {combineReducers} from 'redux'
import {standardReducer, isLoading} from 'helpers/reducers'
import {
  SET_FILTERS,
  TOGGLE_PASSIVE_COLUMNS,
  TOGGLE_HISTORIC_EVENTS,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  UPDATE_STATUS_REQUEST,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAILURE,
  UPDATE_EVENT_STATUS,
  LIST_UPDATE_EVENT,
  LIST_DESTROY_EVENT,
} from './constants'
import {getId, getFlowBody} from './helpers'
import type {
  FlowActionFetchSuccessPayload,
  FlowActionListDeleteEvent,
  FlowActionListUpdateEvent,
  FlowActionMeta,
  FlowActionSetFiltersPayload,
  FlowActionToggleHistoricEventsPayload,
  FlowActionTogglePassiveColumnsPayload,
  FlowActionUpdateEventStatusPayload,
  FlowKind,
  FlowStoreData,
  FlowStoreFilters,
} from './types'

const updateEventStatus = (
  state: FlowStoreData,
  action: PayloadAction<
    FlowActionUpdateEventStatusPayload,
    string,
    FlowActionMeta
  >
) => {
  const {id, status} = action.payload
  const eventIndex = findIndex(
    state,
    (event) => getId(event, action.meta) === id
  )

  if (eventIndex <= -1) {
    return state
  }

  return [
    ...state.slice(0, eventIndex),
    Object.assign({}, state[eventIndex], getFlowBody(status, action.meta)),
    ...state.slice(eventIndex + 1),
  ]
}

// ------------------------------------
// INITIAL STATE
// ------------------------------------
export const filtersInitialState = {
  kind: null,
  actId: null,
  tourId: null,
}
export const dataInitialState = []

// ------------------------------------
// REDUCER
// ------------------------------------
const FILTERS_ACTION_HANDLERS = {
  [SET_FILTERS]: (
    state: FlowStoreFilters,
    action: PayloadAction<FlowActionSetFiltersPayload, string, FlowActionMeta>
  ) => {
    return Object.assign({}, filtersInitialState, action.payload)
  },
}

const TOGGLE_PASSIVE_COLUMNS_ACTION_HANDLERS = {
  [TOGGLE_PASSIVE_COLUMNS]: (
    state: FlowStoreFilters,
    action: PayloadAction<
      FlowActionTogglePassiveColumnsPayload,
      string,
      FlowActionMeta
    >
  ) => action.payload,
}

const TOGGLE_HISTORIC_EVENTS_ACTION_HANDLERS = {
  [TOGGLE_HISTORIC_EVENTS]: (
    state: FlowStoreFilters,
    action: PayloadAction<
      FlowActionToggleHistoricEventsPayload,
      string,
      FlowActionMeta
    >
  ) => action.payload,
}

const DATA_ACTION_HANDLERS = {
  [FETCH_SUCCESS]: (
    state: FlowStoreData,
    action: PayloadAction<FlowActionFetchSuccessPayload, string, FlowActionMeta>
  ) => action.payload,
  [FETCH_FAILURE]: () => dataInitialState,
  [UPDATE_EVENT_STATUS]: updateEventStatus,
  [LIST_UPDATE_EVENT]: (
    state: FlowStoreData,
    action: FlowActionListUpdateEvent
  ) => {
    const {id} = action.payload
    const eventIndex = findIndex(state, (event) => event.id === id)

    if (eventIndex <= -1) {
      return [...state, action.payload]
    } else {
      return [
        ...state.slice(0, eventIndex),
        action.payload,
        ...state.slice(eventIndex + 1),
      ]
    }
  },
  [LIST_DESTROY_EVENT]: (
    state: FlowStoreData,
    action: FlowActionListDeleteEvent
  ) => {
    const eventIndex = findIndex(state, (event) => event.id === action.payload)

    if (eventIndex <= -1) {
      return state
    } else {
      return [...state.slice(0, eventIndex), ...state.slice(eventIndex + 1)]
    }
  },
}

const baseFlowReducer =
  (flow: FlowKind) =>
  (reducer: any) =>
  (state: any, action: PayloadAction<any, string, FlowActionMeta>) =>
    action.meta?.flow === flow ? reducer(state, action) : reducer(state, {})

const flowReducers = (flow: FlowKind) =>
  combineReducers({
    filters: baseFlowReducer(flow)(
      standardReducer(filtersInitialState, FILTERS_ACTION_HANDLERS)
    ),
    showPassiveColumns: baseFlowReducer(flow)(
      standardReducer(false, TOGGLE_PASSIVE_COLUMNS_ACTION_HANDLERS)
    ),
    showHistoricEvents: baseFlowReducer(flow)(
      standardReducer(false, TOGGLE_HISTORIC_EVENTS_ACTION_HANDLERS)
    ),
    loading: baseFlowReducer(flow)(
      isLoading({
        constants: [FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE],
      })
    ),
    updating: baseFlowReducer(flow)(
      isLoading({
        constants: [
          UPDATE_STATUS_REQUEST,
          UPDATE_STATUS_SUCCESS,
          UPDATE_STATUS_FAILURE,
        ],
      })
    ),
    data: baseFlowReducer(flow)(
      standardReducer(dataInitialState, DATA_ACTION_HANDLERS)
    ),
  })

export const flowReducer = combineReducers({
  production: flowReducers('production') as any,
  booking: flowReducers('booking') as any,
})
