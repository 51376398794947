import {createQueryKeys} from '@lukemorales/query-key-factory'
import {api} from 'api/api'

export const basicData = createQueryKeys('basicData', {
  countries: {
    queryKey: null,
    queryFn: () => api.basicData.countries(),
  },
  currencies: {
    queryKey: null,
    queryFn: () => api.basicData.currencies(),
  },
  languages: {
    queryKey: null,
    queryFn: () => api.basicData.languages(),
  },
})
