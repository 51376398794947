import {useDispatch, useSelector} from 'react-redux'
import {deletePlanner} from 'store/planner/actions'
import type {PlannerStoreAsyncAction} from 'store/planner/types'
import type {RootStore} from 'store/types'
import type {EventType} from 'types/event'

export const useCell = (
  date: string,
  actId: number | 'noAct' = 'noAct',
  type: EventType,
  id: number
) => {
  const dispatch = useDispatch()
  const asyncAction = useSelector<RootStore, PlannerStoreAsyncAction>(
    ({listPlanner}) => listPlanner.asyncAction
  )

  const cellId = id ? `${type}_${id}` : type
  const cellKey = `${date}_${actId}__${cellId}`

  const hasAsyncAction = asyncAction.key === cellKey
  const asyncActivity = hasAsyncAction ? asyncAction.activity : false
  const asyncResult = hasAsyncAction ? asyncAction.result : null

  return {
    cellKey,
    hasAsyncAction,
    asyncActivity,
    asyncResult,
    deletePlanner: () => dispatch(deletePlanner({id, type}, {cellKey})),
  }
}
