import {createAction} from '@reduxjs/toolkit'
import {createActionWithThunk} from 'utils'
import {
  CONTRACT_OPEN,
  CONTRACT_COPY,
  CONTRACT_RESET,
  CONTRACT_SHOW,
  CONTRACT_CREATE,
  CONTRACT_UPDATE,
  CONTRACT_INITIALIZE,
  CONTRACT_DESTROY,
  CHANGE_STATE,
  EXPORT_FAILURE,
  CONTRACT_DESTROY_SUCCESS,
  CONTRACT_UPDATE_SUCCESS,
  CONTRACT_CREATE_SUCCESS,
  CONTRACT_INITIALIZE_SUCCESS,
  CONTRACT_INITIALIZE_FAILURE,
  CONTRACT_SHOW_SUCCESS,
  CONTRACT_SHOW_FAILURE,
  CHANGE_STATE_SUCCESS,
  CHANGE_STATE_FAILURE,
} from './constants'
import type {
  ContractDeleteResponsePayload,
  ContractDeleteParams,
  ContractInitializeParams,
  ContractOpenParams,
  ContractShowParams,
  ContractUpdateParams,
  ContractUpdateResponsePayload,
  ContractCreateParams,
  ContractCreateResponsePayload,
  ContractInitializeResponsePayload,
  ContractShowResponsePayload,
  ContractChangeStateResponsePayload,
  ContractChangeStateParams,
} from './types'

export const resetContract = createAction(CONTRACT_RESET)

export const openContract = createAction<ContractOpenParams>(CONTRACT_OPEN)

export const initializeContract =
  createAction<ContractInitializeParams>(CONTRACT_INITIALIZE)
export const initializeContractSuccess =
  createAction<ContractInitializeResponsePayload>(CONTRACT_INITIALIZE_SUCCESS)
export const initializeContractFailure = createAction<unknown>(
  CONTRACT_INITIALIZE_FAILURE
)

export const showContract = createAction<ContractShowParams>(CONTRACT_SHOW)
export const showContractSuccess = createAction<ContractShowResponsePayload>(
  CONTRACT_SHOW_SUCCESS
)
export const showContractFailure = createAction<unknown>(CONTRACT_SHOW_FAILURE)

export const createContract =
  createActionWithThunk<ContractCreateParams>(CONTRACT_CREATE)
export const createContractSuccess =
  createActionWithThunk<ContractCreateResponsePayload>(CONTRACT_CREATE_SUCCESS)

export const updateContract =
  createActionWithThunk<ContractUpdateParams>(CONTRACT_UPDATE)
export const updateContractSuccess =
  createActionWithThunk<ContractUpdateResponsePayload>(CONTRACT_UPDATE_SUCCESS)

export const copyContract = createAction<ContractShowParams>(CONTRACT_COPY)

export const deleteContract =
  createActionWithThunk<ContractDeleteParams>(CONTRACT_DESTROY)
export const destroyContractSuccess =
  createActionWithThunk<ContractDeleteResponsePayload>(CONTRACT_DESTROY_SUCCESS)

export const changeState = createAction<ContractChangeStateParams>(CHANGE_STATE)
export const changeStateSuccess =
  createAction<ContractChangeStateResponsePayload>(CHANGE_STATE_SUCCESS)
export const changeStateFailure = createAction<unknown>(CHANGE_STATE_FAILURE)

export const exportFailure = (error: unknown) => ({
  type: EXPORT_FAILURE,
  error,
})
