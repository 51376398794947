import {startOfMonth, endOfMonth, areRangesOverlapping} from 'date-fns'
import {sortBy} from 'lodash'
import type {PlannerEvent} from 'types/planner'
import type {ListTour} from 'types/tour'

export function getShowOrTBA(event: PlannerEvent) {
  if (event.is_show_at_tba) {
    return 'TBA'
  } else {
    const [hour, minute] = event.show_at.split(':')
    return `${hour}:${minute}`
  }
}

export function processTours(date: Date, tours: ListTour[]) {
  const dateStart = startOfMonth(date)
  const dateEnd = endOfMonth(date)
  const filtered = tours.filter((tour) =>
    areRangesOverlapping(tour.start_at, tour.end_at, dateStart, dateEnd)
  )
  return sortBy(filtered, ['start_at'])
}
