import type {EntityType} from 'store/entity/types'
import type {EventType} from 'types/event'

export const URLS = {
  // Auth
  jwtToken: '/authentication/jwt/token/',
  jwtRefresh: '/authentication/jwt/refresh/',
  authSwitchContext: '/authentication/change-working-context',
  // Current
  currentUser: '/people/me',
  currentOrganization: '/organizations/organizations/current',
  // Entity
  organizations: '/organizations/organizations',
  acts: '/organizations/departments/acts',
  venues: '/organizations/departments/venues',
  organizers: '/organizations/departments/organizers',
  bookingAgencies: '/organizations/departments/booking-agencies',
  persons: '/people/persons',
  // Events
  plannerEvents: '/planner/current-organization',
  shows: '/events/shows',
  tours: '/events/tours',
  showTypes: '/events/show-types',
  noteEvents: '/events/note-events/',
  reservedDates: '/events/reserved-dates/',
  offDays: '/events/off-days/',
  logisticsEvents: '/events/logistics-events/',
  // Contract
  contracts: '/contracts/booking-contracts',
  contractDummy: '/contracts/booking-contracts/dummy',
  contractTemplates: '/contracts/terms-templates',
  // Economy
  plannerEconomy: '/planner/economy',
  // Utils
  search: `/search`,
  // Basic data
  countries: '/basic-data/countries',
  currencies: '/basic-data/currencies',
  languages: '/basic-data/languages',
} as const

export type ApiUrl = keyof typeof URLS

export function getApiUrl(
  key: ApiUrl,
  id?: number | undefined,
  postfix?: string
): string {
  if (!URLS[key]) {
    throw new Error('invalid key')
  }

  return [URLS[key], id, postfix].filter(Boolean).join('/')
}

function mapEntityTypeToApiUrlKey(entityType: EntityType): ApiUrl {
  switch (entityType) {
    case 'ACT':
      return 'acts'
    case 'BOOKING_AGENCY':
      return 'bookingAgencies'
    case 'ORGANIZATION':
      return 'organizations'
    case 'ORGANIZER':
      return 'organizers'
    case 'PERSON':
      return 'persons'
    case 'TOUR':
      return 'tours'
    case 'VENUE':
      return 'venues'
  }
}

export function getApiUrlFromEntityType(
  entityType: EntityType,
  id?: Parameters<typeof getApiUrl>[1],
  postfix?: Parameters<typeof getApiUrl>[2]
) {
  const key = mapEntityTypeToApiUrlKey(entityType)

  if (!key) {
    throw new Error(`entityType ${entityType} not supported`)
  }

  return getApiUrl(key, id, postfix)
}

function mapEventTypeToApiUrl(eventType: EventType): ApiUrl {
  switch (eventType) {
    case 'EVENT_TYPE_SHOW':
      return 'shows'
    case 'EVENT_TYPE_NOTE_EVENT':
      return 'noteEvents'
    case 'EVENT_TYPE_RESERVED_DATE':
      return 'reservedDates'
    case 'EVENT_TYPE_OFF_DAY':
      return 'offDays'
    case 'EVENT_TYPE_LOGISTICS_EVENT':
      return 'logisticsEvents'
    case 'EVENT_TYPE_TOUR':
      return 'tours'
    default:
      throw new Error(`Planner: ${eventType} cannot be fetched`)
  }
}

export function getApiUrlFromEventType(
  eventType: EventType,
  id?: Parameters<typeof getApiUrl>[1],
  postfix?: Parameters<typeof getApiUrl>[2]
) {
  const key = mapEventTypeToApiUrl(eventType)

  if (!key) {
    throw new Error(`entityType ${eventType} not supported`)
  }

  return getApiUrl(key, id, postfix)
}
