import {map, memoize, isArray, reduce} from 'lodash'

export const buildViewKey = memoize((obj) => {
  return reduce(
    obj,
    (sum, val, key) => {
      let rtn
      if (isArray(val)) {
        rtn = `l:${val.length};v:${map(val, (v) => v.id).join(',')}`
      } else {
        rtn = val
      }
      return [...sum, `${key}[${rtn}]`]
    },
    []
  ).join('::')
})
