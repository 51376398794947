export const SET_STARTDAY = 'EconomyPlanner/SET_STARTDAY'
export const SET_ENDDAY = 'EconomyPlanner/SET_ENDDAY'
export const SET_FILTER = 'EconomyPlanner/SET_FILTER'
export const SET_SHOW_EXPORTED = 'EconomyPlanner/SET_SHOW_EXPORTED'
export const SET_SHOW_INVOICE_OFF = 'EconomyPlanner/SET_SHOW_INVOICE_OFF'

export const FETCH = 'EconomyPlanner/FETCH'
export const FETCH_START = 'EconomyPlanner/FETCH_START'
export const FETCH_SUCCESS = 'EconomyPlanner/FETCH_SUCCESS'
export const FETCH_FAILURE = 'EconomyPlanner/FETCH_FAILURE'

export const EXPORT_MANUALLY = 'EconomyPlanner/EXPORT_MANUALLY'
export const EXPORT_MANUALLY_START = 'EconomyPlanner/EXPORT_MANUALLY_START'
export const EXPORT_MANUALLY_SUCCESS = 'EconomyPlanner/EXPORT_MANUALLY_SUCCESS'
export const EXPORT_MANUALLY_FAILURE = 'EconomyPlanner/EXPORT_MANUALLY_FAILURE'

export const EXPORT_AGAIN = 'EconomyPlanner/EXPORT_AGAIN'
export const EXPORT_AGAIN_START = 'EconomyPlanner/EXPORT_AGAIN_START'
export const EXPORT_AGAIN_SUCCESS = 'EconomyPlanner/EXPORT_AGAIN_SUCCESS'
export const EXPORT_AGAIN_FAILURE = 'EconomyPlanner/EXPORT_AGAIN_FAILURE'

export const TAX_ID_EXEMPTION = 'EconomyPlanner/TAX_ID_EXEMPTION'
export const TAX_ID_EXEMPTION_START = 'EconomyPlanner/TAX_ID_EXEMPTION_START'
export const TAX_ID_EXEMPTION_SUCCESS =
  'EconomyPlanner/TAX_ID_EXEMPTION_SUCCESS'
export const TAX_ID_EXEMPTION_FAILURE =
  'EconomyPlanner/TAX_ID_EXEMPTION_FAILURE'

export const SET_INVOICING_STATUS = 'EconomyPlanner/SET_INVOICING_STATUS'
export const SET_INVOICING_STATUS_START =
  'EconomyPlanner/SET_INVOICING_STATUS_START'
export const SET_INVOICING_STATUS_SUCCESS =
  'EconomyPlanner/SET_INVOICING_STATUS_SUCCESS'
export const SET_INVOICING_STATUS_FAILURE =
  'EconomyPlanner/SET_INVOICING_STATUS_FAILURE'
