import {useFormikContext} from 'formik'
import type {SelectProps} from 'components/elements/Select'
import {Select} from 'components/form'
import type {FormikFieldProps} from 'components/form/formikFieldHOC'
import {CONTRACT_DEFAULT_LANGUAGE, CONTRACT_LANGUAGES} from 'options/contract'
import type {Contract} from 'types/contract'

type SupplementaryContractIdProps = Omit<
  FormikFieldProps<SelectProps>,
  'onChange' | 'options'
>

export const SupplementaryContractId: React.FC<
  React.PropsWithChildren<SupplementaryContractIdProps>
> = ({disabled, ...props}) => {
  const {values, setFieldValue} = useFormikContext<Contract>()

  const handleChange = (newId: number) => {
    const id = values.supplementary_contract.id
    const language = values.language

    const supplementary_contracts =
      values.terms_template.supplementary_contracts

    if (newId !== id) {
      const lang = Object.keys(CONTRACT_LANGUAGES).includes(language)
        ? language
        : CONTRACT_DEFAULT_LANGUAGE

      const contract = supplementary_contracts.find((c) => c.id === newId)

      if (!contract) {
        return
      }

      setFieldValue(
        'supplementary_contract_text',
        lang === 'en' ? contract.text_en : contract.text_da
      )
    }
  }

  return (
    <Select
      {...props}
      disabled={disabled}
      onChange={handleChange}
      options={values.terms_template.supplementary_contracts.map(
        ({id, name}) => ({label: name, value: id})
      )}
    />
  )
}
