import styled from 'styled-components'
import {Icon as IconComp} from 'components/elements'
import {CONTRACT_STATUS_OPTIONS} from 'options/contractStatus'
import {colors, spacing} from 'styles/variables'
import type {EventType} from 'types/event'
import type {PlannerEvent} from 'types/planner'

const Container = styled.div`
  position: absolute;
  top: 8px;
  right: 2px;
  padding: 0 ${spacing.u2};
  opacity: 0.3;
  pointer-events: none;
  color: ${colors.primaryTextColor};
`

function getIcon(type: EventType, data: PlannerEvent) {
  if (type === 'EVENT_TYPE_RESERVED_DATE') {
    return 'bookmark'
  }
  if (type === 'EVENT_TYPE_OFF_DAY') {
    return 'palm'
  }
  if (type === 'EVENT_TYPE_LOGISTICS_EVENT') {
    return 'van'
  }
  if (type === 'EVENT_TYPE_SHOW') {
    return CONTRACT_STATUS_OPTIONS[data.status].icon
  }
}

interface IconProps {
  type: EventType
  data: PlannerEvent
}

export const Icon = ({type, data}: IconProps) => {
  const icon = getIcon(type, data)

  if (!icon) {
    return null
  }

  return (
    <Container>
      <IconComp icon={icon} size='12' />
    </Container>
  )
}
