import type {PayloadAction} from '@reduxjs/toolkit'
import {takeLatest, fork, put} from 'redux-saga/effects'
import {api} from 'api/api'
import {standardErrorAction} from 'helpers/saga'
import type {ContractRemote} from 'types/contract'
import {showContractSuccess} from '../actions'
import {CONTRACT_SHOW, CONTRACT_SHOW_FAILURE} from '../constants'
import {processResult} from '../helpers'
import type {ContractShowParams} from '../types'

export function* showContract(action: PayloadAction<ContractShowParams>) {
  try {
    const result: ContractRemote = yield api.contracts.show(action.payload.id)
    const payload = processResult(result)
    yield put(showContractSuccess(payload))
  } catch (e: any) {
    yield put(standardErrorAction(CONTRACT_SHOW_FAILURE, e))
    throw e
  }
}
export function* watchShowContract() {
  yield takeLatest(CONTRACT_SHOW, showContract)
}

// BASE SAGA
export function* showSaga() {
  yield fork(watchShowContract)
}
