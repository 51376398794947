import {Search} from 'components/elements'
import {Dropdown} from 'components/utils/Dropdown'
import {ResultsList} from './ResultsList'
import type {SearchDropdownProps} from './types'

export const SearchDropdown = ({
  value,
  className,
  onSearch,
  onClick,
  onFocus,
  onBlur,
  isSearching,
  results,
  footer,
  autoFocus,
  showAllResults,
  minSearchLength = 0,
}: SearchDropdownProps) => (
  <Dropdown
    placement='bottom-start'
    fitContentWidth
    className={className}
    onClose={onBlur}
    deferClickOutsideInitialization
    target={({ref, open}) => (
      <div ref={ref}>
        <Search
          value={value}
          onChange={(value: string) => onSearch(value)}
          isFetching={isSearching}
          autoFocus={autoFocus}
          onFocus={() => {
            open()
            if (onFocus) {
              onFocus()
            }
          }}
        />
      </div>
    )}
    content={() => (
      <ResultsList
        value={value}
        results={results}
        onClick={onClick}
        isSearching={isSearching}
        showAllResults={showAllResults}
        minSearchLength={minSearchLength}
        footer={footer}
      />
    )}
  />
)
