import styled from 'styled-components'
import {colors, effects, spacing} from 'styles/variables'
import {Icon as IconComp} from '../Icon'

export const Content = styled.div`
  flex: 1 1 auto;
  color: ${colors.primaryTextColor};
`

export const Wrapper = styled.div<{empty: boolean}>`
  display: flex;
  flex-flow: row nowrap;

  ${({empty}) =>
    empty &&
    `
  ${Content} {
    color: var(--secondaryTextColor);
    font-style: italic;
  }`}
`

export const Icon = styled(IconComp)`
  flex: 0 0 16px;
  margin-top: 2px;
  margin-right: ${spacing.u5};
  color: ${colors.santasGray};
`

export const Item = styled.div`
  line-height: ${spacing.u5};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

export const Note = styled.div`
  color: ${colors.casablanca};
`

export const Primary = styled.span`
  flex: 0 0 auto;
`

export const Divider = styled.span`
  flex: 0 0 auto;
  margin: 0 ${spacing.u1};
  color: ${colors.secondaryTextColor};
  text-align: center;
`

export const Secondary = styled.span`
  flex: 0 0 auto;
  color: ${colors.secondaryTextColor};
`

export const LockedLine = styled.span`
  flex: 1 1 auto;
  display: block;
  margin: 0 ${spacing.u1_5};
  border-bottom: ${effects.inputSecondaryBorder};
`

export const LockedWrapper = styled.div`
  flex: 0 0 auto;
`

export const LockedIcon = styled(IconComp)`
  color: ${colors.secondaryTextColor};
  top: 0;
  cursor: help;
`
