import {combineReducers} from 'redux'
import {BOOKING_VIEWS} from 'options/booking'
import {SET_START_MONTH, SET_END_MONTH, SET_SHOW_KIND} from '../../constants'
import {month, monthWithMinMonth, showKind} from '../../helpers/reducers'

export const multipleMonthsInColumnsReducer = combineReducers({
  startMonth: month(SET_START_MONTH, BOOKING_VIEWS.MULTIPLE_MONTHS_IN_COLUMNS),
  endMonth: monthWithMinMonth(
    SET_END_MONTH,
    BOOKING_VIEWS.MULTIPLE_MONTHS_IN_COLUMNS,
    18,
    SET_START_MONTH
  ),
  showKind: showKind(SET_SHOW_KIND, BOOKING_VIEWS.MULTIPLE),
})
