import styled from 'styled-components'
import {HeaderText, Icon} from 'components/elements'
import {Tab, TabList, Tabs} from 'components/elements/Tabs'
import {Container, Header, HeaderLeft, Navigation} from 'containers/Core'
import {colors} from 'styles/variables'
import {AffiliationsView} from './Affiliations'
import {EntityViewContext} from './context'
import {DepartmentView} from './Department'
import {getIcon, getInitialTab} from './helpers'
import {useNavigation, useTitles} from './hooks'
import {OrganizationView} from './Organization'
import {PersonView} from './Person'
import {StagesView} from './Stages'
import {TourView} from './Tour'
import type {EntityTypes, EntityViewModes, EntityViews} from './types'

const StyledIcon = styled.div`
  box-sizing: border-box;
  width: 64px;
  height: 64px;
  border: 1px solid ${colors.westar};
  border-radius: 50%;
  padding: 15px;
  color: ${colors.secondaryTextColor};
  background: ${colors.springWood};

  & > svg {
    top: 0;
  }
`

export interface EntityViewProps {
  kind: EntityTypes
  viewMode?: EntityViewModes
}

export const EntityView: React.FC<React.PropsWithChildren<EntityViewProps>> = ({
  kind,
  viewMode: initialViewMode,
}) => {
  const {tabs, view, viewMode, setView, setViewMode} = useNavigation(
    kind,
    initialViewMode
  )
  const {primary, secondary} = useTitles(kind, viewMode)

  const contextValues = {
    kind,
    view,
    viewMode,
    setView,
    setViewMode,
  }

  return (
    <EntityViewContext.Provider value={contextValues}>
      <Container>
        <Header>
          <HeaderLeft>
            <StyledIcon>
              <Icon icon={getIcon(kind)} size={32} />
            </StyledIcon>
            <HeaderText primary={primary} secondary={secondary} twoLines />
          </HeaderLeft>
        </Header>

        {!!tabs && (
          <Navigation>
            <Tabs
              component={false}
              onChange={(v) => setView(v as EntityViews)}
              initialTab={getInitialTab(kind)}
            >
              <TabList>
                {tabs.map(({value, title}) => (
                  <Tab
                    key={value}
                    name={value}
                    disabled={view !== value && viewMode !== 'show'}
                  >
                    {title}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          </Navigation>
        )}

        {view === 'person' && <PersonView />}
        {view === 'tour' && <TourView />}
        {view === 'department' && <DepartmentView />}
        {view === 'stages' && <StagesView />}
        {view === 'affiliations' && <AffiliationsView />}
        {view === 'organization' && <OrganizationView />}
      </Container>
    </EntityViewContext.Provider>
  )
}
