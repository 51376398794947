import cx from 'classnames'
import styles from './container.module.css'

interface NavigationProps {
  className?: string
}

export const Navigation: React.FC<React.PropsWithChildren<NavigationProps>> = ({
  className,
  children,
}) => <nav className={cx(className, styles.navigation)}>{children}</nav>
