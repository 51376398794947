import type {CSSProperties} from 'react'
import {createElement, useRef, useEffect, useCallback, useState} from 'react'
import 'react-virtualized/styles.css'
import type {GridCellProps} from 'react-virtualized'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Grid,
} from 'react-virtualized'
import {useGroupedData} from './helpers'
import type {
  GroupDefaults,
  GroupedListProps,
  ItemDefaults,
  ItemProps,
} from './types'

const GroupedListItem = <I, G>({
  registerChild,
  measure,
  columns,
  columnWidth,
  padding,
  data: item,
  itemRenderer,
  groupHeaderRenderer,
  noItemsText,
  style: baseStyle,
}: ItemProps<I, G>) => {
  const [initialized, setInitialized] = useState(false)

  let content: any
  if ('group' in item) {
    content = createElement(groupHeaderRenderer, {group: item.group})
  } else if ('emptyMessage' in item) {
    content = <div>{noItemsText}</div>
  } else if ('item' in item) {
    content = createElement(itemRenderer, {
      data: item.item,
      style: {width: columnWidth - padding * 2},
    })
  }

  const style: CSSProperties = {
    ...baseStyle,
    padding: 'item' in item ? padding : 0,
    boxSizing: 'border-box',
    display: 'grid',
    width: 'item' in item ? columnWidth : columnWidth * columns,
  }

  useEffect(() => {
    if (!initialized && content) {
      measure()
      setInitialized(true)
    }
  }, [content, initialized, measure])

  return (
    <div
      style={style}
      ref={(r) => {
        if (registerChild && r) {
          registerChild(r)
        }
      }}
    >
      {content}
    </div>
  )
}

const GroupedListInner = <I extends ItemDefaults, G extends GroupDefaults>({
  width,
  height,
  groups = {},
  items,
  group = false,
  renderEmptyGroups = false,
  numberOfColumns = () => 3,
  gap = 5,
  itemGroupKey = (item) => item.type,
  noItemsText = 'No items',
  groupHeaderRenderer = ({group, className}) => (
    <h4 className={className}>{group.name}</h4>
  ),
  noResultsRenderer,
  itemRenderer,
  className,
}: GroupedListProps<I, G>) => {
  const ref = useRef<Grid | null>(null)

  const {data, rowCount, columnCount, columnWidth} = useGroupedData({
    items,
    groups,
    group,
    numberOfColumns,
    itemGroupKey,
    renderEmptyGroups,
    width,
    height,
    gap,
  })

  const cellCacheKeyMapper = useCallback(
    (rowIndex: number, columnIndex: number) => {
      const matrix = `${rowIndex}-${columnIndex}`
      const item = data?.[rowIndex]?.[columnIndex]

      if (!item) {
        return matrix
      }

      if ('group' in item) {
        return `${matrix}-group-${item.group.name}`
      } else if ('emptyMessage' in item) {
        return `${matrix}-emptyMessage`
      } else if ('item' in item) {
        return `${matrix}-item-${item.item.type}-${item.item.id}`
      } else {
        return matrix
      }
    },
    [data]
  )

  const _cache = useRef(
    new CellMeasurerCache({
      defaultWidth: columnWidth,
      fixedWidth: true,
      keyMapper: cellCacheKeyMapper,
    })
  )

  const _cellRenderer = ({
    rowIndex,
    columnIndex,
    style,
    parent,
    key,
  }: GridCellProps) => {
    const item = data?.[rowIndex]?.[columnIndex]

    if (!item) {
      return null
    }

    return (
      <CellMeasurer
        key={key}
        parent={parent}
        cache={_cache.current}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
      >
        {({registerChild, measure}) => (
          <GroupedListItem
            registerChild={registerChild}
            measure={measure}
            columns={columnCount}
            columnWidth={columnWidth}
            padding={gap}
            data={item}
            style={style}
            itemRenderer={itemRenderer}
            groupHeaderRenderer={groupHeaderRenderer}
            noItemsText={noItemsText}
          />
        )}
      </CellMeasurer>
    )
  }

  useEffect(() => {
    if (_cache.current) {
      _cache.current.clearAll()
      ref.current?.recomputeGridSize()
    }
  }, [width, height, columnCount, columnWidth, data, cellCacheKeyMapper])

  return (
    <Grid
      ref={ref}
      className={className}
      width={width}
      height={height}
      columnWidth={columnWidth}
      rowHeight={_cache.current.rowHeight}
      deferredMeasurementCache={_cache.current}
      overscanRowCount={10}
      columnCount={columnCount}
      rowCount={rowCount}
      cellRenderer={_cellRenderer}
      noContentRenderer={noResultsRenderer}
    />
  )
}

export const GroupedList = <I extends ItemDefaults, G extends GroupDefaults>({
  groups = {},
  items,
  group,
  renderEmptyGroups,
  numberOfColumns = () => 3,
  gap = 5,
  itemGroupKey = (item) => item.type,
  noItemsText = 'No items',
  groupHeaderRenderer,
  itemRenderer,
  noResultsRenderer,
  className,
}: Omit<GroupedListProps<I, G>, 'width' | 'height'>) => {
  return (
    <AutoSizer className={className}>
      {({width, height}) => (
        <GroupedListInner
          width={width}
          height={height}
          groups={groups}
          items={items}
          group={group}
          renderEmptyGroups={renderEmptyGroups}
          numberOfColumns={numberOfColumns}
          gap={gap}
          itemGroupKey={itemGroupKey}
          noItemsText={noItemsText}
          groupHeaderRenderer={groupHeaderRenderer}
          itemRenderer={itemRenderer}
          noResultsRenderer={noResultsRenderer}
          className={className}
        />
      )}
    </AutoSizer>
  )
}
