import type {SagaIterator} from 'redux-saga'
import {fork, put, takeLatest} from 'redux-saga/effects'
import {toastrActions} from 'features/toastr'
import {actBookingAgencyBookerSaga} from './actBookingAgencyBooker/sagas'
import {departmentSaga} from './department/sagas'
import {organizationSaga} from './organization/sagas'
import {personSaga} from './person/sagas'
import {roleSaga} from './role/sagas'
import {tourSaga} from './tour/sagas'

interface ErrorAction {
  type: string
  originalMessage?: string
  error?: string
}

function* createSuccessToast(): SagaIterator {
  yield put(toastrActions.successToast('Created successfully'))
}

function* updateSuccessToast(): SagaIterator {
  yield put(toastrActions.successToast('Updated successfully'))
}

function* deleteSuccessToast(): SagaIterator {
  yield put(toastrActions.successToast('Removed successfully'))
}

function* createFailureToast(action: ErrorAction): SagaIterator {
  yield put(
    toastrActions.errorToast(
      'Could not create',
      action?.originalMessage ?? action.error
    )
  )
}

function* updateFailureToast(action: ErrorAction): SagaIterator {
  yield put(
    toastrActions.errorToast(
      'Could not update',
      action?.originalMessage ?? action.error
    )
  )
}

function* deleteFailureToast(action: ErrorAction): SagaIterator {
  yield put(
    toastrActions.errorToast(
      'Could not remove',
      action?.originalMessage ?? action.error
    )
  )
}

export function* entitySaga() {
  yield fork(organizationSaga)
  yield fork(departmentSaga)
  yield fork(personSaga)
  yield fork(tourSaga)
  yield fork(roleSaga)
  yield fork(actBookingAgencyBookerSaga)

  yield takeLatest(
    (action: any) => /^Entity\/.*\/CREATE_SUCCESS$/.test(action.type),
    createSuccessToast
  )
  yield takeLatest(
    (action: any) => /^Entity\/.*\/UPDATE_SUCCESS$/.test(action.type),
    updateSuccessToast
  )
  yield takeLatest(
    (action: any) => /^Entity\/.*\/DELETE_SUCCESS$/.test(action.type),
    deleteSuccessToast
  )

  yield takeLatest(
    (action: any) => /^Entity\/.*\/CREATE_FAILURE$/.test(action.type),
    createFailureToast
  )
  yield takeLatest(
    (action: any) => /^Entity\/.*\/UPDATE_FAILURE$/.test(action.type),
    updateFailureToast
  )
  yield takeLatest(
    (action: any) => /^Entity\/.*\/DELETE_FAILURE$/.test(action.type),
    deleteFailureToast
  )
}
