import {createReducer} from '@reduxjs/toolkit'
import {startOfMonth} from 'date-fns'
import compact from 'lodash/compact'
import {combineReducers} from 'redux'
import {isLoading} from 'helpers/reducers'
import type {PlannerEconomy} from 'types/planner'
import {formatDate} from 'utils'
import {
  exportAgainSuccess,
  exportManuallySuccess,
  fetchFailure,
  fetchSuccess,
  setEndday,
  setFilter,
  setInvoicingStatusSuccess,
  setShowExported,
  setShowInvoiceOff,
  setStartday,
  taxIdExemptionSuccess,
} from './actions'
import {FETCH, FETCH_SUCCESS, FETCH_FAILURE} from './constants'
import type {
  EconomyPlannerResponse,
  EconomyPlannerStoreData,
  EconomyPlannerStoreEndDay,
  EconomyPlannerStoreFilter,
  EconomyPlannerStoreShowExported,
  EconomyPlannerStoreShowInvoiceOff,
  EconomyPlannerStoreStartDay,
} from './types'

function getTaxIdValue(item: EconomyPlannerResponse): string | undefined {
  if (item.inferred_organizer_for_invoicing?.tax_id?.value) {
    return item.inferred_organizer_for_invoicing?.tax_id?.value
  }
  if (item.inferred_organizer_for_invoicing_organization_tax_id?.value) {
    return item.inferred_organizer_for_invoicing_organization_tax_id?.value
  }
  return undefined
}

function getTaxIdCountryCode(
  item: EconomyPlannerResponse
): string | null | undefined {
  if (item.inferred_organizer_for_invoicing?.tax_id?.value) {
    return item.inferred_organizer_for_invoicing?.tax_id?.country_code
  }
  if (item.inferred_organizer_for_invoicing_organization_tax_id) {
    return item.inferred_organizer_for_invoicing_organization_tax_id
      ?.country_code
  }
  return undefined
}

function getTaxIdValidationStatus(
  item: EconomyPlannerResponse
): string | null | undefined {
  if (
    item.inferred_organizer_for_invoicing?.tax_id &&
    'validation_status' in item.inferred_organizer_for_invoicing.tax_id &&
    item.inferred_organizer_for_invoicing?.tax_id?.validation_status
  ) {
    return item.inferred_organizer_for_invoicing?.tax_id?.validation_status
  }
  if (
    item.inferred_organizer_for_invoicing_organization_tax_id &&
    'validation_status' in
      item.inferred_organizer_for_invoicing_organization_tax_id &&
    item.inferred_organizer_for_invoicing_organization_tax_id?.validation_status
  ) {
    return item.inferred_organizer_for_invoicing_organization_tax_id
      ?.validation_status
  }
  return undefined
}

export const processData = (data: EconomyPlannerResponse[]): PlannerEconomy[] =>
  data.map(
    (item) =>
      ({
        id: item.contract_id,
        date: formatDate(item.start_at, 'DateLong'),
        status: item.contract_status,
        rawDate: item.start_at,
        contractId: item.contract_number ?? 'TBC',
        actId: item.act?.id ?? null,
        actName: item.act?.name ?? null,
        organizerId: item.inferred_organizer_for_invoicing?.id ?? null,
        organizerName: item.inferred_organizer_for_invoicing?.name ?? null,
        taxIdStatus: getTaxIdValidationStatus(item),
        taxId:
          compact([getTaxIdCountryCode(item), getTaxIdValue(item)]).join(' ') ??
          undefined,
        exportStatus: item.financial_export_status?.value ?? null,
        invoicingStatus: item.invoicing_status?.value ?? null,
        invoicingStatusExternalId:
          item.invoicing_status?.external_invoice_id ?? null,
        sapCustomerId: item.sap_customer_id,
      } satisfies PlannerEconomy)
  )

export const economyPlannerReducer = combineReducers({
  activity: isLoading(FETCH, FETCH_SUCCESS, FETCH_FAILURE),
  startDay: createReducer<EconomyPlannerStoreStartDay>(
    formatDate(startOfMonth(new Date()), 'ISOYearMonthDay'),
    (builder) => {
      builder.addCase(setStartday, (s, a) =>
        formatDate(a.payload, 'ISOYearMonthDay')
      )
    }
  ),
  endDay: createReducer<EconomyPlannerStoreEndDay>(
    formatDate(startOfMonth(new Date()), 'ISOYearMonthDay'),
    (builder) => {
      builder.addCase(setEndday, (s, a) =>
        formatDate(a.payload, 'ISOYearMonthDay')
      )
    }
  ),
  filter: createReducer<EconomyPlannerStoreFilter>(
    'pendingExport',
    (builder) => {
      builder.addCase(setFilter, (s, a) => a.payload)
    }
  ),
  showExported: createReducer<EconomyPlannerStoreShowExported>(
    false,
    (builder) => {
      builder.addCase(setShowExported, (s, a) => a.payload)
    }
  ),
  showInvoiceOff: createReducer<EconomyPlannerStoreShowInvoiceOff>(
    false,
    (builder) => {
      builder.addCase(setShowInvoiceOff, (s, a) => a.payload)
    }
  ),
  data: createReducer<EconomyPlannerStoreData>([], (builder) => {
    builder
      .addCase(fetchSuccess, (s, a) => processData(a.payload))
      .addCase(fetchFailure, () => [])
      .addCase(exportManuallySuccess, (s, a) => {
        const {contractId} = a.payload
        // Update local item
        const index = s.findIndex((c) => c.id === contractId)
        const item = {...s[index]}

        return [
          ...s.slice(0, index),
          {
            ...item,
            exportStatus: 'EXPORT_STATUS_EXPORTED_MANUALLY',
          },
          ...s.slice(index + 1),
        ]
      })
      .addCase(exportAgainSuccess, (s, a) => {
        const {contractId} = a.payload
        // Update local item
        const index = s.findIndex((c) => c.id === contractId)
        const item = {...s[index]}

        return [
          ...s.slice(0, index),
          {
            ...item,
            exportStatus: 'EXPORT_STATUS_TO_BE_EXPORTED_AGAIN',
          },
          ...s.slice(index + 1),
        ]
      })
      .addCase(taxIdExemptionSuccess, (s, a) => {
        const {id, validation_status} = a.payload

        return s.map((item) => {
          if (item.organizerId === id) {
            return {
              ...item,
              taxId:
                validation_status === 'VALIDATION_STATUS_EXEMPT'
                  ? null
                  : item.taxId,
              taxIdStatus: validation_status,
            }
          } else {
            return item
          }
        })
      })
      .addCase(setInvoicingStatusSuccess, (s, a) => {
        const {id, invoicing_status} = a.payload

        return s.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              invoicingStatus: invoicing_status?.value ?? null,
              invoicingStatusExternalId:
                invoicing_status?.external_invoice_id ?? null,
            }
          } else {
            return item
          }
        })
      })
  }),
})
