import {useQuery} from '@tanstack/react-query'
import {queryKeys} from 'api/queries'
import {getTTL} from 'utils'

/* ORGANIZER */

export const usePeople = () =>
  useQuery({
    ...queryKeys.people.list,
    staleTime: getTTL('NORMAL'),
  })
