import {standardReducer} from 'helpers/reducers'
import {CREATE, SET_ERROR, SET_RECIPIENTS} from '../constants'

const initialState = {}

export const errorsReducer = standardReducer(initialState, {
  [CREATE]: () => initialState,
  [SET_ERROR]: (state, action) => Object.assign({}, state, action.payload),
  [SET_RECIPIENTS]: (state) => Object.assign({}, state, {recipients: null}),
})
