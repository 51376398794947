import {createContext, createElement, useState} from 'react'
import type {ModalContainerProps} from 'containers/ModalContainer'
import {ModalContainer} from 'containers/ModalContainer'

export type ModalProps = ModalContainerProps

export interface ModalSettings {
  component: React.FC<React.PropsWithChildren<unknown>>
  closedCallback?: () => void
}

export interface ModalContextProps {
  openModal: (
    component: React.FC<React.PropsWithChildren<unknown>>,
    closedCallback?: () => void
  ) => void
  closeModal: () => void
}

export const ModalContext = createContext<ModalContextProps>({
  openModal: () => undefined,
  closeModal: () => undefined,
})

export const ModalProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [modals, setModals] = useState<ModalSettings[]>([])
  const [closingModal, setClosingModal] = useState(false)

  const openModal = function (
    component: React.FC<React.PropsWithChildren<unknown>>,
    closedCallback?: () => void
  ) {
    setModals((stack) => {
      return [...stack, {component, closedCallback}]
    })
  }

  const closeModal = () => {
    if (modals.length) {
      setClosingModal(true)
    }
  }

  const closeModalCallback = () => {
    setModals((stack) => {
      setClosingModal(false)
      const newStack = [...stack]
      newStack.pop()
      return newStack
    })
  }

  const modalContext = {
    openModal,
    closeModal,
  }

  return (
    <ModalContext.Provider value={modalContext}>
      <>
        {modals.map(({component, closedCallback}, index, allModals) => {
          const topOfStack = index < allModals.length - 1
          const archived = index < allModals.length - (closingModal ? 2 : 1)
          const show = topOfStack ? true : !closingModal

          const handleClosed = () => {
            closeModalCallback()
            if (closedCallback) {
              // WHen closing an entity modal, the view screams with errors because the view
              // has not properly unmounted yet.
              // Delaying the closedCallback migitates the problem
              setTimeout(() => closedCallback(), 50)
            }
          }

          return (
            <ModalContainer
              show={show}
              key={index}
              archived={archived}
              onClose={closeModal}
              onClosed={handleClosed}
            >
              {createElement(component)}
            </ModalContainer>
          )
        })}

        {children}
      </>
    </ModalContext.Provider>
  )
}
