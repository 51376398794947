import type {PayloadAction} from '@reduxjs/toolkit'
import {takeLatest, put, call, select} from 'redux-saga/effects'
import {queryKeys} from 'api'
import {sagaFetch, standardErrorAction} from 'helpers/saga'
import {queryClient} from 'main'
import type {RootStore} from 'store/types'
import type {Act} from 'types/department'
import {deleteActBookingAgencyBookerSuccess} from '../actions'
import {DELETE, DELETE_FAILURE} from '../constants'
import type {ActBookingAgencyBookerDeleteParams} from '../types'

function* deleteActBookingAgencyBooker(
  action: PayloadAction<ActBookingAgencyBookerDeleteParams>
) {
  try {
    const act: Act | undefined = yield select((store: RootStore) =>
      !!store.entity.department.data &&
      store.entity.department.data.type === 'ACT'
        ? store.entity.department.data
        : undefined
    )

    if (!act) {
      throw new Error(`DeleteBookingAgencyBooker: No suitable act found`)
    }

    yield call(() =>
      sagaFetch(
        `/organizations/act-bookers/${action.payload.id}`,
        'delete',
        null,
        {
          queryParams: {act_id: act.id},
        }
      )
    )

    yield put(
      deleteActBookingAgencyBookerSuccess(action.payload, {actId: act.id})
    )
    queryClient.invalidateQueries(queryKeys.acts._def)
  } catch (e: any) {
    yield put(standardErrorAction(DELETE_FAILURE, e))
    throw e
  }
}

export function* deleteSaga() {
  yield takeLatest(DELETE, deleteActBookingAgencyBooker)
}
