import type {RouteProps} from 'react-router-dom'
import {Redirect, Route, Switch} from 'react-router-dom'
import {AuthContextProvider} from 'context/Auth'
import {ModalProvider} from 'context/Modal'
import {useAuth} from 'hooks/useAuth'
import type {RoleType} from 'types/role'
import {AccountingSwitch} from './Accounting'
import {BookingSwitch} from './Booking'
import {MainLayout} from './layout'
import {PlaygroundSwitch} from './Playground'
import {ProductionSwitch} from './Production'
import {ReportsSwitch} from './Reports'

interface RouteHasRoleProps extends RouteProps {
  hasRole?: RoleType
  showWhen?: () => boolean
}

const RestrictedRoute = ({
  hasRole: role,
  showWhen,
  children,
  ...props
}: RouteHasRoleProps) => {
  const {hasRole} = useAuth()

  if (role && !hasRole(role)) {
    return <></>
  }

  if (showWhen && typeof showWhen === 'function' && !showWhen()) {
    return <></>
  }

  return <Route {...props}>{children}</Route>
}

const MainRouterInner = () => {
  return (
    <Switch>
      <Route path='/booking'>
        <BookingSwitch />
      </Route>
      <Route path='/reports'>
        <ReportsSwitch />
      </Route>
      <Route path='/production'>
        <ProductionSwitch />
      </Route>
      <RestrictedRoute path='/accounting' hasRole='BOOKING_AGENCY_ACCOUNTANT'>
        <AccountingSwitch />
      </RestrictedRoute>
      <RestrictedRoute
        path='/playground'
        showWhen={() => !import.meta.env.PROD}
      >
        <PlaygroundSwitch />
      </RestrictedRoute>
      <Redirect from='*' to='/booking' />
    </Switch>
  )
}

export const MainRouter = () => {
  return (
    <AuthContextProvider>
      <ModalProvider>
        <MainLayout>
          <MainRouterInner />
        </MainLayout>
      </ModalProvider>
    </AuthContextProvider>
  )
}
