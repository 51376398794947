import type {ReactNode} from 'react'
import {isValidElement, PureComponent} from 'react'
import {isArray, isFunction, isNil, isEmpty, map, get} from 'lodash'
import {Tooltip} from 'components/elements'
import {
  Content,
  Divider,
  Icon,
  Item,
  LockedIcon,
  LockedLine,
  LockedWrapper,
  Note,
  Primary,
  Secondary,
  Wrapper,
} from './components'
import type {IconName} from '../Icon'

function hasNoValue(i: unknown) {
  return isNil(i) || i === ''
}

function getValue(item: unknown, key: any) {
  if (isValidElement(key)) {
    return key
  } else if (isFunction(key)) {
    return key(item)
  } else {
    return get(item, key)
  }
}

interface DefinitionItemProps {
  primary: ReactNode
  secondary?: ReactNode
  locked?: boolean | string
  index?: number
}

interface DefinitionProps extends Omit<DefinitionItemProps, 'index'> {
  label?: string
  icon: IconName
  list?: unknown[]
  note?: string
  className?: string
  emptyText?: string
  showSecondaryWhenEmpty?: boolean
}

export class Definition extends PureComponent<DefinitionProps> {
  renderLockedIcon() {
    const {locked} = this.props
    if (!locked) {
      return null
    }
    return (
      <Tooltip content={locked}>
        <LockedWrapper>
          <LockedIcon icon='lock' size='16' />
        </LockedWrapper>
      </Tooltip>
    )
  }

  renderItem({primary, secondary, locked, index}: DefinitionItemProps) {
    const {emptyText} = this.props
    return (
      <Item key={index}>
        <Primary>{hasNoValue(primary) ? emptyText : primary}</Primary>
        {!!secondary && <Divider>|</Divider>}
        {!!secondary && <Secondary>{secondary}</Secondary>}
        {!!locked && <LockedLine />}
        {this.renderLockedIcon()}
      </Item>
    )
  }

  renderList() {
    const {
      list,
      primary: primaryKey,
      secondary: secondaryKey,
      locked: lockedKey,
    } = this.props

    return (
      <div>
        {map(list, (item, index) => {
          return this.renderItem({
            primary: getValue(item, primaryKey),
            secondary: getValue(item, secondaryKey),
            locked: getValue(item, lockedKey),
            index,
          })
        })}
      </div>
    )
  }

  isEmpty() {
    const {list, primary} = this.props
    return list && isArray(list) ? isEmpty(list) : hasNoValue(primary)
  }

  renderContent() {
    const {
      list,
      primary,
      secondary,
      locked,
      emptyText,
      showSecondaryWhenEmpty,
    } = this.props
    let content: JSX.Element[] | JSX.Element | string | undefined

    if (this.isEmpty()) {
      if (showSecondaryWhenEmpty) {
        content = this.renderItem({primary: emptyText, secondary, locked})
      } else {
        content = emptyText
      }
    } else {
      content = isArray(list)
        ? this.renderList()
        : this.renderItem({primary, secondary, locked})
    }

    return (
      <Content>
        {content}
        {this.renderNote()}
      </Content>
    )
  }

  renderIcon() {
    return this.props.label ? (
      <Tooltip content={this.props.label}>
        <Icon icon={this.props.icon} size='16' />
      </Tooltip>
    ) : (
      <Icon icon={this.props.icon} size='16' />
    )
  }

  renderNote() {
    const {note} = this.props
    if (!note) {
      return null
    }
    return <Note>{note}</Note>
  }

  render() {
    const {className} = this.props
    return (
      <Wrapper className={className} empty={this.isEmpty()}>
        {this.renderIcon()}
        {this.renderContent()}
      </Wrapper>
    )
  }
}
