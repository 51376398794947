import type {MouseEvent} from 'react'
import {useState} from 'react'
import {
  setMonth,
  isSameMonth,
  isBefore,
  getYear,
  addYears,
  subYears,
  addMonths,
  subMonths,
  startOfMonth,
} from 'date-fns'
import {map, range} from 'lodash'
import styled from 'styled-components'
import {spacing, colors, fonts, fontSizes} from 'styles/variables'
import {Button} from '../Button' // Global import fucks up webpack
import type {DropdownMenuProps} from '../DropdownMenu'
import {DropdownMenu as DropdownMenuComp} from '../DropdownMenu'
import {Time} from '../Time'

const Container = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: ${spacing.u2};
  align-items: center;
`

const DropdownMenu = styled(DropdownMenuComp)`
  width: 175px;
`

const Target = styled.div`
  user-select: none;
`

const Content = styled.div`
  user-select: none;
  padding: ${spacing.u2_5};
`

const YearSelector = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.u2_5};

  & button {
    flex: 0 0 auto;
  }

  & time {
    flex: 1 1 auto;
    text-align: center;
    font-size: ${fontSizes.dogSize};
    font-family: ${fonts.fontBold};
  }
`

const MonthSelector = styled.div`
  text-align: center;

  & button {
    width: 100%;
    margin-bottom: ${spacing.u1};
  }

  & button:last-child {
    margin-bottom: 0;
  }
`

const NullSelector = styled.div`
  border-top: 1px dotted ${colors.borderColor};
  margin-top: ${spacing.u2};
  padding-top: ${spacing.u2};

  & button {
    width: 100%;
  }
`

const TextMonth = styled.div`
  font-family: ${fonts.fontBold};
`
const TimeMonth = styled(Time)`
  font-family: ${fonts.fontBold};
`

interface MonthPickerContentButtonsProps {
  onChange: (value: Date) => void
  baseMonth: string | number | Date
  currentValue: string | number | Date | null
  minMonth: string | number | Date | undefined
}

const MonthPickerContentButtons: React.FC<
  React.PropsWithChildren<MonthPickerContentButtonsProps>
> = ({onChange, baseMonth, currentValue, minMonth}) => {
  return (
    <>
      {map(range(0, 12), (m, key) => {
        const month = setMonth(baseMonth, m)
        const selected = currentValue ? isSameMonth(currentValue, month) : false
        const disabled = minMonth ? isBefore(month, minMonth) : false

        return (
          <Button
            key={key}
            theme={selected ? 'secondary' : 'light'}
            disabled={disabled}
            onClick={(e: MouseEvent) => {
              console.log('click month')
              e.preventDefault()
              e.stopPropagation()
              onChange(month)
            }}
          >
            <Time obj={month} format='MonthLong' />
          </Button>
        )
      })}
    </>
  )
}

interface MonthPickerContentPropType {
  value: string | number | Date | null
  onChange: (value: Date | null) => void
  minMonth: string | number | Date | undefined
  allowForever?: boolean
}

const MonthPickerContent: React.FC<
  React.PropsWithChildren<MonthPickerContentPropType>
> = ({value: currentValue, onChange, minMonth, allowForever}) => {
  const [value, setValue] = useState(currentValue)
  const baseMonth = value || minMonth || new Date()

  const canGotoPrevYear = minMonth
    ? getYear(minMonth) <= getYear(subYears(baseMonth, 1))
    : true

  const gotoPrevYear = (e: MouseEvent) => {
    console.log('Prev', e)
    e.preventDefault()
    e.stopPropagation()
    setValue(subYears(baseMonth, 1))
  }
  const gotoNextYear = (e: MouseEvent) => {
    console.log('Next', e)
    e.preventDefault()
    e.stopPropagation()
    setValue(addYears(baseMonth, 1))
  }

  return (
    <Content>
      <YearSelector>
        <Button
          icon='chevron-left'
          onClick={gotoPrevYear}
          disabled={!canGotoPrevYear}
        />
        <Time obj={baseMonth} format='YearLong' />
        <Button icon='chevron-right' onClick={gotoNextYear} />
      </YearSelector>
      <MonthSelector>
        <MonthPickerContentButtons
          onChange={onChange}
          baseMonth={baseMonth}
          currentValue={currentValue}
          minMonth={minMonth}
        />
      </MonthSelector>
      {allowForever && (
        <NullSelector>
          <Button
            theme={value === null ? 'primary' : 'light'}
            onClick={() => onChange(null)}
            label='Forever'
          />
        </NullSelector>
      )}
    </Content>
  )
}

interface MonthPickerInputProps {
  value: string | number | Date | null
  allowForever?: boolean
}

const MonthPickerInput: React.FC<
  React.PropsWithChildren<MonthPickerInputProps>
> = ({value, allowForever}) => {
  if (allowForever && value === null) {
    return (
      <Target>
        <TextMonth>Forever</TextMonth>
      </Target>
    )
  } else if (value === null) {
    return <Target>Select month</Target>
  } else {
    return (
      <Target>
        <TimeMonth obj={value} format='MonthLong' />
        <span> | </span>
        <Time obj={value} format='YearLong' />
      </Target>
    )
  }
}

export interface MonthPickerProps
  extends Omit<DropdownMenuProps, 'target' | 'content'> {
  value: string | number | Date | null
  onChange: (value: string | number | Date | null) => void
  minMonth?: string | number | Date
  allowForever?: boolean
  showNavigation?: boolean
}

export const MonthPicker: React.FC<
  React.PropsWithChildren<MonthPickerProps>
> = ({
  minMonth,
  allowForever,
  value,
  onChange,
  showNavigation,
  ...dropdownProps
}): React.ReactElement => {
  const handleChange = (value: Date | null) =>
    onChange(allowForever && value === null ? null : value)

  const handleGotoPrev = () => {
    onChange(value ? subMonths(value, 1) : startOfMonth(new Date()))
  }

  const handleGotoNext = () => {
    onChange(value ? addMonths(value, 1) : startOfMonth(new Date()))
  }

  const element = (
    <DropdownMenu
      {...dropdownProps}
      target={() => (
        <MonthPickerInput allowForever={allowForever} value={value} />
      )}
      content={({close}) => (
        <MonthPickerContent
          allowForever={allowForever}
          value={value}
          onChange={(value) => {
            handleChange(value)
            close()
          }}
          minMonth={minMonth}
        />
      )}
    />
  )

  if (showNavigation) {
    return (
      <Container>
        <Button onClick={handleGotoPrev} icon='chevron-left' />
        {element}
        <Button onClick={handleGotoNext} icon='chevron-right' />
      </Container>
    )
  }

  return element
}
