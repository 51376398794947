import cx from 'classnames'
import styles from './Form.module.css'

interface ColumnProps {
  className?: string
  fitted?: boolean
  nowrap?: boolean
}

export const Column: React.FC<React.PropsWithChildren<ColumnProps>> = ({
  children,
  className,
  fitted,
  nowrap,
}) => {
  return (
    <section
      className={cx(
        styles.column,
        {
          [styles.columnFitted]: fitted,
          [styles.columnNowrap]: nowrap,
        },
        className
      )}
    >
      {children}
    </section>
  )
}
