import {identity, isString} from 'lodash'
import type {AnyAction} from 'redux'

type Creator<T> = (value: T) => T

export const actionFactory = ({
  suffix,
  error,
}: {
  suffix?: string
  error?: boolean
} = {}) => {
  return <P, M>(
    constant: string,
    payloadCreator: Creator<P> = identity,
    metaCreator: Creator<M> = identity
  ) => {
    if (!constant) {
      throw new Error('Action: Constant required')
    }
    if (!isString(constant)) {
      throw new Error('Action: Constant should be a string')
    }

    return (payload: P, meta: M): AnyAction => {
      if (error && !(payload instanceof Error)) {
        throw new Error('Action: Failure payload must be an Error')
      }
      const type = suffix ? `${constant}_${suffix}` : constant

      return {
        type,
        payload: payloadCreator(payload),
        error: error ? true : undefined,
        meta: metaCreator(meta),
      }
    }
  }
}

export const actionCreator = actionFactory()

export const requestCreator = actionFactory({suffix: 'REQUEST'})
export const successCreator = actionFactory({suffix: 'SUCCESS'})
export const failureCreator = actionFactory({suffix: 'FAILURE', error: true})

export const action = <P, M>(constant: string, payload: P, meta?: M) =>
  actionCreator(constant)(payload, meta)

export const request = <P, M>(constant: string, payload: P, meta?: M) =>
  requestCreator(constant)(payload, meta)

export const success = <P, M>(constant: string, payload: P, meta?: M) =>
  successCreator(constant)(payload, meta)

export const failure = <E, M>(constant: string, error: E, meta?: M) =>
  failureCreator(constant)(error, meta)
