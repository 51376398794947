import type {ChangeEvent} from 'react'
import styled from 'styled-components'
import {colors, fonts, fontSizes, spacing} from 'styles/variables'

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 32px;
  align-items: center;
  grid-gap: 0 ${spacing.u2_5};
  font-size: ${fontSizes.catSize};
`

const Title = styled.div`
  color: ${colors.secondaryTextColor};
`

const Input = styled.input`
  background: transparent;
  color: ${colors.primaryTextColor};
  border: none;
  font-family: ${fonts.fontRegular};
  font-size: inherit;

  &::placeholder {
    color: rgba(85, 84, 89, 0.4);
  }

  &,
  &:focus {
    outline: none;
  }
`

interface SubjectProps {
  value: string
  onChange: (value: string) => void
}

export const Subject = ({value, onChange}: SubjectProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  return (
    <Container>
      <Title>Subject</Title>
      <Input
        type='text'
        value={value}
        onChange={handleChange}
        placeholder='Enter email subject'
      />
    </Container>
  )
}
