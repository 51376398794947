import {useFormikContext} from 'formik'
import {useDispatch} from 'react-redux'
import type {SelectProps} from 'components/elements/Select'
import {Select} from 'components/form'
import type {FormikFieldProps} from 'components/form/formikFieldHOC'
import {toastrActions} from 'features/toastr'
import {CONTRACT_DEFAULT_LANGUAGE, CONTRACT_LANGUAGES} from 'options/contract'
import type {Contract} from 'types/contract'

type EconomicTermsIdProps = Omit<
  FormikFieldProps<SelectProps>,
  'onChange' | 'options'
>

export const EconomicTermsId: React.FC<
  React.PropsWithChildren<EconomicTermsIdProps>
> = ({disabled, ...selectProps}) => {
  const {values, setFieldValue} = useFormikContext<Contract>()
  const dispatch = useDispatch()

  const handleChange = (newId: number) => {
    const id = values.economic_terms_id
    const language = values.language
    const economic_term_templates =
      values.terms_template.economic_term_templates

    if (newId !== id) {
      const lang = Object.keys(CONTRACT_LANGUAGES).includes(language)
        ? language
        : CONTRACT_DEFAULT_LANGUAGE

      const newTemplate = economic_term_templates.find((t) => t.id === newId)
      if (!newTemplate) {
        return
      }
      setFieldValue(
        'custom_economic_terms',
        lang === 'en' ? newTemplate.text_en : newTemplate.text_da
      )

      const currentExportSetting = values.financial_export_status?.value
      const newExportSetting =
        newTemplate.automatically_mark_for_financial_export
          ? 'EXPORT_STATUS_TO_BE_EXPORTED'
          : 'EXPORT_STATUS_NO_EXPORT'

      if (currentExportSetting !== newExportSetting) {
        const newTextState = newTemplate.automatically_mark_for_financial_export
          ? 'on'
          : 'off'

        setFieldValue('financial_export_status.value', newExportSetting)
        dispatch(
          toastrActions.warningToast(
            `Invoice is now  ${newTextState}`,
            ` Invoice has now been changed to ${newTextState}, since this is the standard setting for the economic terms you just chose. You can change it back to ${
              newTextState === 'on' ? 'off' : 'on'
            } manually on the Economy tab.`,
            {
              timeout: 10,
            }
          )
        )
      }
    }
  }

  return (
    <Select
      {...selectProps}
      disabled={disabled}
      onChange={handleChange}
      options={values.terms_template.economic_term_templates.map(
        ({id, name}) => ({label: name, value: id})
      )}
    />
  )
}
