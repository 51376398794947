import {isNil} from 'lodash'
import styled from 'styled-components'
import {colors, fontSizes, spacing} from 'styles/variables'

const Primary = styled.span`
  color: ${colors.primaryTextColor};
`

const Secondary = styled.span`
  color: ${colors.secondaryTextColor};
  text-transform: uppercase;
`

const Pipe = styled.span`
  color: ${colors.secondaryTextColor};
`

const Root = styled.div<{twoLines: boolean}>`
  font-size: ${fontSizes.lionSize};
  ${({twoLines}) =>
    twoLines
      ? `
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;

      ${Pipe} {
        height: 1px;
        background-color: ${colors.borderColor};
      }
      ${Secondary} {
        font-size: ${fontSizes.wolfSize};
      }`
      : `
      & > :not(:last-child) {
        margin-right: ${spacing.u2_5};
      }`}
`

interface HeaderTextProps {
  primary?: string | number
  secondary?: string | number
  twoLines?: boolean
  pipe?: boolean
  parens?: boolean
}

export const HeaderText = ({
  primary,
  secondary,
  twoLines,
  pipe,
  parens,
}: HeaderTextProps) => {
  const showPipe = !isNil(primary) && !isNil(secondary) && pipe
  const showParens = !twoLines && !isNil(secondary) && parens
  return (
    <Root twoLines={!!twoLines} className='whnue-headerText'>
      <Primary>{primary}</Primary>
      {!!showPipe && <Pipe>{!twoLines && '|'}</Pipe>}
      {secondary !== undefined && (
        <Secondary>{showParens ? `(${secondary})` : secondary}</Secondary>
      )}
    </Root>
  )
}
