import type {FormikErrors} from 'formik'
import {isArray, isObject, reduce} from 'lodash'

export const traverseErrors = <V>(errors: any): FormikErrors<V> => {
  return reduce(
    errors,
    (obj, val, key) => {
      if (isArray(val)) {
        return Object.assign({}, obj, {[key]: val.join(', ')})
      } else if (isObject(val)) {
        return Object.assign({}, obj, {[key]: traverseErrors(val)})
      } else {
        return obj
      }
    },
    {}
  )
}
