import type {PayloadAction} from '@reduxjs/toolkit'
import type {SagaIterator} from 'redux-saga'
import {takeLatest, put, call} from 'redux-saga/effects'
import {standardErrorAction} from 'helpers/saga'
import {showEntity} from 'store/entity/asyncHelpers'
import {showPersonSuccess} from '../actions'
import {SHOW, SHOW_FAILURE} from '../constants'
import type {PersonShowParams} from '../types'
// SHOW DEPARTMENT
export function* showPerson(
  action: PayloadAction<PersonShowParams>
): SagaIterator {
  try {
    const payload = yield call(() =>
      showEntity({kind: 'PERSON', id: action.payload.id})
    )
    yield put(showPersonSuccess(payload))
  } catch (e: any) {
    yield put(standardErrorAction(SHOW_FAILURE, e))
    throw e
  }
}
export function* showSaga() {
  yield takeLatest(SHOW, showPerson)
}
