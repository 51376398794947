import styled from 'styled-components'
import {effects, fontSizes, spacing} from 'styles/variables'
import type {TooltipColor} from './Tooltip'
import {coloring} from '../Badge/helpers'

export const Target = styled.div`
  display: inline-block;
`

export const Arrow = styled.div`
  position: absolute;
  width: 5px;
  height: 5px;
`

interface PopperProps {
  color: TooltipColor
  maxWidth: number
}

export const Popper = styled.div<PopperProps>`
  position: relative;
  z-index: 10000;
  pointer-events: none;
  box-shadow: ${effects.popoverBoxshadow};
  max-width: ${({maxWidth}) => maxWidth}px;
  opacity: 0.9;
  border-radius: 4px;

  ${({color}) => coloring(color)}

  & ${Arrow} {
    ${({color}) => coloring(color)}
  }

  &[data-popper-placement^='top'] ${Arrow} {
    bottom: -5px;
    width: 10px;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
  }

  &[data-popper-placement^='bottom'] ${Arrow} {
    top: -5px;
    width: 10px;
    clip-path: polygon(0 100%, 50% 0, 100% 100%);
  }

  &[data-popper-placement^='left'] ${Arrow} {
    right: -5px;
    height: 10px;
    clip-path: polygon(0 100%, 100% 50%, 0 0);
  }

  &[data-popper-placement^='right'] ${Arrow} {
    left: -5px;
    height: 10px;
    clip-path: polygon(0 50%, 100% 100%, 100% 0);
  }
`

export const Content = styled.div`
  padding: ${spacing.u2} ${spacing.u4};
  font-size: ${fontSizes.catSize};
  line-height: 1.5;
`
