import {captureException} from '@sentry/react'

const modules = import.meta.glob('../../../static/icons/*.svg')

const REGEX = /.*(?:\/)(.*)(?:.svg)$/

function getName(str: string): string {
  const matches = str.match(REGEX)
  if (!matches) {
    throw new Error('no icon name found')
  }
  return matches[1]
}

type SVGComp = React.FC<React.SVGProps<SVGSVGElement>>
type IconObj = {
  component: SVGComp
  viewBoxWidth?: number
  viewBoxHeight?: number
}
export type IconComponents = Record<string, IconObj>

async function loadIcon(
  path: string,
  loader: () => Promise<unknown>
): Promise<[string, IconObj]> {
  const mod = (await loader()) as any
  const name = getName(path).replace('_', '-')
  const component = mod.default as SVGComp
  const strComponent = (component as any).render.toString()

  const viewBoxMatcher = strComponent.match(/viewBox:\s"([0-9\s]+)"/)
  let viewBoxWidth: number | undefined
  let viewBoxHeight: number | undefined
  if (viewBoxMatcher && viewBoxMatcher?.length > 1) {
    const [_x, _y, width, height] = viewBoxMatcher[1].split(' ')
    viewBoxWidth = parseInt(width)
    viewBoxHeight = parseInt(height)
  }

  return [
    name,
    {
      component,
      viewBoxWidth,
      viewBoxHeight,
    } satisfies IconObj,
  ]
}

export async function loadIcons(): Promise<IconComponents> {
  try {
    const promisedIcons: Promise<[string, IconObj]>[] = []
    for (const path in modules) {
      promisedIcons.push(loadIcon(path, modules[path]))
    }

    const loadedIconTuples = await Promise.all(promisedIcons)
    const loadedIcons = Object.fromEntries(loadedIconTuples)
    return loadedIcons
  } catch (e) {
    captureException(e)
    return {}
  }
}
