import {useDispatch, useSelector} from 'react-redux'
import {MobileMenu} from 'components/elements'
import {Filters as FiltersContainer} from 'containers/Core'
import {setFilters} from 'store/flow/actions'
import type {
  FlowActionSetFiltersPayload,
  FlowKind,
  FlowStoreFilters,
} from 'store/flow/types'
import type {RootStore} from 'store/types'
import {ActSelector} from './ActSelector'
import styles from './filters.module.css'
import {GeneralKindSelector} from './GeneralKindSelector'
import {ToggleHistoricEvents} from './ToggleHistoricEvents'
import {TogglePassiveColumns} from './TogglePassiveColumns'
import {TourSelector} from './TourSelector'

interface FiltersProps {
  flow: FlowKind
  passiveText?: string
  historicText?: string
}

export const Filters = ({flow, passiveText, historicText}: FiltersProps) => {
  const dispatch = useDispatch()

  const {kind, actId, tourId} = useSelector<RootStore, FlowStoreFilters>(
    (store) => store.flow[flow].filters
  )

  const handleChange = (value: FlowActionSetFiltersPayload) =>
    dispatch(setFilters(value, {flow}))

  return (
    <FiltersContainer>
      <MobileMenu title='Filters' icon='filter' targetProps={{size: 'big'}}>
        {() => (
          <div className={styles.root}>
            <div className={styles.section}>
              <GeneralKindSelector value={kind} onChange={handleChange} />
              <div className={styles.spacer} />
              <ActSelector
                isSelected={kind === 'act'}
                value={actId}
                onChange={handleChange}
              />
              <div className={styles.spacer} />
              <TourSelector
                isSelected={kind === 'tour'}
                value={tourId}
                onChange={handleChange}
                actId={actId ?? undefined}
              />
            </div>
            <div className={styles.section}>
              <TogglePassiveColumns flow={flow} text={passiveText} />
            </div>
            <div className={styles.section}>
              <ToggleHistoricEvents flow={flow} text={historicText} />
            </div>
          </div>
        )}
      </MobileMenu>
    </FiltersContainer>
  )
}
