import {Input} from 'components/form'
import {CompoundInput, CompoundInputLine} from 'containers/Form'
import type {ItemListRendererProps} from '../util/ItemList/ItemListRow'

export const EmailAddress: React.FC<
  React.PropsWithChildren<ItemListRendererProps>
> = ({model, disabled}) => (
  <CompoundInput>
    <CompoundInputLine>
      <Input
        model={`${model}.value`}
        disabled={disabled}
        kind='email'
        placeholder='my@email.org'
        grid='20'
      />
    </CompoundInputLine>
  </CompoundInput>
)
