import {useFormikContext} from 'formik'
import {get} from 'lodash'
import {useOrganizer} from 'api'
import {InlineText} from 'containers/Form'
import type {Contract} from 'types/contract'

interface OrganizerPreferenceProps {
  selector: string
  formatter?: (data: any) => any
}

export const OrganizerPreference: React.FC<
  React.PropsWithChildren<OrganizerPreferenceProps>
> = ({selector, formatter}) => {
  const {values} = useFormikContext<Contract>()

  const organizerId = values.show.stakeholder_organizer?.department.id
  const {data: organizer} = useOrganizer(organizerId)

  if (!organizer) {
    return null
  }

  const value = get(organizer, selector)
  const text = formatter ? formatter(value) : value
  return <InlineText text={`Organizer preference: ${text}`} />
}
