export const REST_METHOD = ['show', 'create', 'update', 'delete'] as const
export type RestMethod = (typeof REST_METHOD)[number]

export const HTTP_METHOD = [
  'OPTIONS',
  'GET',
  'POST',
  'PUT',
  'PATCH',
  'DELETE',
] as const
export type HttpMethod = (typeof HTTP_METHOD)[number]

export interface FetchOptions {
  method: HttpMethod
  queryParams?: any
  headers?: HeadersInit
  body?: any
  auth?: boolean
}

export interface RawFetchProps {
  uri: string
  options: RequestInit
  meta?: {
    returnResponse?: boolean
  }
}
export interface FetchProps {
  uri: string
  options: FetchOptions
}

export class PayloadError<ErrorData> extends Error {
  payload: ErrorData | undefined

  constructor(message: string, payload?: ErrorData) {
    super(message)
    this.payload = payload ?? undefined
  }
}

export class FetchError<ErrorData> extends PayloadError<ErrorData> {
  response: Response | undefined
  code: number | undefined
  originalMessage: string | undefined

  constructor(
    message: string,
    payload: ErrorData | undefined,
    response: Response | undefined,
    code: number | undefined,
    originalMessage: string | undefined
  ) {
    super(message, payload)
    this.message = message
    this.response = response
    this.code = code
    this.originalMessage = originalMessage
  }
}
