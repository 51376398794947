import type {CSSProperties} from 'react'
import cx from 'classnames'
import type {HeaderProps} from './Table'
import type {CellStylingConfig, TableSortDirection} from './types'
import {getCellFlex} from './types'
import {Icon} from '../Icon'

interface SortArrowProps {
  direction: TableSortDirection | undefined
}
const SortArrow = ({direction}: SortArrowProps) => {
  if (!direction) {
    return null
  }
  if (direction === 'ASC') {
    return <Icon icon='caret-up' className='inline text-malibu' size={12} />
  }
  return <Icon icon='caret-down' className='inline text-malibu' size={12} />
}

interface HeaderCellProps extends CellStylingConfig {
  onSort: () => void
  isSorted: TableSortDirection | undefined
}

const HeaderCell = ({
  label,
  sortKey,
  onSort,
  isSorted,
  alignment = 'left',
  width,
  flexGrow,
  flexShrink,
}: HeaderCellProps) => {
  const styles: CSSProperties = {
    textAlign: alignment,
    flex: getCellFlex({width, flexGrow, flexShrink}),
  }

  return (
    <div
      className={cx(
        'py-2.5 overflow-hidden text-ellipsis flex items-center gap-x-1',
        sortKey ? 'hover:text-malibu cursor-pointer' : ''
      )}
      onClick={onSort}
      style={styles}
    >
      {label} <SortArrow direction={isSorted} />
    </div>
  )
}

export type SetSort = (newSort: {
  sortBy: string
  sortDirection: TableSortDirection
}) => void

interface HeaderInternalProps<Data, ExtraProps>
  extends HeaderProps<Data, ExtraProps> {
  sortBy: string
  sortDirection: TableSortDirection
  setSort: SetSort
}

export const Header = <Data, ExtraProps>({
  cells,
  sortBy,
  sortDirection,
  setSort,
}: HeaderInternalProps<Data, ExtraProps>): JSX.Element => {
  return (
    <>
      {cells.map((config) => {
        const sortId = config.sortKey
        const onSort = () => {
          if (!sortId) {
            return
          }
          if (sortBy === sortId) {
            setSort({
              sortBy: sortId,
              sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC',
            })
          } else {
            setSort({
              sortBy: sortId,
              sortDirection: 'ASC',
            })
          }
        }

        return (
          <HeaderCell
            key={config.id}
            label={config.label}
            alignment={config.alignment}
            width={config.width}
            flexGrow={config.flexGrow}
            flexShrink={config.flexShrink}
            sortKey={config.sortKey}
            onSort={onSort}
            isSorted={sortBy === sortId ? sortDirection : undefined}
          />
        )
      })}
    </>
  )
}
