import type {PayloadAction} from '@reduxjs/toolkit'
import {takeLatest, put, call} from 'redux-saga/effects'
import {standardErrorAction} from 'helpers/saga'
import {showEntity} from 'store/entity/asyncHelpers'
import {entityTypeFromDepartmentType} from 'store/entity/helpers'
import type {StorableDepartment} from 'types/department'
import {showOrganization} from '../../actions'
import {showDepartmentSuccess} from '../actions'
import {SHOW, SHOW_FAILURE} from '../constants'
import type {DepartmentShowParams} from '../types'

export function* showDepartment(action: PayloadAction<DepartmentShowParams>) {
  try {
    const {organizationId, kind: type, id} = action.payload

    if (organizationId) {
      yield put(showOrganization({id: organizationId}))
    }

    const kind = entityTypeFromDepartmentType(type)
    if (!kind) {
      throw new Error(`DepartmentShow: Unsupported type '${type}'`)
    }

    const payload: StorableDepartment = yield call(() =>
      showEntity({
        kind: kind,
        id: id,
      })
    )

    if (!organizationId) {
      yield put(
        showOrganization({
          id: payload.organization.id,
        })
      )
    }

    yield put(showDepartmentSuccess(payload))
  } catch (e: any) {
    yield put(standardErrorAction(SHOW_FAILURE, e))
    throw e
  }
}
export function* showSaga() {
  yield takeLatest(SHOW, showDepartment)
}
