import {standardReducer} from 'helpers/reducers'
import {INIT, INIT_FAILURE, CLEAR} from '../constants'

const initialState = null

export const urlReducer = standardReducer(initialState, {
  [INIT]: (state, action) => action.payload.url,
  [INIT_FAILURE]: () => initialState,
  [CLEAR]: () => initialState,
})
