import type {ReactElement} from 'react'
import flat from 'flat'
import {useFormikContext} from 'formik'
import {map} from 'lodash'
import {Tooltip} from 'components/elements'
import {compact} from 'utils'
import styles from './ErrorsTooltip.module.css'

export const ErrorsTooltip = (): ReactElement | null => {
  const {errors, submitCount} = useFormikContext()
  const formattedErrors = compact(Object.values<string>(flat(errors)))
  const show = submitCount > 0 && formattedErrors.length

  if (!show) {
    return null
  }

  const content = formattedErrors ? (
    <ul>
      {map(formattedErrors, (errorText, key) => (
        <li key={key}>{errorText}</li>
      ))}
    </ul>
  ) : null
  const count = Object.keys(formattedErrors).length

  return (
    <Tooltip
      content={content}
      color='alert'
      popperClassName={styles.popper}
      maxWidth={300}
    >
      <div className={styles.root}>{count}</div>
    </Tooltip>
  )
}
