import type {ReactElement} from 'react'
import {useState, useRef, useMemo} from 'react'
import type {Options} from '@popperjs/core'
import {usePopper} from 'react-popper'
import {Portal} from 'react-portal'
import {Icon} from 'components/elements'
import {useAuth} from 'hooks/useAuth'
import {useClickOutside} from 'hooks/useClickOutside'
import {useIsDesktop} from 'hooks/useIsDesktop'
import {UserMenuButton} from './Button'
import {
  Avatar,
  Content,
  Root,
  Section,
  Target,
  TargetButton,
  TargetButtonActions,
  TargetButtonContent,
  TargetButtonText,
  TargetButtonTitle,
} from './componentsUserMenu'
import {OrganizationSwitcher} from './OrganizationSwitcher'
import {UserMenuSectionHeader} from './SectionHeader'
import {useAppNavigation, useOpenBookingAgency, useUserMenu} from '../hooks'

export const UserMenu = (): ReactElement => {
  const {maximized} = useAppNavigation()
  const {organizationName, organizationType, userName, userTitle} =
    useUserMenu()
  const {logout, hasRole} = useAuth()
  const {canOpenBookingAgency, openBookingAgency} = useOpenBookingAgency()
  const [opened, setOpened] = useState(false)
  const _ref = useRef(null)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const isDesktop = useIsDesktop()

  const popperConfig = useMemo(
    (): Partial<Options> => ({
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            padding: 4,
          },
        },
      ],
    }),
    []
  )

  const {styles, attributes} = usePopper(
    referenceElement,
    popperElement,
    popperConfig
  )

  useClickOutside(_ref, opened, () => {
    setOpened(false)
  })

  const content = (
    <>
      <Section>
        <UserMenuSectionHeader
          avatar='person'
          primary={userName}
          secondary={userTitle}
        />

        <UserMenuButton disabled title='Coming soon...'>
          Profile settings
        </UserMenuButton>
        <UserMenuButton disabled title='Coming soon...'>
          Help & feedback
        </UserMenuButton>
        <OrganizationSwitcher />
      </Section>

      <Section>
        <UserMenuSectionHeader
          avatar='organizer'
          primary={organizationName}
          secondary={organizationType}
        />
        {hasRole('BOOKING_AGENCY_ADMIN') && (
          <UserMenuButton disabled title='Coming soon...'>
            System settings
          </UserMenuButton>
        )}
        {hasRole('BOOKING_AGENCY_ADMIN') && (
          <UserMenuButton
            disabled={!canOpenBookingAgency}
            onClick={openBookingAgency}
          >
            Company setup
          </UserMenuButton>
        )}
        <UserMenuButton onClick={() => logout()}>Sign out</UserMenuButton>
      </Section>
    </>
  )

  if (!isDesktop) {
    return content
  }

  return (
    <Root ref={_ref}>
      <Target
        ref={setReferenceElement}
        onClick={() => setOpened(!opened)}
        maximized={maximized}
      >
        <Avatar kind='person' />
        <TargetButton>
          <TargetButtonContent>
            <TargetButtonTitle opened={opened}>
              {organizationName}
            </TargetButtonTitle>
            <TargetButtonText>{userName}</TargetButtonText>
          </TargetButtonContent>
          <TargetButtonActions>
            <Icon icon='chevron-down' size={16} />
          </TargetButtonActions>
        </TargetButton>
      </Target>

      {opened && (
        <Portal>
          <Content
            ref={setPopperElement}
            onClick={() => setTimeout(() => setOpened(false))}
            style={styles.popper}
            {...attributes.popper}
          >
            {content}
          </Content>
        </Portal>
      )}
    </Root>
  )
}
