import {createReducer} from '@reduxjs/toolkit'
import {
  copyContract,
  initializeContract,
  resetContract,
  showContract,
} from '../actions'
import type {ContractStoreView} from '../types'

export const viewReducer = createReducer<ContractStoreView>(
  'selectAct',
  (builder) => {
    builder
      .addCase(resetContract, () => 'selectAct')
      .addCase(copyContract, () => 'selectAct')
      .addCase(initializeContract, () => 'edit')
      .addCase(showContract, () => 'edit')
  }
)
