import type {CellConfig} from './types'

export const ROW_STYLES =
  'px-2 flex-auto flex flex-nowrap gap-x-2 whitespace-nowrap text-ellipsis'

export function getCellId<Data, ExtraProps>(
  config: CellConfig<Data, ExtraProps>
): string {
  if ('id' in config) {
    return config.id
  }

  throw new Error('getCellId cannot find cell id')
}
