import styled from 'styled-components'
import {colors} from 'styles/variables'
import {ContractFlowActions} from './Actions'
import {ContractAuditTrail} from './AuditTrail'

const Container = styled.div`
  flex: 1 1 auto;
  border-left: 1px solid ${colors.borderColor};
  display: grid;

  /* This width stops the mark as invoiceable button from jumping when it has activity */
  grid-template-columns: 240px;
  grid-template-rows: 1fr auto;
`

export const ContractViewFlow = () => {
  return (
    <Container>
      <ContractAuditTrail />
      <ContractFlowActions />
    </Container>
  )
}
