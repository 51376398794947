import {PlannerSelect} from 'components/connected/PlannerSelect'
import type {FlowActionSetFiltersPayload} from 'store/flow/types'
import {formatDate} from 'utils'
import styles from './filters.module.css'

interface TourSelectorProps {
  value: number | null
  onChange: (data: FlowActionSetFiltersPayload) => void
  isSelected: boolean
  actId?: number
}

export const TourSelector = ({
  value,
  onChange,
  isSelected,
  actId,
}: TourSelectorProps) => {
  const handleChange = (tourId: number) => {
    onChange({
      kind: 'tour',
      tourId,
    })
  }

  return (
    <PlannerSelect
      isSelected={isSelected}
      value={value}
      placeholder='Select Tour...'
      disabled={!actId}
      eventTypes='tours'
      actIds={actId ? [actId] : undefined}
      enabled={(props) => !!props.actIds}
      labelFunc={(item) =>
        `${item?.text ?? 'No name'} (${formatDate(
          item.start_at,
          'DateShort'
        )} - ${formatDate(item.end_at, 'DateShort')})`
      }
      onChange={handleChange}
      className={styles.tourSelector}
    />
  )
}
