// https://codepen.io/zammer/pen/pbxJdo?editors=0010
import {createRef, useCallback, useEffect, useState} from 'react'
import isEqual from 'lodash/isEqual'
import {useResizeDetector} from 'react-resize-detector'
import styled from 'styled-components'
import {Button, ButtonMenu} from 'components/elements'
import type {ButtonProps} from '../Button'

const GAP = 8

const Root = styled.nav`
  display: grid;
  grid-template-columns: 1fr 26px;
  align-items: center;
  overflow-y: hidden;
  /* Used to display the glow from the buttons */
  margin: -2px;
  padding: 2px;
`

const PriorityContainer = styled.nav`
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;

  & > button {
    flex: 0 0 auto;
  }
  & > button:not(:last-child) {
    margin-right: ${GAP}px;
  }
`

const MoreContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
`

interface PriorityItem<Value> {
  label: string
  value: Value
  disabled?: boolean
}

interface PriorityItemProps<Value> {
  item: PriorityItem<Value>
  isSelected: boolean
  onClick: () => void
  buttonProps?: Partial<ButtonProps>
}

const PriorityMenuItem = <Value,>({
  item,
  isSelected,
  onClick,
  buttonProps,
}: PriorityItemProps<Value>) => (
  <Button
    {...buttonProps}
    onClick={onClick}
    isSelected={isSelected}
    label={item.label}
    disabled={item.disabled}
  />
)

const PriorityMenuMoreItem = <Value,>(props: PriorityItemProps<Value>) => (
  <PriorityMenuItem
    {...props}
    buttonProps={{theme: 'light', alignment: 'left', truncate: true}}
  />
)

const getSplitIndex = (
  array: number[],
  outerWidth: number,
  moreMenuWidth: number
): number => {
  let total = 0
  const available = outerWidth - moreMenuWidth

  for (let i = 0; i < array.length; i++) {
    const thisWidth = array[i] + GAP

    if (total + thisWidth > available) {
      return i
    }

    total += thisWidth
  }

  return array.length
}

interface PriorityMenuProps<Value> {
  items: PriorityItem<Value>[]
  itemKey?: (value: Value) => string | number
  value?: Value
  onChange: (value: Value) => void
}

export const PriorityMenu = <Value,>({
  items: itemsFromProps,
  itemKey = (value: Value) => {
    if (typeof value === 'string' || typeof value === 'number') {
      return value
    }
    throw new Error(
      `PriorityMenu: only string are supported. Please provide you own 'itemKey' method.`
    )
  },
  value,
  onChange,
}: PriorityMenuProps<Value>) => {
  const [items, setItems] = useState<PriorityItem<Value>[]>(itemsFromProps)
  const [widths, setWidths] = useState<number[]>([])
  const [updateWidths, setUpdateWidths] = useState(true)
  const [priorityItems, setPriorityItems] =
    useState<PriorityItem<Value>[]>(itemsFromProps)
  const [moreItems, setMoreItems] = useState<PriorityItem<Value>[]>([])

  const {width: outerWidth, ref: _navigationOuter} = useResizeDetector({})
  const _navigation = createRef<HTMLDivElement>()
  const {width: moreMenuWidth, ref: _moreMenu} = useResizeDetector({})

  const updateNavigation = useCallback(
    (widths: number[], outerWidth: number | undefined) => {
      try {
        const splitIndex = getSplitIndex(
          widths,
          outerWidth ?? 0,
          moreMenuWidth ?? 0
        )

        const newPriorityItems = items.slice(0, splitIndex)
        const newMoreItems = items.slice(splitIndex, items.length)

        setPriorityItems(newPriorityItems)
        setMoreItems(newMoreItems)
      } catch (e: any) {
        // No stuff to do
      }
    },
    [items, moreMenuWidth]
  )

  useEffect(() => {
    if (updateWidths) {
      if (!_navigation.current) {
        return
      }
      setWidths(
        Array.from(_navigation.current.children).map(
          (item) => item.getBoundingClientRect().width
        )
      )
      setUpdateWidths(false)
    }
  }, [_navigation, updateWidths])

  useEffect(() => {
    if (isEqual(items, itemsFromProps)) {
      return
    }
    setUpdateWidths(true)
    setItems(itemsFromProps)
    setPriorityItems(itemsFromProps)
  }, [items, itemsFromProps])

  useEffect(() => {
    updateNavigation(widths, outerWidth)
  }, [widths, outerWidth, updateNavigation])

  return (
    <Root ref={_navigationOuter} role='navigation'>
      <PriorityContainer ref={_navigation}>
        {priorityItems.map((item) => (
          <PriorityMenuItem
            key={`navItem-${itemKey(item.value)}`}
            item={item}
            onClick={() => onChange(item.value)}
            isSelected={value ? itemKey(item.value) === itemKey(value) : false}
          />
        ))}
      </PriorityContainer>

      <MoreContainer ref={_moreMenu}>
        <ButtonMenu
          icon='chevron-right'
          position='right'
          disabled={!moreItems.length}
        >
          {moreItems.map((item) => (
            <PriorityMenuMoreItem
              key={`navItem-${itemKey(item.value)}`}
              item={item}
              onClick={() => onChange(item.value)}
              isSelected={
                value ? itemKey(item.value) === itemKey(value) : false
              }
            />
          ))}
        </ButtonMenu>
      </MoreContainer>
    </Root>
  )
}
