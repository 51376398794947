import {combineReducers} from 'redux'
import {standardReducer} from 'helpers/reducers'
import {BOOKING_VIEWS} from 'options/booking'
import {SET_VIEW} from './constants'

const initialState = BOOKING_VIEWS.SINGLE_MONTHS_IN_COLUMNS

const ACTION_HANDLERS = {
  [SET_VIEW]: (state, action) => {
    if (action.view) {
      return action.view
    } else {
      return state
    }
  },
}

export const sharedReducer = combineReducers({
  view: standardReducer(initialState, ACTION_HANDLERS),
})
