import type {PayloadAction} from '@reduxjs/toolkit'
import type {SagaIterator} from 'redux-saga'
import {takeLatest, put, call} from 'redux-saga/effects'
import {standardErrorAction} from 'helpers/saga'
import {updateEntity} from 'store/entity/asyncHelpers'
import {entityTypeFromDepartmentType} from 'store/entity/helpers'
import type {StorableDepartment} from 'types/department'
import {showOrganization} from '../../actions'
import type {EntityUpdateParams} from '../../types'
import {updateDepartmentSuccess} from '../actions'
import {UPDATE, UPDATE_FAILURE} from '../constants'
import {invalidateDepartmentList} from '../helpers'

export function* updateDepartment(
  action: PayloadAction<EntityUpdateParams<StorableDepartment>>
): SagaIterator<StorableDepartment> {
  try {
    const values = action.payload
    const kind = entityTypeFromDepartmentType(values.type)
    if (!kind) {
      throw new Error(`updateDepartment: Unknown kind '${kind}'`)
    }
    const payload = yield call(() =>
      updateEntity({
        kind: kind,
        id: values.id,
        data: values,
      })
    )

    invalidateDepartmentList(values.type)
    yield put(updateDepartmentSuccess(payload))
    yield put(showOrganization({id: payload.organization.id}))
    return payload
  } catch (e: any) {
    yield put(standardErrorAction(UPDATE_FAILURE, e))
    throw e
  }
}

export function* updateSaga() {
  yield takeLatest(UPDATE, updateDepartment)
}
