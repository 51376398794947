import {useDispatch} from 'react-redux'
import useScrollbarSize from 'react-scrollbar-size'
import * as yup from 'yup'
import {Tab, TabList, TabPanel, Tabs} from 'components/elements/Tabs'
import {ErrorsTooltip, Form, SubmitButton} from 'components/form'
import {useAppSelector} from 'hooks/store'
import {initializeContract} from 'store/contract/actions'
import styles from './Form.module.css'
import {SelectAct} from './panels'

const validationSchema = yup.object().shape({
  act_id: yup.number().nullable().required().label('Act'),
})

interface SelectActData {
  act_id: number | null
}

export const SelectActForm = () => {
  const initializeParams = useAppSelector(
    ({contract}) => contract.initializeParams
  )
  const {width: scrollbarWidth} = useScrollbarSize()
  const dispatch = useDispatch()

  return (
    <Form<SelectActData, unknown, unknown>
      initialValues={{act_id: null}}
      validationSchema={validationSchema}
      onSubmit={({act_id}) => {
        if (!act_id) {
          return
        }
        dispatch(
          initializeContract({
            actId: act_id,
            fromContractId: initializeParams?.fromContractId,
          })
        )
      }}
      className={styles.form}
      enableReinitialize
    >
      {() => (
        <>
          <Tabs
            kind='contractPanes'
            className={styles.tabsContainer}
            style={{
              gridTemplateColumns: `140px ${540 + scrollbarWidth}px`,
            }}
          >
            <TabList className={styles.aside}>
              <Tab name='show'>Show</Tab>
              <Tab name='economy' disabled>
                Economy
              </Tab>
              <Tab name='tickets' disabled>
                Tickets
              </Tab>
              <Tab name='scope' disabled>
                Scope
              </Tab>
              <Tab name='production' disabled>
                Production
              </Tab>
              <Tab name='settings' disabled>
                Settings
              </Tab>
            </TabList>

            <section className={styles.main}>
              <section className={styles.content}>
                <TabPanel name='show'>
                  <SelectAct />
                </TabPanel>
              </section>
              <section className={styles.actions}>
                <section className={styles.submitActions}>
                  <ErrorsTooltip />

                  <SubmitButton
                    width='fixedDouble'
                    theme='primary'
                    label='Next'
                    type='submit'
                  />
                </section>
              </section>
            </section>
          </Tabs>
        </>
      )}
    </Form>
  )
}
