export const CONTRACT_DEFAULT_LANGUAGE = 'en'

export const CONTRACT_LANGUAGES = {
  da: {name: 'Danish', value: 'da'},
  en: {name: 'English', value: 'en'},
}

export const CONTRACT_FINANCIAL_EXPORT_STATUS_SORTING = [
  'EXPORT_STATUS_NO_EXPORT',
  'EXPORT_STATUS_TO_BE_EXPORTED',
  'EXPORT_STATUS_TO_BE_EXPORTED_AGAIN',
  'EXPORT_STATUS_EXPORTED',
  'EXPORT_STATUS_EXPORTED_MANUALLY',
  'INVOICING_STATUS_INVOICE_SENT',
  'INVOICING_STATUS_INVOICE_PAID',
]
