import type {FieldInputProps} from 'formik'
import {FieldArray, useFormikContext} from 'formik'
import {map} from 'lodash'
import {Column, InlineText} from 'containers/Form'
import type {ItemListRenderer} from './ItemListRow'
import {ItemListRow} from './ItemListRow'

const NoItemsRenderer: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Column>
    <InlineText text='No Items' />
  </Column>
)

interface ItemListProps {
  model: string
  itemRenderer: ItemListRenderer
  allowEmpty?: boolean
  itemWidth?: number
  disabled?: boolean
}

export const ItemList: React.FC<React.PropsWithChildren<ItemListProps>> = ({
  model,
  itemRenderer,
  allowEmpty,
  itemWidth,
  disabled,
}) => {
  const {getFieldProps} = useFormikContext()

  const {value: items} = getFieldProps(model) as unknown as FieldInputProps<
    unknown[]
  >
  const hasItems = !!items && !!items.length

  return (
    <FieldArray name={model}>
      {({insert: onAdd, remove: onRemove}) => {
        return (
          <section>
            {!hasItems && allowEmpty && (
              <ItemListRow
                model={model}
                disabled={disabled}
                index={0}
                itemRenderer={() => <NoItemsRenderer />}
                showAdd
                onAdd={onAdd}
                itemWidth={itemWidth}
              />
            )}
            {!hasItems && !allowEmpty && (
              <ItemListRow
                model={model}
                disabled={disabled}
                index={0}
                itemRenderer={itemRenderer}
                showAdd
                onAdd={onAdd}
                itemWidth={itemWidth}
              />
            )}
            {hasItems &&
              map(items, (item, index) => (
                <ItemListRow
                  model={model}
                  disabled={disabled}
                  key={`${model}[${index}]`}
                  index={index}
                  itemRenderer={itemRenderer}
                  showAdd
                  showRemove={allowEmpty ? true : items.length > 1}
                  onAdd={onAdd}
                  onRemove={onRemove}
                  itemWidth={itemWidth}
                />
              ))}
          </section>
        )
      }}
    </FieldArray>
  )
}
