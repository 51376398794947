import type {IconName} from 'components/elements'
import type {ContractStatusType} from 'types/contract'

export const CONTRACT_STATUS_SORTING: ContractStatusType[] = [
  'CONTRACT_STATUS_TBC',
  'CONTRACT_STATUS_CONFIRMED',
  'CONTRACT_STATUS_FINALIZED',
  'CONTRACT_STATUS_SIGNED',
  'CONTRACT_STATUS_CANCELLED',
]

export const CONTRACT_STATUS_OPTIONS: {
  [key in ContractStatusType]: {
    name: string
    icon: IconName
  }
} = {
  CONTRACT_STATUS_TBC: {
    name: 'TBC',
    icon: 'pencil',
  },
  CONTRACT_STATUS_CONFIRMED: {
    name: 'confirmed',
    icon: 'checkmark-fill',
  },
  CONTRACT_STATUS_FINALIZED: {
    name: 'sent',
    icon: 'mail',
  },
  CONTRACT_STATUS_SIGNED: {
    name: 'signed',
    icon: 'signed',
  },
  CONTRACT_STATUS_UNSIGNED: {
    name: 'Signing link sent',
    icon: 'link',
  },
  CONTRACT_STATUS_PARTIALLY_SIGNED: {
    name: 'signed by organizer',
    icon: 'phone',
  },
  CONTRACT_STATUS_FULLY_SIGNED: {
    name: 'signed by booker',
    icon: 'signed',
  },
  CONTRACT_STATUS_CANCELLED: {
    name: 'cancelled',
    icon: 'error',
  },
}
