import {Link} from 'react-router-dom'
import styled from 'styled-components'
import type {IconName} from 'components/elements'
import {Icon} from 'components/elements'
import {colors, effects, fontSizes, mq, spacing} from 'styles/variables'
import {useAppNavigation} from './hooks'

export const MenuItemContainer = styled(({active, ...rest}) => (
  <Link {...rest} />
))<{active: boolean}>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: ${fontSizes.wolfSize};
  text-decoration: none;
  cursor: default;
  position: relative;

  padding: ${spacing.u2_5} 0;

  ${({active}) =>
    active
      ? `
      background-color: ${colors.pictonBlue};
      color: ${colors.white};
      &::before {
        background: ${colors.bostonBlue};
      }`
      : `
      color: ${colors.midGray};
    }`}

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    pointer-events: none;
  }

  @media (${mq.desktop}) {
    height: 56px;
    padding: unset;

    ${({active}) =>
      active
        ? `
      color: ${colors.white};
      background-color: unset;
      &::before {
        background: ${colors.pictonBlue};
      }
      `
        : `
      color: ${colors.midGray};
      cursor: pointer;
      &:hover {
        color: ${colors.pictonBlue};
      }
      &:hover::before {
        background: ${colors.pictonBlue};
        opacity: 0.5;
      }`}
  }
`

const IconWrapper = styled.div`
  line-height: 1em;
  flex: 0 0 56px;
  text-align: center;
  transition: color ${effects.transitionTime};
  display: grid;
  place-items: center;
`

const Text = styled.div<{maximized: boolean}>`
  flex: 0 0 auto;

  @media (${mq.desktop}) {
    opacity: ${({maximized}) => (maximized ? 1 : 0)};
    transition: color ${effects.transitionTime};
  }
`

interface MenuItemProps {
  to: string
  active: boolean
  icon: IconName
  title: string
  onClick?: () => void
}

export const MenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = ({
  to,
  active,
  icon,
  title,
  onClick,
}) => {
  const {maximized} = useAppNavigation()

  return (
    <MenuItemContainer to={to} active={active} onClick={onClick}>
      <IconWrapper>
        <Icon icon={icon} size={20} />
      </IconWrapper>
      <Text maximized={maximized}>{title}</Text>
    </MenuItemContainer>
  )
}
