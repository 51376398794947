import cx from 'classnames'
import styles from './Form.module.css'
export interface SpacerProps {
  paddingTop?: 'none' | 'normal' | 'double'
  paddingBottom?: 'none' | 'normal' | 'double'
}

export const Spacer: React.FC<React.PropsWithChildren<SpacerProps>> = ({
  paddingTop = 'none',
  paddingBottom = 'none',
}) => {
  const classNames = cx(
    styles.spacer,
    styles[`paddingTop-${paddingTop}`],
    styles[`paddingBottom-${paddingBottom}`]
  )
  return <hr className={classNames} />
}
