import * as yup from 'yup'

export const nameValidationSchema = yup
  .string()
  .nullable()
  .required()
  .label('Name')

export const phoneNumbersValidationSchema = yup
  .array()
  .of(
    yup.object().shape({
      value: yup
        .object()
        .shape({
          number: yup.string().nullable().label('Phone number'),
          country_code: yup
            .string()
            .nullable()
            .when('number', (val: string | null, schema: yup.AnySchema) =>
              val ? schema.required() : schema.notRequired()
            )
            .label('Country code'),
        })
        .nullable(),
      number_type: yup
        .string()
        .nullable()
        .when('value.number', (val: string | null, schema: yup.AnySchema) =>
          val ? schema.required() : schema.notRequired()
        )
        .oneOf(['land', 'mobi', 'fax'])
        .label('Number type'),
    })
  )
  .ensure()

export const emailAddressesValidationSchema = yup
  .array()
  .of(
    yup.object().shape({
      value: yup
        .string()
        .nullable()
        .email()
        .label('Email address')
        .transform(function (value) {
          return this.isType(value) && value !== null ? value : value
        }),
    })
  )
  .ensure()

export const postalAddressValidationSchema = yup
  .object()
  .shape({
    line1: yup.string().nullable().label('Address line 1'),
    line2: yup.string().nullable().label('Address line 2'),
    postal_code: yup
      .string()
      .nullable()
      .max(255)
      .label('Postal code')
      .when('line1', (val: string | null, schema: yup.AnySchema) =>
        val ? schema.required() : schema.notRequired()
      ),
    city_name: yup
      .string()
      .nullable()
      .label('City')
      .when('line1', (val: string | null, schema: yup.AnySchema) =>
        val ? schema.required() : schema.notRequired()
      ),
    country_code: yup
      .string()
      .nullable()
      .label('Country')
      .when('line1', (val: string | null, schema: yup.AnySchema) =>
        val ? schema.required() : schema.notRequired()
      ),
  })
  .nullable()

export const websiteValidationSchema = yup
  .string()
  .nullable()
  .url()
  .label('Website')

export const businessCardValidationSchema = yup.object().shape({
  phone_numbers: phoneNumbersValidationSchema,
  email_addresses: emailAddressesValidationSchema,
  postal_address: postalAddressValidationSchema,
  website: websiteValidationSchema,
})

export const eanValidationSchema = yup.object().shape({
  value: yup.string().nullable().label('EAN'),
})

export const taxIdValidationSchema = yup
  .object()
  .shape({
    value: yup.string().nullable().label('Tax ID (CVR)'),
    country_code: yup
      .string()
      .nullable()
      .when('value', (val: string | null, schema: yup.AnySchema) =>
        val ? schema.required() : schema
      )
      .label('Country code'),
  })
  .nullable()
