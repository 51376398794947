import {combineReducers} from 'redux'
import {BOOKING_VIEWS} from 'options/booking'
import {
  SET_START_MONTH,
  SET_END_MONTH,
  SET_ACT,
  SET_SORT,
} from '../../constants'
import {
  month,
  monthWithMinMonth,
  act,
  sortBy,
  sortDirection,
} from '../../helpers/reducers'

export const singleListReducer = combineReducers({
  startMonth: month(SET_START_MONTH, BOOKING_VIEWS.SINGLE_LIST),
  endMonth: monthWithMinMonth(
    SET_END_MONTH,
    BOOKING_VIEWS.SINGLE_LIST,
    null,
    SET_START_MONTH
  ),
  act: act(SET_ACT, BOOKING_VIEWS.SINGLE),
  sortBy: sortBy(SET_SORT, BOOKING_VIEWS.SINGLE_LIST),
  sortDirection: sortDirection(SET_SORT, BOOKING_VIEWS.SINGLE_LIST),
  eventTypes: () => 'shows',
})
