import {createQueryKeys} from '@lukemorales/query-key-factory'
import {isNil, omitBy} from 'lodash'
import {api} from 'api/api'
import type {FullSearchProps} from 'api/api/search'
import {getFullSearchQueryParams} from 'api/api/search'
import type {KeyTuple} from 'api/types'

function getQueryKey(props: FullSearchProps): KeyTuple {
  const queryParams = getFullSearchQueryParams(props)
  return Object.entries(omitBy(queryParams, isNil)) as KeyTuple
}

export const search = createQueryKeys('search', {
  fullSearch: (props: FullSearchProps) => ({
    queryKey: getQueryKey(props),
    queryFn: () => api.search.fullSearch(props),
  }),
})
