import flat from 'flat'
import {
  compact,
  reduce,
  flatten,
  get,
  has,
  map,
  keys,
  isArray,
  isObject,
  isNil,
} from 'lodash'

const isTraverableError = (error) => isArray(error) || isObject(error)

const traverseError = ({key, data, errors, touched}, options) => {
  const flatData = flat(data)
  const flatKeys = keys(flatData)
  const flatMappedKeys = map(flatKeys, (k) => `${key}.${k}`)
  return errorsForGroup({errors, touched, keys: flatMappedKeys}, options)
}

const hasError = ({errors, touched, error, shouldBeTouched}) => {
  return shouldBeTouched
    ? has(errors, error) && !isNil(get(errors, error)) && !!get(touched, error)
    : has(errors, error) && !isNil(get(errors, error))
}

export const errorsForGroup = ({errors, touched, keys}, options) => {
  return reduce(
    keys,
    (sum, error) =>
      compact(
        flatten([
          ...sum,
          hasError({
            errors,
            touched,
            error,
            shouldBeTouched: get(options, 'shouldBeTouched'),
          })
            ? isTraverableError(get(errors, error))
              ? traverseError(
                  {
                    key: error,
                    data: get(errors, error),
                    errors,
                    touched,
                  },
                  options
                )
              : error
            : null,
        ])
      ),
    []
  )
}

export const errorCountForGroup = ({errors, touched, keys}, options) => {
  return errorsForGroup({errors, touched, keys}, options).length
}
