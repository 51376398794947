import {useContext} from 'react'
import {ModalInstanceContext} from 'containers/ModalContainer/ModalContainer'
import {ModalContext} from 'context/Modal'

export const useModal = () => {
  const {openModal} = useContext(ModalContext)
  const {inModal, closeModal} = useContext(ModalInstanceContext)

  return {
    openModal,
    closeModal,
    inModal,
  }
}
