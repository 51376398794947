import {useEffect, useCallback} from 'react'

export const useKeyPress = (
  isOpen: boolean,
  key: string,
  callback: (e: KeyboardEvent) => void
): void => {
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === key && !e.repeat) {
        callback(e)
      }
    },
    [callback, key]
  )

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeyPress)
    } else {
      document.removeEventListener('keydown', handleKeyPress)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [isOpen, handleKeyPress])
}
