import {combineReducers} from 'redux'
import {isLoading} from 'helpers/reducers'
import {
  CONTRACT_INITIALIZE,
  CONTRACT_INITIALIZE_SUCCESS,
  CONTRACT_INITIALIZE_FAILURE,
  CONTRACT_SHOW,
  CONTRACT_SHOW_SUCCESS,
  CONTRACT_SHOW_FAILURE,
  CONTRACT_CREATE,
  CONTRACT_CREATE_SUCCESS,
  CONTRACT_CREATE_FAILURE,
  CONTRACT_UPDATE,
  CONTRACT_UPDATE_SUCCESS,
  CONTRACT_UPDATE_FAILURE,
  CONTRACT_DESTROY,
  CONTRACT_DESTROY_SUCCESS,
  CONTRACT_DESTROY_FAILURE,
  CHANGE_STATE,
  CHANGE_STATE_SUCCESS,
  CHANGE_STATE_FAILURE,
} from './constants'
import {initializeParamsReducer} from './reducers/initializeParams'
import {resultReducer} from './reducers/result'
import {stateChangeReducer} from './reducers/stateChange'
import {viewReducer} from './reducers/view'

export const contractReducer = combineReducers({
  isInitializing: isLoading(
    CONTRACT_INITIALIZE,
    CONTRACT_INITIALIZE_SUCCESS,
    CONTRACT_INITIALIZE_FAILURE
  ),
  isLoading: isLoading(
    CONTRACT_SHOW,
    CONTRACT_SHOW_SUCCESS,
    CONTRACT_SHOW_FAILURE
  ),
  isCreating: isLoading(
    CONTRACT_CREATE,
    CONTRACT_CREATE_SUCCESS,
    CONTRACT_CREATE_FAILURE
  ),
  isUpdating: isLoading(
    CONTRACT_UPDATE,
    CONTRACT_UPDATE_SUCCESS,
    CONTRACT_UPDATE_FAILURE
  ),
  isDestroying: isLoading(
    CONTRACT_DESTROY,
    CONTRACT_DESTROY_SUCCESS,
    CONTRACT_DESTROY_FAILURE
  ),
  isChangingState: isLoading(
    CHANGE_STATE,
    CHANGE_STATE_SUCCESS,
    CHANGE_STATE_FAILURE
  ),
  view: viewReducer,
  initializeParams: initializeParamsReducer,
  result: resultReducer,
  stateChange: stateChangeReducer,
})
