import styled from 'styled-components'
import {colors, effects} from 'styles/variables'
import type {IconName} from '../Icon'
import {Icon as BaseIcon} from '../Icon'

type ToggleSize = 'normal' | 'small'
type ToggleTheme = 'normal' | 'negative' | 'neutral' | 'positive'

interface ToggleContainerProps {
  size: ToggleSize
  theme: ToggleTheme
  disabled: boolean
  isActive: boolean
}
export const ToggleContainer = styled.div<ToggleContainerProps>`
  position: relative;
  border: 1px solid transparent;
  border-radius: 999px;
  user-select: none;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  transition: all 100ms ease-in-out;
  background: ${({disabled}) =>
    disabled ? effects.inputDisabledBackground : colors.white};
  display: inline-grid;

  ${({disabled}) => disabled && `border: ${effects.inputDisabledBorder};`}

  &:hover {
    box-shadow: ${({disabled}) =>
      disabled ? effects.inputDisabledBoxShadow : effects.inputHoverBoxShadow};
  }

  height: ${({size}) => (size === 'small' ? 24 : 30)}px;

  ${({theme, isActive}) =>
    theme === 'normal' &&
    (isActive
      ? `
        color: ${colors.bostonBlue};
        border-color: ${colors.bostonBlue};`
      : `
        color: ${colors.santasGray};
        border-color: ${colors.santasGray};`)}

  ${({theme, isActive}) =>
    theme === 'negative' &&
    (isActive
      ? `
        color: ${colors.amaranth};
        border-color: ${colors.amaranth};`
      : `
        color: ${colors.santasGray};
        border-color: ${colors.santasGray};`)}

  ${({theme, isActive}) =>
    theme === 'positive' &&
    (isActive
      ? `
        color: ${colors.mountainMeadow};
        border-color: ${colors.mountainMeadow};`
      : `
        color: ${colors.santasGray};
        border-color: ${colors.santasGray};`)}

  ${({theme}) =>
    theme === 'neutral' &&
    `
      color: ${colors.santasGray};
      border-color: ${colors.santasGray};`}
`

const Content = styled.div`
  margin: 3px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-content: center;
  align-items: center;
`

interface SliderProps {
  size: ToggleSize
  theme: ToggleTheme
  isActive: boolean
}
const Slider = styled.div<SliderProps>`
  position: absolute;
  top: 3px;
  bottom: 3px;
  border-radius: 999px;
  transition: all 100ms ease-in-out;

  left: ${({isActive}) => (isActive ? `calc(100% - 3px)` : `3px`)};
  transform: translateX(${({isActive}) => (isActive ? '-100%' : 0)});

  width: ${({size}) => (size === 'small' ? 18 : 24)}px;
  height: ${({size}) => (size === 'small' ? 18 : 24)}px;

  ${({theme, isActive}) =>
    theme === 'normal' &&
    (isActive
      ? `background: ${colors.pictonBlue};`
      : `background: ${colors.santasGray};`)}

  ${({theme, isActive}) =>
    theme === 'negative' &&
    (isActive
      ? `background: ${colors.thunderbird};`
      : `background: ${colors.santasGray};`)}

  ${({theme, isActive}) =>
    theme === 'positive' &&
    (isActive
      ? `background: ${colors.watercourse};`
      : `background: ${colors.santasGray};`)}

  ${({theme}) => theme === 'neutral' && `background: ${colors.santasGray};`}
`
interface BaseContainerProps {
  size: ToggleSize
  theme: ToggleTheme
  isActive: boolean
}
const BaseContainer = styled.div<BaseContainerProps>`
  grid-column: 1 / -1;
  grid-row: 1;
  display: grid;
  transition: all 100ms ease-in-out;
  text-transform: uppercase;
  fill: currentColor;

  &:empty {
    min-width: ${({size}) => (size === 'small' ? 20 : 24)}px;
  }
`

const InactiveContainer = styled(BaseContainer)`
  justify-items: end;

  margin-left: ${({size}) => (size === 'small' ? 22 : 27)}px;
  opacity: ${({isActive}) => (isActive ? 0 : 1)};
`

const ActiveContainer = styled(BaseContainer)`
  justify-items: start;

  opacity: ${({isActive}) => (isActive ? 1 : 0)};
  margin-right: ${({size}) => (size === 'small' ? 22 : 27)}px;
`
interface TextProps {
  size: ToggleSize
}
const Text = styled.span<TextProps>`
  font-size: ${({size}) => (size === 'small' ? 7 : 9)}px;
  line-height: ${({size}) => (size === 'small' ? 7 : 9)}px;
  padding-left: ${({size}) => (size === 'small' ? 0 : 4)}px;
  padding-right: ${({size}) => (size === 'small' ? 0 : 4)}px;
`

const Icon = styled(BaseIcon)`
  margin-left: ${({size}) => (size === 'small' ? 0 : 4)}px;
  margin-right: ${({size}) => (size === 'small' ? 0 : 4)}px;
`

interface ToggleProps {
  value: boolean
  onChange: (val: any) => void
  disabled?: boolean
  inactiveValue?: any
  activeValue?: any
  inactiveText?: string
  activeText?: string
  inactiveIcon?: IconName
  activeIcon?: IconName
  invert?: boolean
  className?: string
  style?: React.CSSProperties
  size?: ToggleSize
  theme?: ToggleTheme
}

export const Toggle: React.FC<React.PropsWithChildren<ToggleProps>> = ({
  value,
  onChange,
  disabled,
  inactiveValue,
  activeValue,
  inactiveText,
  activeText,
  inactiveIcon,
  activeIcon,
  invert,
  className,
  style,
  size = 'normal',
  theme = 'normal',
}) => {
  const isSimple = inactiveValue === undefined || activeValue === undefined

  const isActive = isSimple
    ? !invert
      ? !!value
      : !value
    : !invert
    ? value === activeValue
    : value !== activeValue

  const handleToggle = () => {
    if (disabled) {
      return
    }
    if (isSimple) {
      onChange(!value)
    } else {
      onChange(value === activeValue ? inactiveValue : activeValue)
    }
  }

  const leftText = !invert ? activeText : inactiveText
  const leftIcon = !invert ? activeIcon : inactiveIcon
  const rightText = !invert ? inactiveText : activeText
  const rightIcon = !invert ? inactiveIcon : activeIcon

  return (
    <ToggleContainer
      size={size}
      theme={theme}
      disabled={!!disabled}
      isActive={isActive}
      className={className}
      style={style}
      onClick={handleToggle}
    >
      <Content>
        <ActiveContainer size={size} theme={theme} isActive={isActive}>
          {!!leftText && <Text size={size}>{leftText}</Text>}
          {!!leftIcon && <Icon icon={leftIcon} />}
        </ActiveContainer>
        <InactiveContainer size={size} theme={theme} isActive={isActive}>
          {!!rightText && <Text size={size}>{rightText}</Text>}
          {!!rightIcon && <Icon icon={rightIcon} />}
        </InactiveContainer>
      </Content>
      <Slider size={size} theme={theme} isActive={isActive} />
    </ToggleContainer>
  )
}
