import {useShowTypes} from 'api'
import {Select} from 'components/elements'
import type {SelectProps} from 'components/elements/Select'

type ShowTypeSelectProps = Omit<SelectProps, 'options'>

export const ShowTypeSelect: React.FC<
  React.PropsWithChildren<ShowTypeSelectProps>
> = (props) => {
  const {data = []} = useShowTypes()

  return (
    <Select
      {...props}
      options={data.map((showType) => ({
        label: showType.name,
        value: showType.id,
      }))}
    />
  )
}
