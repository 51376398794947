import {createAction} from '@reduxjs/toolkit'
import {createActionWithThunk} from 'utils'
import {
  INIT,
  CLEAR,
  CREATE,
  SET_RECIPIENTS,
  SET_SUBJECT,
  SET_BODY,
} from './constants'

export const init = createActionWithThunk<any>(INIT)

export const clear = createAction(CLEAR)

export const create = createActionWithThunk<any>(CREATE)

export const setRecipients = createAction<any>(SET_RECIPIENTS)

export const setSubject = createAction<any>(SET_SUBJECT)

export const setBody = createAction<any>(SET_BODY)
