import type {ReactElement} from 'react'
import type {
  GroupBase,
  DropdownIndicatorProps,
  ClearIndicatorProps,
  MenuProps,
} from 'react-select'
import ReactSelect, {components} from 'react-select'
import {Icon} from 'components/elements'
import {styles} from './styles'
import type {ReactSelectProps} from './types'

function ClearIndicator<
  OptionType,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>(props: ClearIndicatorProps<OptionType, IsMulti, GroupType>): ReactElement {
  return (
    components.ClearIndicator && (
      <components.ClearIndicator {...props}>
        <Icon icon='delete-13px' />
      </components.ClearIndicator>
    )
  )
}

function DropdownIndicator<
  OptionType,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>(props: DropdownIndicatorProps<OptionType, IsMulti, GroupType>): ReactElement {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon icon='chevron-down' />
      </components.DropdownIndicator>
    )
  )
}

function Menu<
  OptionType,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>({
  selectProps,
  ...props
}: MenuProps<OptionType, IsMulti, GroupType>): ReactElement {
  return (
    components.Menu && (
      <components.Menu selectProps={selectProps} {...props}>
        <>
          {props.children}
          {'actionRenderer' in selectProps &&
            selectProps.actionRenderer &&
            typeof selectProps.actionRenderer === 'function' &&
            selectProps.actionRenderer(selectProps.inputValue)}
        </>
      </components.Menu>
    )
  )
}

export const Select = ({
  theme = 'standard',
  showError = false,
  isDisabled,
  disabled,
  isSelected = false,
  onFocus,
  onBlur,
  ...props
}: ReactSelectProps): ReactElement => {
  const handleFocus = (e: React.FocusEvent<HTMLElement>) => {
    if (onFocus) {
      onFocus(e as React.FocusEvent<HTMLInputElement>)
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
    if (onBlur) {
      onBlur(e as React.FocusEvent<HTMLInputElement>)
    }
  }

  return (
    <ReactSelect
      {...props}
      onFocus={handleFocus}
      onBlur={handleBlur}
      isDisabled={isDisabled || disabled}
      components={{
        ClearIndicator,
        IndicatorSeparator: undefined,
        DropdownIndicator,
        Menu,
      }}
      classNamePrefix='select'
      styles={styles({theme, showError, isSelected})}
    />
  )
}
