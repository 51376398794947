import {createAction} from '@reduxjs/toolkit'
import {
  CLEAR,
  CREATE,
  CREATE_SUCCESS,
  NEW,
  SHOW,
  SHOW_SUCCESS,
  UPDATE,
  UPDATE_SUCCESS,
} from './constants'
import type {
  PersonCreateParams,
  PersonCreateResponsePayload,
  PersonNewParams,
  PersonShowParams,
  PersonShowResponsePayload,
  PersonUpdateParams,
  PersonUpdateResponsePayload,
} from './types'

export const newPerson = createAction<PersonNewParams>(NEW)

export const showPerson = createAction<PersonShowParams>(SHOW)
export const showPersonSuccess =
  createAction<PersonShowResponsePayload>(SHOW_SUCCESS)

export const createPerson = createAction<PersonCreateParams>(CREATE)
export const createPersonSuccess =
  createAction<PersonCreateResponsePayload>(CREATE_SUCCESS)

export const updatePerson = createAction<PersonUpdateParams>(UPDATE)
export const updatePersonSuccess =
  createAction<PersonUpdateResponsePayload>(UPDATE_SUCCESS)

export const clearPerson = createAction(CLEAR)
