import {takeLatest, fork, put, putResolve} from 'redux-saga/effects'
import {api} from 'api/api'
import {toastrActions} from 'features/toastr'
import {standardErrorAction} from 'helpers/saga'
import {prompt} from 'store/prompt'
import type {Contract} from 'types/contract'
import type {PayloadActionWithThunk} from 'utils'
import {destroyContractSuccess} from '../actions'
import {CONTRACT_DESTROY, CONTRACT_DESTROY_FAILURE} from '../constants'
import type {ContractDeleteParams} from '../types'

export function* destroyContract(
  action: PayloadActionWithThunk<ContractDeleteParams>
) {
  try {
    let confirmed
    try {
      yield putResolve(
        prompt({
          kind: 'danger',
          title: 'This will delete the contract',
          description: 'There is no going back from this...',
          cancelText: 'Abort',
        })
      )
      confirmed = true
    } catch {
      confirmed = false
    }

    if (!confirmed) {
      yield put(
        standardErrorAction(
          CONTRACT_DESTROY_FAILURE,
          new Error('Contract deletion aborted')
        )
      )
      return
    }

    const payload: Contract = yield api.contracts.show(action.payload.id)
    yield api.contracts.destroy(action.payload.id)
    yield put(destroyContractSuccess(payload, action.meta))
    // TODO: Close modal
    yield put(toastrActions.successToast('Contract was successfully deleted'))
    return payload
  } catch (e: any) {
    if (e.message === 'Contract not confirmed') {
      console.info('Tried to destroy a non-confirmed contract')
      e.handled = true
    } else {
      yield put(toastrActions.errorToast('Contract could not be deleted'))
    }
    yield put(standardErrorAction(CONTRACT_DESTROY_FAILURE, e, action.meta))
    throw e
  }
}
export function* watchDestroyContract() {
  yield takeLatest(CONTRACT_DESTROY, destroyContract)
}

// BASE SAGA
export function* destroySaga() {
  yield fork(watchDestroyContract)
}
