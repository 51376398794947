import {Spinner} from 'components/elements'
import {Container} from 'containers/Core'
import {Main} from './elements'
import {useMultipleMonthsInColumns} from './hooks'
import {ViewHeader, ShowKind, MonthRangePicker} from '../components'
import {MonthsInColumnsNew} from '../views'

export const MultipleMonthsInColumns = () => {
  const {viewKey, isLoading, grid} = useMultipleMonthsInColumns()

  return (
    <Container>
      <ViewHeader>
        <MonthRangePicker />
        <ShowKind />
      </ViewHeader>

      <Main flex>
        {isLoading && <Spinner overlay />}
        <MonthsInColumnsNew viewKey={viewKey} grid={grid} />
      </Main>
    </Container>
  )
}
