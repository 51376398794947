import {useContext} from 'react'
import {CloseButton} from 'components/elements'
import {MobileMenuInstanceContext} from './context'

export const MobileMenuCloseButton = () => {
  const {inMenu, closeMenu} = useContext(MobileMenuInstanceContext)

  if (!inMenu) {
    return null
  }

  return <CloseButton onClick={closeMenu} />
}
