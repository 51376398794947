import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import type {Toast, ToastOptions} from 'types/toast'

let ID = 0

interface AddPayload {
  message: string
  description?: string
  options?: Partial<ToastOptions>
}

interface RemovePayload {
  id: number
}

function buildOptions({
  options,
  append: appendOptions,
  prepend: prependOptions,
}: {
  options?: Partial<ToastOptions>
  append?: Partial<ToastOptions>
  prepend?: Partial<ToastOptions>
}): Partial<ToastOptions> {
  return {
    ...prependOptions,
    ...options,
    ...appendOptions,
  }
}

function buildToast(payload: AddPayload): Toast {
  const {message, description, options} = payload

  const toast: Toast = {
    kind: 'info',
    icon: 'info',
    message,
    description,
    ...options,
    id: (ID += 1),
  }

  return toast
}

export const toastrSlice = createSlice({
  name: 'toastr',
  initialState: [] as Toast[],
  reducers: {
    add: (state, action: PayloadAction<AddPayload>) =>
      state.concat(buildToast(action.payload)),
    remove: (state, action: PayloadAction<RemovePayload>) =>
      state.filter((toast) => toast.id !== action.payload.id),
  },
})

const addFunc = toastrSlice.actions.add

toastrSlice.getInitialState

type Action = (
  message: AddPayload['message'],
  description?: AddPayload['description'],
  options?: AddPayload['options']
) => ReturnType<typeof addFunc>

type Actions =
  | 'add'
  | 'successToast'
  | 'warningToast'
  | 'errorToast'
  | 'infoToast'

const toastrAddActions: Record<Actions, Action> = {
  add: (message, description, options) =>
    addFunc({message, description, options}),

  successToast: (message, description, options) =>
    addFunc({
      message,
      description,
      options: buildOptions({
        options,
        append: {icon: 'checkmark', kind: 'success'},
      }),
    }),

  warningToast: (message, description, options) =>
    addFunc({
      message,
      description,
      options: buildOptions({
        options,
        append: {icon: 'warning', kind: 'warning'},
      }),
    }),

  errorToast: (message, description, options) =>
    addFunc({
      message,
      description,
      options: buildOptions({
        prepend: {timeout: 10},
        options,
        append: {icon: 'error-8', kind: 'error'},
      }),
    }),

  infoToast: (message, description, options) =>
    addFunc({
      message,
      description,
      options: buildOptions({
        options,
        append: {icon: 'info', kind: 'info'},
      }),
    }),
}

export const toastrActions = {
  ...toastrAddActions,
  removeToast: toastrSlice.actions.remove,
}
