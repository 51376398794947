import type {PayloadAction} from '@reduxjs/toolkit'
import {createReducer} from '@reduxjs/toolkit'
import {
  createPlannerFailure,
  createPlannerStart,
  createPlannerSuccess,
  deletePlannerFailure,
  deletePlannerStart,
  deletePlannerSuccess,
  updatePlannerFailure,
  updatePlannerStart,
  updatePlannerSuccess,
} from '../actions'
import type {PlannerActionMeta, PlannerStoreAsyncAction} from '../types'

// ------------------------------------
// INITIAL STATE
// ------------------------------------
export const INITIAL_STATE = {
  key: null,
  activity: false,
  result: null,
}

const request = (
  s: PlannerStoreAsyncAction,
  a: PayloadAction<unknown, string, PlannerActionMeta>
): PlannerStoreAsyncAction => ({
  key: a.meta.cellKey,
  activity: true,
  result: null,
})

const success = (
  s: PlannerStoreAsyncAction,
  a: PayloadAction<unknown, string, PlannerActionMeta>
): PlannerStoreAsyncAction => ({
  key: a.meta.cellKey,
  activity: false,
  result: 'SUCCESS',
})

const failure = (
  s: PlannerStoreAsyncAction,
  action: PayloadAction<unknown, string, PlannerActionMeta>
): PlannerStoreAsyncAction => ({
  key: action.meta.cellKey,
  activity: false,
  result: 'FAILURE',
})

export const asyncActionReducer = createReducer<PlannerStoreAsyncAction>(
  INITIAL_STATE,
  (builder) => {
    builder
      .addCase(createPlannerStart, request)
      .addCase(createPlannerSuccess, success)
      .addCase(createPlannerFailure, failure)
      .addCase(updatePlannerStart, request)
      .addCase(updatePlannerSuccess, success)
      .addCase(updatePlannerFailure, failure)
      .addCase(deletePlannerStart, request)
      .addCase(deletePlannerSuccess, success)
      .addCase(deletePlannerFailure, failure)
  }
)
