import {combineReducers} from 'redux'
import {BOOKING_VIEWS} from 'options/booking'
import {SET_MONTH, SET_SHOW_KIND} from '../../constants'
import {
  month,
  monthWeekStartDay,
  monthWeekEndDay,
  showKind,
} from '../../helpers/reducers'

export const multipleMonthReducer = combineReducers({
  month: month(SET_MONTH, BOOKING_VIEWS.MULTIPLE_MONTH),
  startDay: monthWeekStartDay(SET_MONTH, BOOKING_VIEWS.MULTIPLE_MONTH),
  endDay: monthWeekEndDay(SET_MONTH, BOOKING_VIEWS.MULTIPLE_MONTH),
  showKind: showKind(SET_SHOW_KIND, BOOKING_VIEWS.MULTIPLE),
})
