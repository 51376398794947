import {createContext} from 'react'

interface MobileMenuInstanceContextProps {
  inMenu: boolean
  closeMenu: () => void
}

export const MobileMenuInstanceContext =
  createContext<MobileMenuInstanceContextProps>({
    inMenu: false,
    closeMenu: () => undefined,
  })
