import type {FC} from 'react'
import {Search} from 'app/modal/Search'
import {Header, HeaderLeft, HeaderRight} from 'containers/Core'
import {AddNewButtonMenu} from './AddNewButtonMenu'

export const ViewHeader: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <Header>
    <HeaderLeft>{children}</HeaderLeft>
    <HeaderRight>
      <AddNewButtonMenu />
      <Search />
    </HeaderRight>
  </Header>
)
