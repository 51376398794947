import {Input, CountrySelect} from 'components/form'
import {CompoundInput, CompoundInputLine} from 'containers/Form'
import type {ItemListRendererProps} from '../util/ItemList/ItemListRow'

export const PostalAddress: React.FC<
  React.PropsWithChildren<ItemListRendererProps>
> = ({model, disabled}) => (
  <CompoundInput>
    <CompoundInputLine>
      <Input
        model={`${model}.line1`}
        disabled={disabled}
        placeholder='Address line 1'
        kind='text'
        grid='20'
      />
    </CompoundInputLine>
    <CompoundInputLine>
      <Input
        model={`${model}.line2`}
        disabled={disabled}
        placeholder='Address line 2'
        kind='text'
        grid='20'
      />
    </CompoundInputLine>
    <CompoundInputLine>
      <Input
        model={`${model}.postal_code`}
        disabled={disabled}
        placeholder='Zip-code'
        kind='text'
        grid='4'
      />
      <Input
        model={`${model}.city_name`}
        disabled={disabled}
        placeholder='City'
        kind='text'
        grid='9'
      />
      <CountrySelect
        theme='form'
        model={`${model}.country_code`}
        disabled={disabled}
        placeholder='Country'
        kind='country'
        grid='7'
      />
    </CompoundInputLine>
  </CompoundInput>
)
