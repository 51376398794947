import type {
  BusinessCardPhoneNumber,
  BusinessCardPostalAddress,
} from 'types/businessCard'
import type {DateTimeString} from 'types/generic'
import type {SearchResultPhoneNumber} from 'types/searchResult'
import {formatDate} from 'utils'

export function phoneNumber(
  obj: BusinessCardPhoneNumber | SearchResultPhoneNumber
): string | null {
  // Suppert both numbers embedded en value object and root objects
  const countryCode = 'value' in obj ? obj.value.country_code : obj.country_code
  let number = 'value' in obj ? obj.value.number : obj.number

  if (number) {
    if (countryCode === '45') {
      number = number.match(/.{1,2}/g)?.join(' ') ?? ''
    }
    return `+${countryCode} ${number}`
  }

  return null
}
export function phoneNumberType(
  obj: BusinessCardPhoneNumber | SearchResultPhoneNumber
): string | null {
  // TODO: Search results type are not supported
  const numberType = 'value' in obj ? obj.number_type : 'mobile'
  switch (numberType) {
    case 'land':
      return 'land-line'
    case 'fax':
      return 'fax'
    case 'mobi':
    default:
      return 'mobile'
  }
}

export function postalAddress(
  obj: BusinessCardPostalAddress
): {value: string}[] {
  if (
    !obj ||
    !obj.line1 ||
    !obj.postal_code ||
    !obj.city_name ||
    !obj.country_code
  ) {
    return []
  }
  const address = [{value: obj.line1}]
  if (obj.line2) {
    address.push({value: obj.line2})
  }
  address.push({
    value: `${obj.postal_code} ${obj.city_name} - ${obj.country_code}`,
  })
  return address
}

export function dateFromStartOnAndStartAt(obj: {
  start_at: DateTimeString
  show_at: DateTimeString
}): string {
  const date = new Date(obj.start_at)

  const [hours, minutes] = obj.show_at.split(':')
  date.setHours(parseInt(hours))
  date.setMinutes(parseInt(minutes))

  return formatDate(date, 'DateShortWithTime')
}
