import type {FC} from 'react'
import styled from 'styled-components'
import {colors, fontSizes} from 'styles/variables'

const Root = styled.div`
  color: ${colors.secondaryTextColor};
  font-style: italic;
  font-size: ${fontSizes.ratSize};
`

export const HelpText: FC<React.PropsWithChildren<unknown>> = ({children}) => {
  return <Root>{children}</Root>
}
