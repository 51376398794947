import {Container} from 'containers/Form'
import type {ContractPanelProps} from './types'
import {Tickets as TicketsComp, Capacities} from '../sections'

export const Tickets: React.FC<React.PropsWithChildren<ContractPanelProps>> = ({
  locked,
}) => {
  return (
    <Container>
      <TicketsComp />
      <Capacities locked={locked} />
    </Container>
  )
}
