import {useEffect} from 'react'
import {useFormikContext} from 'formik'
import {useOrganizer} from 'api'
import type {SelectOptionItem, SelectProps} from 'components/elements/Select'
import {Select} from 'components/form'
import type {FormikFieldProps} from 'components/form/formikFieldHOC'
import type {Contract} from 'types/contract'

interface ContractOrganizerContactIdProps
  extends Omit<FormikFieldProps<SelectProps>, 'onChange' | 'options'> {
  organizerModel: string
}

export const OrganizerContactId: React.FC<
  React.PropsWithChildren<ContractOrganizerContactIdProps>
> = ({disabled, organizerModel, model, ...props}) => {
  const {getFieldProps, setFieldValue} = useFormikContext<Contract>()
  const currentValue = getFieldProps(model).value
  const organizerId = getFieldProps(organizerModel).value
  const {data: organizer} = useOrganizer(organizerId)

  let options: SelectOptionItem[] = []

  if (organizer) {
    options = organizer.roles.map((role) => {
      return {
        label: role.affiliation.person.full_name,
        value: role.id,
      }
    })
  }

  useEffect(() => {
    if (organizer && currentValue) {
      if (!organizer.roles.some((role) => role.id === currentValue)) {
        setFieldValue(model, null)
      }
    }
  }, [organizer, currentValue])

  return (
    <Select
      theme='form'
      {...props}
      model={model}
      disabled={disabled || !organizerId}
      placeholder={
        organizerId ? 'Select contact...' : 'Select organizer first...'
      }
      options={options}
    />
  )
}
