import {useCallback, useEffect, useRef, useState} from 'react'
import {Icon} from 'components/elements'
import {toastrActions} from 'features/toastr'
import {useAppDispatch} from 'hooks/store'
import type {Toast as IToast} from 'types/toast'
import {Content, Root, IconWrapper, Message, Description} from './components'

interface ToastProps {
  data: IToast
}

export const Toast = ({data}: ToastProps) => {
  const dispatch = useAppDispatch()
  const {
    id,
    kind,
    sticky,
    icon,
    message,
    description,
    timeout: timeoutFromData = 5,
  } = data

  const timeoutRef = useRef<NodeJS.Timeout | null>()
  const [timeoutCount, setTimeoutCount] = useState(timeoutFromData)
  const [visible, setVisible] = useState(false)
  const [paused, setPaused] = useState(false)
  const [removing, setRemoving] = useState(false)

  const clearTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  const handleRemove = useCallback(() => {
    clearTimer()
    setVisible(false)
    setRemoving(true)
    setTimeout(() => dispatch(toastrActions.removeToast({id})), 200)
  }, [dispatch, id])

  const handleMouseEnter = () => setPaused(true)
  const handleMouseLeave = () => setPaused(false)

  const handleClick = () => handleRemove()

  useEffect(() => {
    if (sticky) {
      return
    }

    if (paused) {
      return
    }

    if (timeoutCount <= 0) {
      handleRemove()
      return
    }

    if (!sticky) {
      timeoutRef.current = setTimeout(() => {
        setTimeoutCount(timeoutCount - 1)
      }, 1000)
    }
  }, [sticky, timeoutRef, timeoutCount, handleRemove, paused])

  useEffect(() => {
    if (!visible && !removing) {
      setVisible(true)
    }
  }, [visible, removing])

  return (
    <Root
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      visible={visible}
      kind={kind}
    >
      {icon && (
        <IconWrapper>
          <Icon icon={icon} size={24} />
        </IconWrapper>
      )}
      <Content>
        {message && <Message>{message}</Message>}
        {description && <Description>{description}</Description>}
      </Content>
    </Root>
  )
}
