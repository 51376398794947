import {Select as SelectField} from 'components/elements'
import {CountrySelect as CountrySelectField} from './fields/CountrySelect'
import {CurrencySelect as CurrencySelectField} from './fields/CurrencySelect'
import {OrganizerSelect as OrganizerSelectField} from './fields/OrganizerSelect'
import {ShowTypeSelect as ShowTypeSelectField} from './fields/ShowTypeSelect'
import {VenueSelect as VenueSelectField} from './fields/VenueSelect'
import {formikFieldHOC} from './formikFieldHOC'
import {MyActsSelect as MyActsSelectField} from '../connected/MyActsSelect'
import {PlannerSelect as PlannerSelectField} from '../connected/PlannerSelect'
import {Checkbox as CheckboxField} from '../elements/Checkbox'
import {DatePicker as DatePickerField} from '../elements/DatePicker'
import {Input as InputField} from '../elements/Input'
import {NumberInput as NumberInputField} from '../elements/NumberInput'
import {RadioGroup as RadioGroupField} from '../elements/RadioGroup'
import {TimePicker as TimePickerField} from '../elements/TimePicker'
import {Toggle as ToggleField} from '../elements/Toggle/Toggle'

// Base fields
export const Checkbox = formikFieldHOC(CheckboxField)
export const DatePicker = formikFieldHOC(DatePickerField)
export const Input = formikFieldHOC(InputField)
export const NumberInput = formikFieldHOC(NumberInputField)
export const RadioGroup = formikFieldHOC(RadioGroupField)
export const TimePicker = formikFieldHOC(TimePickerField)
export const Toggle = formikFieldHOC(ToggleField)
// Form fields
export const CountrySelect = formikFieldHOC(CountrySelectField)
export const CurrencySelect = formikFieldHOC(CurrencySelectField)
export const ShowTypeSelect = formikFieldHOC(ShowTypeSelectField)
export const Select = formikFieldHOC(SelectField)

export const MyActsSelect = formikFieldHOC(MyActsSelectField)
export const PlannerSelect = formikFieldHOC(PlannerSelectField)
export const OrganizerSelect = formikFieldHOC(OrganizerSelectField)
export const VenueSelect = formikFieldHOC(VenueSelectField)
// Form utils
export {Form} from './util/Form'
export {ItemList} from './util/ItemList'

// Helpers
export {ErrorsTooltip} from './helpers/ErrorsTooltip'
export {SubmitButton} from './helpers/SubmitButton'
export {TabWithErrorCount} from './helpers/TabWithErrorCount'
// Types
export type {FormikFieldProps} from './formikFieldHOC'
