import {useFormikContext} from 'formik'
import {Select} from 'components/form'
import {Container, Section, Row, Column} from 'containers/Form'
import {PRODUCTION_STATUS_OPTIONS} from 'options/productionStatus'
import type {Contract} from 'types/contract'
import type {ContractPanelProps} from './types'

export const Production: React.FC<
  React.PropsWithChildren<ContractPanelProps>
> = () => {
  const {values} = useFormikContext<Contract>()

  return (
    <Container>
      <Section label='Production status'>
        <Row>
          <Column>
            {values.status === 'CONTRACT_STATUS_TBC' && (
              <div>
                Please confirm the contract to open for production options
              </div>
            )}
            {values.status !== 'CONTRACT_STATUS_TBC' && (
              <Select
                theme='form'
                model='show.production_status'
                horizontal
                options={PRODUCTION_STATUS_OPTIONS}
                placeholder='Select production status...'
              />
            )}
          </Column>
          <Column />
        </Row>
      </Section>
    </Container>
  )
}
