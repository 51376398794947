import {Container} from 'containers/Form'
import {Contact, Address, AdditionalInformation} from '../sections'

export const EntityFormPanelContact = () => {
  return (
    <Container>
      <Contact />
      <Address />
      <AdditionalInformation />
    </Container>
  )
}
