import {useQuery} from '@tanstack/react-query'
import {queryKeys} from 'api/queries'
import {getTTL} from 'utils'

export const useOrganizers = () =>
  useQuery({
    ...queryKeys.organizers.list,
    staleTime: getTTL('NORMAL'),
  })

export const useOrganizer = (id?: number) => {
  return useQuery({
    ...queryKeys.organizers.show(id ?? 0),
    enabled: !!id,
  })
}
