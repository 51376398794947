/* THE VALUES ARE NAMED TO MATCH STORE NAMES */
// TODO: Dynamically name stores
export const BOOKING_VIEWS = {
  FLOW: 'flow',
  SINGLE: 'single',
  MULTIPLE: 'multiple',
  SINGLE_MONTHS_IN_COLUMNS: 'singleMonthsInColumns',
  SINGLE_MONTH: 'singleMonth',
  SINGLE_LIST: 'singleList',
  MULTIPLE_ACTS_IN_COLUMNS: 'multipleActsInColumns',
  MULTIPLE_MONTHS_IN_COLUMNS: 'multipleMonthsInColumns',
  MULTIPLE_MONTH: 'multipleMonth',
  MULTIPLE_LIST: 'multipleList',
}

export const BOOKING_BASE_PATH = 'booking'

export const BOOKING_PATHS = {
  [BOOKING_VIEWS.FLOW]: 'flow',
  [BOOKING_VIEWS.SINGLE_MONTHS_IN_COLUMNS]: 'single/months-in-columns',
  [BOOKING_VIEWS.SINGLE_MONTH]: 'single/month',
  [BOOKING_VIEWS.SINGLE_LIST]: 'single/list',
  [BOOKING_VIEWS.MULTIPLE_ACTS_IN_COLUMNS]: 'multiple/acts-in-columns',
  [BOOKING_VIEWS.MULTIPLE_MONTHS_IN_COLUMNS]: 'multiple/months-in-columns',
  [BOOKING_VIEWS.MULTIPLE_MONTH]: 'multiple/month',
  [BOOKING_VIEWS.MULTIPLE_LIST]: 'multiple/list',
}
