import {createAction} from '@reduxjs/toolkit'
import {createActionWithMeta} from 'utils'
import {
  FETCH,
  CREATE,
  UPDATE,
  DELETE,
  HIGHLIGHT_TOUR,
  CREATE_SUCCESS,
  UPDATE_START,
  UPDATE_SUCCESS,
  DELETE_START,
  DELETE_SUCCESS,
  FETCH_START,
  FETCH_SUCCESS,
  CREATE_FAILURE,
  UPDATE_FAILURE,
  DELETE_FAILURE,
  FETCH_FAILURE,
  LIST_UPDATE_EVENT,
  LIST_DESTROY_EVENT,
  CREATE_START,
} from './constants'
import type {
  PlannerCreateParams,
  PlannerCreateSuccessPayload,
  PlannerDeleteParams,
  PlannerDeleteSuccessPayload,
  PlannerActionMeta,
  PlannerUpdateParams,
  PlannerUpdateSuccessPayload,
  PlannerFetchParams,
  PlannerFetchSuccessPayload,
  PlannerHighlightTour,
  PlannerListDestroyEventParams,
  PlannerListUpdateEventParams,
} from './types'

export const fetchPlanners = createAction<PlannerFetchParams>(FETCH)
export const fetchPlannersStart = createAction<void>(FETCH_START)
export const fetchPlannersSuccess =
  createAction<PlannerFetchSuccessPayload>(FETCH_SUCCESS)
export const fetchPlannersFailure = createAction<unknown>(FETCH_FAILURE)

export const createPlanner = createActionWithMeta<
  PlannerCreateParams,
  PlannerActionMeta
>(CREATE)
export const createPlannerStart = createActionWithMeta<void, PlannerActionMeta>(
  CREATE_START
)
export const createPlannerSuccess = createActionWithMeta<
  PlannerCreateSuccessPayload,
  PlannerActionMeta
>(CREATE_SUCCESS)
export const createPlannerFailure = createActionWithMeta<
  unknown,
  PlannerActionMeta
>(CREATE_FAILURE)

export const updatePlanner = createActionWithMeta<
  PlannerUpdateParams,
  PlannerActionMeta
>(UPDATE)
export const updatePlannerStart = createActionWithMeta<void, PlannerActionMeta>(
  UPDATE_START
)
export const updatePlannerSuccess = createActionWithMeta<
  PlannerUpdateSuccessPayload,
  PlannerActionMeta
>(UPDATE_SUCCESS)
export const updatePlannerFailure = createActionWithMeta<
  unknown,
  PlannerActionMeta
>(UPDATE_FAILURE)

export const deletePlanner = createActionWithMeta<
  PlannerDeleteParams,
  PlannerActionMeta
>(DELETE)
export const deletePlannerStart = createActionWithMeta<void, PlannerActionMeta>(
  DELETE_START
)
export const deletePlannerSuccess = createActionWithMeta<
  PlannerDeleteSuccessPayload,
  PlannerActionMeta
>(DELETE_SUCCESS)
export const deletePlannerFailure = createActionWithMeta<
  unknown,
  PlannerActionMeta
>(DELETE_FAILURE)

export const highlightTour = createAction<PlannerHighlightTour>(HIGHLIGHT_TOUR)

export const listUpdateEvent =
  createAction<PlannerListUpdateEventParams>(LIST_UPDATE_EVENT)
export const listDestroyEvent =
  createAction<PlannerListDestroyEventParams>(LIST_DESTROY_EVENT)
