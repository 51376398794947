import {useFormikContext} from 'formik'
import {useOrganizer} from 'api'
import {Button, Tooltip} from 'components/elements'
import type {InlineContentProps} from 'containers/Form'
import {InlineContent} from 'containers/Form'
import {useEntity} from 'hooks/useEntity'
import type {Contract} from 'types/contract'

interface ViewOrganizerContactProps extends InlineContentProps {
  model: string
  label?: string
}

export const ViewOrganizerContact: React.FC<
  React.PropsWithChildren<ViewOrganizerContactProps>
> = ({model, label = 'View organizer contact', ...props}) => {
  const {openPerson} = useEntity()
  const {getFieldProps} = useFormikContext<Contract>()

  const organizerId = getFieldProps(
    'show.stakeholder_organizer.department.id'
  ).value
  const roleId = getFieldProps(model).value

  const {data: organizer} = useOrganizer(organizerId)

  const personId = (organizer?.roles ?? []).find((r) => r.id === roleId)
    ?.affiliation.person.id

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (!personId) {
      return
    }
    openPerson(personId)
  }

  return (
    <InlineContent {...props} aligned>
      <Tooltip content={label}>
        <Button
          onClick={handleClick}
          disabled={!personId}
          modal
          round
          theme='light'
          icon='new-window'
        />
      </Tooltip>
    </InlineContent>
  )
}
