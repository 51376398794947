import {useCountries} from 'api'
import {Select} from 'components/elements'
import type {SelectProps} from 'components/elements/Select'
import {buildOptions} from './helpers'
import type {CountrySelectKind} from './types'

interface CountrySelectProps extends Omit<SelectProps, 'options'> {
  kind: CountrySelectKind
}

export const CountrySelect: React.FC<
  React.PropsWithChildren<CountrySelectProps>
> = ({kind, ...rest}) => {
  const {data: countries = []} = useCountries()

  const options = buildOptions(countries, kind)

  return <Select {...rest} options={options} />
}
