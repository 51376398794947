import * as Sentry from '@sentry/react'
import get from 'lodash/get'
import {takeLatest, fork, select, put, call} from 'redux-saga/effects'
import {getApiUrl} from 'api'
import {sagaFetch} from 'helpers/saga'
import {
  // CREATE
  CONTRACT_CREATE_SUCCESS,
  // UPDATE
  CONTRACT_UPDATE_SUCCESS,
  CHANGE_STATE_SUCCESS,
  // DESTROY
  CONTRACT_DESTROY_SUCCESS,
} from 'store/contract/constants'
import {
  SHOW_CREATED_SUCCESS,
  SHOW_UPDATED_SUCCESS,
  SHOW_DESTROYED_SUCCESS,
} from '../constants'

function* contractCreatedCallback(action) {
  try {
    const id = get(action.payload, 'show.id') || get(action.payload, 'id')

    const payload = yield call(sagaFetch, getApiUrl('plannerEvents', id), 'get')
    yield put({type: SHOW_CREATED_SUCCESS, payload})
    return payload
  } catch (e) {
    Sentry.captureException(e)
  }
}

function* contractUpdatedCallback(action) {
  try {
    const id = get(action.payload, 'show.id') || get(action.payload, 'id')

    const payload = yield call(sagaFetch, getApiUrl('plannerEvents', id), 'get')
    yield put({type: SHOW_UPDATED_SUCCESS, payload})
    return payload
  } catch (e) {
    Sentry.captureException(e)
  }
}

function* contractStateChangedCallback(action) {
  try {
    const contractId = get(action.payload, 'id')
    const currentContract = yield select(({contract}) => contract.result)

    if (currentContract && currentContract.id === contractId) {
      yield call(contractUpdatedCallback, {
        payload: {id: currentContract.show.id},
      })
    }
  } catch (e) {
    Sentry.captureException(e)
  }
}

function* contractDestroyedCallback(action) {
  try {
    const id = get(action.payload, 'show.id')
    yield put({type: SHOW_DESTROYED_SUCCESS, payload: {id}})
  } catch (e) {
    Sentry.captureException(e)
  }
}

export function* showCallbacksSaga() {
  // CREATE
  yield fork(function* () {
    yield takeLatest([CONTRACT_CREATE_SUCCESS], contractCreatedCallback)
  })
  // UPDATE
  yield fork(function* () {
    yield takeLatest([CONTRACT_UPDATE_SUCCESS], contractUpdatedCallback)
  })
  // STATE CHANGE
  yield takeLatest([CHANGE_STATE_SUCCESS], contractStateChangedCallback)
  // DESTROY
  yield fork(function* () {
    yield takeLatest([CONTRACT_DESTROY_SUCCESS], contractDestroyedCallback)
  })
}
