import {Container} from 'containers/Form'
import {Name} from '../sections'

export const EntityFormPanelOrganization = () => {
  return (
    <Container>
      <Name />
    </Container>
  )
}
