import {combineReducers} from 'redux'
import {BOOKING_VIEWS} from 'options/booking'
import {SET_MONTH, SET_ACT} from '../../constants'
import {
  month,
  monthWeekStartDay,
  monthWeekEndDay,
  act,
} from '../../helpers/reducers'

export const singleMonthReducer = combineReducers({
  month: month(SET_MONTH, BOOKING_VIEWS.SINGLE_MONTH),
  startDay: monthWeekStartDay(SET_MONTH, BOOKING_VIEWS.SINGLE_MONTH),
  endDay: monthWeekEndDay(SET_MONTH, BOOKING_VIEWS.SINGLE_MONTH),
  act: act(SET_ACT, BOOKING_VIEWS.SINGLE),
})
