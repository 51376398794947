import {useContext, useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {clearDepartment, showDepartment} from 'store/entity/actions'
import {entityTypeFromDepartmentType} from 'store/entity/helpers'
import type {PersonStore, TourStore} from 'store/entity/types'
import type {DepartmentStore, OrganizationStore, RootStore} from 'store/types'
import type {DepartmentBase, DepartmentType} from 'types/department'
import type {OrganizationDepartment, OrganizationType} from 'types/organization'
import {EntityViewContext} from './context'
import {buildNavigationFromKind, getInitialTab, newName} from './helpers'
import type {EntityViews, EntityViewModes, EntityTypes} from './types'

export const useEntityView = () => {
  const {view, viewMode, setView, setViewMode} = useContext(EntityViewContext)
  return {view, viewMode, setView, setViewMode}
}

export const useOrganization = () => {
  const {isLoading, data} = useSelector<RootStore, OrganizationStore>(
    (store) => store.entity.organization
  )

  return {isLoading, data}
}

export const useDepartment = () => {
  const {isLoading, data} = useSelector<RootStore, DepartmentStore>(
    (store) => store.entity.department
  )

  return {isLoading, data}
}

export const usePerson = () => {
  const {isLoading, data} = useSelector<RootStore, PersonStore>(
    (store) => store.entity.person
  )

  return {
    isLoading,
    data,
  }
}

export const useTour = () => {
  const {isLoading, data} = useSelector<RootStore, TourStore>(
    (store) => store.entity.tour
  )

  return {
    isLoading,
    data,
  }
}

export function useNavigation(
  kind: EntityTypes,
  initialViewMode: EntityViewModes = 'show'
) {
  const initialized = useRef<boolean>(false)
  const tabs = buildNavigationFromKind(kind)
  const [view, setView] = useState<EntityViews>(getInitialTab(kind))
  const [viewMode, setViewMode] = useState<EntityViewModes>(initialViewMode)

  useEffect(() => {
    if (initialized.current) {
      setViewMode('show')
    } else {
      initialized.current = true
    }
  }, [view])

  return {tabs, view, setView, viewMode, setViewMode}
}

export function useTitles(
  kind: EntityTypes,
  viewMode: EntityViewModes
): {
  isLoading: boolean
  primary?: string
  secondary?: string
} {
  const organization = useOrganization()
  const department = useDepartment()
  const person = usePerson()
  const tour = useTour()

  if (kind === 'PERSON') {
    const {data, isLoading} = person
    return {
      isLoading,
      primary:
        viewMode === 'new'
          ? newName(kind)
          : isLoading
          ? ''
          : [
              data?.business_card.given_name,
              data?.business_card.family_name,
            ].join(' '),
    }
  } else if (kind === 'TOUR') {
    const {data, isLoading} = tour
    return {
      isLoading,
      primary:
        viewMode === 'new'
          ? newName(kind)
          : isLoading
          ? ''
          : data?.text || 'Unnamed tour',
    }
  } else {
    const {data, isLoading} = department
    return {
      isLoading,
      primary:
        viewMode === 'new'
          ? newName(department.data.type)
          : isLoading
          ? ''
          : data.name,
      secondary:
        viewMode === 'new'
          ? undefined
          : isLoading
          ? undefined
          : data.type !== 'ACT'
          ? organization.data?.name ?? data.organization.name ?? ''
          : undefined,
    }
  }
}

export const useMainDepartment = () => {
  const dispatch = useDispatch()
  const {kind} = useContext(EntityViewContext)
  const {isLoading: organizationIsLoading, data: organization} =
    useOrganization()
  const {isLoading: departmentIsLoading, data: department} = useDepartment()

  useEffect(() => {
    if (kind === 'ORGANIZER' || kind === 'VENUE') {
      let foundOrganizer = false
      if (!departmentIsLoading && !organizationIsLoading) {
        if (department && department.type !== 'ORGANIZER') {
          const organizer = organization.departments.find(
            (d) => d.type === 'ORGANIZER'
          )
          if (organizer) {
            const kind = entityTypeFromDepartmentType(organizer.type)
            if (kind) {
              dispatch(showDepartment({id: organizer.id, kind: 'ORGANIZER'}))
              foundOrganizer = true
            }
          }
        }
      }

      if (!foundOrganizer) {
        console.log(organization.type)
      }
    }
  }, [
    department,
    departmentIsLoading,
    dispatch,
    kind,
    organization,
    organizationIsLoading,
  ])
}

interface InjectDepartmentConfig {
  name: string
  type: DepartmentType
}
const INJECT_DEPARTMENT: Partial<
  Record<OrganizationType, InjectDepartmentConfig>
> = {
  ORGANIZATION_TYPE_ACT: {name: 'Act', type: 'ACT'},
  ORGANIZATION_TYPE_BOOKING_AGENCY: {
    name: 'Booking agency',
    type: 'BOOKING_AGENCY',
  },
  ORGANIZATION_TYPE_ORGANIZER: {name: 'Organizer', type: 'ORGANIZER'},
  ORGANIZATION_TYPE_ORGANIZING_VENUE: {name: 'Organizer', type: 'ORGANIZER'},
  ORGANIZATION_TYPE_VENUE: {name: 'Organizer', type: 'ORGANIZER'},
}

const FILTER_DEPARTMENT: Partial<Record<OrganizationType, DepartmentType>> = {
  ORGANIZATION_TYPE_ORGANIZER: 'VENUE',
  ORGANIZATION_TYPE_ORGANIZING_VENUE: 'VENUE',
  ORGANIZATION_TYPE_VENUE: 'VENUE',
}

export const useDepartments = (mode: 'stages' | 'affiliations') => {
  const dispatch = useDispatch()
  const {viewMode} = useEntityView()
  const [selectedDepartment, setSelectedDepartment] =
    useState<OrganizationDepartment>()
  const {isLoading: organizationIsLoading, data: organization} =
    useOrganization()
  const {isLoading: departmentIsLoading, data: department} = useDepartment()

  const departments = useMemo(() => {
    let departments: DepartmentBase[] = []

    if (mode === 'affiliations' && INJECT_DEPARTMENT[organization.type]) {
      const config = INJECT_DEPARTMENT[organization.type]

      if (config) {
        const department = (organization.departments ?? []).find(
          (d) => d.type === config.type
        )

        if (department) {
          departments.push({...department, name: config.name})
        }
      }
    }

    if (organization.type in FILTER_DEPARTMENT) {
      departments = departments.concat(
        (organization?.departments ?? []).filter(
          (d) => d.type === FILTER_DEPARTMENT[organization.type]
        )
      )
    }

    return departments
  }, [mode, organization.departments, organization.type])

  useEffect(() => {
    if (viewMode === 'new') {
      return
    }

    if (!selectedDepartment && !departmentIsLoading && !organizationIsLoading) {
      if (
        department &&
        FILTER_DEPARTMENT[organization.type] &&
        department.type === FILTER_DEPARTMENT[organization.type]
      ) {
        const stage = departments.find(
          (s) => s.global_id === department.global_id
        )
        if (stage) {
          setSelectedDepartment(stage)
          return
        }
      }
      if (departments && departments.length) {
        setSelectedDepartment(departments[0])
        return
      }

      dispatch(clearDepartment())
    }
  }, [
    organization.type,
    department,
    departmentIsLoading,
    organizationIsLoading,
    selectedDepartment,
    departments,
    viewMode,
    dispatch,
  ])

  useEffect(() => {
    if (departmentIsLoading || viewMode === 'new') {
      return
    }

    if (selectedDepartment) {
      if (department && department.global_id === selectedDepartment.global_id) {
        return
      }

      const kind = selectedDepartment.type as EntityTypes

      dispatch(
        showDepartment({
          id: selectedDepartment.id,
          kind: kind,
        })
      )
    }
  }, [department, departmentIsLoading, dispatch, selectedDepartment, viewMode])

  return {
    showMenu: FILTER_DEPARTMENT[organization.type],
    departments,
    selectedDepartment,
    setSelectedDepartment,
  }
}
