import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Prompt as PromptComp} from 'components/utils/Prompt'
import {confirmPrompt, cancelPrompt} from 'store/prompt'
import type {PromptStore, RootStore} from 'store/types'
import {InputField} from './components'

const usePrompt = () => {
  const dispatch = useDispatch()
  const {
    opened,
    kind,
    title,
    description,
    showInput,
    inputPlaceholder,
    cancelText,
    confirmText,
  } = useSelector<RootStore, PromptStore>((store) => store.prompt)

  return {
    opened,
    kind,
    title,
    description,
    showInput,
    inputPlaceholder,
    cancelText,
    confirmText,
    cancelPrompt: () => dispatch(cancelPrompt()),
    confirmPrompt: (value: unknown) => dispatch(confirmPrompt(value)),
  }
}

export const Prompt = () => {
  const [inputValue, setInputValue] = useState('')
  const {
    opened,
    kind,
    title,
    description,
    showInput,
    inputPlaceholder,
    cancelText,
    confirmText,
    cancelPrompt,
    confirmPrompt,
  } = usePrompt()

  const dangerous = kind === 'danger'

  return (
    <PromptComp
      opened={opened}
      header={title}
      content={
        <>
          {' '}
          <div>{description}</div>
          {showInput && (
            <InputField
              value={inputValue}
              onChange={(value) => setInputValue(value as unknown as string)}
              placeholder={inputPlaceholder}
            />
          )}
        </>
      }
      onCancel={cancelPrompt}
      cancelText={cancelText}
      onConfirm={() => confirmPrompt(inputValue)}
      confirmText={confirmText}
      dangerous={dangerous}
    />
  )
}
