import type {ReactElement} from 'react'
import React from 'react'
import type {GroupBase, MultiValueRemoveProps, StylesConfig} from 'react-select'
import {components} from 'react-select'
import Creatable from 'react-select/creatable'
import isEmail from 'validator/lib/isEmail'
import {Icon} from 'components/elements'
import type {EmailRecipient} from 'store/email/types'
import {colors, fonts} from 'styles/variables'

interface StylesProps {
  hasError: boolean
}

function styles<
  OptionType,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>({hasError}: StylesProps): StylesConfig<OptionType, IsMulti, GroupType> {
  return {
    control: () => ({
      border: 'none',
      '&:hover': {
        border: 'none',
      },
    }),
    valueContainer: (base) => ({
      ...base,
      padding: 0,
      background: hasError ? colors.pippin : 'transparent',
    }),
    input: (base) => ({
      ...base,
      color: hasError ? colors.crimson : colors.primaryTextColor,
      input: {
        fontFamily: fonts.fontRegular,
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: hasError ? colors.crimson : colors.primaryTextColor,
    }),
    multiValue: (base) => ({
      ...base,
      background: 'transparent',
      alignItems: 'center',
      margin: '0 10px 0 0',
    }),
    multiValueLabel: (base) => ({
      ...base,
      fontSize: '100%',
      padding: '0',
      paddingLeft: '2px',
      paddingRight: '5px',
      color: colors.primaryTextColor,
    }),
    multiValueRemove: () => ({
      width: '18px',
      height: '18px',
      display: 'grid',
      boxSizing: 'border-box',
      justifyContent: 'center',
      alignContent: 'center',
      color: colors.secondaryTextColor,
      border: `1px solid ${colors.secondaryTextColor}`,
      borderRadius: '999px',
      '&:hover': {
        background: 'transparent',
        cursor: 'pointer',
        color: colors.pictonBlue,
        borderColor: colors.pictonBlue,
      },
    }),
  }
}

function MultiValueRemove<
  OptionType,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>(props: MultiValueRemoveProps<OptionType, true, GroupType>): ReactElement {
  return (
    <components.MultiValueRemove {...props}>
      <Icon icon='close' size={8} />
    </components.MultiValueRemove>
  )
}

interface RecipientsSelectorProps {
  value: EmailRecipient[]
  onChange: (value: any) => void
  options: EmailRecipient[]
  hasError?: boolean
}

export const RecipientsSelector: React.FC<
  React.PropsWithChildren<RecipientsSelectorProps>
> = ({value, onChange, options, hasError}) => {
  return (
    <Creatable
      isMulti
      classNamePrefix='selector'
      value={value}
      options={options}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.address}
      getNewOptionData={(inputValue, optionLabel) => ({
        address: inputValue,
        name: optionLabel as string,
      })}
      isClearable={false}
      styles={
        styles({hasError: !!hasError}) as Partial<
          StylesConfig<EmailRecipient, boolean, GroupBase<EmailRecipient>>
        >
      }
      noOptionsMessage={({inputValue}) =>
        inputValue ? `Create '${inputValue}'` : 'Enter custom email'
      }
      placeholder='Search organizer contacts or enter custom email'
      components={{
        DropdownIndicator: null,
        MultiValueRemove,
      }}
      isValidNewOption={(value) => isEmail(value)}
      onChange={onChange}
    />
  )
}
