import {createQueryKeys} from '@lukemorales/query-key-factory'
import {api} from 'api/api'

export const acts = createQueryKeys('acts', {
  list: {
    queryKey: null,
    queryFn: () => api.acts.list(),
  },
  show: (id: number) => ({
    queryKey: [id],
    queryFn: () => api.acts.show(id),
  }),
})
