import {Spinner} from 'components/elements'
import {AppLayout} from 'components/layout'
import {useSingleMonth} from './hooks'
import {SingleActPriorityMenu, MonthPicker, EmptyMessage} from '../components'
import {OneMonth} from '../views'

export const SingleMonth = () => {
  const props = useSingleMonth()

  return (
    <AppLayout
      title='Single act - One month'
      headerFilters={<MonthPicker />}
      filters={<SingleActPriorityMenu />}
    >
      {props.isLoading && <Spinner overlay />}
      {props.actId && <OneMonth {...props} />}
      {!props.actId && (
        <EmptyMessage title='Please choose an act from the menu above' />
      )}
    </AppLayout>
  )
}
