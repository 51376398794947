import {Spinner} from 'components/elements'
import {Main} from 'containers/Core'
import {useEntityView, useOrganization} from './hooks'
import {EntityEditView} from './shared/EditView'
import {EntityShowView} from './shared/ShowView'

export const OrganizationView = () => {
  const {viewMode, setViewMode} = useEntityView()
  const {isLoading, data} = useOrganization()

  if (isLoading) {
    return <Spinner />
  }

  if (viewMode === 'edit') {
    return (
      <Main flex>
        <EntityEditView
          initialValues={data}
          onCancel={() =>
            // viewMode === 'new' ? closeModal() : setViewMode('show')
            setViewMode('show')
          }
          onSubmitSuccess={() => setViewMode('show')}
        />
      </Main>
    )
  }

  return (
    <Main>
      <EntityShowView data={data} onEditClick={() => setViewMode('edit')} />
    </Main>
  )
}
