import {createQueryKeys} from '@lukemorales/query-key-factory'
import {isNil, omitBy} from 'lodash'
import {api} from 'api/api'
import type {ListPlannerProps} from 'api/api/planner'
import {getPlannerQueryParams} from 'api/api/planner'
import type {KeyTuple} from 'api/types'

function getQueryKey(props: ListPlannerProps): KeyTuple {
  const queryParams = getPlannerQueryParams(props)
  return Object.entries(omitBy(queryParams, isNil)) as KeyTuple
}

export const planner = createQueryKeys('planner', {
  list: (props: ListPlannerProps) => ({
    queryKey: getQueryKey(props),
    queryFn: () => api.planner.list(props),
  }),
})
