import {createAction} from '@reduxjs/toolkit'
import {createActionWithMeta} from 'utils'
import {
  CLEAR,
  CLEAR_ON_CREATE_SUCCESS,
  CREATE,
  CREATE_SUCCESS,
  NEW,
  NEW_SUCCESS,
  SHOW,
  SHOW_SUCCESS,
  UPDATE,
  UPDATE_SUCCESS,
} from './constants'
import type {
  DepartmentNewParams,
  DepartmentShowParams,
  DepartmentNewSuccessPayload,
  DepartmentNewSuccessMeta,
  DepartmentShowSuccessPayload,
  DepartmentCreateSuccessPayload,
  DepartmentUpdateSuccessPayload,
  DepartmentCreateParams,
  DepartmentUpdateParams,
} from './types'

export const newDepartment = createAction<DepartmentNewParams>(NEW)
export const newDepartmentSuccess = createActionWithMeta<
  DepartmentNewSuccessPayload,
  DepartmentNewSuccessMeta
>(NEW_SUCCESS)

export const showDepartment = createAction<DepartmentShowParams>(SHOW)
export const showDepartmentSuccess =
  createAction<DepartmentShowSuccessPayload>(SHOW_SUCCESS)

export const createDepartment = createAction<DepartmentCreateParams>(CREATE)
export const createDepartmentSuccess =
  createAction<DepartmentCreateSuccessPayload>(CREATE_SUCCESS)

export const updateDepartment = createAction<DepartmentUpdateParams>(UPDATE)
export const updateDepartmentSuccess =
  createAction<DepartmentUpdateSuccessPayload>(UPDATE_SUCCESS)

export const clearDepartment = createAction(CLEAR)
export const clearOnCreateSuccess = createAction(CLEAR_ON_CREATE_SUCCESS)
