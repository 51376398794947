import cx from 'classnames'
import styles from './Form.module.css'

export interface ContainerProps {
  className?: string
}

export const Container: React.FC<React.PropsWithChildren<ContainerProps>> = ({
  children,
  className,
}) => <div className={cx(styles.container, className)}>{children}</div>
