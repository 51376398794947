import type {MouseEvent} from 'react'
import type {Placement} from '@popperjs/core'
import styled from 'styled-components'
import {Button} from 'components/elements'
import {colors, effects, spacing} from 'styles/variables'
import {Dropdown} from '../../utils/Dropdown'
import type {IconName} from '../Icon'

export type ButtonMenuOnClick = React.MouseEvent<Element, MouseEvent>

export type ButtonMenuPosition = 'left' | 'center' | 'right'

const getPlacement = (
  position: ButtonMenuPosition,
  flipped: boolean
): Placement => {
  const vertical = flipped ? 'top' : 'bottom'
  switch (position) {
    case 'left':
      return `${vertical}-start`
    case 'right':
      return `${vertical}-end`
    default:
      return vertical
  }
}

const Menu = styled.div`
  border-radius: ${effects.borderRadius};
  border: ${effects.inputBorder};
  padding: ${spacing.u2_5};
  display: flex;
  flex-direction: column;
  min-width: 180px;
  box-shadow: ${effects.popoverBoxshadow};
  background: ${colors.white};
  outline: 0 !important;
  max-height: 50vh;
  overflow-y: auto;

  & > :not(:last-child) {
    margin-bottom: ${spacing.u1};
  }
`

interface ButtonMenuProps {
  children: React.ReactNode | React.ReactNode[]
  className?: string
  contentClassName?: string
  disabled?: boolean
  position?: ButtonMenuPosition
  round?: boolean
  icon: IconName
  flipped?: boolean
}

export const ButtonMenu: React.FC<React.PropsWithChildren<ButtonMenuProps>> = ({
  className,
  contentClassName,
  position = 'center',
  children,
  round = true,
  disabled,
  flipped,
  ...restProps
}) => {
  const placement = getPlacement(position, !!flipped)

  return (
    <Dropdown
      placement={placement}
      target={({ref, open, close, opened}) => (
        <Button
          ref={ref}
          {...restProps}
          round={round}
          active={opened}
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            opened ? close() : open()
          }}
        />
      )}
      content={({close}) => (
        <Menu
          onClick={(evt) => {
            if (evt.target instanceof Element) {
              const btn =
                evt.target.closest('button') ?? evt.target.closest('a')
              if (btn && evt.currentTarget.contains(btn)) {
                close()
              }
            }
          }}
        >
          {children}
        </Menu>
      )}
    />
  )
}
