import type {StylesConfig, GroupBase} from 'react-select'
import {colors, effects, fontSizes, spacing} from 'styles/variables'
import type {SelectTheme} from './types'

interface ThemeConfig {
  controlMinHeight: number
  valueContainerPadding: string
  clearIndicatorPadding: string
  dropdownIndicatorPadding: string
  valueFontSize: string
}

function getThemeConfig(theme: SelectTheme): ThemeConfig {
  switch (theme) {
    case 'big':
      return {
        controlMinHeight: 38,
        valueContainerPadding: '0px 8px',
        clearIndicatorPadding: '8px 2px',
        dropdownIndicatorPadding: '8px',
        valueFontSize: fontSizes.dogSize,
      }
      break
    case 'form':
      return {
        controlMinHeight: 30,
        valueContainerPadding: '0px 8px',
        clearIndicatorPadding: '6px 4px',
        dropdownIndicatorPadding: '6px 4px',
        valueFontSize: fontSizes.inputFontSize,
      }
      break
    default:
      return {
        controlMinHeight: 32,
        valueContainerPadding: '0px 8px',
        clearIndicatorPadding: '5px 1px',
        dropdownIndicatorPadding: '5px',
        valueFontSize: fontSizes.catSize,
      }
  }
}

interface StylesProps {
  theme: SelectTheme
  showError: boolean
  isSelected: boolean
}

export function styles<
  OptionType,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>({
  theme,
  showError,
  isSelected,
}: StylesProps): StylesConfig<OptionType, IsMulti, GroupType> {
  const {
    controlMinHeight,
    valueContainerPadding,
    clearIndicatorPadding,
    dropdownIndicatorPadding,
    valueFontSize,
  } = getThemeConfig(theme)

  return {
    control: (base, {isFocused, isDisabled}) => ({
      ...base,
      minHeight: controlMinHeight,
      border: showError
        ? !isFocused
          ? effects.inputErrorBorder
          : effects.inputErrorFocusBorder
        : !isFocused
        ? isSelected
          ? `1px solid ${colors.bostonBlue}`
          : effects.inputBorder
        : effects.inputFocusBorder,
      borderRadius: theme === 'form' ? 0 : 3,
      backgroundColor: showError
        ? colors.pippin
        : isDisabled
        ? effects.inputDisabledBackground
        : !isFocused
        ? isSelected
          ? colors.pictonBlue
          : colors.white
        : isSelected
        ? colors.malibu
        : colors.springWood,
      transition: `all ${effects.transitionTime}`,
      boxShadow: showError
        ? !isFocused
          ? 'none'
          : effects.inputErrorFocusBoxShadow
        : !isFocused
        ? 'none'
        : effects.inputFocusBoxShadow,
      ':hover': {
        border: showError
          ? !isFocused
            ? effects.inputErrorHoverBorder
            : effects.inputErrorFocusBorder
          : !isFocused
          ? effects.inputHoverBorder
          : effects.inputFocusBorder,
        boxShadow:
          theme !== 'form' && !isFocused
            ? showError
              ? effects.inputErrorHoverBoxShadow
              : isSelected
              ? `0 0 0 2px ${colors.bostonBlue}`
              : effects.inputHoverBoxShadow
            : undefined,
      },
    }),
    valueContainer: (base) => ({
      ...base,
      padding: valueContainerPadding,
    }),
    indicatorsContainer: (base) => ({
      ...base,
    }),
    clearIndicator: (base) => ({
      ...base,
      color: colors.secondaryTextColor,
      padding: clearIndicatorPadding,
      background: 'transparent',
      ':hover': {
        color: colors.pictonBlue,
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      margin: 0,
      backgroundColor: colors.borderColor,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      background: 'transparent',
      color: isSelected ? colors.white : colors.primaryTextColor,
      padding: dropdownIndicatorPadding,
      ':hover': {
        color: colors.pictonBlue,
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: showError
        ? effects.inputErrorPlaceholderColor
        : isSelected
        ? colors.white
        : effects.inputPlaceholderColor,
      fontSize: valueFontSize,
    }),
    singleValue: (base) => ({
      ...base,
      color: showError
        ? effects.inputErrorColor
        : isSelected
        ? colors.white
        : effects.inputColor,
      fontSize: valueFontSize,
    }),
    menu: (base) => ({
      ...base,
      borderRadius: theme === 'form' ? 0 : 3,
      boxShadow: effects.popoverBoxshadow,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    option: (base, {isSelected, isFocused}) => ({
      ...base,
      padding: `${spacing.u1} ${spacing.u2_5}`,
      background: isFocused
        ? colors.malibu
        : isSelected
        ? colors.bostonBlue
        : colors.white,
      color: isFocused || isSelected ? colors.white : colors.primaryTextColor,
    }),
    group: (base) => ({
      ...base,
      '&:first-child': {
        paddingTop: 0,
      },
    }),
    groupHeading: (base) => ({
      ...base,
      padding: `${spacing.u1} ${spacing.u2_5}`,
      background: colors.springWood,
      borderTop: `1px solid ${colors.westar}`,
      borderBottom: `1px solid ${colors.westar}`,
      margin: 0,
      position: 'sticky',
      top: 0,
    }),
    multiValue: (base) => ({
      ...base,
      background: colors.pictonBlue,
      border: `1px solid ${colors.bostonBlue}`,
      borderRadius: '3px',
    }),
    multiValueLabel: (base) => ({
      ...base,
      padding: '1px 4px',
      color: colors.white,
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: colors.white,
      ':hover': {
        backgroundColor: colors.bostonBlue,
        color: 'white',
      },
    }),
  }
}
