import {useVenues} from 'api'
import {Select} from 'components/elements'
import type {SelectOptionItem, SelectProps} from 'components/elements/Select'
import type {ListVenue} from 'types/department'

function mapOption(venue: ListVenue): SelectOptionItem {
  return {
    label: `${venue.name} (${venue.organization.name})`,
    value: venue.id,
  }
}

export interface VenueSelectProps extends Omit<SelectProps, 'options'> {
  filterOptions?: (item: ListVenue) => boolean
  groupByOrganizationId?: number
}

export const VenueSelect: React.FC<VenueSelectProps> = ({
  filterOptions,
  groupByOrganizationId,
  ...props
}) => {
  const {data = []} = useVenues()
  const venues = filterOptions ? data.filter(filterOptions) : data

  const options = groupByOrganizationId
    ? [
        {
          label: 'Organizers stages',
          options: venues
            .filter((venue) => venue.organization.id === groupByOrganizationId)
            .map(mapOption),
          emptyMessage: 'Organizer has no venues',
        },
        {
          label: 'Other stages',
          options: venues
            .filter((venue) => venue.organization.id !== groupByOrganizationId)
            .map(mapOption),
        },
      ]
    : venues.map(mapOption)

  return <Select {...props} options={options} />
}
