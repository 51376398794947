import type {DepartmentType} from 'types/department'
import type {EntityType} from './types'

export function entityTypeFromDepartmentType(
  departmentType: DepartmentType
): EntityType {
  switch (departmentType) {
    case 'ACT':
      return 'ACT'
    case 'ORGANIZER':
      return 'ORGANIZER'
    case 'VENUE':
      return 'VENUE'
    case 'TOUR':
      return 'TOUR'
    case 'BOOKING_AGENCY':
      return 'BOOKING_AGENCY'
    default:
      throw new Error(
        'entityTypeFromDepartmentType: departmentType not supported'
      )
  }
}
