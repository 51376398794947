import type {SagaIterator} from 'redux-saga'
import {call} from 'redux-saga/effects'
import {getApiUrlFromEntityType} from 'api'
import {sagaFetch} from 'helpers/saga'
import type {HttpMethod, RestMethod} from 'lib/fetch/types'
import type {
  EntityAction,
  EntityShowAction,
  EntityCreateAction,
  EntityUpdateAction,
  EntityDeleteAction,
} from './types'

function getHttpMethod(key: RestMethod): HttpMethod {
  switch (key) {
    case 'show':
      return 'GET'
    case 'create':
      return 'POST'
    case 'update':
      return 'PUT'
    case 'delete':
      return 'DELETE'
  }
}

type Response<ResponsePayload> = ResponsePayload | undefined

function* doAsync<ResponsePayload, Data = void>(
  fetchType: RestMethod,
  action: EntityAction<Data>
): SagaIterator<Response<ResponsePayload>> {
  try {
    const result: ResponsePayload = yield call(
      sagaFetch,
      getApiUrlFromEntityType(
        action.kind,
        'id' in action ? action.id : undefined
      ),
      getHttpMethod(fetchType),
      'data' in action ? action.data : undefined,
      action.options
    )
    return result
  } catch (e: any) {
    if (e.code === 401) {
      return
    }
  }
}

export function* showEntity<ResponsePayload>(
  action: EntityShowAction
): SagaIterator<ResponsePayload> {
  const resp = yield call(doAsync, 'show', action)
  return resp
}

export function* createEntity<Data, ResponsePayload>(
  action: EntityCreateAction<Data>
): SagaIterator<ResponsePayload> {
  const resp = yield call(doAsync, 'create', action)
  return resp
}

export function* updateEntity<Data, ResponsePayload>(
  action: EntityUpdateAction<Data>
): SagaIterator<ResponsePayload> {
  const resp = yield call(doAsync, 'update', action)
  return resp
}

export function* deleteEntity<ResponsePayload>(
  action: EntityDeleteAction
): SagaIterator<ResponsePayload> {
  const resp = yield call(doAsync, 'delete', action)
  return resp
}
