import type {ReactChild} from 'react'

interface VisibleProps {
  ifTrue?: boolean
  ifFalse?: boolean
  children: ReactChild | ReactChild[]
}

export const Visible = ({ifTrue, ifFalse, children}: VisibleProps) => {
  if (!children) {
    return null
  }

  if (ifTrue !== undefined && !ifTrue) {
    return null
  }
  if (ifFalse !== undefined && ifFalse) {
    return null
  }

  return <>{children}</>
}
