import {endOfYesterday, isAfter} from 'date-fns'
import {filter, includes} from 'lodash'
import type {DraggableRubric} from 'react-beautiful-dnd'
import {
  CONTRACT_STATUS_SORTING,
  CONTRACT_STATUS_OPTIONS,
} from 'options/contractStatus'
import {CONTRACT_STATUS_FLOW_OPTIONS} from 'options/contractStatusFlow'
import type {FlowKind} from 'store/flow/types'
import type {
  ContractProductionStatusType,
  ContractStatusType,
} from 'types/contract'
import type {BookingStatusEvent} from 'types/planner'
import type {FlowColumnConfig, FlowConfig, FlowFilterMethod} from './types'

const standardFilter = ({
  data,
  status,
}: {
  data: BookingStatusEvent[]
  status: ContractProductionStatusType
}): BookingStatusEvent[] => {
  return data.filter((item) => {
    return (
      item.production_status === status &&
      !includes(
        ['CONTRACT_STATUS_TBC', 'CONTRACT_STATUS_CANCELLED'],
        item.status
      )
    )
  })
}

const FILTER_METHODS: {
  [key in ContractStatusType]: (key: ContractStatusType) => FlowFilterMethod
} = {
  CONTRACT_STATUS_TBC: (key) => (data) =>
    data.filter((item) => item.status === key),
  CONTRACT_STATUS_CONFIRMED: (key) => (data) =>
    data.filter((item) => item.status === key),
  CONTRACT_STATUS_FINALIZED: (key) => (data) =>
    data.filter((item) => item.status === key),
  CONTRACT_STATUS_UNSIGNED: (key) => (data) =>
    data.filter((item) => item.status === key),
  CONTRACT_STATUS_PARTIALLY_SIGNED: (key) => (data) =>
    data.filter((item) => item.status === key),
  CONTRACT_STATUS_SIGNED: (key) => (data, filterOptions) =>
    data.filter(
      (item) =>
        item.status === key &&
        (filterOptions?.showHistoricEvents ||
          (!filterOptions?.showHistoricEvents &&
            isAfter(item.start_on, endOfYesterday())))
    ),
  CONTRACT_STATUS_FULLY_SIGNED: (key) => (data, filterOptions) =>
    data.filter(
      (item) =>
        item.status === key &&
        (filterOptions?.showHistoricEvents ||
          (!filterOptions?.showHistoricEvents &&
            isAfter(item.start_on, endOfYesterday())))
    ),
  CONTRACT_STATUS_CANCELLED: (key) => (data, filterOptions) =>
    data.filter(
      (item) =>
        item.status === key &&
        (filterOptions?.showHistoricEvents ||
          (!filterOptions?.showHistoricEvents &&
            isAfter(item.start_on, endOfYesterday())))
    ),
}

export const BOOKING_STATUS_COLUMNS = CONTRACT_STATUS_SORTING.map((key) => {
  const obj = CONTRACT_STATUS_OPTIONS[key]
  return {
    id: key,
    title: obj.name,
    filterMethod: FILTER_METHODS[key](key),
    items: [],
    passive: key === 'CONTRACT_STATUS_CANCELLED',
    columnProps: {
      CONTRACT_STATUS_CANCELLED: {
        visuallyDisabled: true,
      },
      isDropDisabled: (current: DraggableRubric) => {
        if (!current) {
          return true
        }

        return !includes(
          CONTRACT_STATUS_FLOW_OPTIONS[
            current.source.droppableId as ContractStatusType
          ].canAdvanceTo,
          key
        )
      },
    },
    itemProps: {
      getId: (data: BookingStatusEvent) => data.contract_id,
    },
  }
})

export const FLOW_BOOKING_CONFIG: FlowColumnConfig[] =
  CONTRACT_STATUS_SORTING.map((key) => {
    const obj = CONTRACT_STATUS_OPTIONS[key]
    return {
      id: key,
      title: obj.name,
      filterMethod: FILTER_METHODS[key](key),
      items: [],
      passive: key === 'CONTRACT_STATUS_CANCELLED',
      columnProps: {
        CONTRACT_STATUS_CANCELLED: {
          visuallyDisabled: true,
        },
        isDropDisabled: (current: DraggableRubric) => {
          if (!current) {
            return true
          }

          return !includes(
            CONTRACT_STATUS_FLOW_OPTIONS[
              current.source.droppableId as ContractStatusType
            ].canAdvanceTo,
            key
          )
        },
      },
      itemProps: {
        getId: (data: BookingStatusEvent) => data.contract_id,
      },
    }
  })

export const FLOW_PRODUCTION_STATUS: FlowColumnConfig[] = [
  {
    id: 'TBC',
    title: 'To be confirmed',
    filterMethod: (data) =>
      filter(data, (item) => item.status === 'CONTRACT_STATUS_TBC'),
    items: [],
    passive: true,
    columnProps: {
      isDropDisabled: true,
      visuallyDisabled: true,
    },
    itemProps: {
      isDragDisabled: true,
      getId: (data) => data.id,
    },
  },
  {
    id: 'PRODUCTION_STATUS_READY',
    title: 'Ready for production',
    filterMethod: (data) =>
      standardFilter({data, status: 'PRODUCTION_STATUS_READY'}),
    items: [],
    columnProps: {
      isDropDisabled: true,
    },
    itemProps: {getId: (data) => data.id},
  },
  {
    id: 'PRODUCTION_STATUS_IN_PROGRESS',
    title: 'Production in progress',
    filterMethod: (data) =>
      standardFilter({data, status: 'PRODUCTION_STATUS_IN_PROGRESS'}),
    items: [],
    columnProps: {
      isDropDisabled: (current) => {
        if (!current) {
          return true
        }
        return current.source.droppableId !== 'PRODUCTION_STATUS_READY'
      },
    },
    itemProps: {getId: (data) => data.id},
  },
  {
    id: 'PRODUCTION_STATUS_DONE',
    title: 'Production is done',
    filterMethod: (data, filterOptions) =>
      filter(
        data,
        (item) =>
          item.production_status === 'PRODUCTION_STATUS_DONE' &&
          !includes(
            ['CONTRACT_STATUS_TBC', 'CONTRACT_STATUS_CANCELLED'],
            item.status
          ) &&
          (filterOptions?.showHistoricEvents ||
            (!filterOptions?.showHistoricEvents &&
              isAfter(item.start_on, endOfYesterday())))
      ),
    items: [],
    columnProps: {
      isDropDisabled: (current) => {
        if (!current) {
          return true
        }
        return current.source.droppableId !== 'PRODUCTION_STATUS_IN_PROGRESS'
      },
    },
    itemProps: {
      getId: (data) => data.id,
      isDragDisabled: true,
    },
  },
]

export const FLOW_CONFIG: {[key in FlowKind]: FlowConfig} = {
  booking: {
    columns: FLOW_BOOKING_CONFIG,
    entityType: 'BOOKING_STATUS_EVENT',
  },
  production: {
    columns: FLOW_PRODUCTION_STATUS,
    entityType: 'PRODUCTION_EVENT',
  },
}
