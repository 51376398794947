import {useCallback} from 'react'
import * as Sentry from '@sentry/react'
import {useDispatch} from 'react-redux'
import {useOrganizer, useAct} from 'api'
import {toastrActions} from 'features/toastr'
import {useAppSelector} from 'hooks/store'
import {useEntity} from 'hooks/useEntity'
import {CONTRACT_STATUS_OPTIONS} from 'options/contractStatus'
import {CONTRACT_STATUS_FLOW_OPTIONS} from 'options/contractStatusFlow'
import {showContract} from 'store/contract/actions'
import {prompt} from 'store/prompt'

export const useExportFailure = () => {
  const dispatch = useDispatch()
  const {openOrganizer} = useEntity()

  const contract = useAppSelector(({contract}) => contract.result)
  const invoiceOrganizerId =
    contract?.show.stakeholder_organizer_for_invoicing?.department.id
  const organizerId = contract?.show.stakeholder_organizer?.department.id

  const {data: invoiceOrganizer} = useOrganizer(invoiceOrganizerId)
  const {data: organizer} = useOrganizer(organizerId)

  const handleExportFailure = async (e: Error) => {
    if (invoiceOrganizerId) {
      try {
        await dispatch(
          prompt({
            title: 'Invoice Organizer is missing Tax-ID',
            description: `The invoice organizer (${invoiceOrganizer?.name}) has no Tax-ID. This is required in order to export the contract. If they don’t have a Tax-ID, please contact your financial department, and they can help you out.`,
            confirmText: 'Add Tax-ID',
          })
        )
        openOrganizer(invoiceOrganizerId)
      } catch {
        console.info('Prompt rejected')
      }
    } else if (organizerId) {
      try {
        await dispatch(
          prompt({
            title: 'Organizer is missing Tax-ID',
            description: `The organizer (${organizer?.name}) has no Tax-ID. This is required in order to export the contract. If they don’t have a Tax-ID, please contact your financial department, and they can help you out.`,
            confirmText: 'Add Tax-ID',
          })
        )
        openOrganizer(organizerId)
      } catch {
        console.info('Prompt rejected')
      }
    } else {
      dispatch(
        toastrActions.errorToast(
          'Unknown validation error',
          'We could not export due to an unknown validation error. Please try again.'
        )
      )
      Sentry.captureException(e)
    }
  }

  return {
    handleExportFailure,
  }
}

export const useContractWrapper = () => {
  const {
    view,
    initializeParams,
    isInitializing,
    isLoading,
    isCreating,
    isUpdating,
    isDestroying,
    isChangingState,
    result: contract,
  } = useAppSelector((store) => store.contract)

  const actId =
    contract?.show?.stakeholder_act?.department.id ?? initializeParams?.actId
  const {data: act} = useAct(actId)
  const actName = act?.name ?? 'Unknown'

  let headerPrimaryText = 'Please select act'
  let headerSecondaryText = ''

  if (view === 'new') {
    headerPrimaryText = actName
    headerSecondaryText = 'New'
  } else if (view === 'edit') {
    const statusText =
      contract && contract.id
        ? CONTRACT_STATUS_OPTIONS[contract.status].name
        : 'New'
    const contractNumberText = contract?.number ? `#${contract.number}` : null

    headerPrimaryText = actName
    headerSecondaryText = [contractNumberText, statusText]
      .filter(Boolean)
      .join(' - ')
  }

  return {
    view,
    isLoading,
    isCancelled: contract?.status === 'CONTRACT_STATUS_CANCELLED',
    hasActivity:
      isInitializing ||
      isLoading ||
      isCreating ||
      isUpdating ||
      isDestroying ||
      isChangingState,
    headerPrimaryText,
    headerSecondaryText,
  }
}

export const useContractForm = () => {
  const {result: contract} = useAppSelector((store) => store.contract)

  const locked = contract?.status
    ? ['CONTRACT_STATUS_FINALIZED', 'CONTRACT_STATUS_SIGNED'].includes(
        contract.status
      )
    : false

  return {
    data: contract,
    isNew: !contract?.id,
    locked,
  }
}

export const useContractFlow = () => {
  const {
    result: contract,
    isChangingState,
    stateChange,
  } = useAppSelector((store) => store.contract)

  if (!contract) {
    return {}
  }

  const isNew = !contract.id
  const status = contract.status
  const auditTrail = contract.status_audit_trail

  const lockedByInvoicing =
    !!contract?.invoicing_status?.value &&
    ['INVOICING_STATUS_INVOICE_SENT', 'INVOICING_STATUS_INVOICE_PAID'].includes(
      contract?.invoicing_status?.value
    )

  const advanceOptions = CONTRACT_STATUS_FLOW_OPTIONS[status]?.advanceOptions

  return {
    isNew,
    status,
    lockedByInvoicing,
    isChangingState,
    stateChange,
    auditTrail,
    advanceOptions,
  }
}

export const useContractActions = () => {
  const {
    result: contract,
    isChangingState,
    isCreating,
    isUpdating,
    isDestroying,
  } = useAppSelector((store) => store.contract)

  return {
    disabled:
      !contract ||
      !contract?.id ||
      isCreating ||
      isUpdating ||
      isChangingState ||
      isDestroying,
    status: contract?.status,
    draftEmailUrl: contract?.draft_email_url,
    finalEmailUrl: contract?.final_email_url,
    signingInvitationUrl: contract?.signing_invitation_url,
    signingKeyUrl: contract?.signing_key_url,
    pdfDownloadUrl: contract?.pdf_download_url,
    document: contract?.document,
    emailHeader: `${contract?.show?.stakeholder_act?.department.name} - ${
      contract?.number || 'Draft'
    }`,
    id: contract?.id,
  }
}

export const useReloadContract = () => {
  const dispatch = useDispatch()
  const {result: contract} = useAppSelector((store) => store.contract)

  const reloadContract = useCallback(() => {
    if (contract) {
      dispatch(showContract({id: contract.id}))
    }
  }, [dispatch, contract])

  return {reloadContract}
}
