import {addDays} from 'date-fns'
import {unescape} from 'lodash'
import striptags from 'striptags'

export function formatText(raw: string): string {
  // Strip html tags
  let text = striptags(raw)
  // Unescape html entities
  text = unescape(text)
  // Remove non breaking spaces and
  text = text.replace(/&nbsp;/g, ' ')
  // Trim unwanted spaces
  return text.trim()
}

export function formatPayload(data: any) {
  const date = new Date(data.start_on)
  return {
    // Support stakeholder_act from both Event and Planner data
    stakeholder_act: data.stakeholder_act
      ? data.stakeholder_act
      : {department: {id: data.act.id}},
    text: data.text ? formatText(data.text) : null,
    start_at: date.toISOString(),
    end_at: addDays(date, 1).toISOString(),
  }
}
