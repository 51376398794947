import {useFormikContext} from 'formik'
import type {ButtonProps} from 'components/elements/Button'
import {Button} from 'components/elements/Button'

export const SubmitButton: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  disabled: disabledFromProps,
  ...props
}) => {
  const {isSubmitting} = useFormikContext()

  const disabled = disabledFromProps || isSubmitting

  return <Button {...props} disabled={disabled} hasActivity={isSubmitting} />
}
