import styled from 'styled-components'
import {applyClassNameToWords} from 'helpers/content'
import {colors, spacing} from 'styles/variables'
import type {PlannerEvent} from 'types/planner'

const Root = styled.div`
  padding: ${spacing.u2_5} 25px ${spacing.u2_5} ${spacing.u2};
  line-height: 13px;
`

const Placeholder = styled.div`
  display: none;

  .calendar-cell:hover & {
    color: ${colors.santasGray};
    display: block;
  }
`

const Text = styled.div`
  display: flex;
  flex-flow: row wrap;

  &.cancelled {
    text-decoration: line-through;
    color: ${colors.santasGray};
  }

  & > *:not(:last-child) {
    margin-right: 3px;
  }
`

const ShowTime = styled.time<{isTBA: boolean}>`
  display: inline-block;
  font-weight: bold;

  ${Text}:not(.cancelled) & {
    color: ${({isTBA}) => (isTBA ? colors.scarpaFlow : colors.midGray)};
  }
`

const ShowText = styled.div`
  ${Text}:not(.cancelled) & {
    color: ${colors.midGray};
  }
  span:not(:last-child) {
    margin-right: 3px;
  }
`
const ActNameComp = styled.div`
  ${Text}:not(.cancelled) & {
    color: ${colors.mountainMeadow};
  }
`
const ShowCity = styled.div`
  ${Text}:not(.cancelled) & {
    color: ${colors.santasGray};
  }
`

interface ShowProps {
  data: PlannerEvent
  showActName?: boolean
}

const ActName: React.FC<React.PropsWithChildren<ShowProps>> = ({
  data,
  showActName,
}) => {
  if (!showActName) {
    return null
  }

  return <ActNameComp>{data.act?.name}</ActNameComp>
}

const ShowEvent: React.FC<React.PropsWithChildren<ShowProps>> = ({
  data,
  showActName,
}) => {
  const {show_at: showAt, is_show_at_tba: isTBA} = data

  let val
  try {
    const [h, m] = showAt.split(':')
    val = isTBA ? 'TBA' : `${h}:${m}`
  } catch (e: any) {
    val = 'N/A'
  }

  return (
    <Text
      className={
        data.status === 'CONTRACT_STATUS_CANCELLED' ? 'cancelled' : undefined
      }
    >
      <ShowTime isTBA={isTBA}>{val}</ShowTime>
      <ShowText>{applyClassNameToWords(data.venue?.name)}</ShowText>
      <ShowCity>{applyClassNameToWords(data.venue?.city_name)}</ShowCity>
      {<ActName data={data} showActName={showActName} />}
    </Text>
  )
}

const EventGeneric: React.FC<React.PropsWithChildren<ShowProps>> = ({
  data,
  showActName,
}) => {
  const {type, text} = data

  if (text) {
    return (
      <Text>
        {applyClassNameToWords(text)}
        {<ActName data={data} showActName={showActName} />}
      </Text>
    )
  }

  const placeholder =
    type === 'EMPTY_DAY' ? 'Add new Note...' : 'Add description...'

  return (
    <Text>
      {placeholder.split(' ').map((s, i) => (
        <Placeholder key={i}>{s}</Placeholder>
      ))}
      {<ActName data={data} showActName={showActName} />}
    </Text>
  )
}

export const Show: React.FC<React.PropsWithChildren<ShowProps>> = ({
  data,
  showActName,
}) => {
  if (data.type === 'EVENT_TYPE_SHOW') {
    return <Root>{<ShowEvent data={data} showActName={showActName} />}</Root>
  }

  return <Root>{<EventGeneric data={data} showActName={showActName} />}</Root>
}
