import {fork} from 'redux-saga/effects'
import {createSaga} from './create'
import {newSaga} from './new'
import {showSaga} from './show'
import {updateSaga} from './update'

export function* departmentSaga() {
  yield fork(newSaga)
  yield fork(showSaga)
  yield fork(createSaga)
  yield fork(updateSaga)
}
