import {useQuery} from '@tanstack/react-query'
import type {ListPlannerProps} from 'api/api/planner'
import {beforeAndAfter} from 'api/helpers/beforeAndAfter'
import {queryKeys} from 'api/queries'

export function usePlanner(
  props: ListPlannerProps,
  enabled = (props: ListPlannerProps): boolean =>
    !!props && !!Object.keys(props).length
) {
  return useQuery({
    ...queryKeys.planner.list(props),
    enabled: enabled(props),
  })
}

export interface ToursForActAndDayParams {
  actId?: number
  date?: string
}

export const useToursForActAndDay = ({actId, date}: ToursForActAndDayParams) =>
  usePlanner(
    {
      actIds: [actId ?? 0],
      eventTypes: 'tours',
      ...beforeAndAfter('day', date ?? ''),
    },
    () => !!actId && !!date
  )
