import type {PayloadAction} from '@reduxjs/toolkit'
import {subDays} from 'date-fns'
import find from 'lodash/find'
import map from 'lodash/map'
import type {SagaIterator} from 'redux-saga'
import {fork, takeLatest, select, call, put, all} from 'redux-saga/effects'
import {getApiUrl} from 'api'
import {toastrActions} from 'features/toastr'
import {dateDeforeAndAfter} from 'helpers/api'
import {sagaFetch, standardErrorAction} from 'helpers/saga'
import {
  SHOW_CREATED_SUCCESS,
  SHOW_UPDATED_SUCCESS,
  SHOW_DESTROYED_SUCCESS,
} from 'store/planner/constants'
import type {
  PlannerShowCreatedSuccess,
  PlannerShowUpdatedSuccess,
  PlannerShowDeletedSuccess,
  PlannerShowCreatedSuccessPayload,
  PlannerShowUpdatedSuccessPayload,
} from 'store/planner/types'
import type {RootStore} from 'store/types'
import {listDeleteEvent, listUpdateEvent, updateEventStatus} from './actions'
import {
  SET_FILTERS,
  TOGGLE_HISTORIC_EVENTS,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  UPDATE_STATUS,
  UPDATE_STATUS_REQUEST,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAILURE,
} from './constants'
import {handleEventUpdate} from './helpers'
import type {
  FlowActionMeta,
  FlowActionUpdateStatusPayload,
  FlowStoreFilters,
} from './types'

function getFetchParams(
  filters: FlowStoreFilters,
  showHistoricEvents: boolean
) {
  let act_ids
  let tour_ids
  const startDay = subDays(new Date(), 14)
  let endDay

  if (filters.kind === 'roster') {
    // Do nothing
  } else if (filters.kind === 'represented_by_me') {
    act_ids = 'represented_by_me'
  } else if (filters.kind === 'act') {
    act_ids = filters.actId
  } else if (filters.kind === 'tour') {
    tour_ids = filters.tourId
  }

  // Handle dates
  let {before, after} = dateDeforeAndAfter({
    startDay,
    endDay,
  })

  if (filters.kind === 'tour' || showHistoricEvents) {
    before = undefined
    after = undefined
  }

  return {
    type: 'EVENT_TYPE_SHOW',
    after,
    before,
    act_ids,
    tour_ids,
  }
}

export function* fetchPlanners(
  action: PayloadAction<void, string, FlowActionMeta>
): SagaIterator {
  const {meta} = action
  yield put({type: FETCH_REQUEST, meta})
  try {
    const {filters, showHistoricEvents} = yield select(({flow}) => ({
      filters: flow[meta.flow].filters,
      showHistoricEvents: flow[meta.flow].showHistoricEvents,
    }))

    if (!filters.kind) {
      throw new Error('Filter kind needed')
    }

    const queryParams = yield call(() =>
      getFetchParams(filters, showHistoricEvents)
    )

    const payload = yield call(
      sagaFetch,
      getApiUrl('plannerEvents'),
      'get',
      null,
      {
        queryParams,
      }
    )
    yield put({type: FETCH_SUCCESS, payload, meta})
  } catch (e: any) {
    yield put(standardErrorAction(FETCH_FAILURE, e, meta))
    if (e.message === 'Filter kind needed') {
      // Ignore
    } else {
      throw e
    }
  }
}

export function* updateStatus(
  action: PayloadAction<FlowActionUpdateStatusPayload, string, FlowActionMeta>
): SagaIterator {
  const {meta} = action
  yield put({type: UPDATE_STATUS_REQUEST, meta})
  const {id, nextStatus, prevStatus} = action.payload
  try {
    yield put(updateEventStatus({id, status: nextStatus}, meta))
    const payload = yield call(handleEventUpdate, id, nextStatus, meta)
    yield put({type: UPDATE_STATUS_SUCCESS, payload, meta})
  } catch (e: any) {
    yield put(updateEventStatus({id, status: prevStatus}, meta))

    if (e.code === 403) {
      if (prevStatus === 'CONTRACT_STATUS_CONFIRMED') {
        yield put(
          toastrActions.errorToast(
            'Tax-ID missing',
            'Please check that the Invoice Organizer or the organizer has a Tax-ID'
          )
        )
      } else {
        yield put(toastrActions.errorToast('Contract state change not allowed'))
      }
    } else {
      yield put(
        toastrActions.errorToast(
          'Could not update show status',
          'Please try again'
        )
      )
    }

    yield put(standardErrorAction(UPDATE_STATUS_FAILURE, e, meta))
    throw e
  }
}

function* handleContractSaveSuccess(
  action: PayloadAction<
    PlannerShowCreatedSuccessPayload | PlannerShowUpdatedSuccessPayload,
    string,
    FlowActionMeta
  >
): SagaIterator {
  const {payload, meta} = action
  // RESOLVE IF ACTION SHOULD BE TAKEN
  const currentFilters = yield select(({flow}) => flow[meta.flow].filters)
  const payloadActId = payload.act?.id
  const payloadTourId = payload.tour?.id

  if (!currentFilters.kind) {
    return
  }

  if (currentFilters.kind === 'act' && payloadActId !== currentFilters.actId) {
    return
  }

  if (
    currentFilters.kind === 'tour' &&
    payloadTourId !== currentFilters.tourId
  ) {
    return
  }

  yield put(listUpdateEvent(payload, meta))
}

function* handleContractDestroySuccess(
  action: PayloadAction<any, string, FlowActionMeta>
): SagaIterator {
  const {payload, meta} = action
  const state = yield select(({flow}) => flow[meta.flow].data)

  if (find(state, (event) => event.id === payload.id)) {
    yield put(listDeleteEvent(payload, meta))
  }
}

const handleAllFlowStores = (
  saga: (action: PayloadAction<any, string, FlowActionMeta>) => SagaIterator
) => {
  return function* (
    action:
      | PlannerShowCreatedSuccess
      | PlannerShowUpdatedSuccess
      | PlannerShowDeletedSuccess
  ): SagaIterator {
    const flowStores = yield select(({flow}: RootStore) => Object.keys(flow))
    yield all(
      map(flowStores, (flow) => {
        const {meta, ...rest} = action
        return call(() =>
          saga({
            ...rest,
            meta: {...meta, flow},
          })
        )
      })
    )
  }
}

function* handleContractSaveSuccessAll(
  action: PlannerShowCreatedSuccess | PlannerShowUpdatedSuccess
) {
  yield call(handleAllFlowStores(handleContractSaveSuccess), action)
}
function* handleContractDestroySuccessAll(action: PlannerShowDeletedSuccess) {
  yield call(handleAllFlowStores(handleContractDestroySuccess), action)
}

export function* flowSaga() {
  yield fork(function* () {
    yield takeLatest([SET_FILTERS, TOGGLE_HISTORIC_EVENTS], fetchPlanners)
  })
  yield fork(function* () {
    yield takeLatest(UPDATE_STATUS, updateStatus)
  })
  yield fork(function* () {
    yield takeLatest(
      [SHOW_CREATED_SUCCESS, SHOW_UPDATED_SUCCESS],
      handleContractSaveSuccessAll
    )
  })
  yield fork(function* () {
    yield takeLatest(SHOW_DESTROYED_SUCCESS, handleContractDestroySuccessAll)
  })
}
