import {addDays} from 'date-fns'
import type {SagaIterator} from 'redux-saga'
import {takeLatest, select, fork, put, call} from 'redux-saga/effects'
import type {Optional} from 'utility-types'
import {api} from 'api/api'
import {toastrActions} from 'features/toastr'
import {dateToIsoString} from 'helpers/date'
import {standardErrorAction} from 'helpers/saga'
import {deletePlanner} from 'store/planner/actions'
import type {Contract, ContractRemote, ContractStatusType} from 'types/contract'
import type {PlannerEvent} from 'types/planner'
import type {PayloadActionWithThunk} from 'utils'
import {
  createContractSuccess,
  showContract,
  updateContractSuccess,
} from '../actions'
import {
  // CREATE
  CONTRACT_CREATE,
  CONTRACT_CREATE_FAILURE,
  // UPDATE
  CONTRACT_UPDATE,
  CONTRACT_UPDATE_FAILURE,
} from '../constants'
import {processResult} from '../helpers'
import type {ContractCreateParams, ContractUpdateParams} from '../types'

// SAVE API SAGA
export function* submitContract(data: Optional<Contract, 'id'>) {
  const status: ContractStatusType = yield select(
    ({contract}) => contract.status
  )

  const saveData: Optional<Contract, 'id'> = {
    ...data,
    status,
    show: {
      ...data.show,
      // Ensure end_at it set to one day after start_at
      end_at: dateToIsoString(addDays(new Date(data.show.start_at), 1)),
    },
  }

  let payload: ContractRemote
  if (data?.id) {
    payload = yield api.contracts.update(data.id, saveData)
  } else {
    payload = yield api.contracts.create(saveData)
  }

  return processResult(payload)
}

// CREATE CONTRACT
export function* createContract(
  action: PayloadActionWithThunk<ContractCreateParams>
): SagaIterator {
  try {
    const payload: Contract = yield call(() => submitContract(action.payload))
    const initializeParams = yield select(
      (store) => store.contract.initializeParams
    )

    if (initializeParams && initializeParams.fromEventId) {
      yield put(
        deletePlanner(
          {
            id: parseInt(initializeParams.fromEventId),
            type: 'EVENT_TYPE_NOTE_EVENT',
          } as PlannerEvent,
          {cellKey: null}
        )
      )
    }
    yield put(createContractSuccess(payload, action.meta))
    yield put(toastrActions.successToast('Contract created'))
    yield put(showContract({id: payload.id}))
    return payload
  } catch (e: any) {
    yield put(toastrActions.errorToast('Contract could not be created'))
    yield put(standardErrorAction(CONTRACT_CREATE_FAILURE, e, action.meta))
    throw e
  }
}
export function* watchCreateContract() {
  yield takeLatest(CONTRACT_CREATE, createContract)
}

// UPDATE CONTRACT
export function* updateContract(
  action: PayloadActionWithThunk<ContractUpdateParams>
): SagaIterator {
  try {
    const payload: Contract = yield call(submitContract, action.payload)
    yield put(updateContractSuccess(payload, action.meta))
    yield put(toastrActions.successToast('Contract updated'))
    return payload
  } catch (e: any) {
    yield put(toastrActions.errorToast('Contract could not be updated'))
    yield put(standardErrorAction(CONTRACT_UPDATE_FAILURE, e))
    throw e
  }
}
export function* watchUpdateContract() {
  yield takeLatest(CONTRACT_UPDATE, updateContract)
}

// BASE SAGA
export function* saveSaga() {
  yield fork(watchCreateContract)
  yield fork(watchUpdateContract)
}
