import {useState} from 'react'
import styled from 'styled-components'
import {Icon, Input} from 'components/elements'
import {ICON_NAMES} from 'components/elements/Icon/config'
import {colors, spacing} from 'styles/variables'
import {Container, Content, Filter, Filters, Select} from './components'

const options = Object.keys(colors).map((key) => ({
  label: key,
  value: (colors as any)[key] as string,
}))

const Grid = styled(Content)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: ${spacing.u10} ${spacing.u2};
`

const Cell = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`

const IconWrapper = styled.div`
  border: 1px solid ${colors.desertStorm};
  padding: ${spacing.u1_5};
`

export const PlaygroundIcon = () => {
  const [size, setSize] = useState<number>(16)
  const [color, setColor] = useState<string | undefined>()

  return (
    <Container>
      <Filters>
        <Filter>
          <label>Size</label>
          <Input
            kind='number'
            min='10'
            max='128'
            value={size}
            onChange={(v) => setSize(v)}
          />
        </Filter>
        <Filter>
          <label>Color</label>
          <Select value={color} onChange={setColor} options={options} />
        </Filter>
      </Filters>

      <Grid>
        {ICON_NAMES.map((icon) => (
          <Cell key={icon}>
            <IconWrapper style={{color}}>
              <Icon icon={icon} size={size} />
            </IconWrapper>
            <pre>{icon}</pre>
          </Cell>
        ))}
      </Grid>
    </Container>
  )
}
