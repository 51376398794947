import {Spinner} from 'components/elements'
import {Main} from 'containers/Core'
import {useModal} from 'hooks/useModal'
import {useEntityView, useTour} from './hooks'
import {EntityEditView} from './shared/EditView'
import {EntityShowView} from './shared/ShowView'

export const TourView = () => {
  const {closeModal} = useModal()
  const {viewMode, setViewMode} = useEntityView()
  const {isLoading, data} = useTour()

  if (isLoading || !data) {
    return <Spinner />
  }

  if (viewMode === 'new' || viewMode === 'edit') {
    return (
      <Main flex>
        <EntityEditView
          initialValues={data}
          onCancel={() =>
            viewMode === 'new' ? closeModal() : setViewMode('show')
          }
          onSubmitSuccess={() => setViewMode('show')}
        />
      </Main>
    )
  }

  return (
    <Main>
      <EntityShowView data={data} onEditClick={() => setViewMode('edit')} />
    </Main>
  )
}
