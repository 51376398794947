import {CurrencySelect, CountrySelect} from 'components/form'
import {Container, Section, Row, Column} from 'containers/Form'
import {Name} from '../sections'

export const EntityFormPanelOrganizer = () => (
  <Container>
    <Name />

    <Section label='Preferred language'>
      <Row>
        <Column>
          <CountrySelect model='preferred_language' kind='language' grid='8' />
        </Column>
      </Row>
    </Section>

    <Section label='Preferred currency'>
      <Row>
        <Column>
          <CurrencySelect model='preferred_currency' grid='8' />
        </Column>
      </Row>
    </Section>
  </Container>
)
