import type {PayloadAction} from '@reduxjs/toolkit'
import type {SagaIterator} from 'redux-saga'
import {takeLatest, put, call, select} from 'redux-saga/effects'
import {sagaFetch, standardErrorAction} from 'helpers/saga'
import {invalidateDepartment} from 'store/entity/department/helpers'
import type {RootStore} from 'store/types'
import type {AffiliationWithPersonAndRoles} from 'types/affiliation'
import type {StorableDepartment} from 'types/department'
import type {Organization} from 'types/organization'
import {createRoleSuccess} from '../actions'
import {CREATE, CREATE_FAILURE} from '../constants'
import type {RoleCreateParams} from '../types'

function* findOrCreateAffiliation(
  personId: number
): SagaIterator<AffiliationWithPersonAndRoles> {
  const organization: Organization = yield select(
    (store: RootStore) => store.entity.organization.data
  )

  if (!organization) {
    throw new Error('Create role: No organization found')
  }

  const affiliation = organization.affiliations.find(
    (a) => a.person.id === personId
  )

  if (affiliation) {
    return affiliation
  }

  // This local search is done both for speed,
  //  but also to avoid refetching the affiliation (POST -> 303 response),
  // because this fails in safari
  return yield call(() =>
    sagaFetch('/organizations/affiliations', 'post', {
      organization_id: organization.id,
      person_id: personId,
      is_admin: false,
    })
  )
}

export function* createRole(
  action: PayloadAction<RoleCreateParams>
): SagaIterator {
  try {
    const department: StorableDepartment = yield select(
      (store: RootStore) => store.entity.department.data
    )

    if (!department) {
      throw new Error('Create role: No department found')
    }

    const affiliation: AffiliationWithPersonAndRoles = yield call(() =>
      findOrCreateAffiliation(action.payload.personId)
    )
    const role = yield call(() =>
      sagaFetch('/organizations/roles', 'post', {
        department_id: department.id,
        affiliation_id: affiliation.id,
        type: action.payload.roleType,
      })
    )
    invalidateDepartment(department.type, department.id)
    return yield put(createRoleSuccess(role))
  } catch (e: any) {
    yield put(standardErrorAction(CREATE_FAILURE, e))
    throw e
  }
}

export function* createSaga() {
  yield takeLatest(CREATE, createRole)
}
