import {useOrganizers} from 'api'
import {Select} from 'components/elements'
import type {SelectProps} from 'components/elements/Select'
import type {ListOrganizer} from 'types/department'

export interface OrganizerSelectProps extends Omit<SelectProps, 'options'> {
  filterOptions: (item: ListOrganizer) => boolean
}

export const OrganizerSelect: React.FC<OrganizerSelectProps> = ({
  filterOptions,
  ...props
}) => {
  const {data = []} = useOrganizers()

  const organizers = filterOptions ? data.filter(filterOptions) : data

  return (
    <Select
      {...props}
      options={organizers.map((organizer) => ({
        label: organizer.name,
        value: organizer.id,
      }))}
    />
  )
}
