import {useFormikContext} from 'formik'
import {OrganizerSelect} from 'components/form'
import type {OrganizerSelectProps} from 'components/form/fields/OrganizerSelect'
import type {FormikFieldProps} from 'components/form/formikFieldHOC'

type InvoiceOrganizerIdProps = Omit<
  FormikFieldProps<OrganizerSelectProps>,
  'onChange'
>

export const InvoiceOrganizerId: React.FC<InvoiceOrganizerIdProps> = (
  props
) => {
  const {setFieldValue} = useFormikContext()

  const handleChange = () => {
    // Clear organizer contact
    setFieldValue(
      'show.stakeholder_organizer_for_invoicing.contact_person.id',
      null
    )
  }

  return <OrganizerSelect {...props} onChange={handleChange} />
}
