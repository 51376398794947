import {standardReducer} from 'helpers/reducers'
import {replaceTokens} from 'utils'
import {
  INIT_SUCCESS,
  INIT_FAILURE,
  CLEAR,
  SET_RECIPIENTS,
  SET_SUBJECT,
  SET_BODY,
} from '../constants'

const initialState = {
  recipients: [],
  attachments: [],
  subject: '',
  html_body: '',
}

export const contentReducer = standardReducer(initialState, {
  [INIT_SUCCESS]: (state, action) => {
    const {tokens, template, recipients, attachments} = action.payload

    const subject = replaceTokens(tokens, template.subject)

    const html_body = replaceTokens(tokens, template.html_body, {
      transformers: {
        signing_key: (value) =>
          `${window.location.origin}/app/contract-sign/${value}`,
      },
    })

    return Object.assign({}, state, {
      subject,
      html_body,
      recipients,
      attachments,
    })
  },

  [INIT_FAILURE]: () => initialState,
  [CLEAR]: () => initialState,

  [SET_RECIPIENTS]: (state, action) => {
    return Object.assign({}, state, {
      recipients: action.payload,
    })
  },

  [SET_SUBJECT]: (state, action) => {
    return Object.assign({}, state, {
      subject: action.payload,
    })
  },

  [SET_BODY]: (state, action) => {
    return Object.assign({}, state, {
      html_body: action.payload,
    })
  },
})
