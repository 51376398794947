import {usePlanner} from 'api'
import type {ListPlannerProps} from 'api'
import type {SelectProps, SelectOption} from 'components/elements/Select'
import {Select} from 'components/elements/Select'
import type {PlannerEvent} from 'types/planner'

interface PlannerSelectProps
  extends Omit<SelectProps, 'options'>,
    ListPlannerProps {
  valueFunc?: (item: PlannerEvent) => unknown
  labelFunc?: (item: PlannerEvent) => string
  enabled?: (props: ListPlannerProps) => boolean
}

export const PlannerSelect = ({
  before,
  after,
  actIds,
  includeInactiveActs,
  eventTypes,
  valueFunc = (item) => item.id,
  labelFunc = (item) => item.text ?? 'No name',
  enabled,
  ...props
}: PlannerSelectProps) => {
  const {data: events} = usePlanner(
    {
      before,
      after,
      actIds,
      includeInactiveActs,
      eventTypes,
    },
    enabled
  )

  const options: SelectOption[] = (events ?? []).map((item) => ({
    value: valueFunc(item),
    label: labelFunc(item),
  }))

  return <Select options={options} {...props} />
}
