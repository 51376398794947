import type {PayloadAction} from '@reduxjs/toolkit'
import type {SagaIterator} from 'redux-saga'
import {
  takeLatest,
  fork,
  select,
  put,
  putResolve,
  call,
} from 'redux-saga/effects'
import {toastrActions} from 'features/toastr'
import {sagaFetch, standardErrorAction} from 'helpers/saga'
import {CONTRACT_STATUS_FLOW_OPTIONS} from 'options/contractStatusFlow'
import {prompt} from 'store/prompt'
import type {RootStore} from 'store/types'
import type {ContractStatusType} from 'types/contract'
import {changeStateSuccess, showContract} from '../actions'
import {CHANGE_STATE, CHANGE_STATE_FAILURE} from '../constants'
import type {ContractChangeStateParams} from '../types'

export function* changeState(
  action: PayloadAction<ContractChangeStateParams>
): SagaIterator {
  const newState = action.payload
  // get current state
  const currentState: ContractStatusType | undefined = yield select(
    ({contract}: RootStore) => contract.result?.status
  )
  try {
    const url: string | undefined = yield select(
      ({contract}: RootStore) => contract.result?.status_url
    )

    if (!currentState) {
      throw new Error('ChangeState: Current status not found')
    }

    // get state options
    const options = CONTRACT_STATUS_FLOW_OPTIONS[currentState]
    const newOptions = CONTRACT_STATUS_FLOW_OPTIONS[newState]
    // resolve if we can change state
    if (!options.canAdvanceTo.includes(newState)) {
      throw new Error('Contract: Cannot change state')
    }

    let confirmed = true
    try {
      if (newOptions.prompt) {
        yield putResolve(prompt(newOptions.prompt))
      }
    } catch {
      confirmed = false
    }

    // Do the update
    if (confirmed) {
      const payload = yield call(sagaFetch, url, 'post', {status: newState})
      yield put(toastrActions.successToast(`Contract state updated`))
      yield put(changeStateSuccess(payload))
      yield put(showContract({id: payload.id}))
    } else {
      throw new Error('Contract: State change cancelled')
    }
  } catch (e: any) {
    if (e.code === 403) {
      if (currentState === 'CONTRACT_STATUS_CONFIRMED') {
        yield put(
          toastrActions.errorToast(
            'Tax-ID missing',
            'Please check that the Invoice Organizer or the organizer has a Tax-ID'
          )
        )
      } else {
        yield put(toastrActions.errorToast('Contract state change not allowed'))
      }
      yield put(standardErrorAction(CHANGE_STATE_FAILURE, e))
    } else if (e.message === 'Contract: State change cancelled') {
      // Just ignore this
    } else {
      throw e
    }
  }
}

function* watchChangeState() {
  yield takeLatest(CHANGE_STATE, changeState)
}

export function* stateChangeSaga() {
  yield fork(watchChangeState)
}
