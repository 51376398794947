import styled from 'styled-components'
import {EntityCard} from 'components/elements'
import {colors, spacing} from 'styles/variables'
import type {SearchDropdownResultsListProps} from './types'

const Root = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr auto;
  max-height: 50vh;
`

const ResultsWrapper = styled.div`
  overflow-y: auto;
`

const Results = styled.div`
  padding: ${spacing.u2_5};
  display: grid;
  gap: ${spacing.u2_5};
`

const NoResultsText = styled.div`
  text-align: center;
  padding: ${spacing.u5} ${spacing.u2_5};
  color: ${colors.santasGray};
`

const Footer = styled.footer`
  flex: 0 0 auto;
  border-top: 1px solid ${colors.borderColor};
`

const ResultsListInner = ({
  minSearchLength = 0,
  value,
  results,
  onClick,
}: SearchDropdownResultsListProps) => {
  if (minSearchLength > 0 && value.length < minSearchLength) {
    return (
      <ResultsWrapper>
        <NoResultsText>
          {`Type as least ${minSearchLength} to search`}
        </NoResultsText>
      </ResultsWrapper>
    )
  }

  if (results.length > 0) {
    return (
      <ResultsWrapper>
        <Results>
          {results.map((item) => (
            <EntityCard
              key={`${item.type}-${item.id}`}
              data={item}
              entityType={item.type}
              onClick={onClick}
            />
          ))}
        </Results>
      </ResultsWrapper>
    )
  }

  return (
    <ResultsWrapper>
      <NoResultsText>No results</NoResultsText>
    </ResultsWrapper>
  )
}

export const ResultsList = ({
  value,
  minSearchLength = 0,
  results,
  isSearching,
  showAllResults,
  onClick,
  footer,
}: SearchDropdownResultsListProps) => {
  const show = () => {
    if (isSearching) {
      return false
    }
    if (showAllResults) {
      return true
    }
    if (minSearchLength > 0) {
      return true
    }
    return value.length > 0
  }

  if (!show()) {
    return null
  }

  return (
    <Root>
      <ResultsListInner
        value={value}
        minSearchLength={minSearchLength}
        results={results}
        isSearching={isSearching}
        showAllResults={showAllResults}
        onClick={onClick}
      />
      {footer !== undefined ? <Footer>{footer()}</Footer> : null}
    </Root>
  )
}
