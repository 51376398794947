import {CurrencySelect, Select} from 'components/form'
import {Container, Section, Row, Column} from 'containers/Form'
import {CONTRACT_LANGUAGES} from 'options/contract'
import type {ContractLanguage} from 'types/contract'
import type {ContractPanelProps} from './types'
import {OrganizerPreference} from '../fields'

function getContractLanguage(key?: ContractLanguage) {
  switch (key) {
    case 'da':
      return CONTRACT_LANGUAGES.da
    case 'en':
      return CONTRACT_LANGUAGES.en
    default:
      return {
        name: 'Unknown',
        value: undefined,
      }
  }
}

const contractLanguages = Object.keys(CONTRACT_LANGUAGES).map((value) => ({
  label: getContractLanguage(value as unknown as ContractLanguage).name,
  value,
}))

export const Settings: React.FC<
  React.PropsWithChildren<ContractPanelProps>
> = ({locked}) => (
  <Container>
    <Section label='Currency'>
      <Row>
        <Column>
          <CurrencySelect
            theme='form'
            model='currency'
            disabled={locked}
            placeholder='Select currency...'
          />
          <OrganizerPreference selector='preferred_currency' />
        </Column>
        <Column />
      </Row>
    </Section>
    <Section label='Language'>
      <Row>
        <Column>
          <Select
            theme='form'
            model='language'
            disabled={locked}
            horizontal
            options={contractLanguages}
            placeholder='Select language...'
          />
          <OrganizerPreference
            selector='preferred_language'
            formatter={(value) => getContractLanguage(value).name}
          />
        </Column>
        <Column />
      </Row>
    </Section>
  </Container>
)
