import {Route, Switch} from 'react-router-dom'
import {ExternalRouter} from 'app/external/router'
import {MainRouter} from 'app/main/router'

export const RootRouter = () => {
  return (
    <Switch>
      <Route path='/app'>
        <ExternalRouter />
      </Route>
      <Route path='*'>
        <MainRouter />
      </Route>
    </Switch>
  )
}
