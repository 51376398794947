import {takeLatest, put, call} from 'redux-saga/effects'
import {sagaFetch} from 'helpers/saga'
import {INIT, INIT_START, INIT_SUCCESS, INIT_FAILURE} from '../constants'

export function* initEmailTemplate(action) {
  const {url} = action.payload
  try {
    yield put({type: INIT_START})
    const payload = yield call(sagaFetch, url, 'get')
    yield put({type: INIT_SUCCESS, payload, meta: action.meta})
  } catch (error) {
    console.error(error)
    yield put({
      type: INIT_FAILURE,
      payload: error,
      error: true,
      meta: action.meta,
    })
  }
}

export function* initSaga() {
  yield takeLatest(INIT, initEmailTemplate)
}
