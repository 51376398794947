import {combineReducers} from 'redux'
import {affiliationsReducer} from './affiliations/reducer'
import {departmentReducer} from './department/reducer'
import {organizationReducer} from './organization/reducer'
import {personReducer} from './person/reducer'
import {tourReducer} from './tour/reducer'

export const entityReducer = combineReducers({
  organization: organizationReducer,
  department: departmentReducer,
  person: personReducer,
  affiliations: affiliationsReducer,
  tour: tourReducer,
})
