import type {PayloadAction} from '@reduxjs/toolkit'
import {combineReducers} from 'redux'
import {standardReducer, isLoading} from 'helpers/reducers'
import type {Organization} from 'types/organization'
import {
  SHOW,
  SHOW_SUCCESS,
  SHOW_FAILURE,
  UPDATE_SUCCESS,
  CLEAR,
} from './constants'
import type {OrganizationShowParams, OrganizationStoreData} from './types'

const initialState = {}

const ACTION_CREATORS = {
  // Keep state if organization is refetching
  [SHOW]: (
    state: OrganizationStoreData,
    action: PayloadAction<OrganizationShowParams>
  ) => (action.payload.id === state.id ? state : initialState),
  [SHOW_SUCCESS]: (
    state: OrganizationStoreData,
    action: PayloadAction<Organization>
  ) => action.payload,
  [SHOW_FAILURE]: () => initialState,
  [UPDATE_SUCCESS]: (
    state: OrganizationStoreData,
    action: PayloadAction<Organization>
  ) => action.payload,
  [CLEAR]: () => initialState,
}

export const organizationReducer = combineReducers({
  isLoading: isLoading({
    constants: [SHOW, SHOW_SUCCESS, SHOW_FAILURE],
  }),
  data: standardReducer(initialState, ACTION_CREATORS),
})
