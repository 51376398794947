import styled from 'styled-components'
import {MyActsSelect} from 'components/connected'
import {PriorityMenu} from 'components/elements'
import {Filters} from 'containers/Core'
import {spacing} from 'styles/variables'
import {useSingleActPriorityMenu} from './hooks'

const Container = styled(Filters)`
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: ${spacing.u5};
  align-items: center;
`

export const SingleActPriorityMenu = () => {
  const {value, onChange, options} = useSingleActPriorityMenu()

  return (
    <Container>
      <MyActsSelect
        theme='big'
        value={value}
        onChange={onChange}
        placeholder='Select act...'
      />
      <PriorityMenu items={options} value={value} onChange={onChange} />
    </Container>
  )
}
