import map from 'lodash/map'
import styled from 'styled-components'
import {Icon, Time, Tooltip, Visible} from 'components/elements'
import {CONTRACT_AUDIT_TRAIL_STATUS_OPTIONS} from 'options/contractAuditTrail'
import {spacing, colors, fonts, fontSizes} from 'styles/variables'
import type {ContractStatusAuditTrail} from 'types/contract'
import {useContractFlow} from '../helpers'

const EntryContainer = styled.div`
  display: grid;
  color: ${colors.secondaryTextColor};
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'title icon'
    'responsible responsible'
    'timestamp timestamp';
  cursor: default;
`
const EntryTitle = styled.div`
  grid-area: title;
  font-family: ${fonts.fontBold};
  color: ${colors.primaryTextColor};
  &::first-letter {
    text-transform: uppercase;
  }
`
const EntryIcon = styled(Icon)`
  grid-area: icon;
  color: ${colors.midGray};
`
const EntryResponsible = styled.div`
  grid-area: responsible;
  font-size: ${fontSizes.ratSize};
  color: ${colors.primaryTextColor};
`
const EntryTimestamp = styled(Time)`
  grid-area: timestamp;
  font-size: ${fontSizes.ratSize};
`

interface EntryProps {
  data: ContractStatusAuditTrail
}

const Entry: React.FC<React.PropsWithChildren<EntryProps>> = ({
  data: {internal_status, changed_at, changed_by, invoice_id},
}) => {
  const options = CONTRACT_AUDIT_TRAIL_STATUS_OPTIONS[internal_status]

  if (!options) {
    return null
  }

  const responsible = changed_by?.full_name ?? ''

  return (
    <Tooltip
      content={
        options.tooltip
          ? options.tooltip({
              name: responsible,
              invoiceId: invoice_id,
            })
          : undefined
      }
      position='left'
    >
      <EntryContainer>
        <EntryTitle>{options.name}</EntryTitle>
        <EntryIcon icon={options.icon} />
        <Visible ifTrue={!!responsible}>
          <EntryResponsible>{responsible}</EntryResponsible>
        </Visible>
        <EntryTimestamp obj={changed_at} format='DateShortWithTime' />
      </EntryContainer>
    </Tooltip>
  )
}

const Container = styled.div`
  padding: ${spacing.u5};
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: ${colors.westar};
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.oxfordBlue};
    border-radius: 999px;
  }
  & > :not(:last-child) {
    margin-bottom: ${spacing.u5};
  }
`

export const ContractAuditTrail = () => {
  const {auditTrail} = useContractFlow()

  return (
    <Container>
      {map(auditTrail, (item) => (
        <Entry key={`${item.status}__${item.changed_at}`} data={item} />
      ))}
    </Container>
  )
}
