import styled, {css, keyframes} from 'styled-components'
import {colors, fontSizes} from 'styles/variables'

const Container = styled.div`
  background: ${colors.oxfordBlue};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
const Content = styled.div`
  font-size: ${fontSizes.bearSize};
  color: ${colors.secondaryTextColor};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 30px;
`
const Logo = styled.img`
  width: 100px;
  height: 64px;
`
const LoaderContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  column-gap: 8px;
`

const shimmer = keyframes`
  0% {
    background-position: 100%;
  }
  100% {
    background-position: -100%;
  }
`
type LoadingState = 'notStarted' | 'loading' | 'done'
const Loader = styled.div<{state: LoadingState}>`
  background-color: ${colors.ebonyClay};
  width: 160px;
  height: 6px;
  border-radius: 2px;

  ${({state}) =>
    state === 'loading' &&
    css`
      background: linear-gradient(
        to right,
        #3181b7 10%,
        #202a33 10%,
        #202a33 90%,
        #3181b7 90%
      );
      background-size: 200% 100%;
      animation: ${shimmer} 2s ease-in-out infinite;
    `}
  ${({state}) =>
    state === 'done' &&
    css`
      background-color: #4aa6ee;
    `}
`

const Text = styled.div`
  color: ${colors.ebonyClay};
  font-size: ${fontSizes.lionSize};
`

export const MainAppLoader = () => {
  return (
    <Container>
      <Content>
        <Logo src='/images/logo_lights_no_bg_css.svg' alt='' />
        <LoaderContainer>
          <Loader state='loading' />
        </LoaderContainer>
        <Text>Loading</Text>
      </Content>
    </Container>
  )
}
