import {createQueryKeys} from '@lukemorales/query-key-factory'
import {api} from 'api/api'

export const people = createQueryKeys('people', {
  list: {
    queryKey: null,
    queryFn: () => api.people.list(),
  },
  // show: (id: number) => ({
  //   queryKey: [id],
  //   queryFn: () => api.people.show(id),
  // }),
})
