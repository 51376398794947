import {useState} from 'react'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import {EmptyMessage} from 'app/main/Booking/components'
import {Button, Icon, PriorityMenu} from 'components/elements'
import {Filters} from 'containers/Core'
import {newDepartment} from 'store/entity/actions'
import {colors, spacing} from 'styles/variables'
import type {DepartmentBase} from 'types/department'
import type {OrganizationDepartment} from 'types/organization'
import {EmptyContainer} from './components'
import {DepartmentCRUDView} from './DepartmentCRUD'
import {useDepartments, useEntityView} from './hooks'

const Container = styled(Filters)`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: ${spacing.u5};
  align-items: center;
`

const NoDepartmentsMenu = styled.div`
  color: ${colors.secondaryTextColor};
  display: flex;
  align-items: center;
  gap: ${spacing.u2_5};
`

export const StagesView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [tempSelectedDepartment, setTempSelectedDepartment] = useState<
    DepartmentBase | undefined
  >()
  const dispatch = useDispatch()
  const {viewMode, setViewMode} = useEntityView()
  const {selectedDepartment, setSelectedDepartment, departments} =
    useDepartments('stages')

  const handleNewClick = () => {
    setTempSelectedDepartment(selectedDepartment)
    setSelectedDepartment(undefined)
    dispatch(newDepartment({type: 'VENUE'}))
    setViewMode('new')
  }

  const handleNewCancel = () => {
    if (tempSelectedDepartment) {
      setSelectedDepartment(tempSelectedDepartment)
      setTempSelectedDepartment(undefined)
      // dispatch(
      //   showDepartment({id: selectedDepartment.id, kind: 'VENUE'})
      // )
    }
    setViewMode('show')
  }

  const hasDepartments = !!departments.length
  const showEmptyMessage = !hasDepartments && viewMode === 'show'

  return (
    <>
      <Container>
        {hasDepartments && (
          <PriorityMenu
            items={departments.map((department) => ({
              label: department.name,
              value: department,
              disabled: viewMode !== 'show',
            }))}
            itemKey={(department) => department.global_id}
            value={selectedDepartment}
            onChange={(department) =>
              setSelectedDepartment(department as OrganizationDepartment)
            }
          />
        )}
        {!hasDepartments && (
          <NoDepartmentsMenu>
            <span>
              Add some stages by clicking the plus button to the right
            </span>
            <Icon icon='arrow-right' />
          </NoDepartmentsMenu>
        )}

        <Button round icon='plus' onClick={handleNewClick} />
      </Container>

      {showEmptyMessage && (
        <EmptyContainer flex>
          <EmptyMessage
            title='No stages has been created yet'
            description='If you want this organizer to also manage stages, click the button below to create its first stage.'
          />
          <Button
            icon='plus'
            label='Create stage'
            theme='primary'
            onClick={handleNewClick}
          />
        </EmptyContainer>
      )}

      {!showEmptyMessage && <DepartmentCRUDView onCancel={handleNewCancel} />}
    </>
  )
}
