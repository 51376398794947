import {createReducer} from '@reduxjs/toolkit'
import {changeState, changeStateFailure, changeStateSuccess} from '../actions'
import type {ContractStoreStateChange} from '../types'

export const stateChangeReducer = createReducer<ContractStoreStateChange>(
  null,
  (builder) => {
    builder
      .addCase(changeState, (s, a) => a.payload)
      .addCase(changeStateSuccess, () => null)
      .addCase(changeStateFailure, () => null)
  }
)
