import type {ContractDummyShow, ContractShow} from './contractShow'
import type {
  ContractTemplate,
  SupplementaryContract,
  SupplementaryContractNullable,
} from './contractTemplate'
import type {DateTimeString, Url} from './generic'
import type {SigningDocument} from './signing'

export type ContractNumber = string | number | null

export type ContractLanguage = 'da' | 'en'

export type ContractCurrency = 'DKK'

export type ContractStatusType =
  | 'CONTRACT_STATUS_TBC'
  | 'CONTRACT_STATUS_CONFIRMED'
  // LEGACY FLOW
  | 'CONTRACT_STATUS_FINALIZED'
  | 'CONTRACT_STATUS_SIGNED'
  // WHNUE SIGN FLOW
  | 'CONTRACT_STATUS_UNSIGNED'
  | 'CONTRACT_STATUS_PARTIALLY_SIGNED'
  | 'CONTRACT_STATUS_FULLY_SIGNED'
  | 'CONTRACT_STATUS_CANCELLED'

export type ContractInvoicingStatusType =
  | 'INVOICING_STATUS_NO_INVOICE'
  | 'INVOICING_STATUS_INVOICE_SENT'
  | 'INVOICING_STATUS_INVOICE_PAID'

export type ContractFinancialExportStatusType =
  | 'EXPORT_STATUS_NO_EXPORT'
  | 'EXPORT_STATUS_TO_BE_EXPORTED'
  | 'EXPORT_STATUS_TO_BE_EXPORTED_AGAIN'
  | 'EXPORT_STATUS_EXPORTED'
  | 'EXPORT_STATUS_EXPORTED_MANUALLY'

export enum ContractAuditTrailStatuses {
  TBC = 'CONTRACT_AUDIT_TRAIL_STATUS_TBC',
  CONFIRMED = 'CONTRACT_AUDIT_TRAIL_STATUS_CONFIRMED',
  FINALIZED = 'CONTRACT_AUDIT_TRAIL_STATUS_FINALIZED',
  SIGNED_PROMISEE = 'CONTRACT_AUDIT_TRAIL_STATUS_SIGNED_PROMISEE',
  SIGNED_PROMISOR = 'CONTRACT_AUDIT_TRAIL_STATUS_SIGNED_PROMISOR',
  SIGNED = 'CONTRACT_AUDIT_TRAIL_STATUS_SIGNED',
  UNSIGNED = 'CONTRACT_STATUS_UNSIGNED',
  PARTIALLY_SIGNED = 'CONTRACT_STATUS_PARTIALLY_SIGNED',
  FULLY_SIGNED = 'CONTRACT_STATUS_FULLY_SIGNED',
  CANCELLED = 'CONTRACT_AUDIT_TRAIL_STATUS_CANCELLED',
  UNCANCELLED = 'CONTRACT_AUDIT_TRAIL_STATUS_UNCANCELLED',
  REEDITED = 'CONTRACT_AUDIT_TRAIL_STATUS_REEDITED',
}

export type ContractProductionStatusType =
  | 'PRODUCTION_STATUS_READY'
  | 'PRODUCTION_STATUS_IN_PROGRESS'
  | 'PRODUCTION_STATUS_DONE'

export type ContractMediatorCommission = {
  name: string
  relative_commission: number | null
  absolute_commission: number | null
}

export interface ContractStatusAuditTrailChangedBy {
  full_name: string
}
export interface ContractStatusAuditTrailRemote {
  changed_at: DateTimeString
  status: ContractStatusType
  changed_by: ContractStatusAuditTrailChangedBy | null
}

export type ContractAuditTrailInternalStatuses =
  | ContractAuditTrailStatuses
  | ContractInvoicingStatusType

export interface ContractStatusAuditTrail
  extends Partial<ContractStatusAuditTrailRemote> {
  internal_status: ContractAuditTrailInternalStatuses
  invoice_id?: string | number | null
}

export interface ContractFinancialExportStatus {
  marked_for_export_at: DateTimeString | null
  most_recently_exported_at: DateTimeString | null
  value: ContractFinancialExportStatusType
}

export interface ContractInvoicingStatus {
  invoice_sent_at: DateTimeString | null
  invoice_paid_at: DateTimeString | null
  value: ContractInvoicingStatusType
  external_invoice_id: string | number | null
}

export interface ContractDummyRemote {
  language: ContractLanguage
  currency: ContractCurrency
  fee: number
  fee_has_vat: boolean
  relative_commission: number | null
  absolute_commission: number | null
  commission_has_vat: boolean
  commission_included_in_fee: boolean
  mediator_commissions: null
  economic_terms_id: number | null
  custom_economic_terms: string
  notes_for_economy: string
  status: null
  show: ContractDummyShow
  document: SigningDocument
  terms_template: ContractTemplate
  supplementary_contract: SupplementaryContract | SupplementaryContractNullable
  supplementary_contract_text: string
  payment_conditions: unknown | null
  enable_disclosure_date: boolean
  financial_export_status: ContractFinancialExportStatus
  invoicing_status: ContractInvoicingStatus
}

export interface ContractDummy extends ContractDummyRemote {
  _commissionAbsolute: boolean
}

export interface ContractRemote
  extends Omit<
    ContractDummyRemote,
    | 'mediator_commissions'
    | 'status'
    | 'show'
    | 'financial_export_status'
    | 'invoicing_status'
  > {
  id: number
  url: string
  status_url: Url
  pdf_download_url: Url
  draft_email_url: Url
  final_email_url: Url
  signing_invitation_url: Url
  signing_key_url: Url
  financial_export_status_url: Url
  invoicing_status_url: Url
  number: ContractNumber
  mediator_commissions: ContractMediatorCommission[]
  status: ContractStatusType
  status_audit_trail: ContractStatusAuditTrailRemote[]
  can_be_finalized: boolean
  can_be_cancelled: boolean
  show: ContractShow
  supplementary_contract: SupplementaryContract
  financial_export_status: ContractFinancialExportStatus | null
  invoicing_status: ContractInvoicingStatus | null
}

export interface Contract extends Omit<ContractRemote, 'status_audit_trail'> {
  status_audit_trail: ContractStatusAuditTrail[]
  status_audit_trail_last_status: ContractAuditTrailStatuses
  _commissionAbsolute: boolean
}
