import styled from 'styled-components'
import {Main} from 'containers/Core'
import {spacing} from 'styles/variables'

export const EmptyContainer = styled(Main)`
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: ${spacing.u5};
`
