import type {PayloadAction} from '@reduxjs/toolkit'
import reject from 'lodash/reject'
import {combineReducers} from 'redux'
import {standardReducer, isLoading} from 'helpers/reducers'
import type {StorableDepartment} from 'types/department'
import type {RoleBase, RoleAgencyBooker} from 'types/role'
import {
  NEW,
  NEW_SUCCESS,
  SHOW,
  SHOW_SUCCESS,
  SHOW_FAILURE,
  CLEAR,
  UPDATE_SUCCESS,
  CREATE_SUCCESS,
  CLEAR_ON_CREATE_SUCCESS,
} from './constants'
import type {
  DepartmentNewParams,
  DepartmentNewSuccessMeta,
  DepartmentNewSuccessPayload,
  DepartmentStoreData,
} from './types'
import {ROLE, ACT_BOOKING_AGENCY_BOOKER} from '../constants'

const initialState = {}

const ACTION_HANDLERS = {
  [NEW_SUCCESS]: (
    state: DepartmentStoreData,
    action: PayloadAction<DepartmentNewParams>
  ) => action.payload,
  [SHOW]: () => initialState,
  [SHOW_SUCCESS]: (
    state: DepartmentStoreData,
    action: PayloadAction<StorableDepartment>
  ) => action.payload,
  [SHOW_FAILURE]: () => initialState,
  [CREATE_SUCCESS]: (
    state: DepartmentStoreData,
    action: PayloadAction<StorableDepartment>
  ) => action.payload,
  [UPDATE_SUCCESS]: (
    state: DepartmentStoreData,
    action: PayloadAction<StorableDepartment>
  ) => action.payload,

  [ROLE.CREATE_SUCCESS]: (
    state: DepartmentStoreData,
    action: PayloadAction<RoleBase>
  ) => {
    return Object.assign({}, state, {
      roles: [...state.roles, action.payload],
    })
  },
  [ROLE.DELETE_SUCCESS]: (
    state: DepartmentStoreData,
    action: PayloadAction<Pick<RoleBase, 'id'>>
  ) => {
    return Object.assign({}, state, {
      roles: reject(state.roles, {id: action.payload.id}),
    })
  },

  [ACT_BOOKING_AGENCY_BOOKER.CREATE_SUCCESS]: (
    state: DepartmentStoreData,
    action: PayloadAction<RoleAgencyBooker>
  ) => {
    return state.type === 'ACT'
      ? Object.assign({}, state, {
          agency_bookers: [...state.agency_bookers, action.payload],
        })
      : state
  },
  [ACT_BOOKING_AGENCY_BOOKER.DELETE_SUCCESS]: (
    state: DepartmentStoreData,
    action: PayloadAction<Pick<RoleAgencyBooker, 'id'>>
  ) => {
    return state.type === 'ACT'
      ? Object.assign({}, state, {
          agency_bookers: reject(state.agency_bookers, {id: action.payload.id}),
        })
      : state
  },
  [CLEAR]: () => initialState,
}

type OnCreateSuccessData = (response: StorableDepartment) => void | null
const ON_CREATE_SUCCESS_ACTION_HANDLERS = {
  [NEW]: () => null,
  [NEW_SUCCESS]: (
    state: OnCreateSuccessData,
    action: PayloadAction<
      DepartmentNewSuccessPayload,
      string,
      DepartmentNewSuccessMeta
    >
  ) =>
    action.meta.onCreateSuccess !== undefined
      ? action.meta.onCreateSuccess
      : null,
  [CLEAR_ON_CREATE_SUCCESS]: () => null,
  [CLEAR]: () => null,
}

export const departmentReducer = combineReducers({
  isLoading: isLoading(SHOW, SHOW_SUCCESS, SHOW_FAILURE),
  data: standardReducer(initialState, ACTION_HANDLERS),
  onCreateSuccess: standardReducer(null, ON_CREATE_SUCCESS_ACTION_HANDLERS),
})
