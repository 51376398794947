import {SignDocument} from 'components/connected/SignDocument'
import {useContractActions, useReloadContract} from '../helpers'

interface SignContractProps {
  disabled?: boolean
}
export const SignContract = ({disabled}: SignContractProps) => {
  const {signingKeyUrl, document} = useContractActions()
  const {reloadContract} = useReloadContract()

  if (!document || !document.promisee.signed_at) {
    return <div>Awaiting organizer&apos;s signature</div>
  }

  if (!signingKeyUrl) {
    return null
  }

  if (disabled) {
    return <div>Awaiting booker signature</div>
  }

  return (
    <SignDocument
      config={{signingKeyUrl}}
      onSuccess={reloadContract}
      buttonProps={{
        icon: 'final',
        label: 'Sign',
        theme: 'secondary',
        width: 'fill',
      }}
    />
  )
}
