import {createReducer} from '@reduxjs/toolkit'
import {combineReducers} from 'redux'
import {isLoading} from 'helpers/reducers'
import {
  clearTour,
  createTourSuccess,
  newTour,
  showTour,
  showTourFailure,
  showTourSuccess,
  updateTourSuccess,
} from './actions'
import {SHOW, SHOW_SUCCESS, SHOW_FAILURE} from './constants'
import type {TourStoreData} from './types'

const initialState = {} as TourStoreData

export const tourReducer = combineReducers({
  isLoading: isLoading(SHOW, SHOW_SUCCESS, SHOW_FAILURE),
  data: createReducer<TourStoreData>(initialState, (builder) => {
    builder
      .addCase(
        newTour,
        (s, a) =>
          ({
            type: 'EVENT_TYPE_TOUR',
            stakeholder_act: {
              department: {
                id: a?.payload?.actId ? a.payload.actId : null,
              },
            },
            start_at: a?.payload?.startOn
              ? new Date(a.payload.startOn).toISOString()
              : null,
          } as TourStoreData)
      )
      .addCase(showTour, () => initialState)
      .addCase(showTourSuccess, (s, a) => a.payload)
      .addCase(showTourFailure, () => initialState)
      .addCase(createTourSuccess, (s, a) => a.payload)
      .addCase(updateTourSuccess, (s, a) => a.payload)
      .addCase(clearTour, () => initialState)
  }),
})
