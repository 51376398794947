import {useContext, useEffect, useState} from 'react'
import cx from 'classnames'
import {TabsContext} from './context'
import {getKindStyle} from './helpers'
import styles from './Tabs.module.css'

export interface TabProps {
  className?: string
  disabled?: boolean
  onClick?: (key: string) => void
  name: string
}

export const Tab: React.FC<React.PropsWithChildren<TabProps>> = ({
  children,
  name,
  disabled,
  className,
  onClick,
}) => {
  const [initialized, setInitialized] = useState(false)
  const {kind, registerTab, selectTab, selectedTab} = useContext(TabsContext)

  useEffect(() => {
    if (initialized) {
      return
    }

    if (!name) {
      throw new Error('Tab must be named')
    }
    setInitialized(true)
    registerTab(name)
  }, [initialized, name, registerTab])

  const handleClick = () => {
    if (disabled) {
      return
    }
    if (onClick) {
      onClick(name)
    }
    selectTab(name)
  }

  return (
    <div
      onClick={handleClick}
      className={cx(getKindStyle(kind), styles.tab, className, {
        [styles.tabSelected]: selectedTab === name,
        [styles.tabDisabled]: disabled,
      })}
    >
      {children}
    </div>
  )
}
