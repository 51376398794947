import {useFormikContext} from 'formik'
import {useAct} from 'api'
import type {SelectProps} from 'components/elements/Select'
import {Select} from 'components/form'
import type {FormikFieldProps} from 'components/form/formikFieldHOC'
import {useAuth} from 'hooks/useAuth'
import type {Contract} from 'types/contract'

interface BookingAgencyContactIdProps
  extends Omit<FormikFieldProps<SelectProps>, 'onChange' | 'options'> {
  actModel: string
}

export const BookingAgencyContactId: React.FC<
  React.PropsWithChildren<BookingAgencyContactIdProps>
> = ({disabled, actModel, ...props}) => {
  const {getFieldProps} = useFormikContext<Contract>()
  const actId = getFieldProps(actModel).value
  const {data: act} = useAct(actId)
  const actRoles = act?.agency_bookers ?? []

  const {affiliations} = useAuth()
  const values = affiliations
    .filter((affiliation) =>
      affiliation.roles.some((r) => r.type === 'BOOKING_AGENCY_BOOKER')
    )
    .map((affiliation) => ({
      label: affiliation.person.full_name,
      value: affiliation.roles.find((r) => r.type === 'BOOKING_AGENCY_BOOKER')
        ?.id,
    }))

  const options = [
    {
      label: 'Associated with act',
      options: values.filter(
        ({value}) => !!actRoles.find((r) => r.id === value)
      ),
    },
    {
      label: 'Other',
      options: values.filter(
        ({value}) => !actRoles.find((r) => r.id === value)
      ),
    },
  ]

  return (
    <Select
      theme='form'
      {...props}
      disabled={disabled}
      placeholder={'Select contact...'}
      options={options}
    />
  )
}
