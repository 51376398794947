import Markdown from 'markdown-to-jsx'
import styled from 'styled-components'
import {Filters, Flow as FlowComp} from 'components/Flow'
import {AppLayout} from 'components/layout'
import {colors, spacing} from 'styles/variables'

const Layout = styled(AppLayout)`
  overflow: hidden;
  position: relative;
`

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
`

const Explainer = styled(Markdown)`
  flex: 0 0 auto;
  margin: ${spacing.u5};
  color: ${colors.secondaryTextColor};
`

export const Flow = () => (
  <Layout
    title='Flow'
    filters={<Filters flow='booking' passiveText='cancelled' />}
  >
    <Container>
      <Explainer>
        {`Drag the events from one column to another, to indicate where they are in the flow. All columns are sorted by date, with the most urgent on top.
          <br />
          The events in the 'Signed' and 'Cancelled' columns are automatically removed when the event is over.`}
      </Explainer>
      <FlowComp kind='booking' />
    </Container>
  </Layout>
)
