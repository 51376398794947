import styled from 'styled-components'
import {HeaderText, Spinner, Visible} from 'components/elements'
import {Container, Header, HeaderLeft, HeaderRight, Main} from 'containers/Core'
import {colors, fonts, fontSizes} from 'styles/variables'
import {ContractExports} from './Exports'
import {ContractViewFlow} from './Flow'
import {ContractForm, SelectActForm} from './Form'
import {useContractWrapper} from './helpers'

const CancelledOverlay = styled.div`
  position: absolute;
  background: rgba(242, 241, 240, 0.6);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    font-size: ${fontSizes.elephantSize};
    line-height: ${fontSizes.elephantSize};
    text-transform: uppercase;
    font-family: ${fonts.fontBold};
    border: 10px solid ${colors.scarpaFlow};
    color: ${colors.scarpaFlow};
    padding: 10px 20px;
    border-radius: 20px;
    transform: rotate(-50deg);
    opacity: 0.5;
  }
`

export const Contract = () => {
  const {
    view,
    isLoading,
    isCancelled,
    hasActivity,
    headerPrimaryText,
    headerSecondaryText,
  } = useContractWrapper()

  return (
    <Container relative width={920}>
      <Header>
        <HeaderLeft>
          <HeaderText
            primary={headerPrimaryText}
            secondary={headerSecondaryText}
            twoLines
          />
        </HeaderLeft>
        <HeaderRight>
          <ContractExports />
        </HeaderRight>
      </Header>

      <Main flex>
        <Visible ifTrue={hasActivity}>
          <Spinner overlay={!isLoading} absolute />
        </Visible>

        <Visible ifFalse={isLoading}>
          <section style={{position: 'relative', display: 'grid'}}>
            {isCancelled && (
              <CancelledOverlay>
                <div>Cancelled</div>
              </CancelledOverlay>
            )}

            {view === 'selectAct' && <SelectActForm />}
            {view !== 'selectAct' && <ContractForm />}
          </section>

          <ContractViewFlow />
        </Visible>
      </Main>
    </Container>
  )
}
