export const NEW = 'Entity/Person/NEW'

export const FETCH = 'Entity/Person/FETCH'
export const FETCH_SUCCESS = 'Entity/Person/FETCH_SUCCESS'
export const FETCH_FAILURE = 'Entity/Person/FETCH_FAILURE'

export const SHOW = 'Entity/Person/SHOW'
export const SHOW_SUCCESS = 'Entity/Person/SHOW_SUCCESS'
export const SHOW_FAILURE = 'Entity/Person/SHOW_FAILURE'

export const CREATE = 'Entity/Person/CREATE'
export const CREATE_SUCCESS = 'Entity/Person/CREATE_SUCCESS'
export const CREATE_FAILURE = 'Entity/Person/CREATE_FAILURE'

export const UPDATE = 'Entity/Person/UPDATE'
export const UPDATE_SUCCESS = 'Entity/Person/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'Entity/Person/UPDATE_FAILURE'

export const CLEAR = 'Entity/Person/CLEAR'
