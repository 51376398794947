import type {BusinessCard} from './businessCard'
import type {DateTimeString, Url, WhnueGlobalId} from './generic'
import type {OrganizationBase} from './organization'
import type {RoleAgencyBooker, RoleWithAffiliation} from './role'
import type {Ean, TaxIdUnvalidated} from './taxAndEan'

export type DepartmentType =
  | 'ORGANIZATION'
  | 'PERSON'
  | 'BOOKING_AGENCY'
  | 'TOUR_MANAGEMENT'
  | 'ACT'
  | 'ORGANIZER'
  | 'VENUE'
  | 'ACT_MANAGEMENT'
  | 'MISCELLANEOUS'
  | 'TOUR'

export const DEPARTMENT_TYPE: DepartmentType[] = [
  'ORGANIZATION',
  'PERSON',
  'BOOKING_AGENCY',
  'TOUR_MANAGEMENT',
  'ACT',
  'ORGANIZER',
  'VENUE',
  'ACT_MANAGEMENT',
  'MISCELLANEOUS',
  'TOUR',
]

export type DepartmentCustomFieldType =
  | 'sap_act_code'
  | 'sap_act_asl'
  | 'sap_organizer_customer_id'

interface DepartmentCustomField {
  available_custom_fields: DepartmentCustomFieldType[]
  custom_fields: Record<Partial<DepartmentCustomFieldType>, string | null>
}

export interface DepartmentBase {
  id: number
  type: DepartmentType
  global_id: WhnueGlobalId
  url: Url
  name: string
}
export interface DepartmentBaseWithOrganizationId extends DepartmentBase {
  organization_id: number
}

export interface DepartmentBookingAgency extends DepartmentBase {
  type: 'BOOKING_AGENCY'
}

export interface DepartmentAct extends DepartmentBase {
  type: 'ACT'
}

export interface Department extends DepartmentBase {
  can_be_changed: boolean
  created: DateTimeString
  modified: DateTimeString
  organization: OrganizationBase
  business_card: BusinessCard
  business_card_is_own: boolean
  tax_id: TaxIdUnvalidated
  exempt_tax_id_verification_url: Url
  ean: Ean
  roles: RoleWithAffiliation[]
  preferred_language: string
  preferred_currency: string
}

export interface BookingAgency extends Department {
  type: 'BOOKING_AGENCY'
  acts_represented: DepartmentAct[]
}

export type ListBookingAgency = BookingAgency

export interface Organizer extends Department, DepartmentCustomField {
  type: 'ORGANIZER'
}

export interface ListOrganizer extends Pick<Organizer, 'id' | 'name'> {
  organization: Pick<Organizer['organization'], 'id' | 'name'>
}

export interface Act extends Department, DepartmentCustomField {
  type: 'ACT'
  booking_agency: DepartmentBookingAgency
  agency_bookers: RoleAgencyBooker[]
  default_commission: number | null
  is_active: boolean
}

export interface ListAct extends Pick<Act, 'id' | 'name' | 'is_active'> {
  organization: Pick<Act['organization'], 'id' | 'name'>
  is_represented_by_me: boolean
}

export interface Venue extends Department {
  type: 'VENUE'
  stage_capacity_seated: number
  stage_capacity_standing: number
}

export interface ListVenue extends Pick<Venue, 'id' | 'name'> {
  organization: Pick<Venue['organization'], 'id' | 'name'>
}

export type StorableDepartment = Organizer | Act | Venue | BookingAgency
