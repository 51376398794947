import {useState} from 'react'
import OtpInput from 'react-otp-input'
import type {ButtonProps} from 'components/elements'
import {Button} from 'components/elements'
import {Prompt} from 'components/utils/Prompt'
import {colors, fontSizes, spacing} from 'styles/variables'
import {useDocumentSigning} from './helpers'
import type {DocumentSigningConfig} from './types'

export interface SignDocumentProps {
  config: DocumentSigningConfig
  onSuccess?: () => void
  buttonProps?: ButtonProps
  showSkipButton?: boolean
}
export const SignDocument = ({
  config,
  onSuccess,
  buttonProps,
  showSkipButton,
}: SignDocumentProps) => {
  const [code, setCode] = useState<string>('')
  const {
    pendingSigning,
    sendCode,
    sign,
    openSign,
    closeSign,
    isSendingCode,
    isSigning,
  } = useDocumentSigning(config, onSuccess)

  const prompt = pendingSigning && (
    <Prompt
      opened
      header='Verify contract signing'
      content={
        <div>
          <OtpInput
            value={code}
            onChange={setCode}
            numInputs={6}
            containerStyle={{
              justifyContent: 'center',
            }}
            inputStyle={{
              marginInline: spacing.u2_5,
              paddingBlock: spacing.u5,
              paddingInline: spacing.u2_5,
              fontSize: fontSizes.bearSize,
            }}
            focusStyle={{
              outlineColor: colors.bostonBlue,
              background: colors.springWood,
            }}
            shouldAutoFocus
          />
        </div>
      }
      onCancel={closeSign}
      onConfirm={() => sign(code)}
      cancelText='Cancel'
      confirmText='Confirm'
      confirmDisabled={code.length !== 6}
      confirmHasActivity={isSigning}
    />
  )

  const {label, theme, ...rest} = buttonProps ?? {}
  return (
    <div>
      <Button
        {...rest}
        label={label || 'Send SMS code'}
        theme={theme || 'primary'}
        onClick={() => sendCode()}
        hasActivity={isSendingCode}
      ></Button>
      {Boolean(showSkipButton && 'signingUrl' in config) && (
        <Button onClick={() => openSign()} theme='text'>
          Already have a code?
        </Button>
      )}
      {prompt}
    </div>
  )
}
