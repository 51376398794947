import {useRouteMatch, Redirect, Route, Switch} from 'react-router-dom'
import {ContractSign} from 'app/external/ContractSign/ContractSign'

export const ExternalRouter = () => {
  const {path} = useRouteMatch()

  return (
    <>
      <Switch>
        <Route exact path={`${path}/contract-sign/:id`}>
          <ContractSign />
        </Route>
        <Redirect from='*' to='/' />
      </Switch>
    </>
  )
}
