import {useState} from 'react'
import type {DragStart, DropResult} from 'react-beautiful-dnd'
import {DragDropContext} from 'react-beautiful-dnd'
import {EmptyMessage, Spinner} from 'components/elements'
import type {FlowKind} from 'store/flow/types'
import type {ContractStatusType} from 'types/contract'
import {Column} from './Column'
import styles from './containerStyles.module.css'
import {useFlow} from './hooks'

interface FlowProps {
  kind: FlowKind
}

export const Flow = ({kind: flow}: FlowProps) => {
  const {columns, entityType, hasFilter, loading, updateEventStatus} =
    useFlow(flow)
  const [currentlyDragged, setCurrentlyDragged] = useState<DragStart | null>(
    null
  )

  const handleDragStart = (result: DragStart) => {
    setCurrentlyDragged(result)
  }

  const handleDragEnd = (result: DropResult) => {
    setCurrentlyDragged(null)

    if (!result.destination) {
      console.log('DROP OUTSIDE - IGNORE')
      return
    }

    if (result.destination.droppableId === result.source.droppableId) {
      console.log('DROP IN SAME COLUMN - IGNORE')
      return
    }

    updateEventStatus({
      id: parseInt(result.draggableId.replace('draggableId-', '')),
      nextStatus: result.destination.droppableId as ContractStatusType,
      prevStatus: result.source.droppableId as ContractStatusType,
    })
  }

  return (
    <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <section className={styles.root}>
        {loading && <Spinner overlay />}
        {!hasFilter && (
          <EmptyMessage
            title='Please select a filter from the menu above'
            className={styles.emptyMessage}
          />
        )}
        {!!hasFilter &&
          columns.map((column, index) => (
            <Column
              data={column}
              key={column.id}
              hasSpacer={index < columns.length - 1}
              currentlyDragged={currentlyDragged}
              entityType={entityType}
            />
          ))}
      </section>
    </DragDropContext>
  )
}
