import styled from 'styled-components'
import {Icon} from 'components/elements'
import {colors} from 'styles/variables'
import type {IconName} from '../Icon'

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`

const Inner = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: ${colors.secondaryTextColor};
  border: 1px solid ${colors.secondaryTextColor};
  border-radius: 999px;
`

export type AvatarKind = 'person' | 'organizer'

const getIcon = (kind: AvatarKind): IconName => {
  if (kind === 'organizer') {
    return 'phone'
  } else {
    return 'user'
  }
}

interface AvatarProps {
  size?: number
  kind: AvatarKind
  className?: string
}

export const Avatar: React.FC<React.PropsWithChildren<AvatarProps>> = ({
  size = 32,
  kind,
  className,
}) => {
  return (
    <Container className={className}>
      <Inner style={{width: `${size}px`, height: `${size}px`}}>
        <Icon icon={getIcon(kind)} size={Math.floor(size * 0.5)} />
      </Inner>
    </Container>
  )
}
