import {useContext} from 'react'
import {useDispatch} from 'react-redux'
import {EntityView} from 'app/modal/Entity'
import {ModalContext} from 'context/Modal'
import {
  newPerson as newPersonAction,
  newTour as newTourAction,
  showTour as showTourAction,
  showPerson as showPersonAction,
  showDepartment as showDepartmentAction,
  clearPerson,
  clearDepartment,
  clearOrganization,
  clearTour,
  newDepartment,
} from 'store/entity/actions'
import type {PersonNewParams} from 'store/entity/person/types'
import type {TourNewParams} from 'store/entity/tour/types'
import type {
  DepartmentType,
  Organizer,
  StorableDepartment,
} from 'types/department'

export const useEntity = () => {
  const dispatch = useDispatch()
  const {openModal} = useContext(ModalContext)

  const clearEntity = () => {
    dispatch(clearDepartment())
    dispatch(clearOrganization())
  }

  // Act
  const newAct = () => {
    dispatch(newDepartment({type: 'ACT'}))
    openModal(
      () => <EntityView kind='ACT' viewMode='new' />,
      () => clearEntity()
    )
  }
  const openAct = (id: number) => {
    dispatch(showDepartmentAction({id, kind: 'ACT'}))
    openModal(
      () => <EntityView kind='ACT' />,
      () => clearEntity()
    )
  }
  // Organizer
  const newOrganizer = (
    data: Partial<Organizer> = {},
    onCreateSuccess?: (response: StorableDepartment) => void
  ) => {
    dispatch(newDepartment({type: 'ORGANIZER', data, onCreateSuccess}))
    openModal(
      () => <EntityView kind='ORGANIZER' viewMode='new' />,
      () => clearEntity()
    )
  }
  const openOrganizer = (id: number, closeCallback?: () => void) => {
    dispatch(showDepartmentAction({id, kind: 'ORGANIZER'}))
    openModal(
      () => <EntityView kind='ORGANIZER' />,
      () => {
        if (closeCallback) {
          closeCallback()
        }
        clearEntity()
      }
    )
  }
  // Person
  const newPerson = (params: PersonNewParams) => {
    dispatch(newPersonAction(params))
    openModal(
      () => <EntityView kind='PERSON' viewMode='new' />,
      () => dispatch(clearPerson())
    )
  }
  const openPerson = (id: number) => {
    dispatch(showPersonAction({id}))
    openModal(
      () => <EntityView kind='PERSON' />,
      () => dispatch(clearPerson())
    )
  }
  // Venue
  const newVenue = () => {
    dispatch(newDepartment({type: 'VENUE'}))
    openModal(
      () => <EntityView kind='VENUE' viewMode='new' />,
      () => {
        clearEntity()
      }
    )
  }
  const openVenue = (id: number, closeCallback?: () => void) => {
    dispatch(showDepartmentAction({id, kind: 'VENUE'}))
    openModal(
      () => <EntityView kind='VENUE' />,
      () => {
        if (closeCallback) {
          closeCallback()
        }
        clearEntity()
      }
    )
  }
  // Tour
  const newTour = (params: TourNewParams) => {
    dispatch(newTourAction(params ?? {}))
    openModal(
      () => <EntityView kind='TOUR' viewMode='new' />,
      () => dispatch(clearTour())
    )
  }
  const openTour = (id: number) => {
    dispatch(showTourAction({id}))
    openModal(
      () => <EntityView kind='TOUR' />,
      () => dispatch(clearTour())
    )
  }
  // BOOKING AGENCY
  const openBookingAgency = (id: number) => {
    dispatch(showDepartmentAction({id, kind: 'BOOKING_AGENCY'}))
    openModal(
      () => <EntityView kind='BOOKING_AGENCY' />,
      () => clearEntity()
    )
  }
  // Open entity resolver
  const openEntity = (
    id: number,
    kind: DepartmentType,
    closeCallback?: () => void
  ) => {
    switch (kind) {
      case 'ACT':
        openAct(id)
        break
      case 'ORGANIZER':
        openOrganizer(id, closeCallback)
        break
      case 'PERSON':
        openPerson(id)
        break
      case 'VENUE':
        openVenue(id, closeCallback)
        break
      case 'TOUR':
        openTour(id)
        break
      case 'BOOKING_AGENCY':
        openBookingAgency(id)
    }
  }

  return {
    newAct,
    openAct,
    newOrganizer,
    openOrganizer,
    newPerson,
    openPerson,
    newVenue,
    openVenue,
    newTour,
    openTour,
    openBookingAgency,
    openEntity,
  }
}
