import styled from 'styled-components'
import type {EmptyMessageProps} from 'components/elements'
import {EmptyMessage as EmptyMessageComp} from 'components/elements'

const StyledEmptyMessageComp = styled(EmptyMessageComp)`
  align-self: center;
  margin: 0 auto;
`

export const EmptyMessage = (props: EmptyMessageProps) => (
  <StyledEmptyMessageComp {...props} />
)
