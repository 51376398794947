import {omitBy} from 'lodash'
import type {IconName} from 'components/elements'
import type {DepartmentType} from 'types/department'
import type {RoleType} from 'types/role'

export interface DepartmentTypeOption {
  name: string
  namePlural: string
  icon: IconName
  disabled?: boolean
}

export const DEPARTMENT_TYPE_OPTIONS_RAW: Record<
  DepartmentType,
  DepartmentTypeOption
> = {
  ORGANIZATION: {
    name: 'Company',
    namePlural: 'Companies',
    icon: 'earth',
    disabled: true,
  },
  BOOKING_AGENCY: {
    name: 'booking_agency',
    namePlural: 'booking_agencys',
    icon: 'pencil',
    disabled: false,
  },
  TOUR_MANAGEMENT: {
    name: 'Tour Management',
    namePlural: 'Tour Managements',
    icon: 'road',
    disabled: true,
  },
  ACT: {
    name: 'Act',
    namePlural: 'Acts',
    icon: 'microphone',
    disabled: false,
  },
  ORGANIZER: {
    name: 'Organizer',
    namePlural: 'Organizers',
    icon: 'phone',
    disabled: false,
  },
  VENUE: {
    name: 'Stage',
    namePlural: 'Stages',
    icon: 'speaker',
    disabled: false,
  },
  ACT_MANAGEMENT: {
    name: 'Act Management',
    namePlural: 'Act Managements',
    icon: 'promotion',
    disabled: true,
  },
  PERSON: {
    name: 'Person',
    namePlural: 'People',
    icon: 'user',
    disabled: false,
  },
  MISCELLANEOUS: {
    name: 'Miscellaneous',
    namePlural: 'Miscellaneous',
    icon: 'paper',
    disabled: true,
  },
  TOUR: {
    name: 'Tour',
    namePlural: 'Tours',
    icon: 'road',
    disabled: true,
  },
}

export const DEPARTMENT_TYPE_OPTIONS = omitBy(
  DEPARTMENT_TYPE_OPTIONS_RAW,
  (type) => type.disabled
)

type RoleTypesForDepartment = {
  [key in DepartmentType]?: RoleType[]
}

export const ROLE_TYPES_FOR_DEPARTMENT: RoleTypesForDepartment = {
  BOOKING_AGENCY: [
    'BOOKING_AGENCY_BOOKER',
    'BOOKING_AGENCY_ACCOUNTANT',
    'BOOKING_AGENCY_ADMIN',
  ],
  TOUR_MANAGEMENT: ['TOUR_MANAGEMENT_STAFF'],
  ACT: ['ACT_MEMBER', 'ACT_BOOKING_AGENCY_BOOKER'],
  ORGANIZER: ['ORGANIZER_STAFF'],
  VENUE: ['VENUE_STAFF'],
}
