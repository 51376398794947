import {useEffect, useState} from 'react'
import {mq} from 'styles/variables'

export const useIsDesktop = () => {
  const mm = window.matchMedia(`(${mq.desktop})`)
  const [isDesktop, setIsDesktop] = useState(mm.matches)

  const handleChange = (e: MediaQueryListEvent) => {
    setIsDesktop(e.matches)
  }

  useEffect(() => {
    mm.addEventListener('change', handleChange)
    return () => {
      mm.removeEventListener('change', handleChange)
    }
  }, [mm])

  return isDesktop
}
