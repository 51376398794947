import {createContext, useEffect, useRef, useState} from 'react'
import styled, {keyframes} from 'styled-components'

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`

interface OverlayProps {
  show: boolean
}

const Overlay = styled.div<OverlayProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: var(--modal);
  display: grid;
  grid-template-columns: minmax(40px, 1fr) minmax(768px, 1200px) minmax(
      40px,
      1fr
    );
  grid-template-rows: minmax(40px, 1fr) minmax(300px, 1000px) minmax(40px, 1fr);
  justify-content: center;
  /* background: color(var(--desertStorm) alpha(60%)); */
  background: rgba(242, 241, 240, 0.6);
  animation-name: ${({show}) => (show ? fadeIn : fadeOut)};
  animation-duration: 250ms;
  animation-fill-mode: forwards;
`

const Wrapper = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  display: flex;
  justify-content: center;
`

interface MainProps {
  archived: boolean
}

const Main = styled.div<MainProps>`
  flex: 0 1 768px;
  background: var(--white);
  box-shadow: 0 0 50px 0 rgba(85, 84, 89, 0.5);
  transition: left 250ms ease-in-out, transform 250ms ease-in-out;
  transform: translateX(0);

  ${({archived}) => archived && `transform: translateX(-100%);`}
`

interface ModalInstanceContextProps {
  inModal: boolean
  active: boolean
  archived: boolean
  closeModal: () => void
}

export const ModalInstanceContext = createContext<ModalInstanceContextProps>({
  inModal: false,
  active: false,
  archived: false,
  closeModal: () => undefined,
})

export interface ModalContainerProps {
  show: boolean
  archived: boolean
  onClose?: () => void
  onClosed?: () => void
}

export const ModalContainer: React.FC<
  React.PropsWithChildren<ModalContainerProps>
> = ({children, show, archived, onClose, onClosed}) => {
  const _ref = useRef<HTMLDivElement>(null)
  const [render, setRender] = useState(false)

  const active = show && !archived

  const handleAnimationEnd = () => {
    if (!show) {
      setRender(false)
      if (onClosed) {
        onClosed()
      }
    }
  }

  // const handleClose = () => {
  //   if (active && onClose) {
  //     onClose()
  //   }
  // }

  // useClickOutside(_ref, active, handleClose)
  // useKeyPress(_ref, active, 'Escape', handleClose)

  useEffect(() => {
    if (show) {
      setRender(true)
    }
  }, [show])

  if (!render) {
    return null
  }

  const instanceContextValue = {
    inModal: true,
    active,
    archived,
    closeModal: () => {
      if (onClose) {
        onClose()
      }
    },
  }

  return (
    <ModalInstanceContext.Provider value={instanceContextValue}>
      <Overlay
        show={show}
        onAnimationEnd={handleAnimationEnd}
        className='modalContainer'
      >
        <Wrapper>
          <Main ref={_ref} archived={archived}>
            {children}
          </Main>
        </Wrapper>
      </Overlay>
    </ModalInstanceContext.Provider>
  )
}
