import {ItemList} from 'components/form'
import {EmailAddress, PhoneNumber} from 'components/form/templates'
import {Section, Column, Row} from 'containers/Form'

interface BaseFormSectionContactProps {
  disabled?: boolean
}

export const Contact: React.FC<
  React.PropsWithChildren<BaseFormSectionContactProps>
> = ({disabled}) => (
  <Section label='Contact'>
    <Row>
      <Column>
        <ItemList
          model='business_card.phone_numbers'
          disabled={disabled}
          itemRenderer={PhoneNumber}
          itemWidth={380}
        />
      </Column>
    </Row>

    <Row>
      <Column>
        <ItemList
          model='business_card.email_addresses'
          disabled={disabled}
          itemRenderer={EmailAddress}
          itemWidth={250}
        />
      </Column>
    </Row>
  </Section>
)
