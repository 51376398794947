import {put, race, take, takeLatest} from 'redux-saga/effects'
import {standardReducer} from 'helpers/reducers'

export const PROMPT_OPEN = 'Propmt/OPEN'
export const PROMPT_CONFIRM = 'Propmt/CONFIRM'
export const PROMPT_CANCEL = 'Propmt/CANCEL'
export const PROMPT_RESOLVE = 'Propmt/RESOLVE'
export const PROMPT_REJECT = 'Propmt/REJECT'

const initialState = {
  opened: false,
  kind: 'confirm',
  title: '',
  description: '',
  showInput: false,
  inputPlaceholder: '',
  confirmText: 'Confirm',
  cancelText: 'Cancel',
}

export const prompt = (payload) => ({
  type: PROMPT_OPEN,
  payload,
  meta: {thunk: true},
})

export const openPrompt = () => ({type: PROMPT_OPEN})
export const confirmPrompt = (inputValue) => ({
  type: PROMPT_CONFIRM,
  inputValue,
})
export const cancelPrompt = () => ({type: PROMPT_CANCEL})

const ACTION_HANDLERS = {
  [PROMPT_OPEN]: (state, action) => {
    return Object.assign({}, initialState, action.payload, {
      opened: true,
    })
  },

  [PROMPT_CONFIRM]: () => initialState,
  [PROMPT_CANCEL]: () => initialState,
}

export const promptReducer = standardReducer(initialState, ACTION_HANDLERS)

function* handlePrompt(action) {
  const {payload, meta} = action

  try {
    const result = yield race({
      confirmed: take(PROMPT_CONFIRM),
      cancelled: take(PROMPT_CANCEL),
    })

    if (!result.confirmed) {
      throw new Error('prompt rejected')
    }
    yield put({
      type: PROMPT_RESOLVE,
      payload: {...payload, inputValue: result.confirmed.inputValue},
      meta,
    })
  } catch (e) {
    yield put({type: PROMPT_REJECT, payload, error: true, meta})
  }
}

export function* promptSaga() {
  yield takeLatest(PROMPT_OPEN, handlePrompt)
}
