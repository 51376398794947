import {
  SET_ACT,
  SET_MONTH,
  SET_START_MONTH,
  SET_END_MONTH,
  SET_SHOW_KIND,
  SET_SORT,
} from './constants'

export const setAct = ({id, view}) => ({
  type: SET_ACT,
  id,
  view,
})

export const setMonth = ({month, view}) => ({
  type: SET_MONTH,
  month,
  view,
})

export const setStartMonth = ({month, view}) => ({
  type: SET_START_MONTH,
  month,
  view,
})

export const setEndMonth = ({month, view}) => ({
  type: SET_END_MONTH,
  month,
  view,
})

export const setShowKind = ({kind, view}) => ({
  type: SET_SHOW_KIND,
  kind,
  view,
})

export const setSort = ({sortBy, sortDirection, view}) => ({
  type: SET_SORT,
  sortBy,
  sortDirection,
  view,
})
