import type {ReactNode} from 'react'
import {Children, cloneElement, forwardRef} from 'react'
import type {DocumentDownloadProps} from 'hooks/useDocumentDownload'
import {useDocumentDownload} from 'hooks/useDocumentDownload'

export interface DocumentDownloaderRenderProps {
  // ref: ForwardedRef<any>
  onClick: () => void
  hasActivity: boolean
  disabled: boolean
}

export interface DocumentDownloaderProps extends DocumentDownloadProps {
  children: ((props: DocumentDownloaderRenderProps) => JSX.Element) | ReactNode
  disabled?: boolean
}

export const DocumentDownloader = forwardRef<any, DocumentDownloaderProps>(
  (
    {
      children,
      directFileUrl,
      url,
      urlParams,
      queryParams,
      queryParamsFormatter,
      method,
      successCallback,
      failureCallback,
      toasts,
      disabled,
    },
    ref
  ) => {
    const {fetchPDF, isFetching} = useDocumentDownload()

    const localDownloadKey = directFileUrl ?? url

    const isDisabled = () => {
      if (disabled !== undefined) {
        return disabled
      }
      return isFetching
    }

    const handleClick = () => {
      if (isDisabled()) {
        return
      }

      fetchPDF({
        directFileUrl,
        url,
        urlParams,
        queryParams,
        queryParamsFormatter,
        method,
        successCallback,
        failureCallback,
        toasts,
      })
    }

    if (typeof children === 'function') {
      return children({
        onClick: handleClick,
        hasActivity: isFetching,
        disabled: isDisabled(),
      })
    }

    const child = Children.only(children)

    if (!child) {
      return null
    }

    if (typeof child === 'string' || typeof child === 'number') {
      return (
        <div
          ref={ref as React.ForwardedRef<HTMLDivElement>}
          onClick={handleClick}
        >
          {child}
        </div>
      )
    }

    if (typeof child === 'object' && 'type' in child) {
      return cloneElement(child, {
        ref: ref,
        onClick: handleClick,
        hasActivity: isFetching,
        disabled: isDisabled(),
      })
    }

    return null
  }
)

DocumentDownloader.displayName = 'DocumentDownloader'
