import {createQueryKeys} from '@lukemorales/query-key-factory'
import {api} from 'api/api'

export const organizers = createQueryKeys('organizers', {
  list: {
    queryKey: null,
    queryFn: () => api.organizers.list(),
  },
  show: (id: number) => ({
    queryKey: [id],
    queryFn: () => api.organizers.show(id),
  }),
})
