import {createRef, useEffect, useState} from 'react'
import {isNumber, clamp} from 'lodash'
import {Container, HourInput, InputDivider, MinuteInput} from './components'

const prefix = (val: number): string => {
  return val < 10 ? `0${val}` : val.toString()
}

interface TimePickerProps {
  value: string
  onChange: (newValue: string) => void
  disabled?: boolean
}

export const TimePicker: React.FC<React.PropsWithChildren<TimePickerProps>> = ({
  value: valueFromProps,
  onChange,
  disabled,
}) => {
  const [hour, setHour] = useState('20')
  const [minute, setMinute] = useState('20')

  const hourInput = createRef<HTMLInputElement>()

  const setValue = (value: string) => {
    const [hour, minute] = value.split(':')
    setHour(hour)
    setMinute(minute)
  }

  const handleChange = (hour: string, minute: string) => {
    if (onChange) {
      onChange(`${hour}:${minute}`)
    }
  }

  const handleHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const raw = e.target.value
    const newValue = prefix(clamp(isNumber(raw) ? raw : parseInt(raw), 0, 23))
    setHour(newValue)
    handleChange(newValue, minute)
  }

  const handleMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const raw = e.target.value
    const newValue = prefix(clamp(isNumber(raw) ? raw : parseInt(raw), 0, 59))
    setMinute(newValue)
    handleChange(hour, newValue)
  }

  useEffect(() => {
    if (valueFromProps) {
      setValue(valueFromProps)
    }
  }, [valueFromProps])

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (disabled) {
      return
    }
    // The input focus overrides the global focus,
    // so dont propagate
    e.preventDefault()
    e.stopPropagation()
    e.target.select()
  }

  return (
    <Container disabled={!!disabled}>
      <HourInput
        ref={hourInput}
        value={hour}
        type='number'
        min='0'
        max='23'
        onFocus={handleInputFocus}
        onChange={handleHourChange}
        disabled={!!disabled}
      />
      <InputDivider>:</InputDivider>
      <MinuteInput
        value={minute}
        type='number'
        min='0'
        max='59'
        step='5'
        onFocus={handleInputFocus}
        onChange={handleMinuteChange}
        disabled={!!disabled}
      />
    </Container>
  )
}
