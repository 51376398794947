import type {CSSProperties} from 'react'
import cx from 'classnames'
import isFunction from 'lodash/isFunction'
import merge from 'lodash/merge'
import {Icon, Button, Tooltip} from 'components/elements'
import {ENTITY_CARD_OPTIONS} from 'options/entityCardTypes'
import type {
  EntityCardData,
  EntityCardType,
  EntityCardTypeActive,
} from 'types/entityCard'
import cardStyles from './EntityCardCard.module.css'
import listStyles from './EntityCardList.module.css'

function isValidCard(type: EntityCardType): type is EntityCardTypeActive {
  switch (type) {
    case 'PERSON':
    case 'LIST_PERSON':
    case 'ACT':
    case 'ORGANIZER':
    case 'BOOKING_AGENCY':
    case 'VENUE':
    case 'ACT_ADMIN':
    case 'ACT_MEMBER':
    case 'ACT_BOOKING_AGENCY_BOOKER':
    case 'BOOKING_AGENCY_ADMIN':
    case 'BOOKING_AGENCY_BOOKER':
    case 'BOOKING_AGENCY_ACCOUNTANT':
    case 'ORGANIZER_ADMIN':
    case 'ORGANIZER_STAFF':
    case 'TOUR_MANAGEMENT_ADMIN':
    case 'TOUR_MANAGEMENT_STAFF':
    case 'VENUE_ADMIN':
    case 'VENUE_STAFF':
    case 'PRODUCTION_EVENT':
    case 'BOOKING_STATUS_EVENT':
      return true
    default:
      return false
  }
}

const getOptions = ({
  options,
  entityType,
}: {
  options: unknown
  entityType: EntityCardTypeActive
}) => {
  const entityCardOptions = ENTITY_CARD_OPTIONS[entityType]

  return merge({}, entityCardOptions, options)
}

interface EntityCardProps {
  entityType: EntityCardType | ((item: EntityCardData) => EntityCardType)
  cardType?: 'card' | 'list' | (() => 'card' | 'list')
  className?: string
  style?: CSSProperties
  data: EntityCardData
  options?: Record<string, unknown>
  onClick?: (item: EntityCardData) => void
}

export const EntityCard: React.FC<React.PropsWithChildren<EntityCardProps>> = ({
  entityType: _entityType,
  cardType = 'card',
  className,
  style,
  data,
  options,
  onClick,
}) => {
  const entityType = isFunction(_entityType) ? _entityType(data) : _entityType

  if (!isValidCard(entityType)) {
    return null
  }

  const styles = cardType === 'list' ? listStyles : cardStyles
  const opts = getOptions({options, entityType})

  const renderIcon = () => {
    const {icon} = opts
    // TODO: Handle actual icon and image
    return (
      <div className={styles.icon}>
        <Icon icon={icon.default} />
      </div>
    )
  }

  const renderLine = (lineKey: any, lineOptions: any) => {
    if (!lineOptions) {
      return null
    }

    const text = lineOptions.text(data)

    if (!text) {
      return null
    }

    return (
      <div className={cx(styles.line, styles[`line-${lineKey}`])}>{text}</div>
    )
  }

  const renderContent = () => {
    const {lines} = opts
    return (
      <>
        {renderLine('primary', lines.primary)}
        {renderLine('secondary', lines.secondary)}
        {renderLine('tertiary', lines.tertiary)}
      </>
    )
  }

  const renderPositionedItem = (
    itemType: any,
    itemKey: any,
    itemOptions: any
  ) => {
    // If the item is not present, return null
    if (!itemOptions) {
      return null
    }
    // If the items should not be visible, return null
    if (!itemOptions.visible || !itemOptions.visible(data)) {
      return null
    }
    const style = isFunction(itemOptions.style)
      ? itemOptions.style(data)
      : itemOptions.style

    const classNames = cx(styles[itemType], styles[`${itemType}-${itemKey}`], {
      [styles[`pItem-style-${style}`]]: style,
    })
    const text = isFunction(itemOptions.text)
      ? itemOptions.text(data)
      : itemOptions.text

    return (
      <div className={classNames}>
        <div className={styles[`${itemType}-content`]}>
          {itemOptions.icon && (
            <Tooltip content={itemOptions.tooltip(data)} maxWidth={200}>
              <Icon icon={itemOptions.icon} size='10' />
            </Tooltip>
          )}
          {text && <span>{text}</span>}
        </div>
      </div>
    )
  }

  const renderCorners = () => {
    const {corners} = opts
    if (!corners) {
      return null
    }
    return (
      <>
        {renderPositionedItem('corner', 'left', corners.left)}
        {renderPositionedItem('corner', 'right', corners.right)}
      </>
    )
  }

  const renderRibbons = () => {
    const {ribbons} = opts
    if (!ribbons) {
      return null
    }
    return (
      <>
        {renderPositionedItem('ribbon', 'left', ribbons.left)}
        {renderPositionedItem('ribbon', 'right', ribbons.right)}
      </>
    )
  }

  const renderAction = (actionKey: any, actionOptions: any) => {
    if (!actionOptions) {
      return null
    }

    const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
      actionOptions.action()
    }

    return (
      <div className={cx(styles.action, styles[`action-${actionKey}`])}>
        <Button
          size='small'
          round
          icon={actionOptions.icon}
          onClick={handleClick}
        />
      </div>
    )
  }

  const renderActions = () => {
    const {actions} = opts
    if (!actions) {
      return null
    }
    return (
      <>
        {renderAction('topLeft', actions.topLeft)}
        {renderAction('topRight', actions.topRight)}
        {renderAction('bottomLeft', actions.bottomLeft)}
        {renderAction('bottomRight', actions.bottomRight)}
      </>
    )
  }

  const isClickable = !!onClick

  return (
    <div
      className={cx(
        styles.container,
        'whnue-entityCard',
        `whnue-entityCard__${cardType}`,
        {
          [styles.isClickable]: isClickable,
        },
        className
      )}
      onClick={() => (onClick ?? (() => undefined))(data)}
      style={style}
    >
      {renderIcon()}
      {renderContent()}
      {renderCorners()}
      {renderRibbons()}
      {renderActions()}
    </div>
  )
}
