import {standardReducer} from 'helpers/reducers'
import {INIT_SUCCESS, CREATE_SUCCESS, CLEAR} from '../constants'

const initialState = false

export const visibleReducer = standardReducer(initialState, {
  [INIT_SUCCESS]: () => true,
  [CLEAR]: () => false,
  [CREATE_SUCCESS]: () => false,
})
