import {useFormikContext} from 'formik'
import {Button, Tooltip} from 'components/elements'
import type {InlineContentProps} from 'containers/Form'
import {InlineContent} from 'containers/Form'
import {useEntity} from 'hooks/useEntity'
import {queryClient} from 'main'
import type {Contract} from 'types/contract'

export const ViewVenue: React.FC<
  React.PropsWithChildren<InlineContentProps>
> = (props) => {
  const {values, getFieldProps} = useFormikContext<Contract>()
  const {openVenue} = useEntity()
  const venueId = values.show.stakeholder_venue?.department.id

  const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    if (!venueId) {
      return
    }
    openVenue(venueId, () => {
      const organizerId = getFieldProps(
        'show.stakeholder_organizer.department.id'
      ).value
      if (organizerId) {
        queryClient.invalidateQueries(['organizer', organizerId])
      }
    })
  }

  return (
    <InlineContent {...props} aligned>
      <Tooltip content='View stage'>
        <Button
          onClick={handleClick}
          disabled={!venueId}
          modal
          round
          theme='light'
          icon='new-window'
        />
      </Tooltip>
    </InlineContent>
  )
}
