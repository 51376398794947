import {Spinner} from 'components/elements'
import {Container} from 'containers/Core'
import {Main} from './elements'
import {useSingleMonthsInColumns} from './hooks'
import {
  ViewHeader,
  SingleActPriorityMenu,
  MonthRangePicker,
  EmptyMessage,
} from '../components'
import {MonthsInColumnsNew} from '../views'

export const SingleMonthsInColumns = () => {
  const {viewKey, isLoading, grid, actId} = useSingleMonthsInColumns()

  return (
    <Container>
      <ViewHeader filters={<SingleActPriorityMenu />}>
        <MonthRangePicker />
      </ViewHeader>

      <Main flex disabled={!actId}>
        {isLoading && <Spinner overlay />}
        {actId && <MonthsInColumnsNew viewKey={viewKey} grid={grid} />}
        {!actId && (
          <EmptyMessage title='Please choose an act from the menu above' />
        )}
      </Main>
    </Container>
  )
}
