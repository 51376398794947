import {getApiUrl} from 'api/urls'
import {apiFetch} from 'lib/fetch'
import {DEPARTMENT_TYPE_OPTIONS} from 'options/departmentTypes'
import type {SearchResult} from 'types/searchResult'

export interface FullSearchProps {
  filterText?: string
  filterTypes?: string[]
  includeInactive?: boolean
}

export interface FullSearchQueryParams {
  q?: string
  type?: string
  include_inactive?: boolean
}

export function getFullSearchQueryParams(
  props: FullSearchProps
): FullSearchQueryParams {
  return {
    q: props.filterText,
    type: props?.filterTypes?.length
      ? props.filterTypes.join(',')
      : Object.keys(DEPARTMENT_TYPE_OPTIONS).join(','),
    include_inactive: props.includeInactive,
  }
}

export const fullSearch = async (
  props: FullSearchProps
): Promise<SearchResult[]> => {
  return apiFetch.get<SearchResult[]>(
    getApiUrl('search'),
    getFullSearchQueryParams(props)
  )
}
