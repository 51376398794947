import {fork} from 'redux-saga/effects'
import {destroySaga} from './sagas/destroy'
import {initializeSaga} from './sagas/initialize'
import {saveSaga} from './sagas/save'
import {showSaga} from './sagas/show'
import {stateChangeSaga} from './sagas/stateChange'

export function* contractSaga() {
  yield fork(showSaga)
  yield fork(initializeSaga)
  yield fork(saveSaga)
  yield fork(destroySaga)
  yield fork(stateChangeSaga)
}
