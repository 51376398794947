import {toFixed} from 'helpers/currency'
import type {Contract, ContractMediatorCommission} from 'types/contract'

const DEFAULT_VAT = 0.25

export interface AbsoluteCalculatedCommission
  extends Omit<ContractMediatorCommission, 'relative_commission'> {
  relativeCommission: number
}

interface AbsoluteBookingContractCommission
  extends Omit<ContractMediatorCommission, 'relative_commission'> {
  absolute_commission: number | null
}
export interface AbsoluteBudgetCalculatorResult {
  relativeCommission: number
  mediatorCommissions: AbsoluteCalculatedCommission[]
  internalRelativeCommission: number
  internalAbsoluteCommission: number
  vatFee: number
  vatCommission: number
  vat: number
  feeAct: number
  total: number
}

export const absoluteBudgetCalculator = (
  input: Contract,
  VAT = DEFAULT_VAT
): AbsoluteBudgetCalculatorResult => {
  const relativeCommission: number = toFixed(
    ((input.absolute_commission ?? 0) / input.fee) * 100,
    2
  )

  const mediatorCommissions: AbsoluteCalculatedCommission[] = (
    input.mediator_commissions || []
  ).map((val: AbsoluteBookingContractCommission) => ({
    name: val.name,
    absolute_commission: val.absolute_commission,
    relativeCommission: toFixed(
      ((val.absolute_commission ?? 0) / input.fee) * 100,
      2
    ),
  }))

  const internalAbsoluteCommission: number =
    (input.absolute_commission ?? 0) -
    (input.mediator_commissions || []).reduce(
      (sum, obj) => sum + (obj.absolute_commission ?? 0),
      0
    )

  const internalRelativeCommission: number = toFixed(
    (internalAbsoluteCommission / input.fee) * 100,
    2
  )

  // VAT for fee needs to be calculated with commission
  // subtracted from the fee, otherwise the commission VAT
  // is included two times
  const vatFee: number = toFixed(
    input.fee_has_vat
      ? input.commission_included_in_fee
        ? (input.fee - (input.absolute_commission ?? 0)) * VAT
        : input.fee * VAT
      : 0,
    2
  )

  const vatCommission: number = toFixed(
    input.commission_has_vat ? (input.absolute_commission ?? 0) * VAT : 0,
    2
  )

  const vat: number = toFixed(vatFee + vatCommission, 2)

  const feeAct: number = toFixed(
    input.commission_included_in_fee
      ? input.fee - (input.absolute_commission ?? 0)
      : input.fee,
    2
  )

  const total: number = toFixed(
    input.commission_included_in_fee
      ? input.fee + vat
      : input.fee + (input.absolute_commission ?? 0) + vat,
    2
  )

  return {
    relativeCommission,
    mediatorCommissions,
    internalRelativeCommission,
    internalAbsoluteCommission,
    vatFee,
    vatCommission,
    vat,
    feeAct: feeAct,
    total,
  }
}

export interface RelativeCalculatedCommission
  extends Omit<ContractMediatorCommission, 'absolute_commission'> {
  absoluteCommission: number
}

interface RelativeBookingContractCommission
  extends Omit<ContractMediatorCommission, 'absolute_commission'> {
  relative_commission: number | null
}

interface RelativeBudgetCalculatorProps {
  fee: number
  relative_commission: number | null
  mediator_commissions?: RelativeBookingContractCommission[]
  fee_has_vat: boolean
  commission_included_in_fee: boolean
  commission_has_vat: boolean
}

export interface RelativeBudgetCalculatorResult {
  absoluteCommission: number
  mediatorCommissions: RelativeCalculatedCommission[]
  internalRelativeCommission: number
  internalAbsoluteCommission: number
  vatFee: number
  vatCommission: number
  vat: number
  feeAct: number
  total: number
}

export const relativeBudgetCalculator = (
  input: RelativeBudgetCalculatorProps,
  VAT = DEFAULT_VAT
): RelativeBudgetCalculatorResult => {
  const absoluteCommission: number = toFixed(
    input.fee * ((input.relative_commission ?? 0) / 100),
    2
  )

  const mediatorCommissions: RelativeCalculatedCommission[] = (
    input.mediator_commissions || []
  ).map((val: RelativeBookingContractCommission) => ({
    name: val.name,
    relative_commission: val.relative_commission,
    absoluteCommission: toFixed(
      input.fee * ((val.relative_commission ?? 0) / 100),
      2
    ),
  }))

  const internalRelativeCommission: number =
    (input.relative_commission ?? 0) -
    (input.mediator_commissions || []).reduce(
      (sum, obj) => sum + (obj.relative_commission ?? 0),
      0
    )

  const internalAbsoluteCommission: number = toFixed(
    input.fee * (internalRelativeCommission / 100),
    2
  )

  // VAT for fee needs to be calculated with commission
  // subtracted from the fee, otherwise the commission VAT
  // is included two times
  const vatFee: number = toFixed(
    input.fee_has_vat
      ? input.commission_included_in_fee
        ? (input.fee - absoluteCommission) * VAT
        : input.fee * VAT
      : 0,
    2
  )

  const vatCommission: number = toFixed(
    input.commission_has_vat ? absoluteCommission * VAT : 0,
    2
  )

  const vat: number = toFixed(vatFee + vatCommission, 2)

  const feeAct: number = toFixed(
    input.commission_included_in_fee
      ? input.fee - absoluteCommission
      : input.fee,
    2
  )

  const total: number = toFixed(
    input.commission_included_in_fee
      ? input.fee + vat
      : input.fee + absoluteCommission + vat,
    2
  )

  return {
    absoluteCommission,
    mediatorCommissions,
    internalRelativeCommission,
    internalAbsoluteCommission,
    vatFee,
    vatCommission,
    vat,
    feeAct: feeAct,
    total,
  }
}
