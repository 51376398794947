import {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import {Button, HeaderText, Spinner, Visible} from 'components/elements'
import {
  Container as ContainerComp,
  Header,
  HeaderLeft,
  Filters,
  Main as MainComp,
  Footer as FooterComp,
} from 'containers/Core'
import {useModal} from 'hooks/useModal'
import {create, setRecipients, setSubject, setBody} from 'store/email/actions'
import type {EmailRecipient} from 'store/email/types'
import {colors, spacing} from 'styles/variables'
import {Attachments} from './Attachments'
import {Body} from './Body'
import {useEmail} from './helpers'
import {Recipients} from './Recipients'
import {Signature as SignatureComp} from './Signature'
import {Subject} from './Subject'

const Container = styled(ContainerComp)`
  width: 900px;
`

const RecipientsWrapper = styled(Filters)`
  z-index: 21;
`
const SubjectWrapper = styled(Filters)`
  z-index: 20;
`

const Main = styled(MainComp)`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  position: relative;
`

const ContentWrapper = styled.div`
  overflow-y: scroll;

  & > * {
    border-top: 1px dotted ${colors.borderColor};
  }
`

const Signature = styled(SignatureComp)`
  border-top: 1px dotted ${colors.borderColor};
`

const AttachmentsWrapper = styled(Filters)`
  border-top: 1px dotted ${colors.borderColor};
`

const Footer = styled(FooterComp)`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: ${spacing.u5};
`

const Actions = styled.div`
  display: flex;
  flex-flow: row nowrap;

  & > :not(:last-child) {
    margin-right: ${spacing.u2_5};
  }
`

export const Email = () => {
  const {closeModal} = useModal()
  const [isSending, setIsSending] = useState(false)
  const dispatch = useDispatch()

  const {loading, ui, values, options, errors, actions, sending, visible} =
    useEmail()

  const handleRecipientsChange = (value: EmailRecipient) =>
    dispatch(setRecipients(value))
  const handleSubjectChange = (value: string) => dispatch(setSubject(value))
  const handleBodyChange = (value: string) => dispatch(setBody(value))
  const handleSubmit = (action: string) => {
    dispatch(create({action}))
    setIsSending(true)
  }
  const handleClose = useCallback(() => closeModal(), [closeModal])

  useEffect(() => {
    if (isSending) {
      if (!sending) {
        if (!visible) {
          handleClose()
        } else {
          setIsSending(false)
        }
      }
    }
  }, [isSending, sending, visible, handleClose])

  return (
    <Container>
      <Visible ifTrue={loading}>
        <Spinner />
      </Visible>

      <Visible ifFalse={loading}>
        <Header>
          <HeaderLeft>
            <HeaderText
              primary={ui.headerPrimary}
              secondary={ui.headerSecondary}
              twoLines
            />
          </HeaderLeft>
        </Header>

        <RecipientsWrapper bg='white' shadow={false}>
          <Recipients
            value={values.recipients}
            onChange={handleRecipientsChange}
            options={options.recipients}
            error={errors.recipients ?? undefined}
          />
        </RecipientsWrapper>
        <SubjectWrapper bg='white'>
          <Subject value={values.subject} onChange={handleSubjectChange} />
        </SubjectWrapper>

        <Main>
          <ContentWrapper>
            <Body initialValue={values.html_body} onChange={handleBodyChange} />
            <Signature value={options.signature} />
          </ContentWrapper>
        </Main>

        {values.attachments && (
          <AttachmentsWrapper bg='white' shadow={false}>
            <Attachments attachments={values.attachments} />
          </AttachmentsWrapper>
        )}

        <Footer>
          <Button onClick={handleClose}>Cancel</Button>
          <Actions>
            {actions.map(({action, name, buttonProps}) => (
              <Button
                key={action}
                {...buttonProps}
                onClick={() => handleSubmit(action)}
                hasActivity={sending}
              >
                {name}
              </Button>
            ))}
          </Actions>
        </Footer>
      </Visible>
    </Container>
  )
}
