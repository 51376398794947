import cx from 'classnames'
import styles from './container.module.css'

interface FiltersProps {
  className?: string
  bg?: 'standard' | 'white'
  shadow?: boolean
}

export const Filters: React.FC<React.PropsWithChildren<FiltersProps>> = ({
  className,
  children,
  bg = 'standard',
  shadow = true,
}) => (
  <section className={cx(className, styles.filters, `bg-${bg}`, {shadow})}>
    {children}
  </section>
)
