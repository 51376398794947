import {useContext} from 'react'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import {Button, SearchDropdown} from 'components/elements'
import {newPerson} from 'store/entity/actions'
import {trimAll} from 'utils'
import {AffiliationSectionContext} from './context'
import {useSearch} from './hooks'

const Container = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
`

export const AffiliationSectionSearch = () => {
  const dispatch = useDispatch()
  const {roleType, showSearch, setView} = useContext(AffiliationSectionContext)
  const {
    query,
    setQuery,
    results,
    onSelect,
    showAllResults,
    minSearchLength,
    showNewPersonButton,
  } = useSearch(roleType)

  if (!showSearch) {
    return null
  }

  const handleNewPerson = () => {
    const [first, ...rest] = trimAll(query).split(' ')

    dispatch(
      newPerson({
        business_card: {given_name: first, family_name: rest.join(' ')},
      })
    )
    setView('new')
  }

  const handleCancel = () => {
    setQuery('')
    setView('show')
  }

  return (
    <Container>
      <SearchDropdown
        value={query}
        onSearch={setQuery}
        onClick={(value) => onSelect(value.id)}
        results={results}
        autoFocus
        showAllResults={showAllResults}
        minSearchLength={minSearchLength}
        onBlur={handleCancel}
        footer={
          showNewPersonButton
            ? () => (
                <Button
                  onClick={handleNewPerson}
                  width='fill'
                  theme='text'
                  label='Create new person'
                />
              )
            : undefined
        }
      />
    </Container>
  )
}
