import {isAfter} from 'date-fns'
import styled from 'styled-components'
import {usePlanner} from 'api'
import {Button, ButtonGroup} from 'components/elements'
import {
  Form as FormComp,
  DatePicker,
  Toggle,
  Select,
  MyActsSelect,
} from 'components/form'
import {
  Container,
  Section,
  Column,
  Row,
  InlineText,
  InlineContent,
} from 'containers/Form'
import type {DateRangeType} from 'types/date'
import type {PlannerEvent} from 'types/planner'
import type {ReportCardParameters} from 'types/reportCards'
import {formatDate} from 'utils'
import type {ReportsExportParams} from './types'

const Form = styled(FormComp)`
  flex: 1 1 100%;
  overflow-y: scroll;
`

export interface ReportsViewOptionsProps {
  onChange: (params: Partial<ReportsExportParams>) => void
  parameters: ReportCardParameters[]
  actId: number | null
  actIds: number[] | null
  tourId: number | null
  startDay: string | null
  endDay: string | null
  dateRangeType: DateRangeType | null
}

const DATE_RANGE_TYPES: DateRangeType[] = ['day', 'month', 'quarter', 'year']

const datePickerProps = (type: DateRangeType | null, end: boolean): any => {
  const label = end ? 'To (and including)' : 'From (and including)'
  switch (type) {
    case 'month':
      return {
        label: label,
        placeholder: end ? 'Select end month...' : 'Select start month...',
        showMonthYearPicker: true,
        dateFormat: 'MMMM yyyy',
      }
    case 'quarter':
      return {
        label: label,
        placeholder: end ? 'Select end quarter...' : 'Select start quarter...',
        showQuarterYearPicker: true,
        dateFormat: 'QQQQ, yyyy',
      }
    case 'year':
      return {
        label: label,
        placeholder: end ? 'Select end year...' : 'Select start year...',
        showYearPicker: true,
        dateFormat: 'yyyy',
      }
    case 'day':
    default:
      return {
        label: label,
        placeholder: end ? 'Select end day...' : 'Select start day...',
      }
  }
}

export const ReportsViewOptions: React.FC<
  React.PropsWithChildren<ReportsViewOptionsProps>
> = ({
  actId,
  actIds,
  parameters,
  tourId,
  onChange,
  startDay,
  endDay,
  dateRangeType,
}) => {
  const showAct = parameters.includes('act')
  const showActs = parameters.includes('acts')
  const showDayRange = parameters.includes('dayrange')
  const showTour = parameters.includes('tour')
  const showShowsOnly = parameters.includes('showsOnly')

  const hasActId = showAct ? !!actId : true

  const actIdsForTour =
    showAct && actId
      ? [actId]
      : showActs && actIds?.length === 1
      ? actIds
      : undefined

  const {data: tours} = usePlanner(
    {
      eventTypes: 'tours',
      actIds: actIdsForTour,
    },
    (props) => {
      const rtn = Array.isArray(props.actIds) && !!props.actIds.length
      console.log('tours', 'enabled', rtn, props)
      return rtn
    }
  )

  const isDisabledStartDay = !hasActId || !!tourId
  const isDisabledEndDay = !hasActId || !!tourId

  const tourLabel = (obj: PlannerEvent) => {
    const base = `${obj?.text ?? 'No name'} (${formatDate(
      obj.start_at,
      'DateShort'
    )} - ${formatDate(obj.end_at, 'DateShort')})`
    return showAct && actId ? base : `${obj.act?.name} - ${base}`
  }

  const isDisabledTour = () => {
    if (showAct) {
      return !hasActId
    } else if (showActs) {
      return !actIds || (actIds && actIds.length !== 1)
    } else {
      return true
    }
  }
  const isDisabledDateRangeType = !!tourId

  const handleActChange = (value: number) => {
    if (value !== actId) {
      onChange({
        actId: value,
        tourId: null,
        startDay: null,
        endDay: null,
      })
    }
  }

  const handleActsChange = (actIds: number[]) => {
    if (actIds.length !== 1) {
      onChange({
        actIds,
        tourId: null,
      })
    } else {
      onChange({actIds})
    }
  }

  const handleStartDayChange = (startDay: string | null) => {
    if (startDay && endDay && isAfter(startDay, endDay)) {
      onChange({startDay, endDay: startDay})
    } else {
      onChange({startDay})
    }
  }
  const handleEndDayChange = (endDay: string | null) => onChange({endDay})
  const setDateRangeType = (type: DateRangeType) =>
    onChange({dateRangeType: type})

  const handleTourChange = (tourId: number | null) => {
    const tour = (tours ?? []).find(({id}) => id === tourId)

    if (!tour) {
      onChange({
        tourId: null,
        startDay: null,
        endDay: null,
      })
    } else {
      onChange({
        tourId,
        actId: tour?.act?.id ?? null,
        startDay: tour.start_at,
        endDay: tour.end_at,
        dateRangeType: 'day',
      })
    }
  }

  const handleShowsOnlyChange = (showsOnly: boolean | null) =>
    onChange({showsOnly})

  return (
    <Form
      initialValues={{
        actId: actId,
        tourId: tourId,
        startDay: startDay,
        endDay: endDay,
      }}
      enableReinitialize
    >
      {() => (
        <Container>
          {showAct && (
            <Section>
              <Row>
                <Column>
                  <MyActsSelect
                    theme='form'
                    model='actId'
                    onChange={handleActChange}
                    label='Act'
                    placeholder='Please select act...'
                  />
                </Column>
                <Column />
              </Row>
            </Section>
          )}

          {showActs && (
            <Section>
              <Row>
                <Column>
                  <MyActsSelect
                    theme='form'
                    model='actIds'
                    onChange={handleActsChange}
                    label='Acts'
                    placeholder='Please select act...'
                    isMulti
                    grid='20'
                  />
                  <InlineText
                    text='If you do not select any acts, all acts will be exported.'
                    grid='20'
                  />
                </Column>
              </Row>
            </Section>
          )}

          {(showDayRange || showTour) && (
            <Section>
              {showDayRange && (
                <Row>
                  <Column>
                    <DatePicker
                      {...datePickerProps(dateRangeType, false)}
                      onChange={handleStartDayChange}
                      disabled={isDisabledStartDay}
                      model='startDay'
                      selectsStart
                    />
                  </Column>
                  <Column>
                    <DatePicker
                      {...datePickerProps(dateRangeType, true)}
                      model='endDay'
                      onChange={handleEndDayChange}
                      disabled={isDisabledEndDay}
                      minDate={startDay ?? undefined}
                      selectsEnd
                    />
                  </Column>
                  <Column>
                    <InlineContent aligned>
                      <ButtonGroup>
                        {DATE_RANGE_TYPES.map((key) => (
                          <Button
                            disabled={isDisabledDateRangeType}
                            key={key}
                            label={key}
                            onClick={() => setDateRangeType(key)}
                            isSelected={dateRangeType === key}
                          />
                        ))}
                      </ButtonGroup>
                    </InlineContent>
                  </Column>
                </Row>
              )}

              {showDayRange && showTour && (
                <Row>
                  <Column>
                    <InlineContent>OR</InlineContent>
                  </Column>
                </Row>
              )}

              {showTour && (
                <Row>
                  <Column>
                    <Select
                      theme='form'
                      model='tourId'
                      onChange={handleTourChange}
                      disabled={isDisabledTour()}
                      options={(tours ?? []).map((item: PlannerEvent) => ({
                        value: item.id,
                        label: tourLabel(item),
                      }))}
                      isClearable
                      label='Tour'
                      placeholder='Please select tour...'
                    />
                  </Column>
                  <Column />
                </Row>
              )}
            </Section>
          )}

          {showShowsOnly && (
            <Section>
              <Row>
                <Column>
                  <Toggle
                    model='showsOnly'
                    onChange={handleShowsOnlyChange}
                    activeText='Exports all'
                    inactiveText='Exports contracts only'
                    grid='fit'
                  />
                </Column>
                <Column />
              </Row>
            </Section>
          )}
        </Container>
      )}
    </Form>
  )
}
