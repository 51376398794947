import {sortBy as sortByFunc} from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {useActs} from 'api'
import {
  buildActsInColumns,
  buildMonthColumn,
  buildMonthsInColumns,
  buildOneMonth,
  filterActs,
  filterMyActs,
  getActAsArray,
} from 'helpers/booking'
import {buildViewKey} from 'helpers/util'
import {
  CONTRACT_STATUS_SORTING,
  CONTRACT_STATUS_OPTIONS,
} from 'options/contractStatus'
import {setSort as setSortAction} from 'store/booking/actions'
import type {
  BookingStoreMultipleActsInColumns,
  BookingStoreMultipleList,
  BookingStoreMultipleMonth,
  BookingStoreMultipleMonthsInColumns,
  BookingStoreSingleList,
  BookingStoreSingleMonth,
  BookingStoreSingleMonthsInColumns,
  BookingViewSortDirection,
} from 'store/booking/types'
import type {PlannerStore, RootStore} from 'store/types'
import type {PlannerEvent} from 'types/planner'
import {formatDate} from 'utils'
import {getShowOrTBA} from './helpers'
import type {
  MultipleActsInColumnsData,
  MultipleListData,
  MultipleMonthData,
  MultipleMonthsInColumnsData,
  SingleListData,
  SingleMonthData,
  SingleMonthsInColumnsData,
} from './types'
import {useCurrentView} from '../hooks'

/* GENERIC */

const useEvents = () => {
  const {list: events, isFetching: isLoading} = useSelector<
    RootStore,
    PlannerStore
  >(({listPlanner}) => listPlanner)
  return {events, isLoading}
}

export const useSort = () => {
  const dispatch = useDispatch()
  const view = useCurrentView()
  function setSort({
    sortBy,
    sortDirection,
  }: {
    sortBy: string
    sortDirection: BookingViewSortDirection
  }) {
    dispatch(setSortAction({view, sortBy, sortDirection}))
  }
  return {setSort}
}

/* MULTIPLE ACTS IN COLUMNS */

export const useMultipleActsInColumns = (): MultipleActsInColumnsData => {
  const {month, showKind} = useSelector<
    RootStore,
    BookingStoreMultipleActsInColumns
  >(({booking}) => booking.views.multipleActsInColumns)
  const {events, isLoading} = useEvents()
  const {data: actsList = []} = useActs()

  const date = new Date(month)

  const acts =
    showKind === 'represented_by_me'
      ? filterMyActs(actsList)
      : filterActs(actsList)

  return {
    isLoading,
    viewKey: buildViewKey({
      month,
      showKind,
      acts,
    }),
    monthColumn: buildMonthColumn({month: date}),
    // By settings the grid on load, the horizontal scroll position is reset
    // We dont want that, so we recalculate the grid when loading as well
    grid: buildActsInColumns({month, events, acts}),
  }
}

/* MULTIPLE LIST */

export const useMapEventsMultipleList = (
  events: PlannerEvent[],
  sortBy: string,
  sortDirection: BookingViewSortDirection
) => {
  const data = events
    .filter((event) => event.type === 'EVENT_TYPE_SHOW')
    .map((event) => ({
      ...event,
      date: `${formatDate(event.start_on, 'DateLong')}, ${getShowOrTBA(event)}`,
      actName: event.act?.name,
      venueName: event.venue?.name,
      venueCity: event.venue?.city_name,
      statusName: CONTRACT_STATUS_OPTIONS[event.status].name,
      organizerName: event.organizer?.name,
      createdBy: event.created_by?.name,
    }))

  let sortedData = data

  switch (sortBy) {
    case 'date':
      sortedData = sortByFunc(data, 'start_on')
      break
    case 'status':
    case 'statusName':
      sortedData = sortByFunc(data, (elm) =>
        CONTRACT_STATUS_SORTING.indexOf(elm.status)
      )
      break
    default:
      sortedData = sortByFunc(data, sortBy)
      break
  }

  if (sortDirection === 'DESC') {
    sortedData.reverse()
  }

  return sortedData
}

export const useMultipleList = (): MultipleListData => {
  const {startMonth, endMonth, showKind, sortBy, sortDirection} = useSelector<
    RootStore,
    BookingStoreMultipleList
  >(({booking}) => booking.views.multipleList)

  const {events, isLoading} = useEvents()
  const {data: acts = []} = useActs()

  return {
    isLoading,
    viewKey: buildViewKey({
      startMonth,
      endMonth,
      showKind,
    }),
    startMonth,
    endMonth,
    sortBy,
    sortDirection,
    events: useMapEventsMultipleList(events, sortBy, sortDirection),
    acts: filterActs(acts),
  }
}

export const useMultipleMonth = (): MultipleMonthData => {
  const {startDay, endDay, month, showKind} = useSelector<
    RootStore,
    BookingStoreMultipleMonth
  >(({booking}) => booking.views.multipleMonth)
  const {events, isLoading} = useEvents()

  const viewKey = buildViewKey({
    startDay,
    endDay,
    showKind,
  })

  return {
    isLoading,
    viewKey,
    month,
    startDay,
    endDay,
    grid: buildOneMonth({
      startDay,
      endDay,
      events,
    }),
  }
}

/* MULTIPLE MONTHS IN COLUMNS */

export const useMultipleMonthsInColumns = (): MultipleMonthsInColumnsData => {
  const {startMonth, endMonth, showKind} = useSelector<
    RootStore,
    BookingStoreMultipleMonthsInColumns
  >(({booking}) => booking.views.multipleMonthsInColumns)
  const {events, isLoading} = useEvents()
  const {data: actsList = []} = useActs()

  const acts =
    showKind === 'represented_by_me'
      ? filterMyActs(actsList)
      : filterActs(actsList)

  return {
    isLoading,
    viewKey: buildViewKey({
      startMonth,
      endMonth,
      showKind,
      acts,
    }),
    grid: buildMonthsInColumns({
      startMonth,
      endMonth,
      events,
    }),
  }
}

/* SINGLE LIST */

const useMapEventsSingleList = (
  events: PlannerEvent[],
  sortBy: string,
  sortDirection: BookingViewSortDirection
) => {
  const data = events
    .filter((event) => event.type === 'EVENT_TYPE_SHOW')
    .map((event) => ({
      ...event,
      date: `${formatDate(event.start_on, 'DateLong')}, ${getShowOrTBA(event)}`,
      statusName: CONTRACT_STATUS_OPTIONS[event.status].name,
      venueName: event.venue?.name,
      venueCity: event.venue?.city_name,
      organizerName: event.organizer?.name,
      createdBy: event.created_by?.name,
    }))

  let sortedData = data

  switch (sortBy) {
    case 'date':
      sortedData = sortByFunc(data, 'start_on')
      break
    case 'status':
    case 'statusName':
      sortedData = sortByFunc(data, (elm) =>
        CONTRACT_STATUS_SORTING.indexOf(elm.status)
      )
      break
    default:
      sortedData = sortByFunc(data, sortBy)
      break
  }

  if (sortDirection === 'DESC') {
    sortedData.reverse()
  }

  return sortedData
}

export const useSingleList = (): SingleListData => {
  const {
    startMonth,
    endMonth,
    act: actId,
    sortBy,
    sortDirection,
  } = useSelector<RootStore, BookingStoreSingleList>(
    ({booking}) => booking.views.singleList
  )
  const {events, isLoading} = useEvents()

  return {
    isLoading,
    viewKey: buildViewKey({
      startMonth,
      endMonth,
      actId,
    }),
    startMonth,
    endMonth,
    sortBy,
    sortDirection,
    events: useMapEventsSingleList(events, sortBy, sortDirection),
    actId,
  }
}

/* SINGLE MONTH */

export const useSingleMonth = (): SingleMonthData => {
  const {startDay, endDay, month, act} = useSelector<
    RootStore,
    BookingStoreSingleMonth
  >(({booking}) => booking.views.singleMonth)
  const {events, isLoading} = useEvents()

  const viewKey = buildViewKey({
    startDay,
    endDay,
    act,
  })

  return {
    isLoading,
    viewKey,
    month,
    startDay,
    endDay,
    actId: act,
    grid: buildOneMonth({
      startDay,
      endDay,
      events,
      actId: act ?? undefined,
    }),
  }
}

/* SINGLE MONTHS IN COLUMNS */

export const useSingleMonthsInColumns = (): SingleMonthsInColumnsData => {
  const {
    startMonth,
    endMonth,
    act: actId,
  } = useSelector<RootStore, BookingStoreSingleMonthsInColumns>(
    ({booking}) => booking.views.singleMonthsInColumns
  )
  const {events, isLoading} = useEvents()
  const {data: actsList = []} = useActs()

  const acts = getActAsArray(actsList, actId)

  return {
    isLoading,
    viewKey: buildViewKey({
      startMonth,
      endMonth,
      acts,
    }),
    actId,
    grid: buildMonthsInColumns({
      startMonth,
      endMonth,
      events,
      actId: acts[0]?.id,
    }),
  }
}
