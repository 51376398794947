import styled from 'styled-components'
import {effects} from 'styles/variables'

const Container = styled.div`
  display: inline-flex;
  flex-flow: row nowrap;

  & button,
  & a {
    border-radius: 0;
    flex: 0 0 auto;
    font-size: 12px;
    margin: 0; /* Needed for safari */

    &.isSelected {
      z-index: 3;
    }
    &:hover {
      z-index: 2;
      box-shadow: none !important;
    }

    &:first-child {
      border-top-left-radius: ${effects.borderRadius};
      border-bottom-left-radius: ${effects.borderRadius};
    }
    &:last-child {
      border-top-right-radius: ${effects.borderRadius};
      border-bottom-right-radius: ${effects.borderRadius};
    }
    &:not(:last-child) {
      margin-right: -1px;
    }
  }
`

interface ButtonGroupProps {
  className?: string
}

export const ButtonGroup: React.FC<
  React.PropsWithChildren<ButtonGroupProps>
> = ({children, className}) => {
  return <Container className={className}>{children}</Container>
}
