import {combineReducers} from 'redux'
import {BOOKING_VIEWS} from 'options/booking'
import {
  SET_START_MONTH,
  SET_END_MONTH,
  SET_SHOW_KIND,
  SET_SORT,
} from '../../constants'
import {
  month,
  monthWithMinMonth,
  showKind,
  sortBy,
  sortDirection,
} from '../../helpers/reducers'

export const multipleListReducer = combineReducers({
  startMonth: month(SET_START_MONTH, BOOKING_VIEWS.MULTIPLE_LIST),
  endMonth: monthWithMinMonth(
    SET_END_MONTH,
    BOOKING_VIEWS.MULTIPLE_LIST,
    null,
    SET_START_MONTH
  ),
  showKind: showKind(SET_SHOW_KIND, BOOKING_VIEWS.MULTIPLE),
  sortBy: sortBy(SET_SORT, BOOKING_VIEWS.MULTIPLE_LIST),
  sortDirection: sortDirection(SET_SORT, BOOKING_VIEWS.MULTIPLE_LIST),
  eventTypes: () => 'shows',
})
