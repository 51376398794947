import Markdown from 'markdown-to-jsx'
import {queryKeys, usefinancialExports} from 'api'
import {HeaderText, Spinner} from 'components/elements'
import {
  Container,
  Header,
  HeaderLeft,
  Main,
  Footer,
  FooterRight,
} from 'containers/Core'
import {queryClient} from 'main'
import {REPORT_CARD_OPTIONS} from 'options/reportCards'
import type {ReportCards} from 'types/reportCards'
import {FinancialExportDownloader} from './Downloader'
import {FinancialExportItem} from './ExportItem'

interface FinancialExportProps {
  kind: Extract<
    ReportCards,
    'NAVISION_EXPORT' | 'SAP_PROJECT_EXPORT' | 'SAP_EXPORT'
  >
}

export const FinancialExport = ({kind}: FinancialExportProps) => {
  const options = REPORT_CARD_OPTIONS[kind]
  const url = options.url ?? ''
  const {data, isFetching} = usefinancialExports(url)

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <HeaderText primary={options.title} twoLines />
        </HeaderLeft>
      </Header>

      <Main className='overflow-hidden grid grid-rows-[auto_1fr] relative'>
        <div className='text-secondaryTextColor py-8 px-5 text-center'>
          <Markdown>
            {`Click the generate button in the footer to download the most recent changes.

You can also redownload old reports from the history below.`}
          </Markdown>
        </div>
        <div className='overflow-y-auto'>
          {isFetching && <Spinner overlay absolute />}
          <div>
            {!!data &&
              data.map((item) => (
                <FinancialExportItem key={item.exported_at} data={item} />
              ))}
          </div>
        </div>
      </Main>

      <Footer>
        <FooterRight>
          <FinancialExportDownloader
            url={options.url ?? ''}
            allowPreview={kind !== 'NAVISION_EXPORT'}
            onSuccess={() =>
              queryClient.invalidateQueries(queryKeys.financialExports._def)
            }
          />
        </FooterRight>
      </Footer>
    </Container>
  )
}
