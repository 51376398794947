import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  Switch,
  Route,
  useLocation,
  useRouteMatch,
  Redirect,
} from 'react-router-dom'
import {resolveViewFromUrl} from 'helpers/booking'
import {BOOKING_VIEWS, BOOKING_PATHS} from 'options/booking'
import {setView as setBookingView} from 'store/booking/shared/actions'
import type {BookingStoreViews, BookingViews} from 'store/booking/types'
import {fetchPlanners} from 'store/planner/actions'
import type {RootStore} from 'store/types'
import {
  Flow,
  MultipleActsInColumns,
  MultipleList,
  MultipleMonth,
  MultipleMonthsInColumns,
  SingleList,
  SingleMonth,
  SingleMonthsInColumns,
} from './routes'

const useFetchPlanner = () => {
  const dispatch = useDispatch()
  const currentView = useSelector<RootStore, BookingViews>(
    ({booking}) => booking.shared.view
  )
  const viewProps = useSelector<RootStore, BookingStoreViews>(
    ({booking}) => booking.views
  )

  useEffect(() => {
    dispatch(fetchPlanners())
  }, [dispatch, currentView, viewProps])
}

export const BookingSwitch = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const {path} = useRouteMatch()

  useEffect(() => {
    dispatch(setBookingView({view: resolveViewFromUrl(location)}))
  }, [dispatch, location])

  useFetchPlanner()

  const pathBuilder = (view: string) => `${path}/${BOOKING_PATHS[view]}`

  return (
    <Switch>
      <Route path={pathBuilder(BOOKING_VIEWS.FLOW)} exact>
        <Flow />
      </Route>
      <Route path={pathBuilder(BOOKING_VIEWS.SINGLE_MONTHS_IN_COLUMNS)} exact>
        <SingleMonthsInColumns />
      </Route>
      <Route path={pathBuilder(BOOKING_VIEWS.SINGLE_MONTH)} exact>
        <SingleMonth />
      </Route>
      <Route path={pathBuilder(BOOKING_VIEWS.SINGLE_LIST)} exact>
        <SingleList />
      </Route>
      <Route path={pathBuilder(BOOKING_VIEWS.MULTIPLE_ACTS_IN_COLUMNS)} exact>
        <MultipleActsInColumns />
      </Route>
      <Route path={pathBuilder(BOOKING_VIEWS.MULTIPLE_MONTHS_IN_COLUMNS)} exact>
        <MultipleMonthsInColumns />
      </Route>
      <Route path={pathBuilder(BOOKING_VIEWS.MULTIPLE_MONTH)} exact>
        <MultipleMonth />
      </Route>
      <Route path={pathBuilder(BOOKING_VIEWS.MULTIPLE_LIST)} exact>
        <MultipleList />
      </Route>
      <Redirect
        from='*'
        to={pathBuilder(BOOKING_VIEWS.SINGLE_MONTHS_IN_COLUMNS)}
      />
    </Switch>
  )
}
