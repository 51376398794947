import styled from 'styled-components'
import {Avatar as AvatarComp} from 'components/elements'
import {colors, effects, fontSizes, mq, spacing, zIndex} from 'styles/variables'

export const Root = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
`

export const Target = styled.div<{maximized: boolean}>`
  @media (${mq.desktop}) {
    display: flex;
    flex-flow: row nowrap;
    transform: translateX(${({maximized}) => (maximized ? '-56px' : 0)});
    transition: transform ${effects.transitionTime};
    cursor: pointer;
  }
`

export const Avatar = styled(AvatarComp)`
  @media (${mq.desktop}) {
    flex: 0 0 56px;

    & > div {
      transition: all ${effects.transitionTime};
    }

    &:hover > div {
      color: ${colors.pictonBlue};
      border-color: ${colors.pictonBlue};
    }
  }
`

export const TargetButton = styled.div`
  display: none;

  @media (${mq.desktop}) {
    flex: 0 0 auto;
    box-sizing: border-box;
    width: 240px;
    padding: 0 ${spacing.u5};
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
`

export const TargetButtonContent = styled.div`
  flex: 1 1 auto;
`

export const TargetButtonActions = styled.div`
  flex: 0 0 auto;
  margin-right: -4px; /* Shift icon */
`

export const TargetButtonTitle = styled.div<{opened: boolean}>`
  font-size: ${fontSizes.wolfSize};
  color: ${({opened}) => (opened ? colors.bostonBlue : colors.white)};
  transition: color ${effects.transitionTime};

  ${Target}:hover & {
    color: ${({opened}) => (opened ? colors.bostonBlue : colors.pictonBlue)};
  }
`

export const TargetButtonText = styled.div`
  color: ${colors.bombay};
`

export const Content = styled.div`
  font-size: ${fontSizes.dogSize};
  color: ${colors.primaryTextColor};

  @media (${mq.desktop}) {
    width: 320px;
    background: ${colors.white};
    border: 1px solid ${colors.borderColor};
    border-radius: ${effects.borderRadius};
    box-shadow: ${effects.popoverBoxshadow};
    cursor: default;
    z-index: ${zIndex.dropdown};
  }

  > * + * {
    border-top: 1px solid ${colors.borderColor};
  }
`

export const Section = styled.div`
  padding: ${spacing.u2_5};
`
