import {useState} from 'react'
import type {Placement} from '@popperjs/core'
import {usePopper} from 'react-popper'
import {Portal} from 'react-portal'
import {useResizeDetector} from 'react-resize-detector'
import styled from 'styled-components'
import {useClickOutside} from 'hooks/useClickOutside'
import {useKeyPress} from 'hooks/useKeyPress'
import {colors, effects} from 'styles/variables'

const Root = styled.div`
  outline: none;
  position: relative;
  display: inline-block;
`

const ContentWrapper = styled.div`
  z-index: 10000;
  background: ${colors.white};
  &:not(:empty) {
    border: ${effects.inputBorder};
    border-radius: ${effects.borderRadius};
    box-shadow: ${effects.popoverBoxshadow};
  }
`

export interface DropdownPropsTargetProps {
  ref: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  opened: boolean
  open: () => void
  close: () => void
}

export interface DropdownPropsContentProps {
  close: () => void
}

export interface DropdownProps {
  target: React.FC<React.PropsWithChildren<DropdownPropsTargetProps>>
  content: React.FC<React.PropsWithChildren<DropdownPropsContentProps>>
  className?: string
  placement?: Placement
  fitContentWidth?: boolean
  clickOutsideToClose?: boolean
  escapeToClose?: boolean
  deferClickOutsideInitialization?: boolean
  onOpen?: () => void
  onClose?: () => void
}

export const Dropdown = ({
  target: inputElement,
  content: contentElement,
  className,
  placement = 'bottom',
  fitContentWidth,
  clickOutsideToClose = true,
  escapeToClose = true,
  deferClickOutsideInitialization = false,
  onOpen,
  onClose,
}: DropdownProps): React.ReactElement => {
  const [opened, setOpened] = useState(false)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)

  const {width, ref: _root} = useResizeDetector({})
  const {styles: popperStyles, attributes} = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            padding: 8,
          },
        },
        {name: 'offset', options: {offset: [0, 5]}},
      ],
    }
  )

  const handleOpen = () => {
    setOpened(true)
    if (onOpen) {
      onOpen()
    }
  }
  const handleClose = () => {
    setOpened(false)
    if (onClose) {
      onClose()
    }
  }

  useClickOutside(
    _root,
    popperElement,
    opened,
    () => {
      if (!clickOutsideToClose) {
        return
      }
      handleClose()
    },
    deferClickOutsideInitialization
  )
  useKeyPress(opened, 'Escape', () => {
    if (!escapeToClose) {
      return
    }
    handleClose()
  })

  return (
    <Root ref={_root} className={className}>
      {inputElement({
        ref: setReferenceElement,
        opened,
        open: handleOpen,
        close: handleClose,
      })}

      {opened && (
        <Portal>
          <ContentWrapper
            ref={setPopperElement}
            style={{
              width: fitContentWidth ? width : undefined,
              ...popperStyles.popper,
            }}
            {...attributes.popper}
          >
            {contentElement({close: handleClose})}
          </ContentWrapper>
        </Portal>
      )}
    </Root>
  )
}
