export const CONTRACT_RESET = 'Contract/RESET'

export const CONTRACT_OPEN = 'Contract/OPEN'
export const CONTRACT_COPY = 'Contract/COPY'

export const CONTRACT_SHOW = 'Contract/SHOW'
export const CONTRACT_SHOW_SUCCESS = 'Contract/SHOW_RESPONSE_SUCCESS'
export const CONTRACT_SHOW_FAILURE = 'Contract/SHOW_RESPONSE_FAILURE'

export const CONTRACT_INITIALIZE = 'Contract/INITIALIZE'
export const CONTRACT_INITIALIZE_SUCCESS = 'Contract/INITIALIZE_SUCCESS'
export const CONTRACT_INITIALIZE_FAILURE = 'Contract/INITIALIZE_FAILURE'

export const CONTRACT_CREATE = 'Contract/CREATE'
export const CONTRACT_CREATE_SUCCESS = 'Contract/CREATE_SUCCESS'
export const CONTRACT_CREATE_FAILURE = 'Contract/CREATE_FAILURE'

export const CONTRACT_UPDATE = 'Contract/UPDATE'
export const CONTRACT_UPDATE_SUCCESS = 'Contract/UPDATE_SUCCESS'
export const CONTRACT_UPDATE_FAILURE = 'Contract/UPDATE_FAILURE'

export const CONTRACT_DESTROY = 'Contract/DESTROY'
export const CONTRACT_DESTROY_SUCCESS = 'Contract/DESTROY_SUCCESS'
export const CONTRACT_DESTROY_FAILURE = 'Contract/DESTROY_FAILURE'

export const CHANGE_STATE = 'Contract/CHANGE_STATE'
export const CHANGE_STATE_SUCCESS = 'Contract/CHANGE_STATE_SUCCESS'
export const CHANGE_STATE_FAILURE = 'Contract/CHANGE_STATE_FAILURE'

export const EXPORT_FAILURE = 'Contract/EXPORT_FAILURE'
