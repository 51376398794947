import type {MouseEvent} from 'react'

export type TableSortDirection = 'ASC' | 'DESC'
export type CellAlignment = 'left' | 'center' | 'right'

export interface CellStylingConfig {
  label?: string
  alignment?: CellAlignment
  sortKey?: string
  width: number
  flexGrow?: number
  flexShrink?: number
  className?: string
}

export interface ItemProps<Data, ExtraProps> {
  item: Data
  index?: number
  extraProps?: ExtraProps
}

export type CellRenderer<Data, ExtraProps> = (
  props: ItemProps<Data, ExtraProps>
) => JSX.Element

export interface CellConfig<Data, ExtraProps> extends CellStylingConfig {
  id: string
  onClick?: (
    data: Data
  ) =>
    | ((
        props: ItemProps<Data, ExtraProps>,
        e: MouseEvent<HTMLDivElement>
      ) => void)
    | undefined
  renderer?: CellRenderer<Data, ExtraProps>
}

export type RowClickHandler<Data> = (
  item: Data,
  event: MouseEvent<HTMLDivElement>
) => void

export function getCellFlex(
  config: Pick<CellStylingConfig, 'width' | 'flexGrow' | 'flexShrink'>
): string {
  return `${config.flexGrow ?? 0} ${config.flexShrink ?? 1} ${config.width}px`
}
