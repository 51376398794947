import {createReducer} from '@reduxjs/toolkit'

type IsLoadingArgs =
  | string[]
  | [
      {
        constants: string[]
      }
    ]

export function isLoading(...args: IsLoadingArgs) {
  let arr: string[]

  if (args.length === 1 && typeof args[0] === 'object') {
    arr = args[0].constants
  } else if (
    args.length === 3 &&
    typeof args[0] === 'string' &&
    typeof args[1] === 'string' &&
    typeof args[2] === 'string'
  ) {
    arr = args
  } else {
    throw new Error('isLoading: input not suppoted')
  }

  const [START, SUCCESS, FAILURE] = arr

  return createReducer<boolean>(false, {
    [START]: () => true,
    [SUCCESS]: () => false,
    [FAILURE]: () => false,
  })
}
