import {useFormikContext} from 'formik'
import type {CheckboxProps} from 'components/elements/Checkbox/Checkbox'
import {Checkbox} from 'components/form'
import type {FormikFieldProps} from 'components/form/formikFieldHOC'
import type {Contract} from 'types/contract'
import {handleCapacityUpdate} from './helpers'

type IsSeatedProps = Omit<FormikFieldProps<CheckboxProps>, 'onChange'>

export const IsSeated: React.FC<React.PropsWithChildren<IsSeatedProps>> = (
  props
) => {
  const {values, setFieldValue} = useFormikContext<Contract>()

  const handleChange = (isSeated: boolean) => {
    handleCapacityUpdate(
      {
        venueId: values.show.stakeholder_venue?.department.id,
        isSeated,
      },
      setFieldValue
    )
  }

  return <Checkbox {...props} onChange={handleChange} />
}
