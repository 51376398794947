import * as yup from 'yup'

export const tourValidationSchema = yup.object().shape({
  stakeholder_act: yup.object({
    department: yup.object({
      id: yup.number().integer().nullable().required().label('Act'),
    }),
  }),
  text: yup.string().nullable().label('Title'),
  start_at: yup.string().required().nullable().label('Start on'),
  end_at: yup.string().required().nullable().label('End on'),
})
