import {useState} from 'react'
import pick from 'lodash/pick'
import {DocumentDownloader} from 'components/connected'
import {Button, HeaderText} from 'components/elements'
import {
  Container,
  Header,
  HeaderLeft,
  Main,
  Footer,
  FooterRight,
} from 'containers/Core'
import {REPORT_CARD_OPTIONS} from 'options/reportCards'
import type {ReportCards} from 'types/reportCards'
import {INITIAL_PARAMS} from './config'
import {ReportsViewOptions} from './Options'
import type {ReportsExportParams} from './types'

interface ReportsExportProps {
  kind: ReportCards
}

export const ReportsExport: React.FC<
  React.PropsWithChildren<ReportsExportProps>
> = ({kind}) => {
  const [params, setParams] = useState<ReportsExportParams>(INITIAL_PARAMS)

  const cardOptions = REPORT_CARD_OPTIONS[kind]

  if (!cardOptions) {
    return null
  }

  const downloaderParams = {
    url: cardOptions.url,
    urlParams: pick(params, cardOptions.urlParams ?? []),
    queryParams: pick(params, cardOptions.queryParams ?? []),
    queryParamsFormatter: cardOptions.queryParamsFormatter,
  }

  const canDownload = Array.isArray(cardOptions.requiredParams)
    ? cardOptions.requiredParams.every(
        (key) => !!params[key as keyof ReportsExportParams]
      )
    : true

  const handleOptionsChange = (newParams: Partial<ReportsExportParams>) =>
    setParams((oldParams) => ({...oldParams, ...newParams}))

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <HeaderText primary={cardOptions.title} secondary='Export' twoLines />
        </HeaderLeft>
      </Header>

      <Main flex>
        <ReportsViewOptions
          {...params}
          parameters={cardOptions.parameters}
          onChange={handleOptionsChange}
        />
      </Main>

      <Footer>
        <FooterRight>
          <DocumentDownloader {...downloaderParams} disabled={!canDownload}>
            <Button theme='primary' icon='download' label='Download' />
          </DocumentDownloader>
        </FooterRight>
      </Footer>
    </Container>
  )
}
