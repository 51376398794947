import styled from 'styled-components'
import {Icon} from 'components/elements/Icon' // Global import fucks up webpack
import {colors, effects, fontSizes, spacing} from 'styles/variables'
import type {
  DropdownProps,
  DropdownPropsTargetProps,
} from '../../utils/Dropdown/Dropdown'
import {Dropdown} from '../../utils/Dropdown/Dropdown'

interface InputWrapperProps {
  big: boolean
}
const InputWrapper = styled.div<InputWrapperProps>`
  display: grid;
  grid-template-columns: 1fr 16px;
  grid-column-gap: ${spacing.u2_5};
  align-items: center;
  padding: 0 ${spacing.u2_5};
  height: ${({big}) => (big ? 36 : 30)}px;
  font-size: ${({big}) => (big ? fontSizes.dogSize : fontSizes.catSize)};
  border: ${effects.inputBorder};
  transition: border ${effects.transitionTime},
    box-shadow ${effects.transitionTime}, color ${effects.transitionTime},
    background-color ${effects.transitionTime};
  border-radius: ${effects.borderRadius};
  color: ${colors.primaryTextColor};

  &:hover {
    border: ${effects.inputHoverBorder};
    box-shadow: ${effects.inputHoverBoxShadow};
    color: ${colors.pictonBlue};
    cursor: pointer;
  }
  &:focus {
    border: ${effects.inputBorder};
    box-shadow: ${effects.focusBoxShadow};
  }
  &:active {
    border: solid 1px ${colors.bostonBlue};
    box-shadow: 0 0 0 2px ${colors.pictonBlue};
    background-color: ${colors.springWood};
  }

  &.opened {
    color: var(--pictonBlue);
    border: solid 1px var(--bostonBlue);
    box-shadow: 0 0 0 2px var(--pictonBlue);
    background-color: var(--springWood);
  }
`

const InputIcon = styled.div`
  height: 16px;
  color: var(--scarpaFlow);
  transition: color var(--transitionTime);

  ${InputWrapper}.opened .icon,
  ${InputWrapper}:hover &,
  ${InputWrapper}:focus &,
  ${InputWrapper}:active & {
    color: var(--pictonBlue);
  }
`
export interface DropdownMenuProps
  extends Omit<DropdownProps, 'fitContentWidth' | 'target'> {
  target: React.FC<
    React.PropsWithChildren<Omit<DropdownPropsTargetProps, 'ref'>>
  >
  big?: boolean
}

export const DropdownMenu = ({
  target,
  content,
  className,
  big,
  placement = 'bottom',
}: DropdownMenuProps): React.ReactElement => {
  return (
    <Dropdown
      placement={placement}
      className={className}
      target={({ref, opened, open, close}) => (
        <InputWrapper
          big={!!big}
          className={opened ? 'opened' : undefined}
          onClick={() => (opened ? close() : open())}
          ref={ref}
        >
          <div>{target({opened, open, close})}</div>
          <InputIcon>
            <Icon icon='chevron-down' size='16' />
          </InputIcon>
        </InputWrapper>
      )}
      content={(props) => content(props)}
      fitContentWidth
    />
  )
}
