import type {ReactNode} from 'react'
import {forwardRef} from 'react'
import styled from 'styled-components'
import {fontSizes} from 'styles/variables'
import {coloring} from './helpers'
import type {BadgeColor} from './types'

interface BadgeContainerProps {
  color?: BadgeColor
  inverted?: boolean
  pill?: boolean
}

const Container = styled.div<BadgeContainerProps>`
  display: inline-block;
  font-size: ${fontSizes.ratSize};
  text-transform: uppercase;
  border-width: 1px;
  border-style: solid;
  padding: ${({pill}) => (pill ? '1px 4px' : '1px 3px')};
  border-radius: ${({pill}) => (pill ? 999 : 2)}px;
  user-select: none;

  ${({color, inverted}) => coloring(color, inverted)}
`

interface BadgeProps extends BadgeContainerProps {
  children: ReactNode
}

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  ({children, color, inverted, pill}, ref) => {
    return (
      <Container ref={ref} color={color} inverted={inverted} pill={pill}>
        {children}
      </Container>
    )
  }
)

Badge.displayName = 'Badge'
