import {useFormikContext} from 'formik'
import {getCustomLabel} from 'app/modal/Entity/helpers'
import {Input, CountrySelect} from 'components/form'
import {
  CompoundInput,
  CompoundInputLine,
  Container,
  Section,
  Column,
  Row,
} from 'containers/Form'
import {useAuth} from 'hooks/useAuth'
import type {StorableDepartment} from 'types/department'

export const EntityFormPanelBilling = () => {
  const {hasRole} = useAuth()
  const {values} = useFormikContext<StorableDepartment>()
  const hasCustomFields =
    'available_custom_fields' in values && values.available_custom_fields.length

  return (
    <Container>
      <Section label='Tax ID (CVR)'>
        <Row>
          <Column>
            <CompoundInput>
              <CompoundInputLine>
                <CountrySelect
                  model='tax_id.country_code'
                  kind='country'
                  grid='10'
                  placeholder='Country'
                  theme='form'
                  isClearable
                />
                <Input model='tax_id.value' grid='10' placeholder='Tax ID' />
              </CompoundInputLine>
            </CompoundInput>
          </Column>
          <Column />
        </Row>
      </Section>
      <Section label='EAN'>
        <Row>
          <Column>
            <Input model='ean.value' placeholder='EAN' />
          </Column>
          <Column />
        </Row>
      </Section>

      {hasCustomFields && (
        <Section label='Finance'>
          {values.available_custom_fields.map((key) => (
            <Row key={key}>
              <Column>
                <Input
                  model={`custom_fields.${key}`}
                  placeholder={getCustomLabel(key)}
                  label={getCustomLabel(key)}
                  disabled={!hasRole('BOOKING_AGENCY_ACCOUNTANT')}
                />
              </Column>
              <Column />
            </Row>
          ))}
        </Section>
      )}
    </Container>
  )
}
