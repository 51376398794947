import type {ReactNode} from 'react'
import cx from 'classnames'
import {useResizeDetector} from 'react-resize-detector'
import useScrollbarSize from 'react-scrollbar-size'
import {Content} from './Content'
import type {SetSort} from './Header'
import {Header} from './Header'
import {ROW_STYLES} from './helpers'
import type {CellConfig, RowClickHandler, TableSortDirection} from './types'

export interface HeaderProps<Data, ExtraProps> {
  cells: CellConfig<Data, ExtraProps>[]
}

export interface ContentProps<Data, ExtraProps> {
  data?: Data[]
  cells: CellConfig<Data, ExtraProps>[]
  rowClassName?: (item: Data) => string
  emptyMessage?: ReactNode
  onRowClick?: RowClickHandler<Data>
  scrollInside?: boolean
  extraProps?: ExtraProps
}

export interface TableProps<Data, ExtraProps>
  extends Omit<HeaderProps<Data, ExtraProps>, 'cells'>,
    Omit<ContentProps<Data, ExtraProps>, 'cells'> {
  data?: Data[]
  cells: (CellConfig<Data, ExtraProps> | undefined)[]
  rowClassName?: (item: Data) => string
  extraProps?: ExtraProps
  emptyMessage?: ReactNode
  loading?: boolean
  sortBy?: string
  sortDirection?: TableSortDirection
  setSort: SetSort
}

export const Table = <Data, ExtraProps>({
  data,
  cells: cellsUnfiltered,
  rowClassName,
  loading,
  extraProps,
  emptyMessage,
  onRowClick,
  scrollInside,
  sortBy = 'NotSorted',
  setSort = () => undefined,
  sortDirection = 'ASC',
}: TableProps<Data, ExtraProps>): JSX.Element => {
  const {height: containerHeight, ref: contentRef} = useResizeDetector({})
  const {width: scrollbarWidth} = useScrollbarSize()
  const cells = cellsUnfiltered.filter(
    (
      c: CellConfig<Data, ExtraProps> | undefined
    ): c is CellConfig<Data, ExtraProps> => !!c
  )

  const scrollHeight = contentRef?.current?.scrollHeight

  const hasScrollbar =
    scrollbarWidth &&
    scrollHeight &&
    containerHeight &&
    scrollHeight > containerHeight

  const activeScrollbarWidth = hasScrollbar ? scrollbarWidth : 0

  if (loading) {
    return (
      <div className='absolute inset-0 bg-white bg-opacity-50 z-10 flex justify-center items-center'></div>
    )
  }

  return (
    <section
      className='w-full grid grid-rows-[auto_1fr] overflow-hidden'
      style={scrollInside ? {position: 'sticky', top: '0'} : undefined}
    >
      <header
        className={cx(
          ROW_STYLES,
          'bg-oxfordBlue text-white font-bold uppercase flex'
        )}
        style={{
          paddingRight: activeScrollbarWidth
            ? `${activeScrollbarWidth + 8}px`
            : undefined,
        }}
      >
        <Header
          cells={cells}
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSort={setSort}
        />
      </header>
      <main className='overflow-y-auto' ref={contentRef}>
        <Content
          data={data /*sorted*/}
          cells={cells}
          emptyMessage={emptyMessage}
          rowClassName={rowClassName}
          onRowClick={onRowClick}
          extraProps={extraProps}
        />
      </main>
    </section>
  )
}
