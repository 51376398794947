import {compact} from 'lodash'
import styled from 'styled-components'
import {ButtonMenu, Button} from 'components/elements'
import type {CellRenderer} from 'components/elements/Table/types'
import type {ContractInvoicingStatusType} from 'types/contract'
import type {PlannerEconomy} from 'types/planner'
import type {AccountingListExtraProps} from './List'

const MoreMenuWrapper = styled.div`
  padding: 3px;
`

export const ActionsMenu: CellRenderer<
  PlannerEconomy,
  AccountingListExtraProps
> = ({item, extraProps}) => {
  if (!item || !extraProps) {
    return <></>
  }

  const {id, organizerId, organizerName, exportStatus, invoicingStatus, taxId} =
    item
  const {onExportManually, onExportAgain, onTaxIdExemption, onInvoiceStatus} =
    extraProps

  const handleExportManually = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onExportManually(id)
  }
  const handleExportAgain = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onExportAgain(id)
  }
  const handleTaxIdExemption = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (organizerId && organizerName) {
      onTaxIdExemption(organizerId, organizerName)
    }
  }
  const handleInvoiceStatus = (e: React.MouseEvent, status: string) => {
    e.preventDefault()
    e.stopPropagation()
    if (status) {
      onInvoiceStatus(id, status as unknown as ContractInvoicingStatusType)
    }
  }

  const pendingExport =
    !!exportStatus &&
    [
      'EXPORT_STATUS_TO_BE_EXPORTED',
      'EXPORT_STATUS_TO_BE_EXPORTED_AGAIN',
    ].includes(exportStatus)
  const exported =
    !!exportStatus &&
    ['EXPORT_STATUS_EXPORTED', 'EXPORT_STATUS_EXPORTED_MANUALLY'].includes(
      exportStatus
    )
  const noExported =
    !!exportStatus && ['EXPORT_STATUS_NO_EXPORT'].includes(exportStatus)
  const noInvoice =
    !!invoicingStatus &&
    ['INVOICING_STATUS_NO_INVOICE'].includes(invoicingStatus)
  const invoiceSent =
    !!invoicingStatus &&
    ['INVOICING_STATUS_INVOICE_SENT'].includes(invoicingStatus)
  const invoicePaid =
    !!invoicingStatus &&
    ['INVOICING_STATUS_INVOICE_PAID'].includes(invoicingStatus)

  const showExportManually = pendingExport
  const showExportAgain = exported && noInvoice
  const showTaxIdExcemption = pendingExport || noExported
  const showInvoiceSent = exported && noInvoice
  const showInvoicePaid = exported && invoiceSent
  const showInvoicePending = exported && (invoiceSent || invoicePaid)

  const menuItems = compact([
    showExportManually && (
      <Button
        key='EXPORT_MANUALLY'
        label='Export manually'
        icon='arrow-right'
        disabled={exported}
        width='fill'
        theme='light'
        alignment='left'
        onClick={handleExportManually}
      />
    ),
    showInvoiceSent && (
      <Button
        key='MARK_INVOICE_AS_SENT'
        label='Mark invoice as sent'
        icon='money'
        width='fill'
        theme='light'
        alignment='left'
        onClick={(e) => handleInvoiceStatus(e, 'INVOICING_STATUS_INVOICE_SENT')}
      />
    ),
    showInvoicePaid && (
      <Button
        key='MARK_INVOICE_AS_PAID'
        label='Mark invoice as paid'
        icon='safe'
        width='fill'
        theme='light'
        alignment='left'
        onClick={(e) => handleInvoiceStatus(e, 'INVOICING_STATUS_INVOICE_PAID')}
      />
    ),
    showInvoicePending && (
      <Button
        key='MARK_INVOICE_AS_PENDING'
        label='Mark invoice as pending'
        icon='arrow-left'
        width='fill'
        theme='light'
        alignment='left'
        onClick={(e) => handleInvoiceStatus(e, 'INVOICING_STATUS_NO_INVOICE')}
      />
    ),
    showExportAgain && (
      <Button
        key='EXPORT_AGAIN'
        label='Export again'
        icon='arrow-left'
        disabled={!exported}
        width='fill'
        theme='light'
        alignment='left'
        onClick={handleExportAgain}
      />
    ),
    showTaxIdExcemption && (
      <Button
        key='TAX_ID_EXEMPTION'
        label='Tax-ID exemption'
        icon='cvr'
        disabled={!!taxId}
        width='fill'
        theme='light'
        alignment='left'
        onClick={handleTaxIdExemption}
      />
    ),
  ])

  return (
    <MoreMenuWrapper>
      <ButtonMenu icon='more' round={false}>
        {menuItems}
      </ButtonMenu>
    </MoreMenuWrapper>
  )
}
