import type {SagaIterator} from 'redux-saga'
import {takeLatest, call, put} from 'redux-saga/effects'
import {getApiUrlFromEventType} from 'api'
import {toastrActions} from 'features/toastr'
import {sagaFetch, standardErrorAction} from 'helpers/saga'
import {updatePlannerStart, updatePlannerSuccess} from '../actions'
import {UPDATE, UPDATE_FAILURE} from '../constants'
import {formatPayload} from '../helpers'
import type {PlannerUpdate} from '../types'

export function* updatePlanner(action: PlannerUpdate): SagaIterator {
  const {type, ...data} = action.payload
  const {cellKey} = action.meta

  yield put(updatePlannerStart(undefined, {cellKey}))
  try {
    const payload = yield call(
      sagaFetch,
      getApiUrlFromEventType(type, data.id),
      'put',
      formatPayload(data)
    )
    yield put(updatePlannerSuccess(payload, {cellKey}))
  } catch (e: any) {
    yield put(toastrActions.errorToast('Event could not be updated!'))
    yield put(standardErrorAction(UPDATE_FAILURE, e, {cellKey}))
    throw e
  }
}

export function* updateSaga() {
  yield takeLatest(UPDATE, updatePlanner)
}
