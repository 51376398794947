import type {PropsWithChildren} from 'react'
import {AppNavigation} from 'shared/AppNavigation'
import {AuthHandler} from 'shared/AuthHandler'

export const MainLayout = ({children}: PropsWithChildren) => {
  return (
    <div className='w-vw h-vh flex flex-col md:flex-row flex-nowrap md:items-stretch overflow-hidden'>
      <AppNavigation />
      <AuthHandler />
      <div className='flex-auto overflow-hidden bg-white'>{children}</div>
    </div>
  )
}
