import {createAction} from '@reduxjs/toolkit'
import {
  SET_FILTERS,
  UPDATE_STATUS,
  TOGGLE_PASSIVE_COLUMNS,
  TOGGLE_HISTORIC_EVENTS,
  FETCH_SUCCESS,
  LIST_UPDATE_EVENT,
  UPDATE_EVENT_STATUS,
} from './constants'
import type {
  FlowActionFetchSuccessPayload,
  FlowActionListDeleteEventPayload,
  FlowActionListUpdateEventPayload,
  FlowActionMeta,
  FlowActionSetFiltersPayload,
  FlowActionToggleHistoricEventsPayload,
  FlowActionTogglePassiveColumnsPayload,
  FlowActionUpdateEventStatusPayload,
  FlowActionUpdateStatusPayload,
} from './types'

export const setFilters = createAction(
  SET_FILTERS,
  (payload: FlowActionSetFiltersPayload, meta: FlowActionMeta) => ({
    payload,
    meta,
  })
)

export const updateStatus = createAction(
  UPDATE_STATUS,
  (payload: FlowActionUpdateStatusPayload, meta: FlowActionMeta) => ({
    payload,
    meta,
  })
)

export const updateEventStatus = createAction(
  UPDATE_EVENT_STATUS,
  (payload: FlowActionUpdateEventStatusPayload, meta: FlowActionMeta) => ({
    payload,
    meta,
  })
)

export const togglePassiveColumns = createAction(
  TOGGLE_PASSIVE_COLUMNS,
  (payload: FlowActionTogglePassiveColumnsPayload, meta: FlowActionMeta) => ({
    payload,
    meta,
  })
)

export const toggleHistoricEvents = createAction(
  TOGGLE_HISTORIC_EVENTS,
  (payload: FlowActionToggleHistoricEventsPayload, meta: FlowActionMeta) => ({
    payload,
    meta,
  })
)

export const fetchSuccess = createAction(
  FETCH_SUCCESS,
  (payload: FlowActionFetchSuccessPayload, meta: FlowActionMeta) => ({
    payload,
    meta,
  })
)

export const listUpdateEvent = createAction(
  LIST_UPDATE_EVENT,
  (payload: FlowActionListUpdateEventPayload, meta: FlowActionMeta) => ({
    payload,
    meta,
  })
)
export const listDeleteEvent = createAction(
  FETCH_SUCCESS,
  (payload: FlowActionListDeleteEventPayload, meta: FlowActionMeta) => ({
    payload,
    meta,
  })
)
