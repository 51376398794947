import {createElement, useContext} from 'react'
import cx from 'classnames'
import {TabsContext} from './context'
import {getKindStyle} from './helpers'
import styles from './Tabs.module.css'

interface TabPanelProps {
  component?: string | false
  className?: string
  name: string
}

export const TabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = ({
  component = 'div',
  className,
  children,
  name,
}) => {
  const {kind, selectedTab} = useContext(TabsContext)

  if (selectedTab !== name) {
    return null
  }

  if (component) {
    return createElement(
      component,
      {className: cx(getKindStyle(kind), styles.tabPanel, className)},
      children
    )
  }

  return children as unknown as JSX.Element
}
