import {getISOWeek} from 'date-fns'
import type {CSSProperties} from 'styled-components'
import styled from 'styled-components'
import {Time} from 'components/elements'
import {colors, fonts, spacing} from 'styles/variables'
import type {MonthString} from 'types/generic'

const HeaderWeekNumber = styled.span`
  align-self: flex-start;
  flex: 1 1 auto;
  font-family: ${fonts.fontBold};
`

function getWeekNumber(date: Date) {
  if (date.getDate() === 1 || date.getDay() === 1) {
    return <HeaderWeekNumber>{getISOWeek(date)}</HeaderWeekNumber>
  } else {
    return null
  }
}

interface HeaderDayContainerProps {
  isToday?: boolean
  isWeekend?: boolean
}

const HeaderDayContainer = styled.div<HeaderDayContainerProps>`
  padding: ${spacing.u2_5} ${spacing.u2};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  line-height: 13px;
  background: ${colors.white};

  & * {
    line-height: 13px;
  }

  ${({isWeekend}) =>
    isWeekend &&
    `
    background: ${colors.westar};`}

  ${({isToday}) =>
    isToday &&
    `
    background: ${colors.hawkesBlue};`}
`

const HeaderWeekDay = styled.span`
  flex: 0 0 20px;
  text-transform: lowercase;
  color: ${colors.secondaryTextColor};
  text-align: right;
`

const HeaderDate = styled.span`
  flex: 0 0 20px;
  font-family: ${fonts.fontBold};
  text-align: right;
`

interface HeaderDayProps {
  className?: string
  style?: CSSProperties
  date: Date
  isWeekend?: boolean
  isToday?: boolean
}

export const HeaderDay = ({
  date,
  isWeekend,
  isToday,
  className,
  style,
}: HeaderDayProps) => (
  <HeaderDayContainer
    className={className}
    style={style}
    isWeekend={isWeekend}
    isToday={isToday}
  >
    {getWeekNumber(date)}
    <HeaderWeekDay>
      <Time obj={date} format='DayOfWeek' />
    </HeaderWeekDay>
    <HeaderDate>
      <Time obj={date} format='DayOfMonth' />
    </HeaderDate>
  </HeaderDayContainer>
)

const HeaderContainer = styled.div`
  padding: 10px 8px;
  font-weight: bold;
  text-align: center;
  background: ${colors.oxfordBlue};
  color: ${colors.white};
  white-space: nowrap;
`

interface HeaderActProps {
  className?: string
  style?: CSSProperties
  name?: string
}

export const HeaderAct = ({name, className, style}: HeaderActProps) => (
  <HeaderContainer className={className} style={style}>
    {name}
  </HeaderContainer>
)

interface HeaderMonthProps {
  className?: string
  style?: CSSProperties
  month: MonthString
}

export const HeaderMonth = ({month, className, style}: HeaderMonthProps) => (
  <HeaderContainer className={className} style={style}>
    <Time obj={month} format='MonthYear' />
  </HeaderContainer>
)
