import type {PayloadAction} from '@reduxjs/toolkit'
import type {SagaIterator} from 'redux-saga'
import {takeLatest, put, call} from 'redux-saga/effects'
import {standardErrorAction} from 'helpers/saga'
import {createEntity} from 'store/entity/asyncHelpers'
import type {Tour} from 'types/tour'
import type {EntityCreateParams} from '../../types'
import {createTourSuccess} from '../actions'
import {CREATE, CREATE_FAILURE} from '../constants'

export function* createTour(
  action: PayloadAction<EntityCreateParams<Tour>>
): SagaIterator<Tour> {
  try {
    const payload = yield call(() =>
      createEntity({
        kind: 'TOUR',
        data: action.payload,
      })
    )
    yield put(createTourSuccess(payload))
    return payload
  } catch (e: any) {
    yield put(standardErrorAction(CREATE_FAILURE, e))
    throw e
  }
}

export function* createSaga() {
  yield takeLatest(CREATE, createTour)
}
