import type {IconName} from 'components/elements/Icon'
import type {DepartmentCustomFieldType} from 'types/department'
import type {DepartmentNavigation, EntityTypes, EntityViews} from './types'

export function newName(kind: EntityTypes): string {
  switch (kind) {
    case 'PERSON':
      return 'New user'
    case 'TOUR':
      return 'New tour'
    case 'ACT':
      return 'New act'
    case 'ORGANIZER':
      return 'New organizer'
    case 'VENUE':
      return 'New stage'
    default:
      return 'New something'
  }
}

export function getIcon(kind: EntityTypes): IconName {
  switch (kind) {
    case 'PERSON':
      return 'user'
    case 'TOUR':
      return 'road'
    case 'ACT':
      return 'microphone'
    case 'ORGANIZER':
    case 'VENUE':
      return 'phone'
    case 'BOOKING_AGENCY':
      return 'roskilde'
    default:
      return 'warning'
  }
}

export function buildNavigationFromKind(
  kind: EntityTypes
): DepartmentNavigation[] | undefined {
  if (!['ACT', 'ORGANIZER', 'VENUE', 'BOOKING_AGENCY'].includes(kind)) {
    return
  }
  const rtn: DepartmentNavigation[] = []

  // Main tabs
  switch (kind) {
    case 'ACT':
      rtn.push({title: 'Act', value: 'department'})
      break
    case 'ORGANIZER':
    case 'VENUE':
      rtn.push({title: 'Organizer', value: 'department'})
      break
    case 'BOOKING_AGENCY':
      rtn.push({title: 'Booking Agency', value: 'department'})
      break
  }
  // Stages
  if (['ORGANIZER', 'VENUE'].includes(kind)) {
    rtn.push({title: 'Stages', value: 'stages'})
  }
  // Affiliations
  if (['ACT', 'ORGANIZER', 'VENUE', 'BOOKING_AGENCY'].includes(kind)) {
    rtn.push({title: 'Affiliations', value: 'affiliations'})
  }
  // Organization
  if (['ORGANIZER', 'VENUE'].includes(kind)) {
    rtn.push({title: 'Company', value: 'organization'})
  }

  return rtn
}

export function getInitialTab(kind: EntityTypes): EntityViews {
  switch (kind) {
    case 'PERSON':
      return 'person'
    case 'TOUR':
      return 'tour'
    case 'VENUE':
      return 'stages'
    default:
      return 'department'
  }
}

export function getCustomLabel(input: DepartmentCustomFieldType): string {
  switch (input) {
    case 'sap_act_code':
      return 'Act Code'
    case 'sap_act_asl':
      return 'ASL code'
    case 'sap_organizer_customer_id':
      return 'Customer ID'
  }
}
