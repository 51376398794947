import {Input} from 'components/form'
import {Section, Column, Row} from 'containers/Form'

export const Name = () => (
  <Section label='Name'>
    <Row>
      <Column>
        <Input model='name' kind='text' placeholder='Name' />
      </Column>
      <Column />
    </Row>
  </Section>
)
