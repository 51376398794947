import {useQuery} from '@tanstack/react-query'
import {queryKeys} from 'api/queries'
import {getTTL} from 'utils'

export const useCountries = () =>
  useQuery({
    ...queryKeys.basicData.countries,
    staleTime: getTTL('INFINITE'),
  })

export const useCurrencies = () =>
  useQuery({
    ...queryKeys.basicData.currencies,
    staleTime: getTTL('INFINITE'),
  })

export const useLanguages = () =>
  useQuery({
    ...queryKeys.basicData.languages,
    staleTime: getTTL('INFINITE'),
  })
