import {useDispatch} from 'react-redux'
import type {ButtonProps} from 'components/elements'
import {Button} from 'components/elements'
import {useModal} from 'hooks/useModal'
import {changeState, deleteContract} from 'store/contract/actions'
import {prompt} from 'store/prompt'
import {useContractActions} from '../helpers'

const DeleteContractButton = (props: ButtonProps) => {
  const {closeModal} = useModal()
  const dispatch = useDispatch()
  const {id, status} = useContractActions()

  if (!id) {
    return null
  }

  if (status !== 'CONTRACT_STATUS_TBC') {
    return null
  }

  const handleClick = () => {
    new Promise((resolve) => {
      resolve(dispatch(deleteContract({id})))
    }).then(() => closeModal())
  }

  return (
    <Button
      {...props}
      icon='cancel'
      onClick={handleClick}
      theme='dangerous'
      key='delete'
      label='Delete'
    />
  )
}

const CancelContractButton = (props: ButtonProps) => {
  const dispatch = useDispatch()
  const {id, status} = useContractActions()

  if (!id || !status) {
    return null
  }

  if (
    ![
      'CONTRACT_STATUS_CONFIRMED',
      'CONTRACT_STATUS_FINALIZED',
      'CONTRACT_STATUS_SIGNED',
      'CONTRACT_STATUS_UNSIGNED',
      'CONTRACT_STATUS_PARTIALLY_SIGNED',
      'CONTRACT_STATUS_FULLY_SIGNED',
    ].includes(status)
  ) {
    return null
  }

  const handleClick = async () => {
    try {
      ;(await dispatch(
        prompt({
          title: 'Sure you want to cancel?',
          description:
            'When the contract is cancelled, you cannot reopen it again. You can still duplicate a cancelled contract and start over again.',
          confirmText: 'Yes, cancel the contract',
          cancelText: 'No',
          kind: 'danger',
        })
      )) as unknown as Promise<unknown>
      dispatch(changeState('CONTRACT_STATUS_CANCELLED'))
    } catch {
      console.log('cancelled')
    }
  }

  return (
    <Button
      {...props}
      icon='cancel'
      onClick={handleClick}
      theme='dangerous'
      key='cancel'
      label='Cancel'
    />
  )
}

export const CancelContract = (props: ButtonProps) => (
  <>
    <DeleteContractButton {...props} />
    <CancelContractButton {...props} />
  </>
)
