import {useState} from 'react'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'
import {Icon, MobileMenu} from 'components/elements'
import {useAuth} from 'hooks/useAuth'
import {useIsDesktop} from 'hooks/useIsDesktop'
import {colors, effects, mq, spacing} from 'styles/variables'
import {AppNavigationContext} from './context'
import {useCurrentBookingViewPath} from './hooks'
import {MenuItem} from './MenuItem'
import {Content} from './UserMenu/componentsUserMenu'
import {UserMenu as UserMenuComp} from './UserMenu/UserMenu'

interface ContainerProps {
  maximized: boolean
}
const Container = styled.div<ContainerProps>`
  flex: 0 0 auto;
  background: ${colors.ebonyClay};
  overflow: hidden;
  box-sizing: border-box;
  position: relative;

  @media (${mq.desktop}) {
    transition: all ${effects.transitionTime} ease-in-out;
    flex: 0 0 ${({maximized}) => (maximized ? '204px' : '56px')};
  }
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.u2_5};

  @media (${mq.desktop}) {
    padding: unset;
    align-items: unset;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-flow: column nowrap;
  }
`

const Header = styled.header`
  @media (${mq.desktop}) {
    height: 64px;
    display: flex;
    flex-flow: row nowrap;
  }
`

const UserMenu = styled(UserMenuComp)`
  flex: 1 1 auto;
`

const MobileMenuButton = styled.div`
  margin: calc(${spacing.u2_5} * -1);
  background-color: ${colors.oxfordBlue};
  color: ${colors.white};
  width: 40px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Primary = styled.nav`
  flex: 0 0 auto;

  @media (${mq.desktop}) {
    flex: 1 1 auto;
    margin: 0;
    list-style: none;
    transition: all ${effects.transitionTime} ease-in-out;
  }
`

const ToggleMaximized = styled.div`
  display: none;

  @media (${mq.desktop}) {
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.midGray};
    transition: color ${effects.transitionTime} ease-in-out;
    cursor: pointer;

    &:hover {
      color: ${colors.pictonBlue};
    }
  }
`

interface MenuItemsProps {
  onItemClick?: () => void
}

const MenuItems: React.FC<React.PropsWithChildren<MenuItemsProps>> = ({
  onItemClick = () => undefined,
}) => {
  const {hasRole} = useAuth()
  const {pathname} = useLocation()
  const currentBookingPath = useCurrentBookingViewPath()

  return (
    <div>
      <MenuItem
        to={currentBookingPath}
        active={pathname.startsWith('/booking')}
        onClick={onItemClick}
        icon='calendar'
        title='Booking'
      />
      <MenuItem
        to='/production'
        active={pathname.startsWith('/production')}
        onClick={onItemClick}
        icon='road'
        title='Production'
      />
      <MenuItem
        to='/reports'
        active={pathname.startsWith('/reports')}
        onClick={onItemClick}
        icon='report'
        title='Reports'
      />
      {hasRole('BOOKING_AGENCY_ACCOUNTANT') && (
        <MenuItem
          to='/accounting'
          active={pathname.startsWith('/accounting')}
          onClick={onItemClick}
          icon='economy'
          title='Accounting'
        />
      )}
      {!import.meta.env.PROD && (
        <MenuItem
          active={pathname.startsWith('/playground')}
          onClick={onItemClick}
          to='/playground'
          icon='star'
          title='Playground'
        />
      )}
    </div>
  )
}

export const AppNavigation = () => {
  const [maximized, setMaximized] = useState(false)
  const contextValue = {maximized}
  const isDesktop = useIsDesktop()

  const content = isDesktop ? (
    <>
      <Header>
        <UserMenu />
      </Header>

      <Primary>
        <MenuItems />
      </Primary>

      <ToggleMaximized onClick={() => setMaximized(!maximized)}>
        <Icon icon={maximized ? 'chevron-left' : 'chevron-right'} size='32' />
      </ToggleMaximized>
    </>
  ) : (
    <MobileMenu
      title='Navigation'
      target={({openMenu}) => (
        <MobileMenuButton onClick={openMenu}>
          <Icon icon='menu' size='20' />
        </MobileMenuButton>
      )}
    >
      {({closeMenu}) => (
        <>
          <Content>
            <MenuItems onItemClick={closeMenu} />
            <UserMenu />
          </Content>
        </>
      )}
    </MobileMenu>
  )

  return (
    <AppNavigationContext.Provider value={contextValue}>
      <Container maximized={maximized}>
        <Inner>{content}</Inner>
      </Container>
    </AppNavigationContext.Provider>
  )
}
