import type {RoleType} from 'types/role'

interface RoleTypeOption {
  displayName: string
  affiliationTexts: {
    emptyTitle: string
    emptyDescription: string
  }
}

export const ROLE_TYPE_OPTIONS: {[key in RoleType]?: RoleTypeOption} = {
  ACT_ADMIN: {
    displayName: 'Admins',
    affiliationTexts: {
      emptyTitle:
        'Click the plus button above to add the people that should be able to administer this Act',
      emptyDescription:
        'This will give them the power to change and delete data about this Act',
    },
  },
  ACT_MEMBER: {
    displayName: 'Members of the Act',
    affiliationTexts: {
      emptyTitle:
        'Click the plus button above to add the people in the Act itself',
      emptyDescription:
        'This will save you time when you later on have to give them access to upcoming tours',
    },
  },
  ACT_BOOKING_AGENCY_BOOKER: {
    displayName: 'Booking agency bookers',
    affiliationTexts: {
      emptyTitle: 'Click the plus button above to add a booking agency booker',
      emptyDescription:
        'By associating people in your organization to an act, they can easily find and manage the act',
    },
  },
  BOOKING_AGENCY_ADMIN: {
    displayName: 'Admins',
    affiliationTexts: {
      emptyTitle:
        'Click the plus button above to add the people that should be able to administer this Booking Agency',
      emptyDescription:
        'This will give them the power to change and delete data about this Booking Agency',
    },
  },
  BOOKING_AGENCY_BOOKER: {
    displayName: 'Bookers',
    affiliationTexts: {
      emptyTitle:
        'Click the plus button above to add the colleagues that are representing the Act',
      emptyDescription:
        'This will make Whnue capable of showing only the relevant acts for your affected colleagues',
    },
  },
  BOOKING_AGENCY_ACCOUNTANT: {
    displayName: 'Accounting',
    affiliationTexts: {
      emptyTitle:
        'Click the plus button above to add the colleagues to the accounting department',
      emptyDescription: 'TODO',
    },
  },
  ORGANIZER_ADMIN: {
    displayName: 'Admins',
    affiliationTexts: {
      emptyTitle:
        'Click the plus button above to add the people that should be able to administer this Organizer',
      emptyDescription:
        'This will give them the power to change and delete data about this Organizer',
    },
  },
  ORGANIZER_STAFF: {
    displayName: 'Employees',
    affiliationTexts: {
      emptyTitle: 'Click the plus button above to add all relevant employees',
      emptyDescription:
        'This will make them quickly available whenever you use this in the system',
    },
  },
  TOUR_MANAGEMENT_ADMIN: {
    displayName: 'Admins',
    affiliationTexts: {
      emptyTitle:
        'Click the plus button above to add the people that should be able to administer this Tour Management Agency',
      emptyDescription:
        'This will give them the power to change and delete data about this Tour Management Agency',
    },
  },
  TOUR_MANAGEMENT_STAFF: {
    displayName: 'Production Managers',
    affiliationTexts: {
      emptyTitle:
        'Click the plus button above to add the colleagues that are Production Managers',
      emptyDescription:
        'This will make them capable of accessing the tour managing features of Whnue',
    },
  },
  VENUE_STAFF: {
    displayName: 'Employees',
    affiliationTexts: {
      emptyTitle: 'Click the plus button above to add all relevant employees',
      emptyDescription:
        'This will make them quickly available whenever you use this in the system',
    },
  },
}
