import {useFormikContext} from 'formik'
import {Input, NumberInput} from 'components/form'
import {Column, InlineText, InlineSpacer} from 'containers/Form'
import type {Contract} from 'types/contract'
import type {ItemListRendererProps} from '../util/ItemList/ItemListRow'

export const ContractTicketLineItem: React.FC<
  React.PropsWithChildren<ItemListRendererProps>
> = ({model}) => {
  const {values} = useFormikContext<Contract>()
  const currency = values.currency ?? 'N/A'

  return (
    <>
      <Column>
        <Input
          model={`${model}.text`}
          kind='text'
          placeholder='Title'
          grid='5'
        />
        <InlineSpacer grid='fill' />
        <NumberInput model={`${model}.price`} placeholder='Price' grid='3' />
        <InlineText text={currency} grid='2' />
        <NumberInput model={`${model}.fee`} placeholder='Fee' grid='3' />
        <InlineText text={currency} grid='2' />
        <Input
          kind='number'
          model={`${model}.capacity`}
          placeholder='Amount'
          grid='3'
          min='0'
        />
      </Column>
    </>
  )
}
