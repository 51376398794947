import isNumber from 'lodash/isNumber'
import * as yup from 'yup'

export const validationSchema = yup.object({
  show: yup.object({
    stakeholder_act: yup.object({
      department: yup.object({
        id: yup.number().integer().nullable().required().label('Act'),
      }),
    }),
    stakeholder_organizer: yup.object({
      department: yup.object({
        id: yup.number().integer().nullable().required().label('Organizer'),
      }),
    }),
    stakeholder_venue: yup.object({
      department: yup.object({
        id: yup.number().integer().nullable().required().label('Stage'),
      }),
    }),
    text: yup.string().nullable(),
    tickets: yup.array().of(
      yup.object({
        text: yup.string().required().label('Ticket description'),
        price: yup.number().nullable().required().label('Ticket label'),
        fee: yup.number().nullable().required().label('Ticket fee'),
        capacity: yup.number().nullable().required().label('Amount'),
      })
    ),
    guest_list_capacity: yup.number().nullable(),
    production_status: yup.string().required().label('Production status'),
  }),
  commission: yup
    .number()
    .integer()
    .nullable()
    .transform(function (value) {
      return isNumber(value) ? value : 0
    }),
  economic_terms_id: yup
    .number()
    .integer()
    .nullable()
    .required()
    .label('Economic terms'),
  supplementary_contract: yup.object({
    id: yup
      .number()
      .integer()
      .nullable()
      .required()
      .label('Supplementary contract'),
  }),
})
