import {useContext} from 'react'
import {useLogoutMutation, useSwitchUserContextMutation} from 'api'
import {AuthContext} from 'context/Auth'
import type {RoleType} from 'types/role'

export const useAuth = () => {
  const {token, user, organization} = useContext(AuthContext)
  const {mutate: logout} = useLogoutMutation()
  const {mutate: switchUserContext} = useSwitchUserContextMutation()

  const affiliations = organization.affiliations
  const userAffiliation = (organization.affiliations ?? []).find(
    (a) => a.person.id === user?.id
  )
  const roles = userAffiliation ? userAffiliation.roles : []

  return {
    token,
    id: user.id,
    user,
    organization,
    organizationId: organization.id,
    affiliations,
    roles,
    hasRole: (searchRole: RoleType): boolean =>
      !!roles.find((role) => role.type === searchRole),
    logout,
    switchUserContext,
  }
}
