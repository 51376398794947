import get from 'lodash/get'
import type {SagaIterator} from 'redux-saga'
import {takeLatest, fork, select, call, put} from 'redux-saga/effects'
import {getApiUrl} from 'api'
import {dateDeforeAndAfter} from 'helpers/api'
import {isBookingViewsWithAct} from 'helpers/booking'
import {sagaFetch, standardErrorAction} from 'helpers/saga'
import type {
  BookingStoreViewsData,
  BookingViewsWithFlow,
} from 'store/booking/types'
import type {RootStore} from 'store/types'
import {fetchPlannersStart, fetchPlannersSuccess} from '../actions'
import {FETCH, FETCH_FAILURE} from '../constants'

function* ensureParams(view: BookingViewsWithFlow) {
  const bookingView: BookingStoreViewsData | undefined = yield select(
    ({booking}: RootStore) =>
      view === 'flow' ? undefined : booking.views?.[view]
  )

  if (!bookingView) {
    throw new Error(`Fetch Planner: ${view}: No view selected`)
  }

  if (view === 'flow') {
    throw new Error(`Fetch Planner: ${view}: View not supported`)
  }

  if (isBookingViewsWithAct(bookingView)) {
    if (!bookingView.act) {
      throw new Error(`Fetch Planner: ${view}: Act required`)
    }
  }

  return true
}

function* getFetchParams(view: BookingViewsWithFlow) {
  const {
    startDay,
    endDay,
    month,
    startMonth,
    endMonth,
    showKind,
    act,
    eventTypes,
  } = yield select(({booking}) => get(booking, `views.${view}`))

  // Handle dates
  const {before, after} = dateDeforeAndAfter({
    startDay,
    endDay,
    month,
    startMonth,
    endMonth,
  })

  // Handle actIds
  const actIds = showKind
    ? showKind === 'represented_by_me'
      ? 'represented_by_me'
      : undefined
    : act

  return {
    after,
    before,
    act_ids: actIds,
    event_types: eventTypes,
  }
}

export function* fetchPlanners(): SagaIterator {
  yield put(fetchPlannersStart())
  try {
    const view = yield select(({booking}) => booking.shared.view)

    yield call(ensureParams, view)

    const queryParams = yield call(getFetchParams, view)

    const payload = yield call(
      sagaFetch,
      getApiUrl('plannerEvents'),
      'get',
      null,
      {
        queryParams,
      }
    )
    yield put(fetchPlannersSuccess(payload))
  } catch (error: any) {
    if (
      error.message.includes('No view selected') ||
      error.message.includes('View not supported') ||
      error.message.includes('Act required')
    ) {
      yield put(standardErrorAction(FETCH_FAILURE, error))
    } else {
      yield put(standardErrorAction(FETCH_FAILURE, error))
      throw error
    }
  }
}

export function* fetchSaga() {
  yield fork(function* () {
    yield takeLatest(FETCH, fetchPlanners)
  })
}
