import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {usePeople} from 'api'
import {useAuth} from 'hooks/useAuth'
import {
  createActBookingAgencyBooker,
  createRole,
  deleteActBookingAgencyBooker,
  deleteRole,
} from 'store/entity/actions'
import type {RootStore} from 'store/types'
import type {StorableDepartment} from 'types/department'
import type {EntityCardData} from 'types/entityCard'
import type {RoleType} from 'types/role'
import {filterPeople} from './helpers'

const hasRole = (needle: RoleType) => (haystack: RoleType | RoleType[]) =>
  Array.isArray(haystack) ? haystack.includes(needle) : haystack === needle

interface UseSearchResult {
  results: EntityCardData[]
}
interface UseSearchSelect {
  onSelect: (id: number) => void
}
interface UseSearchMeta {
  showAllResults: boolean
  minSearchLength: number
  showNewPersonButton: boolean
}
interface UseSearch extends UseSearchResult, UseSearchSelect, UseSearchMeta {
  query: string
  setQuery: (q: string) => void
}

export const useSearchResult = (
  roleType: RoleType,
  query: string
): UseSearchResult => {
  const {data: allPeople} = usePeople()

  let associatedIds: number[]

  const {organization} = useAuth()
  const department = useSelector<RootStore, StorableDepartment>(
    (store) => store.entity.department.data
  )

  const currentOrganizationRolesPersonIds = organization
    ? organization.affiliations.map((affiliation) => affiliation.person.id)
    : []

  const currentBookingAgencyBookersIds = (
    organization
      ? organization.affiliations.filter((affiliation) =>
          affiliation.roles.some((role) =>
            hasRole(role.type)('BOOKING_AGENCY_BOOKER')
          )
        )
      : []
  ).map((affiliation) => affiliation.person.id)

  let people = allPeople ?? []

  if (roleType === 'ACT_BOOKING_AGENCY_BOOKER') {
    people = people.filter((person) =>
      currentBookingAgencyBookersIds.includes(person.id)
    )
  } else if (
    hasRole(roleType)([
      'BOOKING_AGENCY_BOOKER',
      'BOOKING_AGENCY_ACCOUNTANT',
      'BOOKING_AGENCY_ADMIN',
    ])
  ) {
    people = people.filter((person) =>
      currentOrganizationRolesPersonIds.includes(person.id)
    )
  }

  if (hasRole(roleType)('ACT_BOOKING_AGENCY_BOOKER')) {
    associatedIds =
      'agency_bookers' in department
        ? department.agency_bookers.map(
            (agencyBooker) => agencyBooker.affiliation.person.id
          )
        : []
  } else {
    associatedIds = department.roles
      .filter((role) => role.type === roleType)
      .map((role) => role.affiliation.person.id)
  }

  return {
    results: people
      .filter((person) => filterPeople(person, query))
      .filter((person) => !associatedIds.includes(person.id))
      .map((person) => ({...person, type: 'LIST_PERSON'})),
  }
}

export const useSearchSelect = (roleType: RoleType): UseSearchSelect => {
  const dispatch = useDispatch()
  if (hasRole(roleType)('ACT_BOOKING_AGENCY_BOOKER')) {
    return {
      onSelect: (id: number) =>
        dispatch(createActBookingAgencyBooker({personId: id})),
    }
  } else {
    return {
      onSelect: (id: number) => {
        dispatch(createRole({personId: id, roleType}))
      },
    }
  }
}

export const useSearchMeta = (roleType: RoleType) => {
  if (
    hasRole(roleType)([
      'ACT_BOOKING_AGENCY_BOOKER',
      'BOOKING_AGENCY_BOOKER',
      'BOOKING_AGENCY_ACCOUNTANT',
      'BOOKING_AGENCY_ADMIN',
    ])
  ) {
    return {
      showAllResults: true,
      minSearchLength: 0,
      showNewPersonButton: false,
    }
  } else {
    return {
      showAllResults: false,
      minSearchLength: 3,
      showNewPersonButton: true,
    }
  }
}

export const useSearch = (roleType: RoleType): UseSearch => {
  const [query, setQuery] = useState('')
  const {results} = useSearchResult(roleType, query)
  const {onSelect} = useSearchSelect(roleType)
  const {showAllResults, minSearchLength, showNewPersonButton} =
    useSearchMeta(roleType)

  return {
    query,
    setQuery,
    results,
    onSelect,
    showAllResults,
    minSearchLength,
    showNewPersonButton,
  }
}

export const useContent = (roleType: RoleType) => {
  const dispatch = useDispatch()

  const onDelete = (id: number) => {
    if (hasRole(roleType)('ACT_BOOKING_AGENCY_BOOKER')) {
      dispatch(deleteActBookingAgencyBooker({id}))
    } else {
      dispatch(deleteRole({id}))
    }
  }

  return {
    onDelete,
  }
}
