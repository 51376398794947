import {ViewHeader} from 'app/main/Booking/components'
import {Container, Main} from 'containers/Core'
import {compact} from 'utils'

export interface AppLayoutProps {
  title: string
  subtitle?: string
  headerFilters?: any
  filters?: any
  className?: string
}

export const AppLayout: React.FC<React.PropsWithChildren<AppLayoutProps>> = ({
  children,
  title,
  subtitle,
  headerFilters,
  filters,
  className,
}) => {
  const compoundTitle = compact([title, subtitle]).join(' | ')

  return (
    <Container>
      <ViewHeader title={compoundTitle} filters={filters}>
        {headerFilters}
      </ViewHeader>

      <Main flex className={className}>
        {children}
      </Main>
    </Container>
  )
}
