import {combineReducers} from 'redux'
import {BOOKING_VIEWS} from 'options/booking'
import {SET_ACT, SET_START_MONTH, SET_END_MONTH} from '../../constants'
import {act, month, monthWithMinMonth} from '../../helpers/reducers'

export const singleMonthsInColumnsReducer = combineReducers({
  act: act(SET_ACT, BOOKING_VIEWS.SINGLE),
  startMonth: month(SET_START_MONTH, BOOKING_VIEWS.SINGLE_MONTHS_IN_COLUMNS),
  endMonth: monthWithMinMonth(
    SET_END_MONTH,
    BOOKING_VIEWS.SINGLE_MONTHS_IN_COLUMNS,
    18,
    SET_START_MONTH
  ),
})
