import {flatten, filter, find, includes, map} from 'lodash'
import {Select} from './Select'
import type {SelectOptionItem, SelectProps} from './types'

export const SimpleSelect: React.FC<React.PropsWithChildren<SelectProps>> = ({
  value,
  onChange,
  options: optionsRaw,
  isMulti,
  getOptionValue = (opt: SelectOptionItem) => opt.value,
  ...props
}) => {
  const handleChange = (raw: any) => {
    const value = isMulti
      ? map(raw, (o) => getOptionValue(o))
      : raw
      ? getOptionValue(raw)
      : null

    onChange(value)
  }

  const getValue = () => {
    if (value === undefined) {
      return
    }
    if (value === null) {
      return null
    }

    const options = flatten(
      (optionsRaw ?? []).map((option) =>
        'options' in option ? option.options : option
      )
    )

    return isMulti
      ? filter(options, (option) => includes(value, getOptionValue(option)))
      : find(options, (option) => getOptionValue(option) === value)
  }

  return (
    <Select
      {...props}
      isMulti={isMulti}
      value={getValue()}
      options={optionsRaw}
      onChange={handleChange}
    />
  )
}
