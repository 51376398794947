import {takeLatest, fork, select, put} from 'redux-saga/effects'
import {
  SHOW_CREATED_SUCCESS,
  SHOW_UPDATED_SUCCESS,
  SHOW_DESTROYED_SUCCESS,
  LIST_UPDATE_EVENT,
  LIST_DESTROY_EVENT,
} from '../constants'

function* handleShowSaved(action) {
  const {payload} = action
  const currentFilters = yield select(
    ({booking}) => booking.views[booking.shared.view]
  )

  if (!currentFilters || (!currentFilters.showKind && !currentFilters.act)) {
    return
  }

  if (currentFilters.act && payload.act.id !== currentFilters.act) {
    return
  }

  yield put({type: LIST_UPDATE_EVENT, payload})
}

function* handleShowDestroyed(action) {
  const {payload} = action
  yield put({type: LIST_DESTROY_EVENT, payload})
}

export function* callbackReactionsSaga() {
  // CREATE
  yield fork(function* () {
    yield takeLatest(
      [SHOW_CREATED_SUCCESS, SHOW_UPDATED_SUCCESS],
      handleShowSaved
    )
    yield takeLatest([SHOW_DESTROYED_SUCCESS], handleShowDestroyed)
  })
}
