import cx from 'classnames'
import {useFormikContext} from 'formik'
import type {TabProps} from 'components/elements/Tabs'
import {Tab} from 'components/elements/Tabs'
import styles from 'components/elements/Tabs/Tabs.module.css'
import {errorCountForGroup} from 'helpers/form'

interface TabWithErrorCountProps extends TabProps {
  keys?: string[]
  text: string
}

export const TabWithErrorCount: React.FC<
  React.PropsWithChildren<TabWithErrorCountProps>
> = ({keys, name, text}) => {
  const {errors, touched, submitCount} = useFormikContext()
  const shouldBeTouched = submitCount > 0
  const bubbleCount = errorCountForGroup(
    {errors, touched, keys},
    {shouldBeTouched}
  )

  return (
    <Tab
      name={name}
      className={cx(styles.tabWithBubble, {
        [styles.tabHasBubble]: !!bubbleCount,
      })}
    >
      <div className={styles.tabContent}>{text}</div>
      {!!bubbleCount && <div className={styles.tabBubble}>{bubbleCount}</div>}
    </Tab>
  )
}
