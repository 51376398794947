import type {PayloadAction} from '@reduxjs/toolkit'
import type {SagaIterator} from 'redux-saga'
import {takeLatest, put, call} from 'redux-saga/effects'
import {queryKeys} from 'api'
import {standardErrorAction} from 'helpers/saga'
import {queryClient} from 'main'
import {updateEntity} from 'store/entity/asyncHelpers'
import type {Tour} from 'types/tour'
import type {EntityUpdateParams} from '../../types'
import {updatePersonSuccess} from '../actions'
import {UPDATE, UPDATE_FAILURE} from '../constants'

export function* updatePerson(
  action: PayloadAction<EntityUpdateParams<Tour>>
): SagaIterator {
  try {
    const payload = yield call(() =>
      updateEntity({
        kind: 'PERSON',
        id: action.payload.id,
        data: action.payload,
      })
    )
    queryClient.invalidateQueries(queryKeys.people.list.queryKey)
    yield put(updatePersonSuccess(payload))
    return payload
  } catch (e: any) {
    yield put(standardErrorAction(UPDATE_FAILURE, e))
    throw e
  }
}

export function* updateSaga() {
  yield takeLatest(UPDATE, updatePerson)
}
