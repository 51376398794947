import {useFormikContext} from 'formik'
import {Button, Tooltip} from 'components/elements'
import type {InlineContentProps} from 'containers/Form'
import {InlineContent} from 'containers/Form'
import {useEntity} from 'hooks/useEntity'
import {queryClient} from 'main'
import type {Contract} from 'types/contract'

interface ViewOrganizerProps extends InlineContentProps {
  model: string
  label?: string
}

export const ViewOrganizer: React.FC<
  React.PropsWithChildren<ViewOrganizerProps>
> = ({model, label = 'View organizer', ...props}) => {
  const {getFieldProps} = useFormikContext<Contract>()
  const {openOrganizer} = useEntity()
  const organizerId = getFieldProps(model).value

  const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    openOrganizer(organizerId, () => {
      queryClient.invalidateQueries(['organizer', organizerId])
    })
  }

  return (
    <InlineContent {...props} aligned>
      <Tooltip content={label}>
        <Button
          onClick={handleClick}
          disabled={!organizerId}
          round
          theme='light'
          icon='new-window'
        />
      </Tooltip>
    </InlineContent>
  )
}
