import sortBy from 'lodash/sortBy'
import {CONTRACT_FINANCIAL_EXPORT_STATUS_SORTING} from 'options/contract'
import type {PlannerEconomy} from 'types/planner'

export const sortData = (
  data: PlannerEconomy[],
  by: string,
  direction: string
) => {
  let sortedData

  switch (by) {
    case 'date':
      sortedData = sortBy(data, 'rawDate')
      break
    case 'sapCustomerId':
      sortedData = sortBy(data, (item) =>
        item.sapCustomerId === null
          ? direction === 'DESC'
            ? -999999999999
            : 999999999999
          : parseInt(item.sapCustomerId)
      )
      break
    case 'exportStatus':
      sortedData = sortBy(data, (d) => {
        return d.exportStatus
          ? CONTRACT_FINANCIAL_EXPORT_STATUS_SORTING.indexOf(d.exportStatus)
          : 999
      })
      break
    default:
      sortedData = sortBy(data, by)
  }

  if (direction === 'DESC') {
    sortedData.reverse()
  }

  return sortedData
}
