import * as yup from 'yup'
import {
  phoneNumbersValidationSchema,
  emailAddressesValidationSchema,
  postalAddressValidationSchema,
  websiteValidationSchema,
} from './shared'

export const personValidationSchema = yup.object().shape({
  business_card: yup.object().shape({
    formal_title: yup.string().nullable().label('Title'),
    given_name: yup.string().nullable().required().label('Given name'),
    family_name: yup.string().nullable().required().label('Family name'),
    phone_numbers: phoneNumbersValidationSchema,
    email_addresses: emailAddressesValidationSchema,
    postal_address: postalAddressValidationSchema,
    website: websiteValidationSchema,
  }),
})
