import {takeLatest, call, put} from 'redux-saga/effects'
import {getApiUrlFromEventType} from 'api'
import {toastrActions} from 'features/toastr'
import {sagaFetch, standardErrorAction} from 'helpers/saga'
import {deletePlannerStart, deletePlannerSuccess} from '../actions'
import {DELETE, DELETE_FAILURE} from '../constants'
import type {PlannerDelete} from '../types'

export function* deletePlanner(action: PlannerDelete) {
  const {id, type} = action.payload
  const {cellKey} = action.meta

  yield put(deletePlannerStart(undefined, {cellKey}))

  try {
    yield call(sagaFetch, getApiUrlFromEventType(type, id), 'delete')
    yield put(deletePlannerSuccess(id, {cellKey}))
  } catch (e: any) {
    yield put(toastrActions.errorToast('Event could not be deleted!'))
    yield put(standardErrorAction(DELETE_FAILURE, e, {cellKey}))
    throw e
  }
}

export function* deleteSaga() {
  yield takeLatest(DELETE, deletePlanner)
}
