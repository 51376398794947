import {takeEvery, take, race, fork, put} from 'redux-saga/effects'
import {success, actionCreator} from 'helpers/redux'

export const SUBMIT_FORM = 'Form/SUBMIT'

export const submitFormAction = actionCreator(
  SUBMIT_FORM,
  (payload) => payload,
  (meta) => ({...meta, thunk: true})
)

function* submitForm(action) {
  const {meta, payload: data} = action
  const {action: submitAction} = meta
  try {
    if (!submitAction) {
      throw new Error('SubmitForm: action meta is not defined')
    }

    yield put({type: submitAction, payload: data})

    const {payload, error} = yield race({
      payload: take(`${submitAction}_SUCCESS`),
      error: take(`${submitAction}_FAILURE`),
    })

    if (error) {
      yield put({
        type: `${SUBMIT_FORM}_FAILURE`,
        payload: error.payload,
        error: true,
        meta,
      })
    } else if (payload) {
      yield put(success(SUBMIT_FORM, payload, meta))
    }
  } catch (error) {
    yield put({
      type: `${SUBMIT_FORM}_FAILURE`,
      payload: error.payload,
      error: true,
      meta,
    })
    throw error
  }
}

function* watchSubmitForm() {
  yield takeEvery(SUBMIT_FORM, submitForm)
}

export function* formSubmitSaga() {
  yield fork(watchSubmitForm)
}
