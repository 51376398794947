import {isWithinRange} from 'date-fns'
import {get} from 'lodash'
import {getVenue} from 'api'
import type {PlannerEvent} from 'types/planner'

export const hasValue = (val: unknown): boolean =>
  val !== undefined && val !== null && val !== '' && val !== 0

export const handleCapacityUpdate = async (
  {
    venueId,
    isSeated,
  }: {
    venueId?: number
    isSeated?: boolean
  },
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
) => {
  if (!venueId) {
    return
  }

  const venue = await getVenue(venueId)
  // Stop if no venue
  if (!venue) {
    return
  }
  // Get both values
  const seated = get(venue, 'stage_capacity_seated')
  const standing = get(venue, 'stage_capacity_standing')
  // Stop if neither has a value
  if (!hasValue(seated) && !hasValue(standing)) {
    return
  }
  // Choose the new value
  const newCapacity = isSeated ? seated : standing
  console.log('new seating', newCapacity)
  // Update!
  setFieldValue('show.stage_capacity', newCapacity)
}

export const getAvailableTours = ({
  date,
  actId,
  tours,
}: {
  date: string
  actId?: number
  tours: PlannerEvent[]
}): PlannerEvent[] =>
  tours.filter((tour) => {
    if (tour.act?.id !== actId) {
      return false
    }
    return isWithinRange(date, tour.start_at, tour.end_at)
  }) ?? []
