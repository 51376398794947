import {Container, Section, Row, Column} from 'containers/Form'
import {ActId} from '../fields'

export const SelectAct: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Container>
      <Section>
        <Row>
          <Column>
            <ActId
              theme='form'
              placeholder='Please select act...'
              model='act_id'
            />
          </Column>
          <Column />
        </Row>
      </Section>
    </Container>
  )
}
