import deburr from 'lodash/deburr'
import type {StorableDepartment} from 'types/department'
import type {ListPerson} from 'types/person'
import type {RoleType} from 'types/role'
import type {AffiliationItems} from './types'

export function getDataFromRoleType(
  roleType: RoleType,
  data: StorableDepartment
): AffiliationItems[] {
  if (roleType === 'ACT_BOOKING_AGENCY_BOOKER' && data.type === 'ACT') {
    return data.agency_bookers
  }

  return data.roles.filter((role) => role.type === roleType)
}

export function filterPeople(person: ListPerson, searchString: string) {
  const haystack = deburr(`${person.full_name}`.toLowerCase())
  return haystack.includes(deburr(searchString.toLowerCase()))
}
