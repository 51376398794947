import {useFormikContext} from 'formik'
import {Badge, HelpText, Tooltip} from 'components/elements'
import {Toggle} from 'components/form'
import {Section, Row, Column} from 'containers/Form'
import type {Contract} from 'types/contract'
import {
  InvoiceOrganizerId,
  OrganizerContactId,
  ViewOrganizer,
  ViewOrganizerContact,
} from '../fields'

export const EconomyInvoicing: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const {values} = useFormikContext<Contract>()

  const financialExportStatus = values.financial_export_status?.value

  const visibility = financialExportStatus !== 'EXPORT_STATUS_NO_EXPORT'

  const exported = financialExportStatus
    ? [
        'EXPORT_STATUS_TO_BE_EXPORTED_AGAIN',
        'EXPORT_STATUS_EXPORTED',
        'EXPORT_STATUS_EXPORTED_MANUALLY',
      ].includes(financialExportStatus)
    : false

  const headerAction = exported ? (
    <Tooltip
      maxWidth={300}
      content='This contract has already been exported to the financial management program.
      Contact your economy department, if this contract should not be invoiced after all.'
    >
      <Badge color='info'>Exported</Badge>
    </Tooltip>
  ) : (
    <Toggle
      model='financial_export_status.value'
      inactiveValue='EXPORT_STATUS_NO_EXPORT'
      activeValue='EXPORT_STATUS_TO_BE_EXPORTED'
      inactiveText='OFF'
      activeText='ON'
      size='small'
      rawField
    />
  )

  return (
    <Section label='Invoice' action={headerAction}>
      {visibility && (
        <>
          <Row>
            <Column>
              <InvoiceOrganizerId
                theme='form'
                model='show.stakeholder_organizer_for_invoicing.department.id'
                disabled={exported}
                label='Invoice recipient (Organizer)'
                placeholder='CURRENT ORGANZER'
                filterOptions={(data) =>
                  values.show.stakeholder_organizer?.department.id !== data.id
                }
                grid='fill'
                isClearable
              />
              <ViewOrganizer
                grid='fit'
                model='show.stakeholder_organizer_for_invoicing.department.id'
                label='View invoice recipient (Organizer)'
              />
            </Column>
            <Column>
              <OrganizerContactId
                theme='form'
                model='show.stakeholder_organizer_for_invoicing.contact_person.id'
                organizerModel='show.stakeholder_organizer_for_invoicing.department.id'
                label='Invoice recipient (Contact)'
                placeholder='Please select invoice contact...'
                disabled={exported}
                grid='fill'
                isClearable
              />
              <ViewOrganizerContact
                grid='fit'
                model='show.stakeholder_organizer_for_invoicing.contact_person.id'
                label='View invoice recipient (Contact)'
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <HelpText>
                If the recipient of the invoice is different than the organizer
                of the event, you can specify it here.
              </HelpText>
            </Column>
          </Row>
        </>
      )}
    </Section>
  )
}
