import * as actBookingAgencyBooker from './actBookingAgencyBooker/constants'
import * as affiliations from './affiliations/constants'
import * as department from './department/constants'
import * as organization from './organization/constants'
import * as person from './person/constants'
import * as role from './role/constants'
import * as tour from './tour/constants'

export const ORGANIZATION = organization
export const DEPARTMENT = department
export const PERSON = person
export const ROLE = role
export const ACT_BOOKING_AGENCY_BOOKER = actBookingAgencyBooker
export const AFFILIATIONS = affiliations
export const TOUR = tour
