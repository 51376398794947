import {Input, Select} from 'components/form'
import {CompoundInput, CompoundInputLine} from 'containers/Form'
import type {ItemListRendererProps} from '../util/ItemList/ItemListRow'

export const PersonName: React.FC<
  React.PropsWithChildren<ItemListRendererProps>
> = ({model}) => (
  <CompoundInput>
    <CompoundInputLine>
      <Select
        theme='form'
        model={`${model}.formal_title`}
        placeholder='Title'
        options={[
          {label: 'Mr.', value: 'mr'},
          {label: 'Mrs.', value: 'mrs'},
          {label: 'Miss', value: 'miss'},
          {label: 'Lord', value: 'lord'},
        ]}
        grid='4'
      />
      <Input
        model={`${model}.given_name`}
        kind='text'
        placeholder='Given Name'
        grid='6'
      />
      <Input
        model={`${model}.family_name`}
        kind='text'
        placeholder='Family Name'
        grid='10'
      />
    </CompoundInputLine>
  </CompoundInput>
)
