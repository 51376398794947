import type {PayloadAction} from '@reduxjs/toolkit'
import {takeLatest, put, call} from 'redux-saga/effects'
import {sagaFetch, standardErrorAction} from 'helpers/saga'
import {invalidateDepartment} from 'store/entity/department/helpers'
import type {Role} from 'types/role'
import {deleteRoleSuccess} from '../actions'
import {DELETE, DELETE_FAILURE} from '../constants'
import type {RoleDeleteParams} from '../types'

function* deleteRole(action: PayloadAction<RoleDeleteParams>) {
  try {
    const role: Role = yield call(() =>
      sagaFetch(`/organizations/roles/${action.payload.id}`, 'get')
    )

    yield call(() =>
      sagaFetch(`/organizations/roles/${action.payload.id}`, 'delete')
    )
    invalidateDepartment(role.department.type, role.department.id)
    yield put(deleteRoleSuccess(action.payload))
  } catch (e: any) {
    yield put(standardErrorAction(DELETE_FAILURE, e))
    throw e
  }
}
export function* deleteSaga() {
  yield takeLatest(DELETE, deleteRole)
}
