import {createAction} from '@reduxjs/toolkit'
import {CLEAR, SHOW, SHOW_SUCCESS, UPDATE, UPDATE_SUCCESS} from './constants'
import type {
  OrganizationShowParams,
  OrganizationShowReponsePayload,
  OrganizationUpdateParams,
  OrganizationUpdateReponsePayload,
} from './types'

export const showOrganization = createAction<OrganizationShowParams>(SHOW)
export const showOrganizationSuccess =
  createAction<OrganizationShowReponsePayload>(SHOW_SUCCESS)

export const updateOrganization = createAction<OrganizationUpdateParams>(UPDATE)
export const updateOrganizationSuccess =
  createAction<OrganizationUpdateReponsePayload>(UPDATE_SUCCESS)

export const clearOrganization = createAction(CLEAR)
