import {useFormikContext} from 'formik'
import {get, map, reduce} from 'lodash'
import {ItemList} from 'components/form'
import {ContractTicketLineItem} from 'components/form/templates'
import {
  Section,
  Row,
  Column,
  Spacer,
  Cell,
  InlineText,
  InlineSpacer,
} from 'containers/Form'
import {toFixed, showNumber} from 'helpers/currency'
import type {Contract} from 'types/contract'
import styles from './Tickets.module.css'

const numberOptions = {
  thousandsSeparatorSymbol: '.',
  decimalSymbol: ',',
  requireDecimal: true,
  roundWhenMasking: true,
}

export const Tickets: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {values} = useFormikContext<Contract>()

  const budget = (values.show.tickets ?? []).map((line, key) => {
    const amount = line ? line.capacity ?? 0 : 0
    const price = line ? line.price ?? 0 : 0
    const total = amount * price

    return {
      text: get(line, 'text', `Ticket #${key + 1}`),
      amount,
      price,
      total: toFixed(total, 2),
    }
  })
  const total = reduce(budget, (sum, line) => sum + line.total, 0)

  const currency = values.currency ?? 'N/A'

  return (
    <Section secondary label='Tickets'>
      <Row>
        <Column>
          <ItemList
            model='show.tickets'
            itemRenderer={ContractTicketLineItem}
            itemWidth={400}
            allowEmpty
          />
        </Column>
      </Row>

      {!!budget.length && (
        <>
          <Spacer />

          <Row className={styles.list}>
            <Column>
              {map(budget, (line) => (
                <Cell grid='20' className={styles.budgetLineItem}>
                  <InlineText grid='fit' text={line.text} />
                  <InlineSpacer grid='fill' />
                  <InlineText
                    grid='5'
                    text={`${line.amount} x ${line.price} ${currency}`}
                    textAlign='right'
                  />
                  <InlineSpacer grid='1' />
                  <InlineText
                    grid='5'
                    text={`${showNumber(
                      line.total,
                      numberOptions
                    )} ${currency}`}
                    textAlign='right'
                  />
                  <Cell grid='3' />
                </Cell>
              ))}
            </Column>
          </Row>

          <Spacer />

          <Row className={styles.list}>
            <Column>
              <InlineText
                grid='fit'
                text='Total'
                className={styles.budgetTotalText}
              />
              <InlineSpacer grid='fill' />
              <InlineText
                grid='fit'
                text={showNumber(total, numberOptions)}
                className={styles.budgetTotalText}
              />
              <InlineText grid='fit' text={currency} />
              <Cell grid='3' />
            </Column>
          </Row>
        </>
      )}
    </Section>
  )
}
