import {getApiUrl} from 'api/urls'
import {clearToken, setToken} from 'lib/auth'
import {apiFetch} from 'lib/fetch'
import type {Organization} from 'types/organization'
import type {Person} from 'types/person'

type TokenResp = {token: string}
export interface LoginProps {
  username: string
  password: string
  relogin?: boolean
}

export const login = async (body: LoginProps): Promise<TokenResp> => {
  const resp = await apiFetch.post<TokenResp>(getApiUrl('jwtToken'), body, {
    auth: false,
  })
  setToken(resp.token)
  return resp
}

export const logout = async (): Promise<void> => {
  clearToken()
}

export const refreshJwtToken = (token: string): Promise<TokenResp> => {
  return apiFetch.post<TokenResp>(
    getApiUrl('jwtRefresh'),
    {token},
    {auth: false}
  )
}

export const switchUserContext = async (
  organizationId: number
): Promise<TokenResp> => {
  const resp = await apiFetch.post<TokenResp>(getApiUrl('authSwitchContext'), {
    organization_id: organizationId,
  })
  setToken(resp.token)
  return resp
}

export const getCurrentUser = (): Promise<Person> =>
  apiFetch.get<Person>(getApiUrl('currentUser'))

export const getCurrentOrganization = (): Promise<Organization> =>
  apiFetch.get<Organization>(getApiUrl('currentOrganization'))
