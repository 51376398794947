import {useQuery} from '@tanstack/react-query'
import {queryKeys} from 'api/queries'
import {getTTL} from 'utils'

export const useShowTypes = () => {
  return useQuery({
    ...queryKeys.showTypes.list,
    staleTime: getTTL('SLOW'),
  })
}
