import {parse} from 'date-fns'
import isString from 'lodash/isString'

export const isoStringToDate = (str: unknown) => {
  if (!isString(str)) {
    throw new Error('isoStringToDate: String required')
  }

  return parse(str)
}

export const dateToIsoString = (date: unknown) => {
  if (!(date instanceof Date)) {
    throw new Error('dateToIsoString: Date required')
  }

  return date.toISOString()
}
