import {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Email} from 'app/modal/Email'
import {clear, init} from 'store/email/actions'
import type {EmailStore, RootStore} from 'store/types'
import {useModal} from './useModal'

interface EmailerComposeProps {
  url: string
  successCallback?: () => void
  failureCallback?: (error: Error) => void
  headerPrimary: string
  headerSecondary: string
  sendBtnText: string
}

export const useEmailer = () => {
  const {loading} = useSelector<RootStore, EmailStore>((store) => store.email)
  const dispatch = useDispatch()
  const {openModal} = useModal()

  const compose = useCallback(
    async (props: EmailerComposeProps) => {
      try {
        await dispatch(init(props))
        openModal(
          () => <Email />,
          () => dispatch(clear())
        )
      } catch (e: any) {
        if (props.failureCallback) {
          props.failureCallback(e)
        }
      }
    },
    [dispatch, openModal]
  )

  return {
    compose,
    isInitializing: loading,
  }
}
