import {useState} from 'react'
import {Portal} from 'react-portal'
import styled from 'styled-components'
import {Container, Header, Main} from 'containers/Core'
import {useIsDesktop} from 'hooks/useIsDesktop'
import {colors, zIndex} from 'styles/variables'
import {MobileMenuInstanceContext} from './context'
import type {ButtonProps} from '../Button'
import {Button} from '../Button'
import type {IconName} from '../Icon'

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${zIndex.overlay};
  background-color: ${colors.white};
`

interface MobileMenuTargetProps {
  openMenu: () => void
}

interface MobileMenuRenderProps {
  closeMenu: () => void
}

interface MobileMenuProps {
  icon?: IconName
  target?: (props: MobileMenuTargetProps) => React.ReactElement
  targetProps?: Omit<ButtonProps, 'icon' | 'round' | 'onClick'>
  title: string
  children: (props: MobileMenuRenderProps) => React.ReactElement
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
  icon,
  target,
  targetProps,
  title,
  children,
}) => {
  const [visible, setVisible] = useState(false)
  const isDesktop = useIsDesktop()

  if (isDesktop) {
    return children({closeMenu: () => undefined})
  }

  const contentValue = {inMenu: true, closeMenu: () => setVisible(false)}

  return (
    <MobileMenuInstanceContext.Provider value={contentValue}>
      {target && target({openMenu: () => setVisible(true)})}
      {!target && (
        <Button
          {...targetProps}
          icon={icon}
          round
          onClick={() => setVisible(true)}
        />
      )}
      {visible && (
        <Portal>
          <Modal>
            <Container>
              <Header>{title}</Header>
              <Main scroll>
                {children({closeMenu: () => setVisible(false)})}
              </Main>
            </Container>
          </Modal>
        </Portal>
      )}
    </MobileMenuInstanceContext.Provider>
  )
}
