import {createElement} from 'react'
import cx from 'classnames'
import {isFinite} from 'lodash'
import type {FormFieldBaseProps} from 'types/form'
import styles from './Input.module.css'

type InputKind = 'text' | 'password' | 'number' | 'email' | 'hidden'
type InputValueTypes = any

interface InputProps extends FormFieldBaseProps<InputValueTypes> {
  className?: string
  placeholder?: string
  multiline?: boolean
  kind?: InputKind
  autoComplete?: string
  rows?: string
  min?: string
  max?: string
  step?: string
}

export const Input: React.FC<React.PropsWithChildren<InputProps>> = ({
  value,
  onChange,
  className,
  disabled,
  multiline,
  kind = 'text',
  autoComplete = 'off',
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: InputValueTypes = e.target.value

    if (kind === 'number') {
      value = parseInt(value)
      if (!isFinite(value)) {
        value = null
      }
    }

    onChange(value)
  }

  const getValue = () => {
    if (kind === 'number') {
      return isFinite(value) ? value : ''
    } else {
      return value || ''
    }
  }

  // Select type in tag
  const inputType = multiline ? 'textarea' : 'input'
  // Styles!
  const inputClassName = cx(
    styles.input,
    {
      [styles.disabled]: disabled,
      [styles.hidden]: kind === 'hidden',
    },
    className
  )
  // Build the shit!
  return createElement(inputType, {
    ...props,
    className: inputClassName,
    disabled,
    type: kind,
    onChange: handleChange,
    value: getValue(),
    autoComplete,
  })
}
