import {isBefore} from 'date-fns'
import styled from 'styled-components'
import {MonthPicker} from 'components/elements/MonthPicker/MonthPicker'
import {spacing} from 'styles/variables'

const Container = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: ${spacing.u2};
  align-items: center;
`

export interface MonthRangePickerProps {
  onChangeStart: (value: string | number | Date | null) => void
  onChangeEnd: (value: string | number | Date | null) => void
  valueStart: string | number | Date
  valueEnd: string | number | Date | null
  allowForever?: boolean
}

export const MonthRangePicker: React.FC<
  React.PropsWithChildren<MonthRangePickerProps>
> = ({valueStart, valueEnd, onChangeStart, onChangeEnd, allowForever}) => {
  const handleChangeStart = (valueStart: string | number | Date | null) => {
    // Check if current endMonth is before new startMonth
    // Set endMonth to startMonth if it is so
    if (
      !allowForever &&
      valueStart &&
      valueEnd &&
      isBefore(valueEnd, valueStart)
    ) {
      onChangeEnd(valueStart)
    }
    return onChangeStart(valueStart)
  }

  return (
    <Container>
      <MonthPicker value={valueStart} onChange={handleChangeStart} />
      to
      <MonthPicker
        value={valueEnd}
        minMonth={valueStart}
        onChange={onChangeEnd}
        allowForever={allowForever}
      />
    </Container>
  )
}
