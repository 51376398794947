export const FETCH = 'Entity/Organization/FETCH'
export const FETCH_SUCCESS = 'Entity/Organization/FETCH_SUCCESS'
export const FETCH_FAILURE = 'Entity/Organization/FETCH_FAILURE'

export const SHOW = 'Entity/Organization/SHOW'
export const SHOW_SUCCESS = 'Entity/Organization/SHOW_SUCCESS'
export const SHOW_FAILURE = 'Entity/Organization/SHOW_FAILURE'

export const UPDATE = 'Entity/Organization/UPDATE'
export const UPDATE_SUCCESS = 'Entity/Organization/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'Entity/Organization/UPDATE_FAILURE'

export const CLEAR = 'Entity/Organization/CLEAR'
