import {createQueryKeys} from '@lukemorales/query-key-factory'
import {api} from 'api/api'
import {validateAndDecodeToken} from 'lib/auth'

export const auth = createQueryKeys('auth', {
  token: {
    queryKey: null,
    queryFn: () => validateAndDecodeToken(),
  },
  currentUser: {
    queryKey: null,
    queryFn: () => api.auth.getCurrentUser(),
  },
  currentOrganization: {
    queryKey: null,
    queryFn: () => api.auth.getCurrentOrganization(),
  },
})
