import {captureException} from '@sentry/react'
import {useFormikContext} from 'formik'
import {getOrganizer} from 'api'
import {Button} from 'components/elements'
import {OrganizerSelect} from 'components/form'
import {useEntity} from 'hooks/useEntity'
import type {Contract} from 'types/contract'

export const OrganizerId = (props: any) => {
  const {
    values: {language, currency},
    setFieldValue,
  } = useFormikContext<Contract>()
  const {newOrganizer} = useEntity()
  const handleChange = async (newOrganizerId: number) => {
    try {
      const organizer = await getOrganizer(newOrganizerId)
      // Stop if no organizer
      if (!organizer) {
        return
      }
      // Update language from organizer
      const newLanguage = organizer.preferred_language
      if (newLanguage !== language) {
        setFieldValue('language', newLanguage)
      }
      // Update currency from organizer
      const newCurrency = organizer.preferred_currency
      if (newCurrency !== currency) {
        setFieldValue('currency', newCurrency)
      }
      // Clear organizer contact
      setFieldValue('show.stakeholder_organizer.contact_person.id', null)
    } catch (e) {
      captureException(e)
    }
  }

  return (
    <OrganizerSelect
      {...props}
      onChange={handleChange}
      actionRenderer={(value: string) => (
        <Button
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            newOrganizer({name: value}, (response) => {
              setFieldValue(props.model, response.id)
            })
          }}
          theme='text'
          width='fill'
          modal
          label='Create new organizer'
          icon='plus'
        />
      )}
    />
  )
}
