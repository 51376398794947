import {createAction} from '@reduxjs/toolkit'
import {
  SET_STARTDAY,
  SET_ENDDAY,
  SET_FILTER,
  SET_SHOW_EXPORTED,
  SET_SHOW_INVOICE_OFF,
  FETCH,
  FETCH_SUCCESS,
  EXPORT_MANUALLY,
  EXPORT_MANUALLY_SUCCESS,
  EXPORT_AGAIN,
  EXPORT_AGAIN_SUCCESS,
  TAX_ID_EXEMPTION,
  TAX_ID_EXEMPTION_SUCCESS,
  SET_INVOICING_STATUS,
  SET_INVOICING_STATUS_SUCCESS,
  FETCH_FAILURE,
} from './constants'
import type {
  ExportAgainParams,
  ExportAgainReponsePayload,
  ExportManuallyParams,
  ExportManuallyReponsePayload,
  FetchParams,
  FetchResponsePayload,
  SetEnddayParams,
  SetFilterParams,
  SetInvoicingStatusParams,
  SetInvoicingStatusResponsePayload,
  SetShowExportedParams,
  SetShowInvoiceOffParams,
  SetStartdayParams,
  TaxIdExemptionParams,
  TaxIdExemptionResponsePayload,
} from './types'

export const setStartday = createAction<SetStartdayParams>(SET_STARTDAY)

export const setEndday = createAction<SetEnddayParams>(SET_ENDDAY)

export const setFilter = createAction<SetFilterParams>(SET_FILTER)

export const setShowExported =
  createAction<SetShowExportedParams>(SET_SHOW_EXPORTED)

export const setShowInvoiceOff =
  createAction<SetShowInvoiceOffParams>(SET_SHOW_INVOICE_OFF)

export const fetch = createAction<FetchParams>(FETCH)
export const fetchSuccess = createAction<FetchResponsePayload>(FETCH_SUCCESS)
export const fetchFailure = createAction<unknown>(FETCH_FAILURE)

export const exportManually =
  createAction<ExportManuallyParams>(EXPORT_MANUALLY)
export const exportManuallySuccess = createAction<ExportManuallyReponsePayload>(
  EXPORT_MANUALLY_SUCCESS
)

export const exportAgain = createAction<ExportAgainParams>(EXPORT_AGAIN)
export const exportAgainSuccess =
  createAction<ExportAgainReponsePayload>(EXPORT_AGAIN_SUCCESS)

export const taxIdExemption =
  createAction<TaxIdExemptionParams>(TAX_ID_EXEMPTION)
export const taxIdExemptionSuccess =
  createAction<TaxIdExemptionResponsePayload>(TAX_ID_EXEMPTION_SUCCESS)

export const setInvoicingStatus =
  createAction<SetInvoicingStatusParams>(SET_INVOICING_STATUS)
export const setInvoicingStatusSuccess =
  createAction<SetInvoicingStatusResponsePayload>(SET_INVOICING_STATUS_SUCCESS)
