import styled from 'styled-components'
import {spacing} from 'styles/variables'

const Container = styled.div`
  margin: 0 ${spacing.u5};
  cursor: default;
`

interface SignatureProps {
  value: string
}

export const Signature = ({value}: SignatureProps) => {
  return (
    <Container>
      <div dangerouslySetInnerHTML={{__html: value}} />
    </Container>
  )
}
