import type {DateFormat} from 'types/date'
import {formatDate} from 'utils'

interface TimeProps {
  obj?: string | number | Date | null
  format?: DateFormat
  className?: string
}

export const Time = ({obj, format, className}: TimeProps) => {
  if (!obj) {
    return null
  }

  const time = formatDate(obj, format ?? 'DateShort')

  return <time className={className}>{time}</time>
}
