import {PriorityMenu, Spinner} from 'components/elements'
import {Filters, Main} from 'containers/Core'
import {useDepartment, useDepartments} from './hooks'
import {EntityAffiliationsView} from './shared/AffiliationsView'

export const AffiliationsView: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const {isLoading, data} = useDepartment()
  const {showMenu, selectedDepartment, setSelectedDepartment, departments} =
    useDepartments('affiliations')

  return (
    <>
      {showMenu && !!departments.length && (
        <Filters>
          <PriorityMenu
            items={departments.map((department) => ({
              label: department.name,
              value: department,
            }))}
            itemKey={(department) => department.global_id}
            value={selectedDepartment}
            onChange={(department) => setSelectedDepartment(department)}
          />
        </Filters>
      )}
      <Main flex>
        {isLoading && <Spinner />}
        {!isLoading && <EntityAffiliationsView data={data} />}
      </Main>
    </>
  )
}
