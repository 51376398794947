import {createElement} from 'react'
import {Button} from 'components/elements'
import styles from './ItemListRow.module.css'

export interface ItemListRendererProps {
  model: string
  disabled?: boolean
}

export type ItemListRenderer = React.FC<
  React.PropsWithChildren<ItemListRendererProps>
>

interface ItemListRowProps {
  model: string
  index: number
  itemRenderer: ItemListRenderer
  showAdd?: boolean
  showRemove?: boolean
  onAdd: (index: number, value: any) => void
  onRemove?: (index: number) => void
  itemWidth?: number
  disabled?: boolean
}

export const ItemListRow: React.FC<
  React.PropsWithChildren<ItemListRowProps>
> = ({
  model,
  index,
  itemRenderer,
  showAdd,
  showRemove,
  onAdd,
  onRemove,
  itemWidth,
  disabled,
}) => {
  const handleAdd = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    if (disabled) {
      return
    }
    onAdd((index || 0) + 1, undefined)
  }

  const handleRemove = (
    e: React.MouseEvent<Element, MouseEvent>,
    index: number
  ) => {
    e.preventDefault()
    e.stopPropagation()
    if (disabled) {
      return
    }
    if (onRemove) {
      onRemove(index)
    }
  }

  return (
    <section className={styles.row}>
      <div
        className={styles.fields}
        style={itemWidth ? {width: `${itemWidth}px`} : undefined}
      >
        {createElement(itemRenderer, {
          model: `${model}[${index}]`,
          disabled,
        })}
      </div>
      <div className={styles.spacer} />
      <div className={styles.actions}>
        {showRemove && (
          <Button
            round
            disabled={disabled}
            icon='delete-13px'
            onClick={(e) => handleRemove(e, index)}
            className={styles.addBtn}
          />
        )}
        {showAdd && (
          <Button
            round
            disabled={disabled}
            icon='plus-13px'
            onClick={(e) => handleAdd(e)}
            className={styles.removeBtn}
          />
        )}
      </div>
    </section>
  )
}
