import {useQuery} from '@tanstack/react-query'
import type {FullSearchProps} from 'api/api/search'
import {queryKeys} from 'api/queries'

export const useFullSearch = (props: FullSearchProps) => {
  const enabled =
    (!!props.filterText && props.filterText.length >= 2) ||
    (!!props.filterTypes && !!props.filterTypes.length)

  return useQuery({
    ...queryKeys.search.fullSearch(props),
    enabled,
  })
}
