import {useQuery} from '@tanstack/react-query'
import Markdown from 'markdown-to-jsx'
import {useParams} from 'react-router'
import styled from 'styled-components'
import {SignDocument} from 'components/connected/SignDocument'
import {HeaderText, Spinner} from 'components/elements'
import {Header, Main as MainComp, Container, HeaderLeft} from 'containers/Core'
import {apiFetch} from 'lib/fetch'
import {colors, effects, spacing} from 'styles/variables'
import type {Signing} from 'types/signing'
import {replaceTokens} from 'utils'

const useDocumentSigning = () => {
  const {id} = useParams<ContractSignParams>()

  return useQuery<Signing>(['documentSiging', id], () =>
    apiFetch.get(`documents/signing/${id}`, undefined, {auth: false})
  )
}

export interface ContractSignParams {
  id: string
}

const AppContainer = styled(MainComp)`
  display: grid;
  grid-template-columns: 1fr minmax(300px, 30%);
  grid-template-rows: 1fr 100px;
  grid-template-areas:
    'pdf main'
    'pdf footer';
`

const PDFViewer = styled.div`
  grid-area: pdf;
  background: ${colors.desertStorm};
  border-right: ${effects.borderPrimary};
  & > div {
    width: 100%;
    height: 100%;
  }
  overflow: auto;
`

const Main = styled.div`
  grid-area: main;
  background: ${colors.white};
  padding: ${spacing.u5};
`

const Footer = styled.div`
  grid-area: footer;
  background: ${colors.oxfordBlue};
`

interface ContentProps {
  data: Signing
  refresh: () => void
}

const Content = ({data, refresh}: ContentProps) => {
  if (data.document.state === 'STATE_SIGNED') {
    return <Main>Everybody signed</Main>
  }

  if (data.document.state === 'STATE_PARTIALLY_SIGNED') {
    return <Main>Somebody signed</Main>
  }

  return (
    <Main>
      <Markdown>
        {replaceTokens(data.document.raw_data, data.document.template)}
      </Markdown>
      <SignDocument
        config={{sendCodeUrl: data.send_code_url, signingUrl: data.signing_url}}
        onSuccess={() => refresh()}
        showSkipButton
      />
    </Main>
  )
}

export const ContractSign = () => {
  const {data, status, refetch} = useDocumentSigning()

  if (status === 'loading') {
    return <Spinner />
  }

  if (status !== 'success') {
    return <div>Error</div>
  }

  if (!data) {
    return <div>Error</div>
  }

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <HeaderText
            primary='Signing contract'
            secondary={data.document.title}
            pipe
          />
        </HeaderLeft>
      </Header>
      <AppContainer>
        <PDFViewer>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </PDFViewer>
        <Content data={data} refresh={refetch} />
        <Footer>Powered by Whnue</Footer>
      </AppContainer>
    </Container>
  )
}
