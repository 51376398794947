import {useContext} from 'react'
import styled from 'styled-components'
import {Button} from 'components/elements'
import {colors, fontSizes, spacing} from 'styles/variables'
import {EntitiyShowViewContext} from './context'

export const Root = styled.div`
  height: 100%;
  display: grid;
  overflow-y: auto;
  grid-template-columns: 1fr 120px;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'message actions'
    'content actions';
`

export const DeactivedMessage = styled.div`
  grid-area: message;
  background: ${colors.casablanca};
  margin: ${spacing.u8};
  padding: ${spacing.u2_5} ${spacing.u5};
  color: ${colors.white};
  text-transform: uppercase;
  font-size: ${fontSizes.tigerSize};
  text-align: center;
`

export const Content = styled.div`
  grid-area: content;
  margin: ${spacing.u8};
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${spacing.u10};
`

export const Row = styled.div`
  & > * {
    margin-bottom: ${spacing.u5};
  }
`

export const ActionsComp = styled.div`
  padding: ${spacing.u8};
  padding-left: 0;
  grid-area: actions;
`

export const Container: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const {data, onEditClick, canEdit} = useContext(EntitiyShowViewContext)

  const disabled = canEdit ? !canEdit(data) : false

  return (
    <Root>
      {'is_active' in data && !data.is_active && (
        <DeactivedMessage>This act has been deactivated</DeactivedMessage>
      )}
      {children}
      <ActionsComp>
        <Button
          onClick={onEditClick}
          disabled={disabled}
          title={
            disabled ? 'You dont have permission to edit this.' : undefined
          }
          icon='pencil'
          label='Edit'
        />
      </ActionsComp>
    </Root>
  )
}

interface ActionsProps {
  onClick: () => void
  disabled?: boolean
}

export const Actions: React.FC<React.PropsWithChildren<ActionsProps>> = ({
  onClick,
  disabled,
}) => {
  const title = disabled ? 'You dont have permission to edit this.' : undefined

  return (
    <ActionsComp>
      <Button
        onClick={onClick}
        disabled={disabled}
        title={title}
        icon='pencil'
        label='Edit'
      />
    </ActionsComp>
  )
}
