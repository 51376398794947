import cx from 'classnames'
import {EmptyMessage, Icon, Tooltip} from 'components/elements'
import {Table} from 'components/elements/Table'
import type {CellRenderer} from 'components/elements/Table/types'
import {
  CONTRACT_STATUS_SORTING,
  CONTRACT_STATUS_OPTIONS,
} from 'options/contractStatus'
import type {BookingViewSortDirection} from 'store/booking/types'
import type {PlannerEvent} from 'types/planner'

const StatusRenderer: CellRenderer<PlannerEvent, void> = ({item}) => {
  const status = item.status

  if (status === 'CONTRACT_STATUS_CANCELLED') {
    return <></>
  }

  const activeIndex = CONTRACT_STATUS_SORTING.indexOf(status)

  return (
    <>
      {CONTRACT_STATUS_SORTING.map((status, index) => {
        const icon = CONTRACT_STATUS_OPTIONS[status].icon
        const content =
          index <= activeIndex
            ? CONTRACT_STATUS_OPTIONS[status].name
            : undefined

        return (
          <Tooltip key={CONTRACT_STATUS_OPTIONS[status].name} content={content}>
            <Icon
              icon={icon}
              className={cx(
                'flex-none',
                index < activeIndex ? 'opacity-30' : '',
                index === activeIndex ? 'opacity-100 text-bostonBlue' : '',
                index > activeIndex ? 'opacity-0' : ''
              )}
            />
          </Tooltip>
        )
      })}
    </>
  )
}

interface ListProps {
  events: PlannerEvent[]
  sortBy: string
  sortDirection: BookingViewSortDirection
  onSort: (info: {
    sortBy: string
    sortDirection: BookingViewSortDirection
  }) => void
  onRowClick: (data: PlannerEvent) => void
  showActName?: boolean
}

export const List = ({
  events,
  showActName,
  onSort,
  sortBy,
  sortDirection,
  onRowClick,
}: ListProps) => {
  if (!events) {
    return null
  }

  return (
    <Table
      data={events}
      sortBy={sortBy}
      sortDirection={sortDirection}
      setSort={onSort}
      onRowClick={onRowClick}
      rowClassName={(item) =>
        item.status === 'CONTRACT_STATUS_CANCELLED'
          ? 'line-through text-santasGray'
          : ''
      }
      cells={[
        {
          id: 'date',
          sortKey: 'date',
          label: 'Date',
          width: 160,
          flexGrow: 1,
        },
        showActName
          ? {
              id: 'actName',
              sortKey: 'actName',
              label: 'Act',
              width: 150,
              flexGrow: 3,
            }
          : undefined,

        {
          id: 'venueName',
          sortKey: 'venueName',
          label: 'Venue',
          width: 300,
          flexGrow: 10,
        },
        {
          id: 'venueCity',
          sortKey: 'venueCity',
          label: 'City',
          width: 150,
          flexGrow: 3,
        },
        {
          id: 'organizerName',
          sortKey: 'organizerName',
          label: 'Organizer',
          width: 200,
          flexGrow: 3,
        },
        {
          id: 'createdBy',
          sortKey: 'createdBy',
          label: 'Creator',
          width: 150,
          flexGrow: 3,
        },
        {
          id: 'contract_number',
          sortKey: 'contract_number',
          label: 'Contract #',
          width: 100,
          flexGrow: 0,
        },
        {
          id: 'status',
          sortKey: 'status',
          label: 'Status',
          renderer: StatusRenderer,
          width: 105,
          flexGrow: 0,
          flexShrink: 0,
          className: 'flex flex-nowrap items-center gap-x-1',
        },
      ]}
      emptyMessage={
        <EmptyMessage
          title='There are no events in the chosen time span'
          description='Please change the time span in the filters above'
        />
      }
    />
  )
}
