import {createAction} from '@reduxjs/toolkit'
import {createActionWithMeta} from 'utils'
import {CREATE, CREATE_SUCCESS, DELETE, DELETE_SUCCESS} from './constants'
import type {
  ActBookingAgencyBookerCreateParams,
  ActBookingAgencyBookerCreateSuccessMeta,
  ActBookingAgencyBookerCreateSuccessPayload,
  ActBookingAgencyBookerDeleteParams,
  ActBookingAgencyBookerDeleteSuccessMeta,
  ActBookingAgencyBookerDeleteSuccessPayload,
} from './types'

export const createActBookingAgencyBooker =
  createAction<ActBookingAgencyBookerCreateParams>(CREATE)
export const createActBookingAgencyBookerSuccess = createActionWithMeta<
  ActBookingAgencyBookerCreateSuccessPayload,
  ActBookingAgencyBookerCreateSuccessMeta
>(CREATE_SUCCESS)

export const deleteActBookingAgencyBooker =
  createAction<ActBookingAgencyBookerDeleteParams>(DELETE)
export const deleteActBookingAgencyBookerSuccess = createActionWithMeta<
  ActBookingAgencyBookerDeleteSuccessPayload,
  ActBookingAgencyBookerDeleteSuccessMeta
>(DELETE_SUCCESS)
