import type {PayloadAction} from '@reduxjs/toolkit'
import {takeLatest, put, select} from 'redux-saga/effects'
import type {RootStore} from 'store/types'
import type {StorableDepartment} from 'types/department'
import type {Organization} from 'types/organization'
import {trimAll} from 'utils'
import {newDepartmentSuccess} from '../actions'
import {NEW} from '../constants'
import type {DepartmentNewParams} from '../types'

export function* newDepartment(action: PayloadAction<DepartmentNewParams>) {
  const organization: Organization | null = yield select(
    (store: RootStore) => store.entity.organization.data
  )

  const {type, data, onCreateSuccess} = action.payload

  const payload = {
    type: type,
    name: data?.name ? trimAll(data.name) : '',
    business_card_is_own: type !== 'VENUE',
    business_card: organization?.business_card ?? {},
  } as StorableDepartment

  if (payload.type === 'ACT') {
    payload.available_custom_fields = ['sap_act_code', 'sap_act_asl']
  } else if (payload.type === 'ORGANIZER') {
    payload.available_custom_fields = ['sap_organizer_customer_id']
  }

  yield put(newDepartmentSuccess(payload, {onCreateSuccess}))
}

export function* newSaga() {
  yield takeLatest(NEW, newDepartment)
}
