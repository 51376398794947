import {standardReducer} from 'helpers/reducers'
import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILURE,
  CLEAR,
  CREATE_SUCCESS,
} from '../constants'

const DEFAULT_ACTIONS = [
  {
    action: 'default',
    name: 'Send',
    buttonProps: {
      primary: true,
      fixed: true,
      wide: true,
    },
  },
]

function setMainActionBtnText(text) {
  return [{...DEFAULT_ACTIONS[0], name: text}]
}

const initialState = {
  headerPrimary: 'Send email',
  headerSecondary: undefined,
  recipients: [],
  attachments: [],
  subject: '',
  html_body: '',
  actions: DEFAULT_ACTIONS,
  successCallback: undefined,
  failureCallback: undefined,
}

export const metaReducer = standardReducer(initialState, {
  [INIT]: (state, action) => ({
    ...state,
    headerPrimary: action.payload.headerPrimary,
    headerSecondary: action.payload.headerSecondary,
    successCallback: action.payload.successCallback,
    failureCallback: action.payload.failureCallback,
    actions: action.payload.sendBtnText
      ? setMainActionBtnText(action.payload.sendBtnText)
      : DEFAULT_ACTIONS,
  }),
  [INIT_SUCCESS]: (state, action) => Object.assign({}, state, action.payload),
  [INIT_FAILURE]: () => initialState,
  [CLEAR]: () => initialState,
  [CREATE_SUCCESS]: () => initialState,
})
