import type {PayloadAction} from '@reduxjs/toolkit'
import type {SagaIterator} from 'redux-saga'
import {takeLatest, put, call} from 'redux-saga/effects'
import {standardErrorAction} from 'helpers/saga'
import {showEntity} from 'store/entity/asyncHelpers'
import {showOrganizationSuccess} from '../actions'
import {SHOW, SHOW_FAILURE} from '../constants'
import type {OrganizationShowParams} from '../types'

export function* showOrganization(
  action: PayloadAction<OrganizationShowParams>
): SagaIterator {
  try {
    const payload = yield call(() =>
      showEntity({kind: 'ORGANIZATION', id: action.payload.id})
    )
    yield put(showOrganizationSuccess(payload))
  } catch (e: any) {
    yield put(standardErrorAction(SHOW_FAILURE, e))
    throw e
  }
}
export function* showSaga() {
  yield takeLatest(SHOW, showOrganization)
}
