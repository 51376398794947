import styled from 'styled-components'
import {colors, fontSizes} from 'styles/variables'

const Root = styled.div`
  text-align: center;
  color: ${colors.santasGray};
`

const Title = styled.div`
  font-size: ${fontSizes.wolfSize};
`

const Description = styled.div`
  font-size: ${fontSizes.dogSize};
`

export interface EmptyMessageProps {
  title: string
  description?: string
  className?: string
}

export const EmptyMessage = ({
  className,
  title,
  description,
}: EmptyMessageProps) => (
  <Root className={className}>
    <Title>{title}</Title>
    {description && <Description>{description}</Description>}
  </Root>
)
