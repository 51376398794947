import type {PayloadAction} from '@reduxjs/toolkit'
import type {SagaIterator} from 'redux-saga'
import {takeLatest, put, call, select} from 'redux-saga/effects'
import {standardErrorAction} from 'helpers/saga'
import {createEntity} from 'store/entity/asyncHelpers'
import {entityTypeFromDepartmentType} from 'store/entity/helpers'
import type {RootStore} from 'store/types'
import type {DepartmentType, StorableDepartment} from 'types/department'
import type {OrganizationType} from 'types/organization'
import {showOrganization} from '../../actions'
import type {EntityCreateParams} from '../../types'
import {clearOnCreateSuccess, createDepartmentSuccess} from '../actions'
import {CREATE, CREATE_FAILURE} from '../constants'
import {invalidateDepartmentList} from '../helpers'

type DepartmentTypeCreate =
  | Extract<DepartmentType, 'ORGANIZER' | 'VENUE'>
  | Extract<
      OrganizationType,
      'ORGANIZATION_TYPE_ACT' | 'ORGANIZATION_TYPE_ORGANIZER'
    >

interface StorableDepartmentCreate extends Omit<StorableDepartment, 'type'> {
  type: DepartmentTypeCreate
  organization_id?: number
}

function getOrganizationTypeFromDepartmentType(
  d: DepartmentType
): DepartmentTypeCreate {
  switch (d) {
    case 'ACT':
      return 'ORGANIZATION_TYPE_ACT'
    case 'ORGANIZER':
      return 'ORGANIZATION_TYPE_ORGANIZER'
    default:
      throw new Error(
        `CreateDepartment: You can only create 'ACT' and 'ORGANIZER' from scratch. Not '${d}`
      )
  }
}

function* prepareData(
  input: StorableDepartment
): SagaIterator<StorableDepartmentCreate> {
  const {type} = input
  const rtn = {...input} as StorableDepartmentCreate

  const organizationId: number | undefined = yield select(
    (store: RootStore) => store.entity.organization.data.id
  )

  if (organizationId) {
    if (type === 'VENUE' || type === 'ORGANIZER') {
      rtn.organization_id = organizationId
    } else {
      throw new Error(
        `CreateDepartment: Creating '${type} and only be creating with no organization currently loaded`
      )
    }
  } else {
    if (type === 'ACT' || type === 'ORGANIZER') {
      rtn.type = getOrganizationTypeFromDepartmentType(type)
      rtn.business_card_is_own = false
    } else {
      throw new Error(
        `CreateDepartment: Creating '${type} requires an organization to be loaded`
      )
    }
  }

  return rtn
}

export function* createDepartment(
  action: PayloadAction<EntityCreateParams<StorableDepartment>>
): SagaIterator<StorableDepartment> {
  try {
    const kind = entityTypeFromDepartmentType(action.payload.type)

    if (!kind) {
      throw new Error(`CreateDepartment: no suitable kind found '${kind}'`)
    }

    const preppedData = yield call(() => prepareData(action.payload))

    const payload: StorableDepartment = yield call(() =>
      createEntity({
        kind,
        data: preppedData,
      })
    )

    const onCreateSuccess = yield select(
      (store) => store.entity.department.onCreateSuccess
    )
    if (onCreateSuccess) {
      onCreateSuccess(payload)
      yield put(clearOnCreateSuccess())
    }

    invalidateDepartmentList(action.payload.type)
    yield put(createDepartmentSuccess(payload))
    yield put(showOrganization({id: payload.organization.id}))
    return payload
  } catch (e: any) {
    yield put(standardErrorAction(CREATE_FAILURE, e))
    throw e
  }
}

export function* createSaga() {
  yield takeLatest(CREATE, createDepartment)
}
