import type {PayloadAction} from '@reduxjs/toolkit'
import type {SagaIterator} from 'redux-saga'
import {takeLatest, put, call, select} from 'redux-saga/effects'
import {getCurrentOrganization, queryKeys} from 'api'
import {sagaFetch, standardErrorAction} from 'helpers/saga'
import {queryClient} from 'main'
import type {RootStore} from 'store/types'
import type {Act} from 'types/department'
import type {Organization} from 'types/organization'
import type {RoleWithDepartment} from 'types/role'
import {createActBookingAgencyBookerSuccess} from '../actions'
import {CREATE, CREATE_FAILURE} from '../constants'
import type {ActBookingAgencyBookerCreateParams} from '../types'

function* createBookingAgencyBooker(
  action: PayloadAction<ActBookingAgencyBookerCreateParams>
): SagaIterator {
  try {
    const organization: Organization = yield call(getCurrentOrganization)

    const affiliation = organization.affiliations.find(
      (affiliation) => affiliation.person.id === action.payload.personId
    )

    if (!affiliation) {
      throw new Error(
        `CreateBookingAgencyBooker: No affiliation found for person '#${action.payload.personId}'`
      )
    }

    const role: RoleWithDepartment | undefined = affiliation.roles.find(
      (role) => role.type === 'BOOKING_AGENCY_BOOKER'
    )

    if (!role) {
      throw new Error(
        `CreateBookingAgencyBooker: No suitable role found for affiliation '#${affiliation.id}'`
      )
    }

    const act: Act | undefined = yield select((store: RootStore) =>
      !!store.entity.department.data &&
      store.entity.department.data.type === 'ACT'
        ? store.entity.department.data
        : undefined
    )

    if (!act) {
      throw new Error(`CreateBookingAgencyBooker: No suitable act found`)
    }

    const payload = yield call(() =>
      sagaFetch(
        '/organizations/act-bookers',
        'post',
        {id: role.id},
        {queryParams: {act_id: act.id}}
      )
    )
    yield put(createActBookingAgencyBookerSuccess(payload, {actId: act.id}))
    queryClient.invalidateQueries(queryKeys.acts._def)
  } catch (e: any) {
    yield put(standardErrorAction(CREATE_FAILURE, e))
    throw e
  }
}

export function* createSaga() {
  yield takeLatest(CREATE, createBookingAgencyBooker)
}
