import type {RefObject} from 'react'
import {useEffect, useState, useCallback} from 'react'

type RefType = RefObject<any> | HTMLElement | null
type Callback = (e: MouseEvent) => void

type ClickOutsideArgsOneElement = [
  ref: RefType,
  isOpen: boolean,
  callback: Callback,
  deferInitialization?: boolean
]
type ClickOutsideArgsTwoElements = [
  ref: RefType,
  extraRef: RefType,
  isOpen: boolean,
  callback: Callback,
  deferInitialization?: boolean
]

type ClickOutsideArgs = ClickOutsideArgsOneElement | ClickOutsideArgsTwoElements

function checkIfClickIsOutside(event: MouseEvent, ref: RefType) {
  let clickOutside = false

  if (!ref || !event.target) {
    return false
  }

  if ('contains' in ref) {
    if (!ref.contains(event.target as Element)) {
      clickOutside = true
    }
  }

  if ('current' in ref) {
    if (!ref.current) {
      clickOutside = true
    }
    if (ref.current && !ref.current.contains(event.target)) {
      clickOutside = true
    }
  }

  return clickOutside
}

export const useClickOutside = (...args: ClickOutsideArgs) => {
  const hasTwoRefs = typeof args[3] === 'function'

  const mainRef = args[0]
  const auxRef = hasTwoRefs ? args[1] : undefined
  const isOpen = hasTwoRefs ? args[2] : args[1]
  const callback = hasTwoRefs ? args[3] : args[2]

  const deferInitialization = hasTwoRefs ? args[4] : args[3]

  const [initialized, setInitialized] = useState(!deferInitialization)

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (!initialized) {
        setInitialized(true)
        return
      }

      if (!isOpen) {
        return
      }

      if (auxRef) {
        const clickOutsideMain = checkIfClickIsOutside(event, mainRef)
        const clickOutsideAux = checkIfClickIsOutside(event, auxRef)

        if (clickOutsideMain && clickOutsideAux) {
          callback(event)
        }
      } else {
        const clickOutside = checkIfClickIsOutside(event, mainRef)

        if (clickOutside) {
          callback(event)
        }
      }
    },
    [isOpen, mainRef, auxRef, callback, initialized]
  )

  useEffect(() => {
    document.addEventListener('click', handleClick, false)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [handleClick])
}
