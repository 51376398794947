import type {ContractProductionStatusType} from 'types/contract'

interface ProductionStatusOption {
  value: ContractProductionStatusType
  label: string
}

export const PRODUCTION_STATUS_OPTIONS: ProductionStatusOption[] = [
  {
    value: 'PRODUCTION_STATUS_READY',
    label: 'Ready for production',
  },
  {
    value: 'PRODUCTION_STATUS_IN_PROGRESS',
    label: 'Production in progress',
  },
  {
    value: 'PRODUCTION_STATUS_DONE',
    label: 'Production is done',
  },
]
