import cx from 'classnames'
import styles from './gridHOC.module.css'

type GridItemGrid =
  | 'fit'
  | 'fill'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'

interface GridHOCParentProps {
  [key: string]: any
}
interface WithGridProps {
  wrapperClassName?: string
  aligned?: boolean
  grid?: GridItemGrid
}

type ComponentProps<P> = P & WithGridProps

export type GridHOCProps<P> = P & WithGridProps

export const gridHOC = <P extends GridHOCParentProps>(
  Component: React.FC<React.PropsWithChildren<ComponentProps<P>>>
): React.FC<React.PropsWithChildren<GridHOCProps<P>>> => {
  const WithGrid: React.FC<React.PropsWithChildren<GridHOCProps<P>>> = ({
    wrapperClassName,
    grid,
    aligned,
    ...rest
  }) => (
    <Component
      {...(rest as P)}
      wrapperClassName={cx(wrapperClassName, {
        [styles[`grid-${grid}`]]: !!grid,
        aligned: aligned,
      })}
    />
  )

  return WithGrid
}
