import {createAction} from '@reduxjs/toolkit'
import {CREATE, CREATE_SUCCESS, DELETE, DELETE_SUCCESS} from './constants'
import type {
  RoleCreateParams,
  RoleCreateSuccessPayload,
  RoleDeleteParams,
  RoleDeleteSuccessPayload,
} from './types'

export const createRole = createAction<RoleCreateParams>(CREATE)
export const createRoleSuccess =
  createAction<RoleCreateSuccessPayload>(CREATE_SUCCESS)

export const deleteRole = createAction<RoleDeleteParams>(DELETE)
export const deleteRoleSuccess =
  createAction<RoleDeleteSuccessPayload>(DELETE_SUCCESS)
