import type {EntityDataTypes} from 'store/entity/types'
import {Container, Content, Row} from './components'
import {EntitiyShowViewContext} from './context'
import * as Parts from './Parts'
import {TourShowView} from './Tour'

export interface EntityShowViewProps {
  data: EntityDataTypes
  onEditClick: () => void
  canEdit?: (data: EntityDataTypes) => boolean
}

const viewSelector = (data: EntityDataTypes) => {
  if (data.type === 'EVENT_TYPE_TOUR') {
    return <TourShowView />
  }

  if (data.type?.startsWith('ORGANIZATION')) {
    return (
      <Content>
        <Row>
          <Parts.Name />
          <Parts.EmailAddresses />
          <Parts.PhoneNumbers />
          <Parts.Address />
          <Parts.Website />
        </Row>
        <Row>
          <Parts.CompanyRegistration />
        </Row>
      </Content>
    )
  }

  switch (data.type) {
    case 'ACT':
      return (
        <Content>
          <Row>
            <Parts.Name />
            <Parts.EmailAddresses />
            <Parts.PhoneNumbers />
            <Parts.Address />
            <Parts.Website />
            <Parts.WhnueId />
          </Row>
          <Row>
            <Parts.CompanyRegistration />
            <Parts.Commission />
            <Parts.SapActCode />
            <Parts.SapActAsl />
          </Row>
        </Content>
      )
    case 'PERSON':
      return (
        <Content>
          <Row>
            <Parts.Name />
            <Parts.EmailAddresses />
            <Parts.PhoneNumbers />
            <Parts.Address />
            <Parts.Website />
          </Row>
        </Content>
      )
    case 'ORGANIZER':
    case 'BOOKING_AGENCY':
      return (
        <Content>
          <Row>
            <Parts.Name />
            <Parts.EmailAddresses />
            <Parts.PhoneNumbers />
            <Parts.Address />
            <Parts.Website />
            <Parts.WhnueId />
          </Row>
          <Row>
            <Parts.CompanyRegistration />
            <Parts.Language />
            <Parts.Currency />
            <Parts.SapOrganizerCustomerId />
          </Row>
        </Content>
      )
    case 'VENUE':
      return (
        <Content>
          <Row>
            <Parts.Name />
            <Parts.EmailAddresses />
            <Parts.PhoneNumbers />
            <Parts.Address />
            <Parts.Website />
            <Parts.WhnueId />
          </Row>
          <Row>
            <Parts.VenueCapacity />
          </Row>
        </Content>
      )
    default:
      return null
  }
}

export const EntityShowView: React.FC<
  React.PropsWithChildren<EntityShowViewProps>
> = ({data, onEditClick, canEdit}) => {
  const contextValue = {
    data,
    onEditClick,
    canEdit,
  }

  return (
    <EntitiyShowViewContext.Provider value={contextValue}>
      <Container>{viewSelector(data)}</Container>
    </EntitiyShowViewContext.Provider>
  )
}
