import cx from 'classnames'
import get from 'lodash/get'
import map from 'lodash/map'
import {Droppable} from 'react-beautiful-dnd'
import {EmptyMessage} from 'components/elements'
import styles from './columnStyles.module.css'
import {Item} from './Item'
import type {
  FlowColumnConfig,
  FlowCurrentlyDragged,
  FlowIsDropDisabled,
} from './types'

interface ColumnProps {
  entityType: any
  data: FlowColumnConfig
  hasSpacer: boolean
  currentlyDragged: FlowCurrentlyDragged | null
  isDropDisabled?: FlowIsDropDisabled
}

export const Column = ({
  entityType,
  data: {id, title, items, columnProps, itemProps},
  hasSpacer,
  currentlyDragged,
  isDropDisabled: isDropDisabledProp = false,
}: ColumnProps) => {
  const hasItems = items.length > 0

  const isDropDisabledResolver =
    columnProps && 'isDropDisabled' in columnProps
      ? columnProps.isDropDisabled
      : isDropDisabledProp

  const isDropDisabled =
    typeof isDropDisabledResolver === 'function' && currentlyDragged
      ? isDropDisabledResolver(currentlyDragged)
      : isDropDisabledResolver

  return (
    <section
      className={cx(styles.root, `column-${id}`, {
        [styles.hasSpacer]: hasSpacer,
        [styles.empty]: !hasItems,
      })}
    >
      <section className={styles.header}>
        <div className={styles.headerTitle}>{title}</div>
        <div className={styles.headerCount}>{`(${items.length})`}</div>
      </section>
      <Droppable
        droppableId={id}
        {...columnProps}
        isDropDisabled={!!isDropDisabled}
      >
        {(provided, snapshot) => {
          const {draggingFromThisWith, isDraggingOver} = snapshot

          return (
            <section
              className={cx(styles.items, {
                [styles.visuallyDisabled]:
                  get(columnProps, 'visuallyDisabled') === true,
                [styles.isDragging]:
                  !isDropDisabled && !draggingFromThisWith && currentlyDragged,
                [styles.isDraggingOver]:
                  !draggingFromThisWith && isDraggingOver,
              })}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {!hasItems && (
                <EmptyMessage
                  title='No items'
                  className={styles.emptyMessage}
                />
              )}
              {!!hasItems &&
                map(items, (item, index) => (
                  <Item
                    entityType={entityType}
                    data={item}
                    index={index}
                    key={item.id}
                    itemProps={itemProps}
                  />
                ))}
              {provided.placeholder}
            </section>
          )
        }}
      </Droppable>
      {hasSpacer && <div className={styles.spacer} />}
    </section>
  )
}
