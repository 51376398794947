import cx from 'classnames'
import {MobileMenuCloseButton} from 'components/elements/MobileMenu'
import {ModalCloseButton} from 'context/Modal'
import styles from './container.module.css'

interface HeaderProps {
  className?: string
}

export const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  className,
  children,
}) => (
  <header className={cx(className, styles.header)}>
    <section className={styles.headerContent}>{children}</section>
    <div className={styles.headerModalClose}>
      <ModalCloseButton />
      <MobileMenuCloseButton />
    </div>
  </header>
)

export const HeaderLeft: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  className,
  children,
}) => <section className={cx(className, styles.headerLeft)}>{children}</section>

export const HeaderMiddle: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  className,
  children,
}) => (
  <section className={cx(className, styles.headerMiddle)}>{children}</section>
)

export const HeaderRight: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  className,
  children,
}) => (
  <section className={cx(className, styles.headerRight)}>{children}</section>
)
