export const NEW = 'Entity/Department/NEW'
export const NEW_SUCCESS = 'Entity/Department/NEW_SUCCESS'

export const FETCH = 'Entity/Department/FETCH'
export const FETCH_SUCCESS = 'Entity/Department/FETCH_SUCCESS'
export const FETCH_FAILURE = 'Entity/Department/FETCH_FAILURE'

export const SHOW = 'Entity/Department/SHOW'
export const SHOW_SUCCESS = 'Entity/Department/SHOW_SUCCESS'
export const SHOW_FAILURE = 'Entity/Department/SHOW_FAILURE'

export const CREATE = 'Entity/Department/CREATE'
export const CREATE_SUCCESS = 'Entity/Department/CREATE_SUCCESS'
export const CREATE_FAILURE = 'Entity/Department/CREATE_FAILURE'

export const UPDATE = 'Entity/Department/UPDATE'
export const UPDATE_SUCCESS = 'Entity/Department/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'Entity/Department/UPDATE_FAILURE'

export const CLEAR = 'Entity/Department/CLEAR'
export const CLEAR_ON_CREATE_SUCCESS =
  'Entity/Department/CLEAR_ON_CREATE_SUCCESS'
