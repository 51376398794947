import type {PropsWithChildren} from 'react'
import cx from 'classnames'
import styles from './container.module.css'

export interface MainProps extends PropsWithChildren {
  className?: string
  scroll?: 'x' | 'y' | boolean
  flex?: boolean
}

export const Main = ({
  scroll = false,
  className,
  flex,
  children,
}: MainProps) => (
  <div
    className={cx(
      {
        [styles.mainScroll]: scroll === true,
        [styles.mainScrollX]: scroll === 'x',
        [styles.mainScrollY]: scroll === 'y',
        [styles.mainFlex]: flex,
      },
      styles.main,
      className
    )}
  >
    {children}
  </div>
)
