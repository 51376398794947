import {useActs} from 'api'
import {Select} from 'components/elements'
import type {SelectProps} from 'components/elements/Select'

type MyActsSelectProps = Omit<SelectProps, 'options'>

export const MyActsSelect = (props: MyActsSelectProps) => {
  const {data: acts = []} = useActs()
  const activeActs = acts.filter((act) => act.is_active)

  return (
    <Select
      {...props}
      options={[
        {
          label: 'My acts',
          options: activeActs
            .filter((act) => act.is_represented_by_me)
            .map((act) => ({
              label: act.name,
              value: act.id,
            })),
        },
        {
          label: 'Other acts',
          options: activeActs
            .filter((act) => !act.is_represented_by_me)
            .map((act) => ({
              label: act.name,
              value: act.id,
            })),
        },
      ]}
    />
  )
}
