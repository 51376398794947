import {differenceInCalendarDays} from 'date-fns'
import orderBy from 'lodash/orderBy'
import reject from 'lodash/reject'
import {useDispatch, useSelector} from 'react-redux'
import {updateStatus} from 'store/flow/actions'
import type {FlowActionUpdateStatusPayload, FlowKind} from 'store/flow/types'
import type {RootStore, FlowStore} from 'store/types'
import type {BookingStatusEvent, PlannerEvent} from 'types/planner'
import {FLOW_CONFIG} from './config'
import type {FlowColumnConfig, FlowFilterOptions, UseFlowData} from './types'

const mapItems = (items: PlannerEvent[]): BookingStatusEvent[] => {
  const mappedItems = items.map((item) => ({
    ...item,
    daysLeft: differenceInCalendarDays(item.start_on, new Date()),
  }))

  return orderBy(mappedItems, 'start_on')
}

const mapColumns = ({
  columns,
  data,
  filterOptions,
}: {
  columns: FlowColumnConfig[]
  data: PlannerEvent[]
  filterOptions: FlowFilterOptions
}): FlowColumnConfig[] =>
  columns.map(({id, filterMethod, ...column}) => {
    const bookingStatusEvents = mapItems(data)
    return {
      ...column,
      id,
      items: filterMethod
        ? filterMethod(bookingStatusEvents, filterOptions)
        : bookingStatusEvents,
    }
  })

export const useFlow = (flowKind: FlowKind): UseFlowData => {
  const dispatch = useDispatch()
  const flow = useSelector<RootStore, FlowStore>(({flow}) => flow)

  const {columns: columnsRaw, entityType} = FLOW_CONFIG[flowKind]

  const showPassiveColumns = flow[flowKind].showPassiveColumns
  const showHistoricEvents = flow[flowKind].showHistoricEvents
  const data = flow[flowKind].data

  const columns = showPassiveColumns
    ? columnsRaw
    : reject(columnsRaw, ({passive}) => passive === true)

  return {
    loading: flow[flowKind].loading,
    entityType,
    columns: mapColumns({
      columns,
      data,
      filterOptions: {showHistoricEvents},
    }),
    hasFilter: !!flow[flowKind].filters.kind,
    updateEventStatus: (data: FlowActionUpdateStatusPayload) =>
      dispatch(updateStatus(data, {flow: flowKind})),
  }
}
