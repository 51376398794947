import {mergeQueryKeys} from '@lukemorales/query-key-factory'
import {acts} from './acts'
import {auth} from './auth'
import {basicData} from './basicData'
import {bookingAgencies} from './bookingAgencies'
import {contractTemplates} from './contractTemplates'
import {financialExports} from './financialExports'
import {organizers} from './organizers'
import {people} from './people'
import {planner} from './planner'
import {search} from './search'
import {showTypes} from './showTypes'
import {venues} from './venues'

export const queryKeys = mergeQueryKeys(
  acts,
  auth,
  basicData,
  bookingAgencies,
  contractTemplates,
  financialExports,
  organizers,
  people,
  planner,
  venues,
  search,
  showTypes
)
