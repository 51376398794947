import {beforeAndAfter} from 'api/helpers/beforeAndAfter'
import type {ToursForActAndDayParams} from 'api/hooks'
import {queryKeys} from 'api/queries'
import {queryClient} from 'main'

export const getToursForActAndDay = ({
  actId,
  date,
}: Required<ToursForActAndDayParams>) =>
  queryClient.fetchQuery(
    queryKeys.planner.list({
      actIds: [actId],
      eventTypes: 'tours',
      ...beforeAndAfter('day', date),
    })
  )
