import Markdown from 'markdown-to-jsx'
import styled from 'styled-components'
import {FinancialExport} from 'app/modal/FinancialExport'
import {ReportsExport} from 'app/modal/ReportsExport'
import {HeaderText, ReportCard} from 'components/elements'
import {ViewHeader} from 'components/layout'
import {Container as PageContainer, Main as PageMain} from 'containers/Core'
import {useModal} from 'hooks/useModal'
import {REPORT_CARD_OPTIONS} from 'options/reportCards'
import {colors, effects, fontSizes, mq, spacing} from 'styles/variables'
import type {ReportCards} from 'types/reportCards'

const Main = styled(PageMain)`
  max-width: 1024px;
  padding: ${spacing.u5} ${spacing.u2_5};
  overflow-y: auto;

  @media (${mq.desktop}) {
    overflow-y: unset;
    padding: ${spacing.u8};
  }
`

const Header = styled.div`
  color: ${colors.secondaryTextColor};
  margin-bottom: ${spacing.u5};

  @media (${mq.desktop}) {
    margin-bottom: ${spacing.u8};
  }

  & p {
    margin: 0;
  }
`

const Cards = styled.div`
  display: grid;
  align-items: stretch;
  grid-gap: ${spacing.u2_5};

  @media (${mq.desktop}) {
    grid-gap: ${spacing.u8};
    grid-template-columns: 1fr 1fr;
  }

  & > * {
    display: grid;
  }
`

const ComingSoon = styled.div`
  position: relative;
  overflow: hidden;

  &::after {
    display: block;
    content: 'COMING SOON';
    position: absolute;
    top: 0;
    right: 0;
    background: ${colors.amaranth};
    color: ${colors.white};
    padding: 2px 20px;
    font-size: ${fontSizes.mouseSize};
    transform: translate(20px, 20px) rotate(45deg);
    box-shadow: ${effects.popoverBoxshadow};
  }
`

const SORTED_CARDS: ReportCards[] = [
  'FINANCIAL_OVERVIEW',
  'ACT_COMMISSION',
  'JOB_LIST',
  'ACT_ROYALTY',
  'TOUR_FINANCIAL',
  'SAP_PROJECT_EXPORT',
  'SAP_EXPORT',
]

export const ReportsView = () => {
  const {openModal} = useModal()

  const handleClick = (card: ReportCards) => {
    if (
      card === 'NAVISION_EXPORT' ||
      card === 'SAP_PROJECT_EXPORT' ||
      card === 'SAP_EXPORT'
    ) {
      openModal(() => <FinancialExport kind={card} />)
    } else {
      openModal(() => <ReportsExport kind={card} />)
    }
  }

  return (
    <PageContainer>
      <ViewHeader>
        <HeaderText primary='Reports' />
      </ViewHeader>
      <Main>
        <Header>
          <Markdown>
            Here you can download various up to date reports, as means of
            communication or updating data in eg. economy systems.`
          </Markdown>
        </Header>
        <Cards>
          {SORTED_CARDS.map((reportCard) => {
            const card = (
              <ReportCard
                disabled={!!REPORT_CARD_OPTIONS[reportCard].disabled}
                icon={REPORT_CARD_OPTIONS[reportCard].icon}
                title={REPORT_CARD_OPTIONS[reportCard].title}
                description={REPORT_CARD_OPTIONS[reportCard].description}
              />
            )

            if (REPORT_CARD_OPTIONS[reportCard].disabled) {
              return <ComingSoon key={reportCard}>{card}</ComingSoon>
            }
            return (
              <div onClick={() => handleClick(reportCard)} key={reportCard}>
                {card}
              </div>
            )
          })}
        </Cards>
      </Main>
    </PageContainer>
  )
}
