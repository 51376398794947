import {useDispatch, useSelector} from 'react-redux'
import {Toggle} from 'components/elements'
import {toggleHistoricEvents} from 'store/flow/actions'
import type {FlowKind} from 'store/flow/types'
import type {RootStore} from 'store/types'

interface ToggleHistoricEventsProps {
  flow: FlowKind
  text?: string
}

export const ToggleHistoricEvents = ({
  flow: flowKind,
  text = 'passive',
}: ToggleHistoricEventsProps) => {
  const dispatch = useDispatch()
  const value = useSelector<RootStore, boolean>(
    ({flow}) => flow[flowKind].showHistoricEvents
  )

  const handleChange = (value: boolean) => {
    dispatch(toggleHistoricEvents(value, {flow: flowKind}))
  }

  return (
    <Toggle
      value={value}
      onChange={handleChange}
      activeText={`${text} visible`}
      inactiveText={`${text} hidden`}
    />
  )
}
