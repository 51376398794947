import cx from 'classnames'
import {Draggable} from 'react-beautiful-dnd'
import {EntityCard} from 'components/elements'
import {useContract} from 'hooks/useContract'
import type {BookingStatusEvent} from 'types/planner'
import itemStyles from './itemStyles.module.css'
import type {FlowColumnItemProps} from './types'

interface ItemProps {
  entityType: any
  data: BookingStatusEvent
  index: number
  itemProps: FlowColumnItemProps
}

export const Item = (props: ItemProps) => {
  const {openContract} = useContract()
  const {
    entityType,
    data,
    index,
    itemProps: {getId, ...itemProps},
  } = props

  return (
    <Draggable
      {...itemProps}
      draggableId={`draggableId-${getId(data)}`}
      index={index}
    >
      {(provided, snapshot) => (
        <section
          className={cx(itemStyles.root, {
            [itemStyles.isDragging]: snapshot.isDragging,
          })}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <EntityCard
            entityType={entityType}
            data={data}
            onClick={() => {
              if ('contract_id' in data) {
                openContract(data.contract_id)
              }
            }}
          />
        </section>
      )}
    </Draggable>
  )
}
