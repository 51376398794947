import * as yup from 'yup'
import {
  businessCardValidationSchema,
  eanValidationSchema,
  nameValidationSchema,
  taxIdValidationSchema,
} from './shared'

export const actValidationSchema = yup.object().shape({
  name: nameValidationSchema,
  business_card_is_own: yup.bool(),
  business_card: businessCardValidationSchema,
  default_commission: yup.number().nullable().label('Default commission'),
  ean: eanValidationSchema,
  tax_id: taxIdValidationSchema,
})
