import {PersonName} from 'components/form/templates'
import {Container, Section, Column, Row} from 'containers/Form'
import {Contact, Address, AdditionalInformation} from '../sections'

export const BaseFormPanelPerson = () => {
  return (
    <Container>
      <Section label='Name'>
        <Row>
          <Column>
            <PersonName model='business_card' />
          </Column>
        </Row>
      </Section>

      <Contact />

      <Address />

      <AdditionalInformation />
    </Container>
  )
}
