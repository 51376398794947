import {MobileMenu} from 'components/elements'
import {ViewHeader as ViewHeaderComp} from 'components/layout'
import {Header} from 'containers/Core'
import {useIsDesktop} from 'hooks/useIsDesktop'
import {ViewSelector} from './ViewSelector'

interface ViewHeaderProps {
  title?: string
  filters?: React.ReactNode
}

export const ViewHeader: React.FC<React.PropsWithChildren<ViewHeaderProps>> = ({
  title = 'TITLE',
  filters,
  children,
}) => {
  const isDesktop = useIsDesktop()

  if (!isDesktop) {
    return (
      <ViewHeaderComp>
        <ViewSelector mobile />
        <MobileMenu title={title} icon='filter' targetProps={{size: 'big'}}>
          {() => (
            <>
              <Header>{children}</Header>
              {filters !== undefined ? filters : null}
            </>
          )}
        </MobileMenu>
      </ViewHeaderComp>
    )
  }

  return (
    <>
      <ViewHeaderComp>
        <ViewSelector />
        {children}
      </ViewHeaderComp>
      {filters !== undefined ? filters : null}
    </>
  )
}
