import {ButtonSelect} from 'components/elements'
import {useShowKind} from './hooks'

export const ShowKind = () => {
  const {showKind, setShowKind} = useShowKind()

  return (
    <ButtonSelect
      value={showKind || ''}
      onChange={setShowKind}
      options={[
        {label: 'My acts', value: 'represented_by_me', icon: 'user'},
        {label: 'Roster', value: 'roster', icon: 'group'},
      ]}
    />
  )
}
