import * as yup from 'yup'
import {businessCardValidationSchema, nameValidationSchema} from './shared'

export const venueValidationSchema = yup.object().shape({
  name: nameValidationSchema,
  business_card: yup
    .object()
    .when('business_card_is_own', {
      is: true,
      then: businessCardValidationSchema,
    })
    .nullable(),
  stage_capacity_seated: yup
    .number()
    .integer()
    .nullable()
    .label('Seated capacity'),
  stage_capacity_standing: yup
    .number()
    .integer()
    .nullable()
    .label('Standing capacity'),
})
