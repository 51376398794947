import type {SagaIterator} from 'redux-saga'
import {takeLatest, call, put} from 'redux-saga/effects'
import {getApiUrlFromEventType} from 'api'
import {toastrActions} from 'features/toastr'
import {sagaFetch, standardErrorAction} from 'helpers/saga'
import {createPlannerStart, createPlannerSuccess} from '../actions'
import {CREATE, CREATE_FAILURE} from '../constants'
import {formatPayload} from '../helpers'
import type {PlannerCreate} from '../types'

export function* createPlanner(action: PlannerCreate): SagaIterator {
  const {type, ...data} = action.payload
  const {cellKey} = action.meta

  yield put(createPlannerStart(undefined, {cellKey}))

  try {
    const payload = yield call(
      sagaFetch,
      getApiUrlFromEventType(type),
      'post',
      formatPayload(data)
    )

    yield put(createPlannerSuccess(payload, {cellKey}))
  } catch (e: any) {
    yield put(toastrActions.errorToast('Event could not be created!'))
    yield put(standardErrorAction(CREATE_FAILURE, e, {cellKey}))
    throw e
  }
}

export function* createSaga() {
  yield takeLatest(CREATE, createPlanner)
}
