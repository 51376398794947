import {forwardRef, useEffect, useState} from 'react'
import {paramCase} from 'change-case'
import cx from 'classnames'
import isString from 'lodash/isString'
import type {IconComponents} from './import'
import {loadIcons} from './import'
import type {IconProps} from './types'

const useIcons = () => {
  const [icons, setIcons] = useState<IconComponents | undefined>()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (loaded) {
      return
    }

    async function load() {
      setIcons(await loadIcons())
      setLoaded(true)
    }

    load()
  }, [loaded])

  return icons
}

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({icon, size: sizeRaw, className}, ref) => {
    const ICONS = useIcons()

    if (!ICONS) {
      return null
    }

    const iconObj = ICONS[icon] ?? ICONS[paramCase(icon)]

    if (!iconObj) {
      return null
    }

    const {component: Component, viewBoxWidth, viewBoxHeight} = iconObj

    const size =
      (isString(sizeRaw) ? parseInt(sizeRaw) : sizeRaw) || viewBoxWidth || 16
    const width = size
    const height =
      viewBoxWidth && viewBoxHeight
        ? (viewBoxHeight / viewBoxWidth) * size
        : size

    return (
      <Component
        ref={ref}
        fill='currentColor'
        className={cx('whnue-icon', className)}
        style={{verticalAlign: 'top'}}
        width={width}
        height={height}
      />
    )
  }
)

Icon.displayName = 'Icon'
