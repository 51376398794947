export const ICON_NAMES = [
  'abacus',
  'affiliation',
  'arrow-left',
  'arrow-right',
  'bank',
  'board',
  'bold',
  'bookmark',
  'burger',
  'calendar-acts-in-columns',
  'calendar-agenda',
  'calendar-months-in-columns',
  'calendar-one-month',
  'calendar',
  'cancel',
  'capacity',
  'cards',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'checkered-flag',
  'checkmark-fill',
  'checkmark',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'claim',
  'close',
  'cog',
  'commission-number',
  'commission',
  'contract',
  'currency',
  'cvr',
  'delete-13px',
  'delete-16px',
  'download',
  'drag',
  'duplicate',
  'earth',
  'economy',
  'error-8',
  'error',
  'event',
  'excel',
  'export',
  'file-excel',
  'file-pdf',
  'file-report-financial',
  'file-report-royalty',
  'file-report-tour-budget',
  'file-report-tour',
  'filter',
  'final',
  'group',
  'handle',
  'history',
  'indent-decrease',
  'indent-increase',
  'info',
  'invisible',
  'italic',
  'language',
  'link',
  'list-bullet',
  'list-numbered',
  'list',
  'location',
  'lock',
  'logout',
  'mail',
  'menu',
  'microphone',
  'money',
  'more',
  'new-row',
  'new-window',
  'note',
  'notification',
  'palm',
  'paper',
  'pdf',
  'pencil',
  'phone',
  'plus',
  'plus-13px',
  'plus-16px',
  'promotion',
  'refresh',
  'report',
  'road',
  'roskilde',
  'safe',
  'sap-project-creation',
  'sap-sales-order',
  'search-results',
  'search',
  'sent',
  'setup',
  'signed',
  'smiley',
  'speaker',
  'stage',
  'star-empty',
  'star',
  'tiles',
  'underline',
  'user',
  'van',
  'visible',
  'warning',
  'zoom-in',
  'zoom-out',
] as const
