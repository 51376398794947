import type {Optional} from 'utility-types'
import {getApiUrl} from 'api/urls'
import {apiFetch} from 'lib/fetch'
import type {
  Contract,
  ContractDummyRemote,
  ContractRemote,
} from 'types/contract'

export interface ContractInitializeParams {
  actId: number
  fromContractId?: number
  termsTemplateId?: number
}

export const initialize = async (
  params: ContractInitializeParams
): Promise<ContractDummyRemote> =>
  apiFetch.get<ContractDummyRemote>(getApiUrl('contractDummy'), {
    act_id: params.actId,
    contract_id: params.fromContractId,
    terms_template_id: params.termsTemplateId,
  })

export const create = async (
  body: Optional<Contract>
): Promise<ContractRemote> =>
  apiFetch.post<ContractRemote>(getApiUrl('contracts'), body)

export const show = async (id: number): Promise<ContractRemote> =>
  apiFetch.get<ContractRemote>(getApiUrl('contracts', id))

export const update = async (
  id: number,
  body: Optional<Contract>
): Promise<ContractRemote> =>
  apiFetch.put<ContractRemote>(getApiUrl('contracts', id), body)

export const destroy = async (id: number): Promise<void> =>
  apiFetch.delete<void>(getApiUrl('contracts', id))
