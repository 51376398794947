import {useFormikContext} from 'formik'
import {useOrganizer} from 'api'
import type {SelectProps} from 'components/elements/Select'
import {VenueSelect} from 'components/form'
import type {FormikFieldProps} from 'components/form/formikFieldHOC'
import type {Contract} from 'types/contract'
import {handleCapacityUpdate} from './helpers'

interface VenueIdProps
  extends Omit<FormikFieldProps<SelectProps>, 'onChange' | 'options'> {
  organizerModel: string
}

export const VenueId: React.FC<VenueIdProps> = ({organizerModel, ...props}) => {
  const {values, getFieldProps, setFieldValue} = useFormikContext<Contract>()

  const organizerId = getFieldProps(organizerModel).value
  const {data: organizer} = useOrganizer(organizerId)

  const handleSelect = (venueId: number) => {
    handleCapacityUpdate(
      {
        venueId,
        isSeated: values.show.is_seated,
      },
      setFieldValue
    )
  }

  return (
    <VenueSelect
      {...props}
      onChange={handleSelect}
      groupByOrganizationId={organizer?.organization.id}
    />
  )
}
