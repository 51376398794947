import {Spinner} from 'components/elements'
import {Container, Main} from 'containers/Core'
import {useContract} from 'hooks/useContract'
import {useMultipleList, useSort} from './hooks'
import {ViewHeader, MonthRangePicker, ShowKind} from '../components'
import {List} from '../views'

export const MultipleList = () => {
  const {events, isLoading, sortBy, sortDirection} = useMultipleList()
  const {setSort} = useSort()
  const {openContract} = useContract()

  return (
    <Container>
      <ViewHeader>
        <MonthRangePicker />
        <ShowKind />
      </ViewHeader>

      <Main flex>
        {isLoading && <Spinner overlay />}
        <List
          events={events}
          onRowClick={({contract_id}) => openContract(contract_id)}
          onSort={setSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          showActName
        />
      </Main>
    </Container>
  )
}
