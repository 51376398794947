import styled from 'styled-components'
import type {AvatarKind} from 'components/elements/Avatar'
import {Avatar} from 'components/elements/Avatar'
import {colors, fonts, spacing} from 'styles/variables'

const Root = styled.div`
  padding: 0 ${spacing.u2_5};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: ${spacing.u4} 0;
  text-align: left;
`

const Content = styled.div`
  margin-left: ${spacing.u2_5};
`

const PrimaryText = styled.div`
  font-family: ${fonts.fontBold};
`

const SecondaryText = styled.div`
  color: ${colors.secondaryTextColor};
`

interface UserMenuSectionHeaderProps {
  avatar: AvatarKind
  primary: string
  secondary?: string
}
export const UserMenuSectionHeader: React.FC<
  React.PropsWithChildren<UserMenuSectionHeaderProps>
> = ({avatar, primary, secondary}) => {
  return (
    <Root>
      <Avatar kind={avatar} size={48} />
      <Content>
        <PrimaryText>{primary}</PrimaryText>
        {!!secondary && <SecondaryText>{secondary}</SecondaryText>}
      </Content>
    </Root>
  )
}
