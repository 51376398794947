import type {PropsWithChildren} from 'react'
import {createContext} from 'react'
import {useQueries, useQuery} from '@tanstack/react-query'
import {queryKeys} from 'api'
import {Spinner} from 'components/elements'
import type {JwtToken} from 'lib/auth'
import {MainAppLoader} from 'shared/Loader'
import {Login} from 'shared/Login'
import type {Organization} from 'types/organization'
import type {Person} from 'types/person'

interface AuthContextProps {
  token: JwtToken
  user: Person
  organization: Organization
}

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
)

export const AuthContextProvider = ({children}: PropsWithChildren) => {
  const {
    data: token,
    status: tokenStatus,
    fetchStatus: tokenFetchStatus,
  } = useQuery({
    ...queryKeys.auth.token,
    staleTime: 0,
    retry: false,
    placeholderData: null,
  })

  const [
    {data: user, status: userStatus, fetchStatus: userFetchStatus},
    {
      data: organization,
      status: organizationStatus,
      fetchStatus: organizationFetchStatus,
    },
  ] = useQueries({
    queries: [
      {
        ...queryKeys.auth.currentUser,
        enabled: !!token,
        retry: false,
      },
      {
        ...queryKeys.auth.currentOrganization,
        enabled: !!token,
        retry: false,
      },
    ],
  })

  const anythingFetching = [
    tokenFetchStatus,
    userFetchStatus,
    organizationFetchStatus,
  ].some((fetchStatus) => fetchStatus === 'fetching')

  const loggedIn =
    [tokenStatus, userStatus, organizationStatus].every(
      (status) => status === 'success'
    ) &&
    !!token &&
    !!user &&
    !!organization

  if (loggedIn) {
    const value: AuthContextProps = {
      token,
      user,
      organization,
    }

    return (
      <AuthContext.Provider value={value}>
        <>
          {anythingFetching && <Spinner />}
          {children}
        </>
      </AuthContext.Provider>
    )
  }

  if (anythingFetching) {
    return <MainAppLoader />
  }

  return <Login />
}
