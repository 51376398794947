import {useFormikContext} from 'formik'
import {CONTRACT_DEFAULT_LANGUAGE, CONTRACT_LANGUAGES} from 'options/contract'
import type {Contract} from 'types/contract'
import {RefreshButton} from './RefreshButton'

export const RefreshCustomEconomicTerms = (props: any) => {
  const {values, setFieldValue} = useFormikContext<Contract>()

  const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()

    const id = values.economic_terms_id
    const language = values.language
    const economic_term_templates =
      values.terms_template.economic_term_templates

    const lang = Object.keys(CONTRACT_LANGUAGES).includes(language)
      ? language
      : CONTRACT_DEFAULT_LANGUAGE

    const template = economic_term_templates.find((t) => t.id === id)

    if (!template) {
      return
    }

    setFieldValue(
      'custom_economic_terms',
      lang === 'en' ? template.text_en : template.text_da
    )
  }

  return (
    <RefreshButton
      {...props}
      tooltip='Reloading the text template will overwrite your changes below with the original text'
      onClick={handleClick}
    />
  )
}
