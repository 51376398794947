import {range} from 'lodash'
import type {
  GroupedData,
  ItemGroupKey,
  UseGroupedDataProps,
  UseGroupedDataReturn,
  GridItems,
} from './types'

function buildGroups<I, G>(
  items: I[],
  groups: Partial<Record<string, G>>,
  itemGroupKey: ItemGroupKey<I>,
  renderEmptyGroups: boolean,
  group: boolean
): GroupedData<I, G>[] {
  if (group && Object.keys(groups).length) {
    return Object.keys(groups).map((groupKey) => {
      const groupItems = items.filter((item) => itemGroupKey(item) === groupKey)

      const renderGroupHeader = renderEmptyGroups
        ? true
        : groupItems.length !== 0
      const renderItems = renderEmptyGroups ? true : groupItems.length !== 0

      return {
        items: groupItems,
        group: groups[groupKey],
        renderGroupHeader,
        renderItems,
      }
    })
  } else {
    return [
      {
        items,
        renderItems: false,
        renderGroupHeader: false,
      },
    ]
  }
}

function buildGrid<I, G>(
  data: GroupedData<I, G>[],
  rowCount: number,
  columnCount: number,
  groupRowCount: number[]
): GridItems<I, G> {
  const groupRowIndex = groupRowCount
    .map((_notUsed, index) =>
      groupRowCount.slice(0, index).reduce((s, a) => s + a, 0)
    )
    .reverse()

  return range(0, rowCount).map((rowIndex) => {
    const groupIndex = groupRowIndex.findIndex((gi) => gi <= rowIndex)
    const groupIndexReversed = groupRowIndex.length - 1 - groupIndex
    const firstInGroup = groupRowIndex.includes(rowIndex)
    const groupStart = groupRowIndex[groupIndex]
    const group = data[groupIndexReversed]

    if (firstInGroup && group.renderGroupHeader && group.group) {
      return [{group: group.group}]
    }

    const internalIndex =
      rowIndex - groupStart - (group.renderGroupHeader ? 1 : 0)

    const startIndex = internalIndex * columnCount
    const endIndex = startIndex + columnCount

    const items = group.items.slice(startIndex, endIndex)

    if (items.length) {
      return items.map((item) => ({item}))
    }

    return group.renderItems ? [{emptyMessage: true}] : []
  })
}

export function useGroupedData<I, G>({
  items,
  groups,
  group,
  numberOfColumns,
  itemGroupKey,
  renderEmptyGroups,
  width,
  gap,
}: UseGroupedDataProps<I, G>): UseGroupedDataReturn<I, G> {
  const data = buildGroups<I, G>(
    items,
    groups,
    itemGroupKey,
    !!renderEmptyGroups,
    group
  )

  const innerWidth = width - gap * 2
  // const innerHeight = height - gap * 2
  const columnCount = numberOfColumns(innerWidth)

  const groupRowCount = range(0, Object.keys(data).length).map((groupIndex) => {
    const d = data[groupIndex]
    const headerCount = d.renderGroupHeader ? 1 : 0
    const minItemRows = d.renderItems ? 1 : 0
    const itemRows = Math.ceil(d.items.length / columnCount)
    return headerCount + Math.max(minItemRows, itemRows)
  })
  const rowCount = groupRowCount.reduce((sum, count) => sum + count)

  const grid = buildGrid(data, rowCount, columnCount, groupRowCount)

  const columnWidth = innerWidth / columnCount

  return {
    data: grid,
    columnCount,
    rowCount,
    columnWidth,
  }
}
