import Markdown from 'markdown-to-jsx'
import {HeaderText} from 'components/elements'
import {Filters, Flow} from 'components/Flow'
import {ViewHeader} from 'components/layout'
import {Container as PageContainer, Main} from 'containers/Core'
import styles from './viewStyles.module.css'

export const ProductionView = () => (
  <PageContainer>
    <ViewHeader>
      <HeaderText primary='Production' />
    </ViewHeader>
    <Filters flow='production' passiveText='TBC' />
    <Main className={styles.root}>
      <section className={styles.container}>
        <Markdown className={styles.explainer}>
          {`Drag the events from one column to another, to indicate where they are
          in the flow. All columns are sorted by date, with the most urgent on
          top.
          <br />
          The events in the 'Done' column are automatically removed when the
          event is over.`}
        </Markdown>
        <Flow kind='production' />
      </section>
    </Main>
  </PageContainer>
)
