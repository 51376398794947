import type {PayloadAction} from '@reduxjs/toolkit'
import type {SagaIterator} from 'redux-saga'
import {takeLatest, put, call} from 'redux-saga/effects'
import {standardErrorAction} from 'helpers/saga'
import {showEntity} from 'store/entity/asyncHelpers'
import type {Tour} from 'types/tour'
import {showTourSuccess} from '../actions'
import {SHOW, SHOW_FAILURE} from '../constants'
import type {TourShowParams} from '../types'

// SHOW DEPARTMENT
export function* showTour(action: PayloadAction<TourShowParams>): SagaIterator {
  try {
    const payload = yield call(() =>
      showEntity<Tour>({
        kind: 'TOUR',
        id: action.payload.id,
      })
    )
    yield put(showTourSuccess(payload))
  } catch (e: any) {
    yield put(standardErrorAction(SHOW_FAILURE, e))
    throw e
  }
}

export function* showSaga() {
  yield takeLatest(SHOW, showTour)
}
