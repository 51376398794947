import styled from 'styled-components'
import {colors, effects, spacing, units} from 'styles/variables'

export const Container = styled.div<{disabled: boolean}>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  height: ${units.formElementHeight};
  line-height: ${units.formElementHeight};
  background: ${colors.white};
  border: ${effects.inputBorder};
  transition: border ${effects.transitionTime},
    box-shadow ${effects.transitionTime}, color ${effects.transitionTime};
  outline: none;
  cursor: text;
  padding: 0 ${spacing.u1_5};

  &:hover {
    border: ${effects.inputHoverBorder};
  }
  &:focus-within {
    border: ${effects.inputFocusBorder};
    box-shadow: ${effects.inputFocusBoxShadow};
  }

  ${({disabled}) =>
    disabled &&
    `
    & {
      background-color: ${effects.inputDisabledBackground};
      cursor: default;
    }
    &:hover {
      border: ${effects.inputDisabledBorder};
      box-shadow: ${effects.inputDisabledBoxShadow};
    }`}
`

export const MinuteInput = styled.input`
  outline: none;
  background: transparent;
  border: 0;
  width: 16px;
  height: 16px;
  line-height: 16px;
  padding: 0;
  color: ${colors.scarpaFlow};
  -moz-appearance: textfield;

  &::placeholder {
    color: ${colors.santasGray};
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Removes leftover margin */
  }

  &::focus,
  &::active {
    outline: none;
  }
`

export const HourInput = styled(MinuteInput)`
  text-align: right;
`

export const InputDivider = styled.span`
  margin: 0 2px;
  line-height: 10px;
  transform: translateY(-2px);
`
