import {useMutation} from '@tanstack/react-query'
import {api} from 'api/api'
import type {LoginProps} from 'api/api/auth'
import {queryKeys} from 'api/queries'
import type {JwtToken} from 'lib/auth'
import {decodeToken} from 'lib/auth'
import {registerUserTracking, unregisterUserTracking} from 'lib/errorTracking'
import {queryClient} from 'main'

function updateTokenQueryData(token: string) {
  queryClient.setQueryData(queryKeys.auth.token.queryKey, decodeToken(token))
}

function handleLoginSuccess(token: string) {
  registerUserTracking(token)
  updateTokenQueryData(token)
  queryClient.resetQueries()
}

function handleReloginSuccess(token: string) {
  registerUserTracking(token)
  updateTokenQueryData(token)
  queryClient.refetchQueries({
    predicate: (query) => query.queryKey[0] === 'auth',
  })
  queryClient.resetQueries({predicate: (query) => query.queryKey[0] !== 'auth'})
}

// TODO: Remove when saga fetch is removed
export function handleLogoutSuccess() {
  unregisterUserTracking()
  queryClient.removeQueries({
    predicate: (query) => query.queryHash !== JSON.stringify(['auth', 'token']),
  })
  queryClient.resetQueries()
}

export const useLoginMutation = () =>
  useMutation({
    mutationFn: async ({relogin, ...data}: LoginProps) => {
      console.log('useLoginMutation', relogin)
      const {token} = await api.auth.login(data)
      return [token, !!relogin] as const
    },
    onSuccess: ([token, relogin]) => {
      if (relogin) {
        handleReloginSuccess(token)
      } else {
        handleLoginSuccess(token)
      }

      console.log('success', relogin)
    },
  })

export const useLogoutMutation = () =>
  useMutation({
    mutationFn: () => api.auth.logout(),
    onSuccess: handleLogoutSuccess,
  })

export const useRefreshTokenMutation = () =>
  useMutation({
    mutationFn: async () => {
      const oldToken = queryClient.getQueryData(
        queryKeys.auth.token.queryKey
      ) as JwtToken
      const {token} = await api.auth.refreshJwtToken(oldToken.token)
      return token
    },
    onSuccess: updateTokenQueryData,
    onError: handleLogoutSuccess,
  })

export const useSwitchUserContextMutation = () =>
  useMutation({
    mutationFn: async (organizationId: number) => {
      const {token} = await api.auth.switchUserContext(organizationId)

      console.log(decodeToken(token), null, 2)

      return token
    },
    onSuccess: handleLoginSuccess,
    onError: handleLogoutSuccess,
  })
