import type {FormikHelpers} from 'formik'
import {Formik} from 'formik'
import {useLoginMutation} from 'api/mutations'
import {Button, Spinner} from 'components/elements'
import {RecoverLink} from 'shared/AuthHandler/LoginForm/RecoverLink'
import {
  Actions,
  ErrorMessage,
  Fields,
  Form,
  Input,
  ReadOnlyInput,
} from './components'

const messages = {
  helpText: 'Hit enter to login',
  loginBtnText: 'Log in',
  labelText: {
    username: 'Email:',
    password: 'Password:',
  },
  placeholder: {
    username: 'Enter your email',
    password: 'Enter your password',
  },
  errorMessages: {
    usernameEmptyErr: 'Please type your email',
    passwordEmptyErr: 'Please type your password',
    bothEmptyErr: 'Please type your email and password in order to log in',
    notValidLoginErr:
      "Unfortunately we couldn't validate your email and password. Please try again.",
  },
}

interface LoginFormData {
  username: string
  password: string
  message?: string
}

interface LoginFormErrors {
  username?: string
  password?: string
  message?: string
}

const handleValidation = ({
  username,
  password,
}: LoginFormData): LoginFormErrors => {
  const errors: LoginFormErrors = {}

  if (!username && !password) {
    errors.username = messages.errorMessages.bothEmptyErr
    errors.password = messages.errorMessages.bothEmptyErr
  } else if (!username && password) {
    errors.username = messages.errorMessages.usernameEmptyErr
  } else if (username && !password) {
    errors.password = messages.errorMessages.passwordEmptyErr
  }

  return errors
}

interface LoginFormProps {
  className?: string
  username?: string | null
}

export const LoginForm = ({username}: LoginFormProps) => {
  const {mutateAsync} = useLoginMutation()
  const relogin = !!username

  const handleSubmit = async (
    data: LoginFormData,
    {setSubmitting, setFieldError}: FormikHelpers<LoginFormData>
  ) => {
    try {
      setSubmitting(true)
      await mutateAsync({...data, relogin})
    } catch (e) {
      if (
        e instanceof Error &&
        'code' in e &&
        !!e.code &&
        typeof e.code === 'number' &&
        e.code === 400
      ) {
        setFieldError('password', messages.errorMessages.notValidLoginErr)
      } else {
        setFieldError('password', 'Unknown error')
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik<LoginFormData>
      initialValues={{
        username: username || '',
        password: '',
      }}
      validate={handleValidation}
      onSubmit={handleSubmit}
    >
      {({values, errors, touched, handleSubmit, isSubmitting}) => {
        const valid = !!values.username && !!values.password
        const disabled = !valid || isSubmitting

        return (
          <Form onSubmit={handleSubmit}>
            {isSubmitting && <Spinner />}

            {errors.username && touched.username && (
              <ErrorMessage>{errors.username}</ErrorMessage>
            )}
            {errors.password && touched.password && (
              <ErrorMessage>{errors.password}</ErrorMessage>
            )}

            <Fields>
              {!username && (
                <Input
                  type='text'
                  name='username'
                  autoFocus
                  placeholder={messages.placeholder.username}
                  autoComplete='username'
                />
              )}

              {username && <ReadOnlyInput>{username}</ReadOnlyInput>}

              <Input
                type='password'
                name='password'
                autoFocus={!!username}
                placeholder={messages.placeholder.password}
                autoComplete='current-password'
              />
            </Fields>

            <Actions>
              <RecoverLink />
              <Button type='submit' disabled={disabled} theme='secondary'>
                Login
              </Button>
            </Actions>
          </Form>
        )
      }}
    </Formik>
  )
}
