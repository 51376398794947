import {useAuth} from './useAuth'

interface Config {
  digisign: boolean
}

export const useConfig = (): Config => {
  const {organizationId} = useAuth()

  return {
    digisign: organizationId === 1,
  }
}
