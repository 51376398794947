import {getApiRoot} from 'lib/fetch/helpers'

function getRecoverLink() {
  return `${getApiRoot().replace('api/', 'password/recover')}`
}

export const RecoverLink = () => {
  return (
    <a
      href={getRecoverLink()}
      className='hover:text-pictonBlue transition-colors'
    >
      Forgot password?
    </a>
  )
}
