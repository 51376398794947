import cx from 'classnames'
import styles from './CompoundInput.module.css'
import type {GridHOCProps} from './gridHOC'
import {gridHOC} from './gridHOC'

export const CompoundInput: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <div className={styles.root}>{children}</div>

interface CompoundInputLabelInnerProps {
  className?: string
  text: string
}

export type CompoundInputLabelProps = GridHOCProps<CompoundInputLabelInnerProps>

const CompoundInputLabelInner: React.FC<
  React.PropsWithChildren<CompoundInputLabelProps>
> = ({text, wrapperClassName, className}) => {
  return (
    <div className={cx(styles.label, wrapperClassName, className)}>{text}</div>
  )
}

export const CompoundInputLabel = gridHOC(CompoundInputLabelInner)

export const CompoundInputLine: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <div className={styles.line}>{children}</div>
