import {useFormikContext} from 'formik'
import {getToursForActAndDay} from 'api'
import {DatePicker} from 'components/form'
import type {Contract} from 'types/contract'

export const StartAt = (props: any) => {
  const {values, setFieldValue} = useFormikContext<Contract>()

  const handleChange = async (newStartAt: string) => {
    const tourId = values.show.tour?.id
    const actId = values.show.stakeholder_act?.department.id

    if (!tourId) {
      return
    }

    if (!actId) {
      return
    }

    const tours = await getToursForActAndDay({
      date: newStartAt,
      actId,
    })

    if (tourId && !tours.find((t) => t.id === tourId)) {
      console.log('RESET')
      setFieldValue('show.tour.id', null)
    }
  }

  return <DatePicker {...props} onChange={handleChange} />
}
