import type {CSSProperties, MouseEvent} from 'react'
import cx from 'classnames'
import {ROW_STYLES} from './helpers'
import type {ContentProps} from './Table'
import type {CellConfig, CellRenderer, RowClickHandler} from './types'
import {getCellFlex} from './types'

function getAlignment(config?: string) {
  switch (config) {
    case 'center':
      return 'justify-center'
    case 'right':
      return 'justify-end'
    default:
      return ''
  }
}

interface CellProps<Data, ExtraProps> {
  item: Data
  config: CellConfig<Data, ExtraProps>
  extraProps?: ExtraProps
}

export const Cell = <Data, ExtraProps>({
  item,
  config,
  extraProps,
}: CellProps<Data, ExtraProps>): JSX.Element => {
  const {
    renderer = ({item}: Parameters<CellRenderer<Data, ExtraProps>>[0]) => (
      <>{item[config.id as keyof Data]}</>
    ),
    onClick,
    alignment,
  } = config

  const handleClick = onClick ? onClick(item) : undefined

  const styles: CSSProperties = {
    flex: getCellFlex(config),
  }

  return (
    <div
      className={cx(
        'py-2.5 whitespace-nowrap overflow-hidden text-ellipsis',
        'flex items-center',
        getAlignment(config.alignment),
        config.className
      )}
      style={styles}
      onClick={
        handleClick ? (e) => handleClick({item, extraProps}, e) : undefined
      }
    >
      {renderer({item, extraProps})}
    </div>
  )
}

interface RowProps<Data, ExtraProps> {
  item: Data
  cells: CellConfig<Data, ExtraProps>[]
  rowClassName?: (item: Data) => string
  onRowClick?: RowClickHandler<Data>
  extraProps?: ExtraProps
}

export const Row = <Data, ExtraProps>({
  item,
  cells,
  rowClassName,
  onRowClick,
  extraProps,
}: RowProps<Data, ExtraProps>): JSX.Element => {
  const handleClick = onRowClick
    ? (e: MouseEvent<HTMLDivElement>) => onRowClick(item, e)
    : undefined

  return (
    <div
      className={cx(
        ROW_STYLES,
        rowClassName ? rowClassName(item) : undefined,
        'border-t even:bg-springWood',
        handleClick ? 'cursor-pointer hover:bg-hawkesBlue' : ''
      )}
      onClick={handleClick}
    >
      {cells.map((config, index) => (
        <Cell key={index} config={config} item={item} extraProps={extraProps} />
      ))}
    </div>
  )
}

type ContentInternalProps<Data, ExtraProps> = ContentProps<Data, ExtraProps>

export const Content = <Data, ExtraProps>({
  data,
  cells,
  rowClassName,
  emptyMessage,
  onRowClick,
  extraProps,
}: ContentInternalProps<Data, ExtraProps>): JSX.Element => {
  if (!data || !data.length) {
    return <div className='h-full grid place-items-center'>{emptyMessage}</div>
  }

  return (
    <>
      {data.map((item, index) => (
        <Row
          key={index}
          item={item}
          cells={cells}
          rowClassName={rowClassName}
          onRowClick={onRowClick}
          extraProps={extraProps}
        />
      ))}
    </>
  )
}
