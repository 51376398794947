import {DocumentDownloader} from 'components/connected'
import {Badge, Button} from 'components/elements'
import type {FinancialExport} from 'types/financialExport'
import {formatDate} from 'utils'

export const FinancialExportItem = ({
  data: {
    exported_at,
    created_by,
    first_contract_at,
    last_contract_at,
    file,
    is_preview,
  },
}: {
  data: FinancialExport
}) => (
  <div className='grid grid-cols-[1fr_auto] even:bg-desertStorm px-2.5'>
    <div className='py-1.5 px-2.5'>
      <div>
        <span>{formatDate(exported_at, 'DateShortWithTime')}</span>
        {is_preview && (
          <>
            {' '}
            <Badge color='info'>Preview</Badge>
          </>
        )}
      </div>
      <div className='text-secondaryTextColor'>
        {`Created by: ${created_by?.full_name ?? 'Unkown'}`}
      </div>
      <div className='text-secondaryTextColor'>
        {first_contract_at &&
          `From: ${formatDate(first_contract_at, 'DateShortWithTime')}`}
        {first_contract_at && last_contract_at && <span> - </span>}
        {last_contract_at &&
          `To: ${formatDate(last_contract_at, 'DateShortWithTime')}`}
      </div>
    </div>

    <div className='grid place-items-center px-2.5'>
      <DocumentDownloader directFileUrl={file.file_url}>
        <Button icon='download' label='download' theme='secondary' />
      </DocumentDownloader>
    </div>
  </div>
)
