import {useSelector} from 'react-redux'
import type {EmailStore, RootStore} from 'store/types'

export const useEmail = () => {
  const {visible, content, meta, loading, sending, errors} = useSelector<
    RootStore,
    EmailStore
  >((root) => root.email)

  return {
    visible: visible,
    values: {
      ...content,
    },
    ui: {
      headerPrimary: meta.headerPrimary,
      headerSecondary: meta.headerSecondary,
    },
    options: {
      recipients: meta.available_recipients,
      signature: meta.signature,
    },
    actions: meta.actions,
    loading: loading,
    sending: sending,
    errors: errors,
  }
}
