import type {MouseEvent, ReactNode} from 'react'
import {useState} from 'react'
import cx from 'classnames'
import {useFormikContext} from 'formik'
import {Button} from 'components/elements'
import {NumberInput, Checkbox, Toggle} from 'components/form'
import {
  Section,
  Row,
  Column,
  Spacer,
  Cell,
  InlineContent,
  InlineText,
  InlineSpacer,
} from 'containers/Form'
import {showNumber} from 'helpers/currency'
import type {Contract} from 'types/contract'
import styles from './Budget.module.css'
import type {
  RelativeCalculatedCommission,
  AbsoluteCalculatedCommission,
} from './calculator'
import {relativeBudgetCalculator, absoluteBudgetCalculator} from './calculator'
import type {ContractPanelProps} from '../../panels/types'

const numberOptions = {
  thousandsSeparatorSymbol: '.',
  decimalSymbol: ',',
  requireDecimal: true,
  roundWhenMasking: true,
}

interface SectionFeeProps {
  currency: string
  locked: boolean
}
const SectionFee: React.FC<React.PropsWithChildren<SectionFeeProps>> = ({
  currency,
  locked,
}) => (
  <Row>
    <Column>
      <Cell grid='17'>
        <InlineText grid='fit' text='Fee' />
        <InlineSpacer grid='fill' />
        <NumberInput model='fee' disabled={locked} grid='4' />
        <InlineText grid='fit' text={currency} />
      </Cell>

      <Checkbox model='fee_has_vat' disabled={locked} text='VAT' grid='3' />
    </Column>
  </Row>
)

interface SectionCommisionTogglesProps {
  locked: boolean
  onTypeToggle: (absoluteNext: boolean) => void
}
const SectionCommisionToggles: React.FC<
  React.PropsWithChildren<SectionCommisionTogglesProps>
> = ({locked, onTypeToggle}) => {
  return (
    <Row paddingBottom='none'>
      <Column>
        <Cell grid='17'>
          <InlineText text='Commission' />
          <Checkbox
            model='commission_included_in_fee'
            disabled={locked}
            text='Included in fee?'
          />
          <InlineSpacer grid='fill' />
          <Toggle
            model='_commissionAbsolute'
            theme='neutral'
            activeIcon='commission-number'
            inactiveIcon='commission'
            disabled={locked}
            onChange={onTypeToggle}
          />
          <InlineSpacer grid='2' />
        </Cell>
        <Checkbox
          model='commission_has_vat'
          disabled={locked}
          text='VAT'
          grid='3'
        />
      </Column>
    </Row>
  )
}

interface SectionCommissionAbsoluteMediatorProps {
  show: boolean
  currency: string
  locked: boolean
  mediatorCommissions: AbsoluteCalculatedCommission[]
}
const SectionCommissionAbsoluteMediator: React.FC<
  React.PropsWithChildren<SectionCommissionAbsoluteMediatorProps>
> = ({show, currency, locked, mediatorCommissions}) => {
  if (!show) {
    return null
  }
  return (
    <>
      {mediatorCommissions.map(({name}, index) => (
        <Row
          key={name}
          className={styles.detailLine}
          paddingTop='none'
          paddingBottom='none'
        >
          <Column>
            <Cell grid='17'>
              <Cell grid='2' />
              <InlineText grid='fit' text={name} />
              <InlineSpacer grid='fill' />
              <NumberInput
                model={`mediator_commissions.${index}.absolute_commission`}
                disabled={locked}
                grid='4'
              />
              <InlineText grid='fit' text={currency} />
            </Cell>
            <Cell grid='3' />
          </Column>
        </Row>
      ))}
    </>
  )
}
interface SectionCommissionRelativeMediatorProps {
  show: boolean
  currency: string
  locked: boolean
  mediatorCommissions: RelativeCalculatedCommission[]
}
const SectionCommissionRelativeMediator: React.FC<
  React.PropsWithChildren<SectionCommissionRelativeMediatorProps>
> = ({show, currency, locked, mediatorCommissions}) => {
  if (!show) {
    return null
  }
  return (
    <>
      {mediatorCommissions.map(({name, absoluteCommission}, index) => (
        <Row
          key={name}
          className={styles.detailLine}
          paddingTop='none'
          paddingBottom='none'
        >
          <Column>
            <Cell grid='17'>
              <Cell grid='2' />
              <Cell grid='11'>
                <InlineText grid='fit' text={name} />
                <InlineSpacer grid='fill' />
                <NumberInput
                  model={`mediator_commissions.${index}.relative_commission`}
                  disabled={locked}
                  grid='4'
                />
                <InlineText grid='fit' text='%' />
              </Cell>
              <Cell grid='7'>
                <InlineSpacer grid='fill' />
                <InlineText
                  grid='fill'
                  textAlign='right'
                  text={showNumber(absoluteCommission, numberOptions)}
                  className={styles.amount}
                />
                <InlineText grid='fit' text={currency} />
              </Cell>
            </Cell>
            <Cell grid='3' />
          </Column>
        </Row>
      ))}
    </>
  )
}

interface SectionCommissionMainProps {
  showMediatorCommissions: boolean
  toggleMediatorCommissions: (e: MouseEvent) => void
  children: ReactNode | ReactNode[]
}
const SectionCommissionMain: React.FC<
  React.PropsWithChildren<SectionCommissionMainProps>
> = ({showMediatorCommissions, toggleMediatorCommissions, children}) => {
  const classNamesMediatorCommissionsBtn = cx(styles.toggleBtn, {
    [styles.toggleBtnToggled]: showMediatorCommissions,
  })

  return (
    <Row paddingBottom='none'>
      <Column>
        <Cell grid='17'>
          <InlineContent grid='2'>
            <Button
              icon='chevron-right'
              theme='light'
              round
              onClick={toggleMediatorCommissions}
              className={classNamesMediatorCommissionsBtn}
            />
          </InlineContent>
          {children}
        </Cell>
        <Cell grid='3' />
      </Column>
    </Row>
  )
}

interface SectionCommissionInternalProps {
  show: boolean
  currency: string
  internalRelativeCommission?: number
  internalAbsoluteCommission: number
}
const SectionCommissionInternal: React.FC<
  React.PropsWithChildren<SectionCommissionInternalProps>
> = ({
  show,
  currency,
  internalRelativeCommission,
  internalAbsoluteCommission,
}) => {
  if (!show) {
    return null
  }
  return (
    <Row className={styles.detailLine} paddingTop='none' paddingBottom='none'>
      <Column>
        <Cell grid='17'>
          <Cell grid='2' />
          {internalRelativeCommission !== undefined && (
            <>
              <Cell grid='11'>
                <InlineText grid='fit' text='Internal commission' />
                <InlineSpacer grid='fill' />
                <InlineText
                  grid='fit'
                  text={showNumber(internalRelativeCommission, numberOptions)}
                  className={styles.percentage}
                />
                <InlineText grid='fit' text='%' />
              </Cell>
              <Cell grid='7'>
                <InlineSpacer grid='fill' />
                <InlineText
                  grid='fit'
                  text={showNumber(internalAbsoluteCommission, numberOptions)}
                  className={styles.amount}
                />
                <InlineText grid='fit' text={currency} />
              </Cell>
            </>
          )}
          {internalRelativeCommission === undefined && (
            <>
              <InlineText grid='fit' text='Internal commission' />
              <InlineSpacer grid='fill' />
              <InlineText
                grid='fit'
                text={showNumber(internalAbsoluteCommission, numberOptions)}
                className={styles.amount}
              />
              <InlineText grid='fit' text={currency} />
            </>
          )}
        </Cell>
        <Cell grid='3' />
      </Column>
    </Row>
  )
}

interface SectionVatProps {
  currency: string
  vat: number
  vatFee: number
  vatCommission: number
}
const SectionVat: React.FC<React.PropsWithChildren<SectionVatProps>> = ({
  currency,
  vat,
  vatFee,
  vatCommission,
}) => {
  const [showVATDetails, setShowVATDetails] = useState(false)

  const classNamesVATBtn = cx(styles.toggleBtn, {
    [styles.toggleBtnToggled]: showVATDetails,
  })

  const toggleVATDetails = (e: MouseEvent) => {
    e.preventDefault()
    setShowVATDetails((old) => !old)
  }

  return (
    <>
      <Row paddingBottom='none'>
        <Column>
          <Cell grid='17'>
            <InlineContent grid='2'>
              <Button
                icon='chevron-right'
                theme='light'
                round
                onClick={toggleVATDetails}
                className={classNamesVATBtn}
              />
            </InlineContent>
            <InlineText grid='fit' text='VAT' />
            <InlineSpacer grid='fill' />
            <InlineText
              grid='fit'
              text={showNumber(vat, numberOptions)}
              className={styles.amount}
            />
            <InlineText grid='fit' text={currency} />
          </Cell>
          <Cell grid='3' />
        </Column>
      </Row>

      {showVATDetails && (
        <Row
          className={styles.detailLine}
          paddingTop='none'
          paddingBottom='none'
        >
          <Column>
            <Cell grid='17'>
              <Cell grid='2' />
              <InlineText grid='fit' text='VAT (Fee)' />
              <InlineSpacer grid='fill' />
              <InlineText
                grid='fit'
                text={showNumber(vatFee, numberOptions)}
                className={styles.amount}
              />
              <InlineText grid='fit' text={currency} />
            </Cell>
            <Cell grid='3' />
          </Column>
        </Row>
      )}

      {showVATDetails && (
        <Row
          className={styles.detailLine}
          paddingTop='none'
          paddingBottom='none'
        >
          <Column>
            <Cell grid='17'>
              <Cell grid='2' />
              <InlineText grid='fit' text='VAT (Commission)' />
              <InlineSpacer grid='fill' />
              <InlineText
                grid='fit'
                text={showNumber(vatCommission, numberOptions)}
                className={styles.amount}
              />
              <InlineText grid='fit' text={currency} />
            </Cell>
            <Cell grid='3' />
          </Column>
        </Row>
      )}
    </>
  )
}

interface SectionTotalProps {
  currency: string
  feeAct: number
  total: number
}
const SectionTotal: React.FC<React.PropsWithChildren<SectionTotalProps>> = ({
  currency,
  feeAct,
  total,
}) => (
  <>
    <Row>
      <Column>
        <Cell grid='17'>
          <InlineText
            grid='fit'
            text='Act fee (without VAT)'
            className={cx(styles.text, styles.secondary)}
          />
          <InlineSpacer grid='fill' />
          <InlineText
            grid='fit'
            text={showNumber(feeAct, numberOptions)}
            className={cx(styles.amount, styles.secondary)}
          />
          <InlineText grid='fit' text={currency} className={styles.secondary} />
        </Cell>
        <Cell grid='3' />
      </Column>
    </Row>

    <Spacer paddingTop='normal' />

    <Row className={styles.total}>
      <Column>
        <Cell grid='17'>
          <InlineText grid='fit' text='Total' className={styles.text} />
          <InlineSpacer grid='fill' />
          <InlineText
            grid='fit'
            text={showNumber(total, numberOptions)}
            className={styles.amount}
          />
          <InlineText grid='fit' text={currency} />
        </Cell>
        <Cell grid='3' />
      </Column>
    </Row>
  </>
)

export const Budget: React.FC<React.PropsWithChildren<ContractPanelProps>> = ({
  locked,
}) => {
  const {values, setFieldValue} = useFormikContext<Contract>()
  const currency = values.currency ?? 'N/A'

  const [showMediatorCommissions, setShowMediatorCommissions] = useState(false)
  const toggleMediatorCommissions = (e: MouseEvent) => {
    e.preventDefault()
    setShowMediatorCommissions((old) => !old)
  }

  if (values._commissionAbsolute) {
    const {
      relativeCommission,
      mediatorCommissions,
      internalAbsoluteCommission,
      vatFee,
      vatCommission,
      vat,
      feeAct,
      total,
    } = absoluteBudgetCalculator(values)

    const handleTypeToggle = () => {
      setFieldValue('relative_commission', relativeCommission)
      setFieldValue('absolute_commission', null)
      setFieldValue(
        'mediator_commissions',
        mediatorCommissions.map(({name, relativeCommission}) => ({
          name,
          relative_commission: relativeCommission,
          absolute_commission: null,
        }))
      )
    }

    return (
      <Section label='Budget'>
        <SectionFee currency={currency} locked={locked} />
        <SectionCommisionToggles
          locked={locked}
          onTypeToggle={handleTypeToggle}
        />
        <SectionCommissionMain
          showMediatorCommissions={showMediatorCommissions}
          toggleMediatorCommissions={toggleMediatorCommissions}
        >
          <InlineText grid='fit' text='Total commission' />
          <InlineSpacer grid='fill' />
          <NumberInput model='absolute_commission' disabled={locked} grid='4' />
          <InlineText grid='fit' text={currency} />
        </SectionCommissionMain>
        <SectionCommissionAbsoluteMediator
          show={showMediatorCommissions}
          currency={currency}
          locked={locked}
          mediatorCommissions={mediatorCommissions}
        />
        <SectionCommissionInternal
          show={showMediatorCommissions && mediatorCommissions.length > 0}
          currency={currency}
          internalAbsoluteCommission={internalAbsoluteCommission}
        />
        <SectionVat
          currency={currency}
          vat={vat}
          vatFee={vatFee}
          vatCommission={vatCommission}
        />
        <SectionTotal currency={currency} feeAct={feeAct} total={total} />
      </Section>
    )
  } else {
    const {
      absoluteCommission,
      mediatorCommissions,
      internalRelativeCommission,
      internalAbsoluteCommission,
      vatFee,
      vatCommission,
      vat,
      feeAct,
      total,
    } = relativeBudgetCalculator(values)

    const handleTypeToggle = () => {
      setFieldValue('absolute_commission', absoluteCommission)
      setFieldValue('relative_commission', null)
      setFieldValue(
        'mediator_commissions',
        mediatorCommissions.map(({name, absoluteCommission}) => ({
          name,
          absolute_commission: absoluteCommission,
          relative_commission: null,
        }))
      )
    }

    return (
      <Section label='Budget'>
        <SectionFee currency={currency} locked={locked} />
        <SectionCommisionToggles
          locked={locked}
          onTypeToggle={handleTypeToggle}
        />
        <SectionCommissionMain
          showMediatorCommissions={showMediatorCommissions}
          toggleMediatorCommissions={toggleMediatorCommissions}
        >
          <Cell grid='11'>
            <InlineText grid='fit' text='Total commission' />
            <InlineSpacer grid='fill' />
            <NumberInput
              model='relative_commission'
              disabled={locked}
              grid='4'
            />
            <InlineText grid='fit' text='%' />
          </Cell>
          <Cell grid='7'>
            <InlineSpacer grid='fill' />
            <InlineText
              grid='fit'
              text={showNumber(absoluteCommission, numberOptions)}
              className={styles.amount}
            />
            <InlineText grid='fit' text={currency} />
          </Cell>
        </SectionCommissionMain>
        <SectionCommissionRelativeMediator
          show={showMediatorCommissions}
          currency={currency}
          locked={locked}
          mediatorCommissions={mediatorCommissions}
        />
        <SectionCommissionInternal
          show={showMediatorCommissions && mediatorCommissions.length > 0}
          currency={currency}
          internalRelativeCommission={internalRelativeCommission}
          internalAbsoluteCommission={internalAbsoluteCommission}
        />
        <SectionVat
          currency={currency}
          vat={vat}
          vatFee={vatFee}
          vatCommission={vatCommission}
        />
        <SectionTotal currency={currency} feeAct={feeAct} total={total} />
      </Section>
    )
  }
}
