import type {ReactNode} from 'react'
import {useFormikContext} from 'formik'
import {get} from 'lodash'
import {useVenue} from 'api'
import {Icon, Tooltip} from 'components/elements'
import type {InlineContentProps} from 'containers/Form'
import {InlineContent} from 'containers/Form'
import type {Contract} from 'types/contract'
import {hasValue} from './helpers'
import styles from './VenueCapacity.module.css'

export const VenueCapacity: React.FC<
  React.PropsWithChildren<InlineContentProps>
> = (props) => {
  const {values} = useFormikContext<Contract>()
  const venueId = get(values, 'show.stakeholder_venue.department.id')
  const {data: venue} = useVenue(venueId)

  let content: ReactNode = 'Unknown'

  if (venue) {
    const seated = venue.stage_capacity_seated
    const standing = venue.stage_capacity_standing

    const seatedText = hasValue(seated) ? seated : 'N/A'
    const standingText = hasValue(standing) ? standing : 'N/A'

    content = (
      <div>
        <div>Seated capacity: {seatedText}</div>
        <div>Standing capacity: {standingText}</div>
      </div>
    )
  }

  return (
    <InlineContent {...props} aligned>
      <Tooltip content={content} maxWidth={250}>
        <div className={styles.capacityTooltip}>
          <Icon icon='info' />
        </div>
      </Tooltip>
    </InlineContent>
  )
}
