import {createQueryKeys} from '@lukemorales/query-key-factory'
import {api} from 'api/api'

export const venues = createQueryKeys('venues', {
  list: {
    queryKey: null,
    queryFn: () => api.venues.list(),
  },
  show: (id: number) => ({
    queryKey: [id],
    queryFn: () => api.venues.show(id),
  }),
})
