import {takeLatest, select, put, call} from 'redux-saga/effects'
import {toastrActions} from 'features/toastr'
import {sagaFetch, standardErrorAction} from 'helpers/saga'
import {CREATE, CREATE_SUCCESS, CREATE_FAILURE, SET_ERROR} from '../constants'

export function* createEmailTemplate(action) {
  try {
    const {url, content, meta: metaState} = yield select(({email}) => email)

    if (!content.recipients || !content.recipients.length) {
      throw new Error('Email: No recipients')
    }

    const payload = yield call(sagaFetch, url, 'post', content)
    yield put(toastrActions.successToast('Email sent successfully'))

    if (metaState && metaState.successCallback) {
      yield call(metaState.successCallback, payload)
    }

    yield put({type: CREATE_SUCCESS, payload, meta: action.meta})
  } catch (e) {
    const {meta: metaState} = yield select(({email}) => email)

    if (metaState && metaState.failureCallback) {
      yield call(metaState.failureCallback, e)
    }

    yield put(standardErrorAction(CREATE_FAILURE, e, action.meta))

    if (e.message === 'Email: No recipients') {
      yield put({type: SET_ERROR, payload: {recipients: 'No recipients'}})
    } else {
      yield put(toastrActions.errorToast('Email could not be sent'))
      throw e
    }
  }
}

export function* createSaga() {
  yield takeLatest(CREATE, createEmailTemplate)
}
