import {useDispatch, useSelector} from 'react-redux'
import {useActs} from 'api'
import {
  filterMyActs,
  isBookingViewsWithAct,
  isBookingViewsWithShowKind,
} from 'helpers/booking'
import {setAct, setShowKind} from 'store/booking/actions'
import type {BookingStoreViewsData} from 'store/booking/types'
import type {RootStore} from 'store/types'
import {useCurrentView} from '../hooks'

export const useShowKind = () => {
  const dispatch = useDispatch()
  const view = useCurrentView()
  const bookingView = useSelector<RootStore, BookingStoreViewsData>(
    ({booking}) => booking.views[view]
  )

  const showKind = isBookingViewsWithShowKind(bookingView)
    ? bookingView.showKind
    : undefined

  return {
    showKind,
    setShowKind: (kind: string) => dispatch(setShowKind({kind, view})),
  }
}

export const useSingleActPriorityMenu = () => {
  const dispatch = useDispatch()
  const view = useCurrentView()
  const bookingView = useSelector<RootStore, BookingStoreViewsData>(
    ({booking}) => booking.views[view]
  )
  const {data: acts = []} = useActs()

  const value = isBookingViewsWithAct(bookingView)
    ? bookingView.act ?? undefined
    : undefined
  const options = filterMyActs(acts).map((act) => ({
    label: act.name,
    value: act.id,
  }))

  return {
    options,
    value,
    onChange: (id: number) => dispatch(setAct({id, view})),
  }
}
