import {useCallback, useEffect, useRef, useState} from 'react'
import {getTime} from 'date-fns'
import parseMs from 'parse-ms'
import styled from 'styled-components'
import {useRefreshTokenMutation} from 'api'
import {Spinner} from 'components/elements'
import {useAuth} from 'hooks/useAuth'
import {AUTH_RENEW_NOTICE_DELTA, AUTH_RENEW_WARNING_DELTA} from 'options/auth'
import {LoginForm} from 'shared/AuthHandler/LoginForm'
import {colors, effects, spacing} from 'styles/variables'

export type AuthHandlerStatus = 'expired' | 'warning' | 'notice' | 'hidden'

interface StyleProps {
  status: AuthHandlerStatus
}

const Root = styled.div<StyleProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99998;

  ${({status}) =>
    ['hidden', 'notice'].includes(status) &&
    `
    pointer-events: none;`}
`

const Overlay = styled.div<StyleProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background: transparent;
  transition: background ${effects.transitionTime};

  ${({status}) =>
    status === 'warning' && `background: hsla(30, 74%, 95%, 0.6);`}

  ${({status}) =>
    status === 'expired' && `background: rgba(242, 241, 240, 0.85);`}
`

const Content = styled.div<StyleProps>`
  position: absolute;
  z-index: 100000;
  pointer-events: all;
  transition: all ${effects.transitionTime};
  transform: translate3d(0, 0, 0);

  ${({status}) =>
    status === 'notice' &&
    `
    bottom: 0;
    right: 0;`}

  ${({status}) =>
    ['warning', 'expired'].includes(status) &&
    `
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);`}

  ${({status}) =>
    status === 'expired' &&
    `
    padding: ${spacing.u5};
    background: ${colors.ebonyClay};
    box-shadow: ${effects.eventPopoverBoxshadow};`}
`

const NoticeContainer = styled.div<StyleProps>`
  margin: ${spacing.u5};
  padding: ${spacing.u5};
  box-shadow: ${effects.eventPopoverBoxshadow};
  cursor: pointer;
  text-align: center;
  color: ${colors.midGray};
  transition: background ${effects.transitionTime};

  ${({status}) =>
    status === 'notice' &&
    `
    background: ${colors.casablanca};
    color: ${colors.desertStorm};

    &:hover {
      color: ${colors.white};
    }`}

  ${({status}) =>
    status === 'warning' &&
    `
    padding: ${spacing.u5};
    background: ${colors.amaranth};
    color: ${colors.desertStorm};

    &:hover {
      color: ${colors.white};
    }`}
`

function formattedExpiresIn(ttl: number | undefined) {
  if (!ttl) {
    return ''
  }

  const {minutes, seconds} = parseMs(ttl * 1000)
  return `${minutes} minute${minutes !== 1 ? 's' : ''} and ${seconds} second${
    seconds !== 1 ? 's' : ''
  }`
}

const useAuthHandler = () => {
  const timer = useRef<NodeJS.Timeout | null>(null)
  const [ttl, setTtl] = useState<number>()
  const [status, setStatus] = useState<AuthHandlerStatus>('hidden')
  const {token} = useAuth()
  const {mutate: refresh, isLoading} = useRefreshTokenMutation()

  useEffect(() => {
    if (ttl === undefined) {
      return
    }
    let newStatus: AuthHandlerStatus

    if (ttl <= 0) {
      newStatus = 'expired'
    } else if (ttl <= AUTH_RENEW_WARNING_DELTA) {
      newStatus = 'warning'
    } else if (ttl <= AUTH_RENEW_NOTICE_DELTA) {
      newStatus = 'notice'
    } else {
      newStatus = 'hidden'
    }

    if (status !== newStatus) {
      setStatus(newStatus)
    }
  }, [status, ttl])

  const calculateTtl = useCallback(() => {
    if (!token.exp) {
      return
    }

    const now = Math.floor(getTime(new Date()) / 1000)
    const ttl = token.exp - now

    setTtl(ttl)
  }, [token.exp])

  const startTimer = useCallback(() => {
    if (timer.current) {
      clearInterval(timer.current)
    }

    timer.current = setInterval(calculateTtl, 1000)
  }, [calculateTtl])

  useEffect(() => {
    startTimer()
  }, [token.exp, startTimer])

  return {
    status,
    username: token.username,
    exp: token.exp,
    ttl,
    refreshing: isLoading,
    refresh,
    formattedExpiresIn,
  }
}

export const AuthHandler = () => {
  const {status, username, refresh, refreshing, ttl} = useAuthHandler()

  if (status === 'hidden') {
    return null
  }

  return (
    <Root status={status}>
      <Overlay status={status} />
      <Content status={status}>
        {status === 'expired' && <LoginForm username={username} />}
        {status !== 'expired' && (
          <NoticeContainer status={status} onClick={() => refresh()}>
            {refreshing && <Spinner />}
            <div>Your session expires in {formattedExpiresIn(ttl)}</div>
            <div>
              <strong>Click here to stay logged in</strong>
            </div>
          </NoticeContainer>
        )}
      </Content>
    </Root>
  )
}
