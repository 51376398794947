import {compact, isObject} from 'lodash'
import qs from 'qs'
import {validateToken} from 'lib/auth'
import type {FetchOptions, HttpMethod} from './types'
const apiRootRegexp = new RegExp(`^${import.meta.env.VITE_API_ROOT}`)

// You can override the api root
export const getApiRoot = (): string => {
  return import.meta.env.VITE_API_ROOT
}

export const getPathname = (absoluteUrl: string): string => {
  const host = getApiRoot()
    .replace(/\/$/, '')
    .replace(/^\//, '') // Remove leading slash
    .replace(/\/+/g, '/') // Remove double slashes

  return absoluteUrl.replace(host, '')
}

export function getJWTToken(): string | null {
  return localStorage.getItem('token')
}

export function allowBody(method: HttpMethod): boolean {
  return method !== 'GET'
}

export function sanitizeUri(raw: string): string {
  const [uri, params] = raw.split('?')

  if (params) {
    throw new Error('Fetch: Move query params from url to options')
  }

  return uri
    .replace(/^\//, '') // Remove leading slash
    .replace(/\/+/g, '/') // Remove double slashes
    .replace(/\/?$/, '/') // Add missing trailing slash
}

export function buildUri(rawUri: string, options: FetchOptions) {
  if (!rawUri) {
    throw new Error('Fetch: Uri should be present')
  }

  if (!options) {
    throw new Error('Fetch: Options should be present')
  }

  // Allow passing absolute uris right through
  if (rawUri.match(apiRootRegexp)) {
    return rawUri
  }

  const uri = getApiRoot() + sanitizeUri(rawUri)

  let queryParams
  if (options?.queryParams) {
    if (!isObject(options.queryParams)) {
      throw new Error('Fetch: Query params should be object')
    }
    queryParams = qs.stringify(options.queryParams, {skipNulls: true})
  }

  return compact([uri, queryParams]).join('?')
}

export async function buildOptions(
  options?: FetchOptions
): Promise<RequestInit> {
  if (!options) {
    throw new Error('Fetch: provide options for buildOptions')
  }

  const method = options.method

  const headers = new Headers(
    Object.assign(
      {},
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      options.headers
    )
  )

  if (options.auth !== false) {
    const token = await validateToken()
    headers.append('Authorization', `JWT ${token}`)
  }

  const rtn: RequestInit = {
    method,
    headers,
  }

  if (allowBody(method)) {
    rtn.body = options.body ? JSON.stringify(options.body) : undefined
  }

  return rtn
}
