import {useEffect, useState} from 'react'
import {EditorState, convertToRaw, ContentState} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import {Editor} from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './wysiwyg.module.css'

interface BodyProps {
  initialValue: string
  onChange: (value: string) => void
}

export const Body = ({initialValue, onChange}: BodyProps) => {
  const [initialized, setInitialized] = useState<boolean>(false)
  const [editorState, setEditorState] = useState<EditorState | undefined>()

  useEffect(() => {
    if (initialized) {
      return
    }
    let contentBlock
    if (initialValue) {
      contentBlock = htmlToDraft(initialValue)
    }
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      setInitialized(true)
      setEditorState(EditorState.createWithContent(contentState))
    }
  }, [initialized, initialValue])

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState)
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  if (!initialized) {
    return null
  }

  return (
    <Editor
      editorState={editorState}
      wrapperClassName='demo-wrapper'
      editorClassName='demo-editor'
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: ['inline', 'list', 'emoji'],
        inline: {
          options: ['bold', 'italic', 'underline'],
        },
      }}
    />
  )
}
