import {createReducer} from '@reduxjs/toolkit'
import {findIndex, isFinite, map, reject} from 'lodash'
import type {PlannerEvent, PlannerEventAsyncResponse} from 'types/planner'
import {
  createPlannerSuccess,
  deletePlannerSuccess,
  fetchPlannersFailure,
  fetchPlannersSuccess,
  updatePlannerSuccess,
  listDestroyEvent,
  listUpdateEvent,
} from '../actions'
import type {PlannerStoreList} from '../types'

function convertResponse(data: PlannerEventAsyncResponse): PlannerEvent {
  const {stakeholder_act, ...rest} = data

  return {
    ...rest,
    act: {
      id: stakeholder_act.department.id,
      name: stakeholder_act.department.name,
    },
  } as PlannerEvent
}

export const initialState = []

export const listReducer = createReducer<PlannerStoreList>(
  initialState,
  (builder) => {
    builder
      .addCase(fetchPlannersSuccess, (s, a) => {
        return reject(a.payload, (item) => {
          // TODO: https://gitlab.com/whnue/whnue-api/issues/350
          // Guard for EVENT_TYPE_SHOW without attached contracts
          if (item.type === 'EVENT_TYPE_SHOW' && !isFinite(item.contract_id)) {
            return true
          }
          return false
        })
      })
      .addCase(fetchPlannersFailure, () => initialState)
      .addCase(createPlannerSuccess, (s, a) => [
        ...s,
        convertResponse(a.payload),
      ])
      // [CONTRACT_CREATE_SUCCESS]: (state, action) => [...state, action.payload],
      .addCase(updatePlannerSuccess, (state, action) =>
        map(state, (item) => {
          if (item.id === action.payload.id) {
            return convertResponse(action.payload)
          }
          return item
        })
      )
      .addCase(deletePlannerSuccess, (state, action) =>
        reject(state, (item) => item.id === action.payload)
      )

      .addCase(listUpdateEvent, (state, action) => {
        const {id} = action.payload
        const eventIndex = findIndex(state, (event) => event.id === id)

        if (eventIndex <= -1) {
          return [...state, action.payload]
        } else {
          return [
            ...state.slice(0, eventIndex),
            action.payload,
            ...state.slice(eventIndex + 1),
          ]
        }
      })
      .addCase(listDestroyEvent, (state, action) => {
        const {id} = action.payload
        const eventIndex = findIndex(state, (event) => event.id === id)

        if (eventIndex <= -1) {
          return state
        } else {
          return [...state.slice(0, eventIndex), ...state.slice(eventIndex + 1)]
        }
      })
  }
)
