import {CountrySelect, Input, Select} from 'components/form'
import {CompoundInput, CompoundInputLine} from 'containers/Form'
import type {ItemListRendererProps} from '../util/ItemList/ItemListRow'

export const PhoneNumber: React.FC<
  React.PropsWithChildren<ItemListRendererProps>
> = ({model, disabled}) => {
  return (
    <CompoundInput>
      <CompoundInputLine>
        <Select
          theme='form'
          model={`${model}.number_type`}
          disabled={disabled}
          placeholder='Type'
          options={[
            {label: 'Mobile', value: 'mobi'},
            {label: 'Landline', value: 'land'},
            {label: 'Fax', value: 'fax'},
          ]}
          grid='5'
        />
        <CountrySelect
          kind='phonePrefix'
          theme='form'
          model={`${model}.value.country_code`}
          disabled={disabled}
          placeholder='Country code'
          grid='8'
        />
        <Input
          model={`${model}.value.number`}
          disabled={disabled}
          kind='text'
          placeholder='Phone number'
          grid='7'
        />
      </CompoundInputLine>
    </CompoundInput>
  )
}
