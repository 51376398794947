import {useCallback} from 'react'
import type {ButtonProps, IconName} from 'components/elements'
import {Button} from 'components/elements'
import {useEmailer} from 'hooks/useEmailer'
import type {ContractStatusType} from 'types/contract'
import {
  useContractActions,
  useExportFailure,
  useReloadContract,
} from '../helpers'
import type {ContractEmailAction} from '../types'

function getEmailHeader(
  action: ContractEmailAction,
  status: ContractStatusType | undefined
): string {
  console.log(action, status)
  return 'Send contract'
}

function getEmailBtnLabel(action: ContractEmailAction): string {
  switch (action) {
    case 'sendSigningLink':
      return 'Send signing link'
    case 'sendPdfAndMarkAsSent':
      return 'Send and mark as sent'
    case 'sendPdf':
      return 'Send draft'
  }
}

function getBtnIcon(action: ContractEmailAction): IconName {
  switch (action) {
    case 'sendSigningLink':
      return 'link'
    default:
      return 'mail'
  }
}

function getBtnLabel(action: ContractEmailAction): string {
  switch (action) {
    case 'sendSigningLink':
      return 'Send signing link'
    case 'sendPdfAndMarkAsSent':
      return 'Send PDF'
    case 'sendPdf':
      return 'Send draft PDF'
  }
}

interface EmailContractProps extends ButtonProps {
  action: ContractEmailAction
}

export const EmailContract = ({action, ...restProps}: EmailContractProps) => {
  const {compose, isInitializing} = useEmailer()
  const {
    id,
    status,
    disabled,
    draftEmailUrl,
    finalEmailUrl,
    signingInvitationUrl,
    emailHeader,
  } = useContractActions()
  const {reloadContract} = useReloadContract()
  const {handleExportFailure} = useExportFailure()

  const handleClick = useCallback(() => {
    let url: string | undefined

    if (!id) {
      throw new Error('EmailContract: Missing ID')
    }

    if (action === 'sendSigningLink') {
      if (!signingInvitationUrl) {
        throw new Error('EmailContract: Missing signingInvitationUrl')
      }
      url = signingInvitationUrl
    } else if (action === 'sendPdfAndMarkAsSent') {
      if (!finalEmailUrl) {
        throw new Error('EmailContract: Missing finalEmailUrl')
      }
      url = finalEmailUrl
    }
    if (action === 'sendPdf') {
      if (!draftEmailUrl) {
        throw new Error('EmailContract: Missing draftEmailUrl')
      }
      url = draftEmailUrl
    }

    if (!url) {
      throw new Error('EmailContract: Missing url')
    }

    compose({
      url,
      headerPrimary: getEmailHeader(action, status),
      headerSecondary: emailHeader,
      failureCallback: (e: Error) => {
        handleExportFailure(e)
      },
      successCallback: () => {
        reloadContract()
      },
      sendBtnText: getEmailBtnLabel(action),
    })
  }, [
    compose,
    emailHeader,
    draftEmailUrl,
    finalEmailUrl,
    signingInvitationUrl,
    id,
    status,
    reloadContract,
    handleExportFailure,
    action,
  ])

  if (!id) {
    return null
  }

  return (
    <Button
      {...restProps}
      onClick={handleClick}
      disabled={disabled}
      hasActivity={isInitializing}
      icon={getBtnIcon(action)}
      label={getBtnLabel(action)}
    />
  )
}
