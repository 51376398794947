import type {ChangeEvent} from 'react'
import {forwardRef, useRef, useState} from 'react'
import cx from 'classnames'
import type {ButtonProps, IconProps} from 'components/elements'
import {Button, Icon, SpinnerInline} from 'components/elements'
import styles from './Search.module.css'

const messages = {
  placeholderText: 'Search...',
}

type SearchTheme = 'standard' | 'bigSearch'

const getIconProps = (theme: SearchTheme): Partial<IconProps> => {
  switch (theme) {
    case 'standard':
      return {
        size: 14,
      }
    case 'bigSearch':
      return {
        size: 20,
      }
  }
}

const getCloseButtonProps = (theme: SearchTheme): Partial<ButtonProps> => {
  switch (theme) {
    case 'standard':
      return {
        round: true,
        size: 'small',
        icon: 'close',
      }
    case 'bigSearch':
      return {
        theme: 'text',
        label: 'clear',
      }
  }
}

interface SearchProps {
  value: string
  onChange: (value: string) => void
  className?: string
  placeholder?: string
  onFocus?: () => void
  onBlur?: () => void
  isFetching?: boolean
  autoFocus?: boolean
  keepClearButton?: boolean
  theme?: SearchTheme
}

export const Search = forwardRef<HTMLInputElement, SearchProps>(
  (
    {
      value,
      onChange,
      className,
      placeholder = messages.placeholderText,
      onFocus,
      onBlur,
      isFetching,
      autoFocus,
      keepClearButton,
      theme = 'standard',
    },
    ref
  ) => {
    const [searchActive, setSearchActive] = useState(false)
    const _searchInput = useRef<HTMLInputElement>(null)

    const clearSearch = () => onChange('')

    const handleFocus = () => {
      setSearchActive(true)
      if (onFocus) {
        onFocus()
      }
    }

    const handleBlur = () => {
      setSearchActive(false)
      if (onBlur) {
        onBlur()
      }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
      onChange(event.target.value)

    const handleWrapperClick = () => {
      if (_searchInput.current) {
        _searchInput.current.focus()
      }
    }

    return (
      <section
        ref={ref}
        className={cx(
          styles.root,
          `theme-${theme}`,
          {
            searching: isFetching,
            searchActive: searchActive,
            alwaysShowClearButton: keepClearButton,
            clearButtonActive: !!value,
          },
          className
        )}
      >
        <section className={styles.wrapper} onClick={handleWrapperClick}>
          <Icon
            icon='search'
            {...getIconProps(theme)}
            className={styles.icon}
          />
          <input
            className={styles.searchInput}
            ref={_searchInput}
            type='text'
            value={value || ''}
            placeholder={placeholder}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            autoFocus={autoFocus}
            autoComplete='off'
          />
          <SpinnerInline className={styles.spinner} />
          <Button
            {...getCloseButtonProps(theme)}
            disabled={!value}
            onClick={clearSearch}
            className={styles.clearButton}
          />
        </section>
      </section>
    )
  }
)

Search.displayName = 'Search'
